import React from "react";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Loader, Text } from "@mantine/core";
import { GetNullTransactionData, TransactionDeleteApi } from "../../../utils/TransactionSlices/Transaction";
import JournalEntryForm from "./JournalEntryForm";

export function VouEntAct(props) {

  const data = JSON.parse(props);
  console.log(data, "VouEntAct--");

  if (data?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{data?.text}</Text>,
        MAction: true,
        MSize: "98%",
        MBody: (i) => <JournalEntryForm obj={data} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  }
     else if (data?.id && data?.p0 == "E") {
      Store.dispatch(
        ModalFunction({
          onclose: () => {},
          MTitle: <Text fw={700}>{data?.text}</Text>,
          MAction: true,
          MSize: "98%",
          MBody: (i) => <JournalEntryForm obj={data} index={i} />,
          MClose: true,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
          onClickCloseButton: () => {
            Promise.resolve(Store.dispatch(GetNullTransactionData()));
          },
        })
      );
    }
    else if (data.p0 === "D" && data.id) {
      if (window.confirm("Confirm to Delete ?")) {
        Store.dispatch(TransactionDeleteApi(data));
      }
    } else if (data.p0 === "U" && data?.id) {
      console.log(data?.id, "data?.id")
  
      // UserField
      Store.dispatch(
        ModalFunction({
          MTitle: <Text fw={700}>{data?.text}</Text>,
          MAction: true,
          MSize: "50%",
          // position:'right',
          MBody: (i) => <JournalEntryForm obj={data} index={i} />,
          MClose: true,
          onclose: () => {},
          onClickCloseButton: () => {
            Promise.resolve(Store.dispatch(GetNullTransactionData()));
          },
        })
      );
    }
}
