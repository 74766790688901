import React, { useEffect, useRef, useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { Button, Grid, Group } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import GlobalClass from '../../utils/GlobalClass';
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice';
import { useDispatch, useSelector } from 'react-redux';

const OSTableUserField = (props) => {

    const { T02UF, T02ObjSend, setT02Objsend,DrawerOBJ,setDrawerOBJ,PageObj} = props;
    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

  const [UserFieldObj, SetUserFieldObj]=useState(null);
  const userFieldRef = useRef(null);

  const dispatch = useDispatch();
  const map = new Map();

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate1 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
};

    useEffect(()=>{
        if(T02ObjSend){
            SetUserFieldObj(T02ObjSend)
        }
    },[T02ObjSend])

    useEffect(() => {
    
        const formElement = userFieldRef.current;
        if (formElement) {
          formElement.addEventListener('keypress', (e)=>GlobalClass.formNextInput(e,userFieldRef));
        }
        return () => {
          if (formElement) {
            formElement.removeEventListener('keypress', (e)=>GlobalClass.formNextInput(e,userFieldRef));
          }
        };
      }, [userFieldRef]);

      useEffect(() => {
        if (T02UF && PageObj?.p0 == "E") {
          T02UF?.forEach((item) => {
            if (item?.S13F14 == "PLPOPUP") {
              const key = seperate(item.S13F09)[1]; // Key
              const value = UserFieldObj?.[item.S13F02]; // Value
              if (map.has(key)) {
                // If it exists, add the value to the set/array of values for that key
                map.get(key).add(value); // Use a Set to ensure uniqueness of values
              } else {
                map.set(key, new Set([value]));
              }
            }
          });
    
          if (map?.size > 0) {
            map.forEach((value, key) => {
              const dyValue = Array.from(value).length
                ? "'" + Array.from(value).join("', '") + "'"
                : "";
              setTimeout(() => {
                Promise.resolve(()=>{
                  dispatch(
                  GetAccountList({
                    id: key,
                    name: "",
                    type: "A",
                    p0: key,
                    p1: key,
                    p2: "",
                    p3: "",
                    cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                  })
                );}).then(()=>{
                  dispatch(GetTMFormat({
                    "id": key,
                    "name": "",
                    "type": "A",
                    "p0": key,
                    "p1": key,
                    "p2": "TFORM0000001",
                    "p3": ""
                  }))
                })
                
              }, [500]);
            });
          }
        }
      }, [T02UF]);

    const UserFieldRender = () => {
        let InputField;
        return (
            T02UF?.map((t,index) => {
                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    autoFocus = {index == 0 ? true : false}
                                    onKeyDown={(e)=>{
                                        GlobalClass.formNextInput(e,userFieldRef);
                                      }}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                autoFocus = {index == 0 ? true : false}
                                onKeyDown={(e)=>{
                                    GlobalClass.formNextInput(e,userFieldRef);
                                  }}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                autoFocus = {index == 0 ? true : false}
                                onKeyDown={(e)=>{
                                    GlobalClass.formNextInput(e,userFieldRef);
                                  }}
                                  value={{
                                    value: UserFieldObj?.[t?.S13F02],
                                    label: accList?.[seperate(t.S13F09)[1]]?.filter((a) => a?.FIELD01 == UserFieldObj?.[t?.S13F02])?.[0]?.[t?.S13F88 == "t" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(t.S13F09)[1]]?.filter((a) => a?.FIELD01 === UserFieldObj?.[t?.S13F02])?.[0] || {}) ? "FIELD02":tmList[seperate(t?.S13F09)[1]]?.oS60?.cAssignFld)],
                                   }}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                autoFocus = {index == 0 ? true : false}
                                onKeyDown={(e)=>{
                                    GlobalClass.formNextInput(e,userFieldRef);
                                  }}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }

    const SaveTableUserField = () => {
        // console.log("UserFieldObj",UserFieldObj);
        Promise.resolve(setT02Objsend(UserFieldObj)).then(() =>(setDrawerOBJ({...DrawerOBJ,open:false})))
    }

    // console.log("T02UF=>", T02UF);
    return (<div ref={userFieldRef}>
        <Grid gutter={4}>
            {
                UserFieldRender()
            }
            <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button
                    onKeyDown={(e)=>{
                        if(e.key =="Enter"){
                          e.preventDefault();
                          SaveTableUserField();
                        }
                      }} 
                    onClick={() => SaveTableUserField()}>Save</Button>
                </Group>
            </Grid.Col>
        </Grid>

    </div>)
}

export default OSTableUserField;