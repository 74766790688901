import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import axios from "axios";
import gensetting from "../gensetting";

export const GetVoucherReportData = createAsyncThunk(
    "PrintVoucherReportSlice/GetVoucherReportData",
    async (obj) => {
        try {
            console.log("PrintVoucherReportSlice/GetVoucherReportData obj =>", obj)
            let param = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: obj?.id ? obj?.id : "",
                cOPara: "",
                cSData: obj?.XmlData,
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            };
            const response = await axios.post(
                GlobalClass.ApiUrl + GlobalClass.GetS92Src,
                param
            );
            console.log("response==>", response?.data);
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)


const PrintVoucherReportSlice = createSlice({
    name: "PrintVoucherReportSlice",
    initialState: {
        PrintVoucherReportData: [],
        isLoading: false,
        hasError: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetVoucherReportData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetVoucherReportData.fulfilled, (state, action) => {
                state.PrintVoucherReportData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetVoucherReportData.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })

    }
})

export default PrintVoucherReportSlice.reducer