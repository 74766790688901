import React from "react";
import StockTransferForm from "./StockTransferForm";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Text } from "@mantine/core";
import {
  GetNullTransactionData,
  TransactionDeleteApi,
} from "../../../utils/TransactionSlices/Transaction";
// import FormatListTable from '../../SMSEmailWpBtn/FormatListTable';

export function VouEntAct(props) {
  const data = JSON.parse(props);
  // console.log("props VouEntAct===============>", data.p0);

  if (data?.p0 == "A") {
    // console.log(data?.p0 , "data?.p0 ")
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{data?.text}</Text>,
        MAction: true,
        MSize: "98%",
        MBody: (i) => <StockTransferForm obj={data} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (data?.id && data?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{data?.text}</Text>,
        MAction: true,
        MSize: "98%",
        MBody: (i) => <StockTransferForm obj={data} index={i} />,
        MClose: true,
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (data.p0 === "D" && data.id) {
    if (window.confirm("Confirm to Delete ?")) {
      Store.dispatch(TransactionDeleteApi(data));
    }
  } else if (data.p0 === "U" && data?.id) {
    console.log(data?.id, "data?.id");

    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{data?.text}</Text>,
        MAction: true,
        MSize: "50%",
        // position:'right',
        MBody: (i) => <StockTransferForm obj={data} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (data.p0 === 'T' && data?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
        Store.dispatch(TransactionDeleteApi(data))
    }
}
}
