import { createSlice } from "@reduxjs/toolkit";


const DataSaveLoadingSlice = createSlice({
    name: "DataSaveLoadingSlice",
    initialState: {
        SaveLoading: false,
        PaginationObj: [],
        ReportIdArray: [],
        TransactionReportIdArray: [],
        CmpSetUpObjSave: null,
        ButtonPannelSave: {},
        ButtonPannelList: {}
    },
    reducers: {
        DataSaveLoading: (state, action) => {
            state.SaveLoading = action.payload
        },
        GetPaginationObj: (state, action) => {
            // console.log("action.payload=>", action.payload)
            state.PaginationObj = { ...state.PaginationObj, [action.payload.id]: action.payload.pagination }
            // console.log("state.PaginationObj=>", state.PaginationObj)
        },
        GetReportIdArray: (state, action) => {
            state.ReportIdArray = action.payload
        },
        GetTransactionReportIdArray: (state, action) => {
            // console.log("GetTransactionReportIdArray", action.payload);
            const newIds = action.payload;

            // console.log("newIds", newIds);

            const uniqueIds = new Set([
                ...state.TransactionReportIdArray,
                ...newIds
            ]);

            // console.log("uniqueIds", uniqueIds);

            return {
                ...state,
                TransactionReportIdArray: Array.from(uniqueIds)
            };
        },
        GetCmpSetUpObj: (state, action) => {
            console.log("GetCmpSetUpObj action =>", action);
            state.CmpSetUpObjSave = action.payload
        },
        GetButtonPannel: (state, action) => {
            console.log("GetButtonPannel =>", action);
            state.ButtonPannelSave = action.payload


            // state.ButtonPannelSave = {...state.ButtonPannelSave,[action.payload.obj.id]:action.payload.Btn}
        },
        GetButtonPannelList: (state, action) => {
            console.log("GetButtonPannel =>", action);
            state.ButtonPannelList = action.payload
        }
    }

})

export const { GetButtonPannelList, GetButtonPannel, DataSaveLoading, GetPaginationObj, GetReportIdArray, GetTransactionReportIdArray, GetCmpSetUpObj } = DataSaveLoadingSlice.actions
export default DataSaveLoadingSlice.reducer