import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import axios from "axios";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const GetUserDetail = createAsyncThunk(
  "UserDetail/GetuserDetail",
  async (props) => {
    // console.log("props", props);
    const OBJ = props?.OBJ;
    // console.log("obj======>", OBJ?.p0);
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ?.p0 ? OBJ?.p0 : "A",
        cOPara: "",
        cCode: OBJ?.id ? OBJ?.id : "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.getUserDetail + "?pa=" + param
      );
      return response.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

export const DeleteUserDetailData = createAsyncThunk(
  "UserDetail/DeleteUserData",
  async (obj) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        cOPara: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.getUserDetail + "?pa=" + param
      );
      // console.log("response  ", response);
      if (response.data.status === "SUCCESS") {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
      
          Store.dispatch(
            GetAccountList({
              id: "14131410",
              name: "User Detail",
              p0: "MT",
              p1: "P_M81",
              p2: "",
              p3: "",
              type: "A",
              pagination: PaginationObj["14131410"],
            })
          );
        
        GlobalClass.Notify("success", "Deleted", "Deleted successfully");
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);

const UserDetailSlice = createSlice({
  name: "UserDetail",
  initialState: {
    UserDetailData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetUserDetail.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.UserSecurityData = [];
      })
      .addCase(GetUserDetail.fulfilled, (state, action) => {
        state.UserDetailData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetUserDetail.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(DeleteUserDetailData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(DeleteUserDetailData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(DeleteUserDetailData.rejected, (state, action) => {
        state.hasError = true;
        state.ErrorMsg = action.payload?.error;
        state.isLoading = false;
      });
  },
});
export default UserDetailSlice.reducer;
