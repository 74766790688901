import { Button, FileInput, Grid, Group, Modal, Paper, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { GetEInvoice } from '../../utils/TransactionSlices/EInvoiceSlice';
import GlobalClass from '../../utils/GlobalClass';
import EWayBill from './EWayBill';
import PLComboBox from '../../PlusComponent/PLComboBox';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import gensetting from '../../utils/gensetting';
import Store from '../../utils/store';
import { GetEWayBill } from '../../utils/TransactionSlices/EWayBillSlice';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import EForm_A from '../../Utility/Files/einvoice/EForm_A.xlsm';
import EForm_B from '../../Utility/Files/einvoice/EForm_B.xlsm';
import EForm_C from '../../Utility/Files/einvoice/EForm_C.xlsm';
import EForm_D from '../../Utility/Files/einvoice/EForm_D.xlsm';
import { GetAccountList } from '../../utils/slices/AccountListSlice';

const EInvoice = (props) => {
    console.log('EInvoice props', props)
    const { obj } = props
    console.log("obj", obj)

    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const { oYear } = useSelector((state) => state?.Language?.General);
    console.log('oYear', oYear)
    const { isLoading, hasError, ErrorMsg } = useSelector(
        (state) => state.EInvoice
    );
    // const isLoading = useSelector((state) => state.EInvoice?.isLoading)
    const EInvoiceData = useSelector((state) => state.EInvoice?.EInvoiceData)
    const { COPE01, COPE02, E01, P_G07DF, P_G07DT, P_STATE, P_UNTS } = EInvoiceData || {}
    console.log('EInvoiceData', EInvoiceData)
    const dispatch = useDispatch();
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
        onClickCloseBtn: null
    }
    console.log("P_UNTS", P_UNTS?.P_PLSTATE?.PLSF06)
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [EinvoiceObj, setEinvoiceObj] = useState({})
    // const [format, setFormat] = useState("")
    const [buttonLoading, setButtonLoading] = useState({
        generate: false,
        upload: false,
    });
    const [jsonData, setJsonData] = useState([])

    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

    useEffect(() => {
        console.log("jsonData", jsonData);
        console.log("P_UNTS", accList["P_UNTS"]);
        console.log("P_STATE", accList["P_STATE"]);
    }, [jsonData, accList["P_UNTS"], accList["P_STATE"]])

    useEffect(() => {
        dispatch(GetAccountList({
            "id": "P_UNTS",
            "name": "",
            "type": "A",
            "p0": "P_UNTS",
            "p1": "P_UNTS",
            "p2": "",
            "p3": ""
        }))
        dispatch(GetAccountList({
            "id": "P_PLSTATE",
            "name": "",
            "type": "A",
            "p0": "P_PLSTATE",
            "p1": "P_PLSTATE",
            "p2": "",
            "p3": ""
        }))
    }, [])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
            // dispatch(ModalDelete(props.index))
        }
    }, [hasError]);

    useEffect(() => {
        dispatch(GetEInvoice())
    }, [])

    useEffect(() => {
        if (E01) {
            setEinvoiceObj(E01)
        }
    }, [E01])

    // console.log('EinvoiceObj', EinvoiceObj)
    // const currentFormat = format

    const PostFormatData = (action, selectedFormat) => {
        // console.log("selectedFormat", selectedFormat)
        let JData = { "E01": { ...EinvoiceObj, E01F01: obj?.DataGridRow?.FIELD01 } }
        console.log("jdata", JData)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: action,
            cCode: obj.id,
            cOPara: "",
            Custid: "PL-P-4486",
            cSData: JSON.stringify(JData)
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: action,
            cCode: obj.id,
            cOPara: "",
            Custid: "PL-P-4486",
            cSData: JSON.stringify(JData)
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostE01Ent + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Data====>", data)
                if (data.status == "SUCCESS") {
                    // Promise.resolve()
                    // console.log("received format:", selectedFormat)
                    // .then(() => {
                    if (selectedFormat == "J") {
                        console.log("if condition")
                        saveJsonToFile(data?.data?.Json, `EINV_${obj.DataGridRow?.FIELD12}`)
                    } else if (selectedFormat == "A") {
                        console.log("else condition")
                        createFormA(data?.data?.Json)
                    } else if (selectedFormat == "B") {
                        createFormB(data?.data?.Json)
                    } else if (selectedFormat == "C") {
                        createFormC(data?.data?.Json)
                    } else if (selectedFormat == "D") {
                        createFormD(data?.data?.Json)
                    }
                    // })
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false }))
                    // .then(() => setTimeout(() => {
                    setButtonLoading({ ...buttonLoading, generate: false })
                    // }, 1000))
                    GlobalClass.Notify("success", "Successfull", `The file has been created`)
                } else {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    );
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)));
            });
    }

    let FormatVal;
    const GenerateFun = () => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>Format Type</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <Paper shadow="md" radius="md" p="xs" withBorder>
                    <Grid gutter={4}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={3} style={{ fontSize: 12 }}>File</Grid.Col>
                            <Grid.Col span={9}>
                                <PLComboBox
                                    data={COPE01}
                                    value={FormatVal}
                                    dispexpr={"DisplayMember"}
                                    valexpr={"ValueMember"}
                                    setEdit={(e) => {
                                        console.log("setEdit", e)
                                        // setFormat(e)
                                        FormatVal = e;
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        <Grid.Col span={12} style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => {
                                    console.log("FoormatVal", FormatVal);
                                    PostFormatData("J", FormatVal)
                                }}
                            >OK</Button>
                        </Grid.Col>
                    </Grid>
                </Paper>
            </>,
            open: true,
            size: "md",
            position: "right"
        })
    }

    const EWBForm = () => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>E-Way Bill</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <EWayBill
                    OBJ={obj}
                    DrawerObjSend={DrawerObj}
                    setDrawerObjSend={(e) => {
                        setDrawerObj(e)
                    }}
                />
            </>,
            open: true,
            size: "60%",
            position: "right"
        })
    }

    const uploadInvoice = () => {
        let JData = { "E01": { ...EinvoiceObj, E01F01: obj.id } }
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "U",
            cCode: obj.id,
            cOPara: "",
            cSData: JSON.stringify(JData)
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "U",
            cCode: obj.id,
            cOPara: "",
            cSData: JSON.stringify(JData)
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostE01Ent + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    let PaginationObj =
                        Store.getState().DataSaveLoadingSlice.PaginationObj;
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() => setButtonLoading({ ...buttonLoading, upload: false }))
                } else {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    ).then(() => setButtonLoading({ generate: false, upload: false }))
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)))
                    .then(() => setButtonLoading({ generate: false, upload: false }))
            });
    }

    const saveJsonToFile = (jsonData, filename) => {
        // Convert the JSON data to a string
        // console.log('jsonData', [{
        //     "jsonData": jsonData,
        //     "filename": filename
        // }])
        let parsedJsonData;
        try {
            parsedJsonData = JSON.parse(jsonData);
            console.log("parsedJsonData", parsedJsonData)
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return; // Exit if JSON parsing fails
        }
        const jsonString = JSON.stringify(parsedJsonData, null, 2); // Pretty-print with 2 spaces indentation
        console.log("jsonString", jsonString)

        // Create a Blob from the JSON string
        const blob = new Blob([jsonString], { type: 'application/json' });

        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob); // Create a URL for the Blob
        link.download = filename; // Set the filename for the download

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Clean up by removing the link
        document.body.removeChild(link);
    };

    const createFormA = (e) => {
        let parsedJsonData = []
        try {
            parsedJsonData = JSON.parse(e);
            console.log("parsedJsonData", parsedJsonData)
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return;
        }
        // setJsonData(parsedJsonData)
        fetch(EForm_A)
            .then((response) => response.arrayBuffer()) // Get file as array buffer
            .then((arrayBuffer) => {
                console.log("arrayBuffer",arrayBuffer);
                // Read the Excel file
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });

                console.log("workbook",workbook);

                // Assuming the second sheet is the one you need (based on your docx file)
                const sheet = workbook.Sheets[workbook.SheetNames[1]]; // Profile
                const sheet2 = workbook.Sheets[workbook.SheetNames[2]]; // E-Invoice

                // Fill in the data from the JSON into the Excel file
                // Header Fields
                sheet['C4'] = { v: parsedJsonData?.sellerdtls?.gstin };
                sheet['G4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet['K4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet['C5'] = { v: parsedJsonData?.sellerdtls?.addr1 };
                sheet['G5'] = { v: parsedJsonData?.sellerdtls?.addr2 };
                sheet['K5'] = { v: parsedJsonData?.sellerdtls?.loc };
                sheet['C6'] = { v: GetStateNM(parsedJsonData.sellerdtls.stcd) };
                sheet['G6'] = { v: parsedJsonData?.sellerdtls?.pin };

                // Line items (in a loop)
                let nNR = 4
                const itemList = parsedJsonData?.itemlist;
                if (itemList) {
                    itemList?.map((oItem, nI) => {
                        nNR++;

                        sheet2[`AG${nNR}`] = { v: nI + 1 };
                        sheet2[`AH${nNR}`] = { v: oItem?.prddesc };
                        sheet2[`AI${nNR}`] = { v: oItem?.isservc === 'Y' ? 'Yes' : 'No' };
                        sheet2[`AJ${nNR}`] = { v: oItem?.hsncd };
                        sheet2[`AK${nNR}`] = { v: oItem?.barcde }
                        sheet2[`AL${nNR}`] = { v: oItem?.qty };
                        sheet2[`AM${nNR}`] = { v: oItem?.freeqty };
                        sheet2[`AN${nNR}`] = { v: GETUNIT(oItem?.unit) };
                        sheet2[`AO${nNR}`] = { v: oItem?.unitprice };
                        sheet2[`AP${nNR}`] = { v: oItem?.assamt };
                        sheet2[`AQ${nNR}`] = { v: 0 };
                        sheet2[`AR${nNR}`] = { v: 0 };
                        sheet2[`AS${nNR}`] = { v: oItem?.assamt };
                        sheet2[`AT${nNR}`] = { v: oItem?.gstrt };
                        sheet2[`AU${nNR}`] = { v: oItem?.sgstamt };
                        sheet2[`AV${nNR}`] = { v: oItem?.cgstamt };
                        sheet2[`AW${nNR}`] = { v: oItem?.igstamt };
                        sheet2[`AX${nNR}`] = { v: oItem?.cesrt };
                        sheet2[`AY${nNR}`] = { v: oItem?.cesamt };
                        sheet2[`AZ${nNR}`] = { v: oItem?.cesnonadvlamt };
                        sheet2[`BA${nNR}`] = { v: 0 };
                        sheet2[`BB${nNR}`] = { v: 0 };
                        sheet2[`BC${nNR}`] = { v: 0 };
                        sheet2[`BD${nNR}`] = { v: oItem?.othchrg };
                        sheet2[`BE${nNR}`] = { v: oItem?.totitemval };

                        // Transaction Details
                        if (parsedJsonData?.trandtls) {
                            sheet2[`A${nNR}`] = { v: parsedJsonData?.trandtls?.suptyp };
                            sheet2[`B${nNR}`] = { v: parsedJsonData?.trandtls?.regrev === "Y" ? 'Yes' : 'No' };
                            sheet2[`C${nNR}`] = { v: parsedJsonData?.trandtls?.ecmgstin };
                            sheet2[`D${nNR}`] = { v: 'No' }; // Assuming static value
                        }

                        // Document Details
                        if (parsedJsonData?.docdtls) {
                            sheet2[`E${nNR}`] = {
                                v: parsedJsonData?.docdtls?.typ === "CRN" ? "Credit Note" :
                                    parsedJsonData?.docdtls?.typ === "DBN" ? "Debit Note" : "Tax Invoice"
                            };
                            sheet2[`F${nNR}`] = { v: parsedJsonData?.docdtls?.no };
                            sheet2[`G${nNR}`] = { v: parsedJsonData?.docdtls?.dt };
                            // sheet2[`H${nNR}`] = {v:parsedJsonData?.docdtls?.orginvno}
                        }

                        // Buyer Details
                        if (parsedJsonData?.buyerdtls) {
                            sheet2[`H${nNR}`] = { v: parsedJsonData?.buyerdtls?.gstin };
                            sheet2[`I${nNR}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                            sheet2[`J${nNR}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                            sheet2[`K${nNR}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.pos) };
                            sheet2[`L${nNR}`] = { v: parsedJsonData?.buyerdtls?.addr1 };
                            sheet2[`M${nNR}`] = { v: parsedJsonData?.buyerdtls?.addr2 };
                            sheet2[`N${nNR}`] = { v: parsedJsonData?.buyerdtls?.loc };
                            sheet2[`O${nNR}`] = { v: parsedJsonData?.buyerdtls?.pin };
                            sheet2[`P${nNR}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.stcd) };
                        }

                        if (parsedJsonData?.dispdtls) {
                            // Dispatch Details
                            sheet2[`S${nNR}`] = { v: parsedJsonData?.dispdtls?.nm };
                            sheet2[`T${nNR}`] = { v: parsedJsonData?.dispdtls?.addr1 };
                            sheet2[`U${nNR}`] = { v: parsedJsonData?.dispdtls?.addr2 };
                            sheet2[`V${nNR}`] = { v: parsedJsonData?.dispdtls?.loc };
                            sheet2[`W${nNR}`] = { v: parsedJsonData?.dispdtls?.pin };
                            sheet2[`X${nNR}`] = { v: GetStateNM(parsedJsonData?.dispdtls?.stcd) };
                        }

                        // Shipping Details
                        if (parsedJsonData?.shipdtls) {
                            sheet2[`Y${nNR}`] = { v: parsedJsonData?.shipdtls?.gstin };
                            sheet2[`Z${nNR}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                            sheet2[`AA${nNR}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                            sheet2[`AB${nNR}`] = { v: parsedJsonData?.shipdtls?.addr1 };
                            sheet2[`AC${nNR}`] = { v: parsedJsonData?.shipdtls?.addr2 };
                            sheet2[`AD${nNR}`] = { v: parsedJsonData?.shipdtls?.loc };
                            sheet2[`AE${nNR}`] = { v: parsedJsonData?.shipdtls?.pin };
                            sheet2[`AF${nNR}`] = { v: GetStateNM(parsedJsonData?.shipdtls?.stcd) };
                        }

                        // Batch Details
                        if (parsedJsonData?.bchdtls) {
                            sheet2[`BF${nNR}`] = { v: parsedJsonData.bchdtls.nm };
                            sheet2[`BG${nNR}`] = { v: parsedJsonData.bchdtls.expdt };
                            sheet2[`BH${nNR}`] = { v: parsedJsonData.bchdtls.wrdt };
                        }

                        // Value Details
                        if (parsedJsonData?.valdtls) {
                            sheet2[`BI${nNR}`] = { v: parsedJsonData?.valdtls?.assval }
                            sheet2[`BJ${nNR}`] = { v: parsedJsonData?.valdtls?.sgstval }
                            sheet2[`BK${nNR}`] = { v: parsedJsonData?.valdtls?.cgstval }
                            sheet2[`BL${nNR}`] = { v: parsedJsonData?.valdtls?.igstval }
                            sheet2[`BM${nNR}`] = { v: parsedJsonData?.valdtls?.cesval }
                            sheet2[`BN${nNR}`] = { v: parsedJsonData?.valdtls?.stcesval }
                            sheet2[`BO${nNR}`] = { v: parsedJsonData?.valdtls?.discount }
                            sheet2[`BP${nNR}`] = { v: parsedJsonData?.valdtls?.othchrg }
                            sheet2[`BQ${nNR}`] = { v: parsedJsonData?.valdtls?.rndoffamt }
                            sheet2[`BR${nNR}`] = { v: parsedJsonData?.valdtls?.totinvval }
                        }

                        // Export Details
                        if (parsedJsonData?.expdtls) {
                            sheet2[`BS${nNR}`] = { v: parsedJsonData?.expdtls?.shipbno }
                            sheet2[`BT${nNR}`] = { v: parsedJsonData?.expdtls?.shipbdt }
                            sheet2[`BU${nNR}`] = { v: parsedJsonData?.expdtls?.port }
                            sheet2[`BV${nNR}`] = { v: 'No' }
                            sheet2[`BW${nNR}`] = { v: parsedJsonData?.expdtls?.forcur }
                            sheet2[`BX${nNR}`] = { v: parsedJsonData?.expdtls?.cntcode }
                            sheet2[`BY${nNR}`] = { v: 0 }
                        }

                        // E-Way Bill Details
                        if (parsedJsonData?.ewbdtls) {
                            sheet2[`BZ${nNR}`] = { v: parsedJsonData?.ewbdtls?.transid }
                            sheet2[`CA${nNR}`] = { v: parsedJsonData?.ewbdtls?.transname }
                            sheet2[`CB${nNR}`] = { v: parsedJsonData?.ewbdtls?.transmode }
                            sheet2[`CC${nNR}`] = { v: parsedJsonData?.ewbdtls?.distance }
                            sheet2[`CD${nNR}`] = { v: parsedJsonData?.ewbdtls?.transdocno }
                            sheet2[`CE${nNR}`] = { v: parsedJsonData?.ewbdtls?.transdocdt }
                            sheet2[`CF${nNR}`] = { v: parsedJsonData?.ewbdtls?.vehno }
                            sheet2[`CG${nNR}`] = { v: parsedJsonData?.ewbdtls?.vehtype }
                        }
                    })
                }
                // Convert the workbook to binary format and save it
                const newWorkbook = XLSX.write(workbook, { bookType: 'xlsm', type: 'binary' });
                const buffer = new ArrayBuffer(newWorkbook.length);
                const view = new Uint8Array(buffer);
                for (let i = 0; i < newWorkbook.length; i++) {
                    view[i] = newWorkbook.charCodeAt(i) & 0xFF;
                }
                const blob = new Blob([buffer], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });
                saveAs(blob, `EFORM_A_${obj?.DataGridRow?.FIELD12}.xlsm`);
            })
    }

    const createFormB = (e) => {
        let parsedJsonData = [];
        try {
            parsedJsonData = JSON.parse(e);
            console.log("parsedJsonData", parsedJsonData);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return;
        }

        fetch(EForm_B)  // Replace with the actual URL for Form B
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const sheet = workbook.Sheets[workbook.SheetNames[1]]; // Assuming sheet 2 for Form B
                const sheet1 = workbook.Sheets[workbook.SheetNames[2]];
                const sheet2 = workbook.Sheets[workbook.SheetNames[3]];

                // Fill in seller details
                sheet['C4'] = { v: parsedJsonData?.sellerdtls?.gstin };
                sheet['G4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet['K4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet['C5'] = { v: parsedJsonData?.sellerdtls?.addr1 };
                sheet['G5'] = { v: parsedJsonData?.sellerdtls?.addr2 };
                sheet['K5'] = { v: parsedJsonData?.sellerdtls?.loc };
                sheet['C6'] = { v: GetStateNM(parsedJsonData.sellerdtls.stcd) };
                sheet['G6'] = { v: parsedJsonData?.sellerdtls?.pin };

                // Transaction Details
                let nNR = 5;
                sheet1[`A${nNR}`] = { v: parsedJsonData?.trandtls?.suptyp };
                sheet1[`B${nNR}`] = { v: parsedJsonData?.trandtls?.regrev === "Y" ? 'Yes' : 'No' };
                sheet1[`C${nNR}`] = { v: parsedJsonData?.trandtls?.ecmgstin };
                sheet1[`D${nNR}`] = { v: 'No' }; // Assuming static value

                // Document Details
                sheet1[`E${nNR}`] = {
                    v: parsedJsonData?.docdtls?.typ === "CRN" ? "Credit Note" :
                        parsedJsonData?.docdtls?.typ === "DBN" ? "Debit Note" : "Tax Invoice"
                };
                sheet1[`F${nNR}`] = { v: parsedJsonData?.docdtls?.no };
                sheet1[`G${nNR}`] = { v: parsedJsonData?.docdtls?.dt };

                // Buyer Details
                sheet1[`H${nNR}`] = { v: parsedJsonData?.buyerdtls?.gstin };
                sheet1[`I${nNR}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                sheet1[`J${nNR}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                sheet1[`K${nNR}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.pos) };
                sheet1[`L${nNR}`] = { v: parsedJsonData?.buyerdtls?.addr1 };
                sheet1[`M${nNR}`] = { v: parsedJsonData?.buyerdtls?.addr2 };
                sheet1[`N${nNR}`] = { v: parsedJsonData?.buyerdtls?.loc };
                sheet1[`O${nNR}`] = { v: parsedJsonData?.buyerdtls?.pin };
                sheet1[`P${nNR}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.stcd) };

                if (parsedJsonData?.dispdtls) {
                    // Dispatch Details
                    sheet1[`S${nNR}`] = { v: parsedJsonData?.dispdtls?.nm };
                    sheet1[`T${nNR}`] = { v: parsedJsonData?.dispdtls?.addr1 };
                    sheet1[`U${nNR}`] = { v: parsedJsonData?.dispdtls?.addr2 };
                    sheet1[`V${nNR}`] = { v: parsedJsonData?.dispdtls?.loc };
                    sheet1[`W${nNR}`] = { v: parsedJsonData?.dispdtls?.pin };
                    sheet1[`X${nNR}`] = { v: GetStateNM(parsedJsonData?.dispdtls?.stcd) };
                }

                if (parsedJsonData?.shipdtls) {
                    // Shipping Details
                    sheet1[`Y${nNR}`] = { v: parsedJsonData?.shipdtls?.gstin };
                    sheet1[`Z${nNR}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                    sheet1[`AA${nNR}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                    sheet1[`AB${nNR}`] = { v: parsedJsonData?.shipdtls?.addr1 };
                    sheet1[`AC${nNR}`] = { v: parsedJsonData?.shipdtls?.addr2 };
                    sheet1[`AD${nNR}`] = { v: parsedJsonData?.shipdtls?.loc };
                    sheet1[`AE${nNR}`] = { v: parsedJsonData?.shipdtls?.pin };
                    sheet1[`AF${nNR}`] = { v: GetStateNM(parsedJsonData?.shipdtls?.stcd) };
                }

                // Value Details
                sheet1[`AG${nNR}`] = { v: parsedJsonData?.valdtls?.assval }
                sheet1[`AH${nNR}`] = { v: parsedJsonData?.valdtls?.sgstval }
                sheet1[`AI${nNR}`] = { v: parsedJsonData?.valdtls?.cgstval }
                sheet1[`AJ${nNR}`] = { v: parsedJsonData?.valdtls?.igstval }
                sheet1[`AK${nNR}`] = { v: parsedJsonData?.valdtls?.cesval }
                sheet1[`AL${nNR}`] = { v: parsedJsonData?.valdtls?.stcesval }
                sheet1[`AM${nNR}`] = { v: parsedJsonData?.valdtls?.discount }
                sheet1[`AN${nNR}`] = { v: parsedJsonData?.valdtls?.othchrg }
                sheet1[`AO${nNR}`] = { v: parsedJsonData?.valdtls?.rndoffamt }
                sheet1[`AP${nNR}`] = { v: parsedJsonData?.valdtls?.totinvval }

                // Export Details
                sheet1[`AQ${nNR}`] = { v: parsedJsonData?.expdtls?.shipbno }
                sheet1[`AR${nNR}`] = { v: parsedJsonData?.expdtls?.shipbdt }
                sheet1[`AS${nNR}`] = { v: parsedJsonData?.expdtls?.port }
                sheet1[`AT${nNR}`] = { v: 'No' }
                sheet1[`AU${nNR}`] = { v: parsedJsonData?.expdtls?.forcur }
                sheet1[`AV${nNR}`] = { v: parsedJsonData?.expdtls?.cntcode }
                sheet1[`AW${nNR}`] = { v: 0 }

                if (parsedJsonData?.ewbdtls) {
                    // E-Way Bill Details 
                    sheet1[`AX${nNR}`] = { v: parsedJsonData?.ewbdtls?.transid }
                    sheet1[`AY${nNR}`] = { v: parsedJsonData?.ewbdtls?.transname }
                    sheet1[`AZ${nNR}`] = { v: parsedJsonData?.ewbdtls?.transmode }
                    sheet1[`BA${nNR}`] = { v: parsedJsonData?.ewbdtls?.distance }
                    sheet1[`BB${nNR}`] = { v: parsedJsonData?.ewbdtls?.transdocno }
                    sheet1[`BC${nNR}`] = { v: parsedJsonData?.ewbdtls?.transdocdt }
                    sheet1[`BD${nNR}`] = { v: parsedJsonData?.ewbdtls?.vehno }
                    sheet1[`BE${nNR}`] = { v: parsedJsonData?.ewbdtls?.vehtype }
                }

                let nNR1 = 4

                // Item details
                const docDetails = parsedJsonData?.docdtls;
                const itemList = parsedJsonData?.itemlist;
                if (itemList && itemList.length > 0) {
                    console.log("Number of items in itemList:", itemList.length);
                    itemList?.map((oItem, nI) => {
                        nNR1++;

                        sheet2[`D${nNR1}`] = { v: nI + 1 };
                        sheet2[`E${nNR1}`] = { v: oItem.prddesc };
                        sheet2[`F${nNR1}`] = { v: oItem.isservc === 'Y' ? 'Yes' : 'No' };
                        sheet2[`G${nNR1}`] = { v: oItem.hsncd };
                        sheet2[`I${nNR1}`] = { v: oItem.qty };
                        sheet2[`J${nNR1}`] = { v: oItem.freeqty };
                        sheet2[`K${nNR1}`] = { v: GETUNIT(oItem.unit) };
                        sheet2[`L${nNR1}`] = { v: oItem.unitprice };
                        sheet2[`M${nNR1}`] = { v: oItem.assamta };
                        sheet2[`N${nNR1}`] = { v: 0 };
                        sheet2[`O${nNR1}`] = { v: 0 };
                        sheet2[`P${nNR1}`] = { v: oItem.assamt };
                        sheet2[`Q${nNR1}`] = { v: oItem.gstrt };
                        sheet2[`R${nNR1}`] = { v: oItem.sgstamt };
                        sheet2[`S${nNR1}`] = { v: oItem.cgstamt };
                        sheet2[`T${nNR1}`] = { v: oItem.igstamt };
                        sheet2[`U${nNR1}`] = { v: oItem.cesrt };
                        sheet2[`V${nNR1}`] = { v: oItem.cesamt };
                        sheet2[`W${nNR1}`] = { v: oItem.cesnonadvlamt };
                        sheet2[`X${nNR1}`] = { v: 0 };
                        sheet2[`Y${nNR1}`] = { v: 0 };
                        sheet2[`Z${nNR1}`] = { v: 0 };
                        sheet2[`AA${nNR1}`] = { v: oItem.othchrg };
                        sheet2[`AB${nNR1}`] = { v: oItem.totitemval };

                        console.log(`Row ${nNR1} written:`, sheet2[`D${nNR1}`], sheet2[`E${nNR1}`]);

                        // Document details
                        if (docDetails) {
                            sheet2[`A${nNR1}`] = { v: docDetails.typ === 'CRN' ? 'Credit Note' : docDetails.typ === 'DBN' ? 'Debit Note' : 'Tax Invoice' };
                            sheet2[`B${nNR1}`] = { v: docDetails.no };
                            sheet2[`C${nNR1}`] = { v: docDetails.dt };
                        }

                        // Batch details
                        if (parsedJsonData?.bchdtls) {
                            sheet2[`AC${nNR1}`] = { v: parsedJsonData.bchdtls.nm };
                            sheet2[`AD${nNR1}`] = { v: parsedJsonData.bchdtls.expdt };
                            sheet2[`AE${nNR1}`] = { v: parsedJsonData.bchdtls.wrdt };
                        }
                        console.log("Processing item:", oItem);
                    });
                }

                // Export the filled workbook
                const newWorkbook = XLSX.write(workbook, { bookType: 'xlsm', type: 'binary' });
                const buffer = new ArrayBuffer(newWorkbook.length);
                const view = new Uint8Array(buffer);
                for (let i = 0; i < newWorkbook.length; i++) {
                    view[i] = newWorkbook.charCodeAt(i) & 0xFF;
                }
                const blob = new Blob([buffer], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });
                saveAs(blob, `EFORM_B_${obj?.DataGridRow?.FIELD12}.xlsm`);
            })
            .catch((error) => {
                console.error('Error fetching the Form B template:', error);
            });
    };

    const createFormC = (e) => {
        let parsedJsonData = [];
        try {
            parsedJsonData = JSON.parse(e);
            console.log("parsedJsonData", parsedJsonData);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return;
        }
        fetch(EForm_C)  // Replace with the actual URL for Form B
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const sheet1 = workbook.Sheets[workbook.SheetNames[1]]; // Assuming sheet 2 for Form C
                const sheet2 = workbook.Sheets[workbook.SheetNames[2]];
                const sheet3 = workbook.Sheets[workbook.SheetNames[3]];
                const sheet4 = workbook.Sheets[workbook.SheetNames[4]];
                const sheet5 = workbook.Sheets[workbook.SheetNames[5]];
                const sheet6 = workbook.Sheets[workbook.SheetNames[6]];

                sheet1['C4'] = { v: parsedJsonData?.sellerdtls?.gstin };
                sheet1['G4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet1['K4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet1['C5'] = { v: parsedJsonData?.sellerdtls?.addr1 };
                sheet1['G5'] = { v: parsedJsonData?.sellerdtls?.addr2 };
                sheet1['K5'] = { v: parsedJsonData?.sellerdtls?.loc };
                sheet1['C6'] = { v: GetStateNM(parsedJsonData.sellerdtls.stcd) };
                sheet1['G6'] = { v: parsedJsonData?.sellerdtls?.pin };

                // Transaction Details
                let nNR = 5;
                sheet2[`A${nNR}`] = { v: parsedJsonData?.trandtls?.suptyp };
                sheet2[`B${nNR}`] = { v: parsedJsonData?.trandtls?.regrev === "Y" ? 'Yes' : 'No' };
                sheet2[`C${nNR}`] = { v: parsedJsonData?.trandtls?.ecmgstin };
                sheet2[`D${nNR}`] = { v: 'No' };

                // Document Details
                sheet2[`E${nNR}`] = {
                    v: parsedJsonData?.docdtls?.typ === "CRN" ? "Credit Note" :
                        parsedJsonData?.docdtls?.typ === "DBN" ? "Debit Note" : "Tax Invoice"
                };
                sheet2[`F${nNR}`] = { v: parsedJsonData?.docdtls?.no };
                sheet2[`G${nNR}`] = { v: parsedJsonData?.docdtls?.dt };

                // Buyer Details
                sheet2[`H${nNR}`] = { v: parsedJsonData?.buyerdtls?.gstin };
                sheet2[`I${nNR}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                sheet2[`J${nNR}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                sheet2[`K${nNR}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.pos) };
                sheet2[`L${nNR}`] = { v: parsedJsonData?.buyerdtls?.addr1 };
                sheet2[`M${nNR}`] = { v: parsedJsonData?.buyerdtls?.addr2 };
                sheet2[`N${nNR}`] = { v: parsedJsonData?.buyerdtls?.loc };
                sheet2[`O${nNR}`] = { v: parsedJsonData?.buyerdtls?.pin };
                sheet2[`P${nNR}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.stcd) };

                if (parsedJsonData?.dispdtls) {
                    // Dispatch Details
                    sheet2[`S${nNR}`] = { v: parsedJsonData?.dispdtls?.nm };
                    sheet2[`T${nNR}`] = { v: parsedJsonData?.dispdtls?.addr1 };
                    sheet2[`U${nNR}`] = { v: parsedJsonData?.dispdtls?.addr2 };
                    sheet2[`V${nNR}`] = { v: parsedJsonData?.dispdtls?.loc };
                    sheet2[`W${nNR}`] = { v: parsedJsonData?.dispdtls?.pin };
                    sheet2[`X${nNR}`] = { v: GetStateNM(parsedJsonData?.dispdtls?.stcd) };
                }

                if (parsedJsonData?.shipdtls) {
                    // Shipping Details
                    sheet2[`Y${nNR}`] = { v: parsedJsonData?.shipdtls?.gstin };
                    sheet2[`Z${nNR}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                    sheet2[`AA${nNR}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                    sheet2[`AB${nNR}`] = { v: parsedJsonData?.shipdtls?.addr1 };
                    sheet2[`AC${nNR}`] = { v: parsedJsonData?.shipdtls?.addr2 };
                    sheet2[`AD${nNR}`] = { v: parsedJsonData?.shipdtls?.loc };
                    sheet2[`AE${nNR}`] = { v: parsedJsonData?.shipdtls?.pin };
                    sheet2[`AF${nNR}`] = { v: GetStateNM(parsedJsonData?.shipdtls?.stcd) };
                }

                // Value Details
                sheet2[`AG${nNR}`] = { v: parsedJsonData?.valdtls?.assval }
                sheet2[`AH${nNR}`] = { v: parsedJsonData?.valdtls?.sgstval }
                sheet2[`AI${nNR}`] = { v: parsedJsonData?.valdtls?.cgstval }
                sheet2[`AJ${nNR}`] = { v: parsedJsonData?.valdtls?.igstval }
                sheet2[`AK${nNR}`] = { v: parsedJsonData?.valdtls?.cesval }
                sheet2[`AL${nNR}`] = { v: parsedJsonData?.valdtls?.stcesval }
                sheet2[`AM${nNR}`] = { v: parsedJsonData?.valdtls?.discount }
                sheet2[`AN${nNR}`] = { v: parsedJsonData?.valdtls?.othchrg }
                sheet2[`AO${nNR}`] = { v: parsedJsonData?.valdtls?.rndoffamt }
                sheet2[`AP${nNR}`] = { v: parsedJsonData?.valdtls?.totinvval }

                // Export Details
                sheet2[`AQ${nNR}`] = { v: parsedJsonData?.expdtls?.shipbno }
                sheet2[`AR${nNR}`] = { v: parsedJsonData?.expdtls?.shipbdt }
                sheet2[`AS${nNR}`] = { v: parsedJsonData?.expdtls?.port }
                sheet2[`AT${nNR}`] = { v: 'No' }
                sheet2[`AU${nNR}`] = { v: parsedJsonData?.expdtls?.forcur }
                sheet2[`AV${nNR}`] = { v: parsedJsonData?.expdtls?.cntcode }
                sheet2[`AW${nNR}`] = { v: 0 }

                if (parsedJsonData?.ewbdtls) {
                    // E-Way Bill Details 
                    sheet2[`AX${nNR}`] = { v: parsedJsonData?.ewbdtls?.transid }
                    sheet2[`AY${nNR}`] = { v: parsedJsonData?.ewbdtls?.transname }
                    sheet2[`AZ${nNR}`] = { v: parsedJsonData?.ewbdtls?.transmode }
                    sheet2[`BA${nNR}`] = { v: parsedJsonData?.ewbdtls?.distance }
                    sheet2[`BB${nNR}`] = { v: parsedJsonData?.ewbdtls?.transdocno }
                    sheet2[`BC${nNR}`] = { v: parsedJsonData?.ewbdtls?.transdocdt }
                    sheet2[`BD${nNR}`] = { v: parsedJsonData?.ewbdtls?.vehno }
                    sheet2[`BE${nNR}`] = { v: parsedJsonData?.ewbdtls?.vehtype }
                }

                let nNR1 = 4

                // Item details
                const docDetails = parsedJsonData?.docdtls;
                const itemList = parsedJsonData?.itemlist;
                if (itemList) {
                    itemList?.map((oItem, nI) => {
                        nNR1++;

                        // Document details
                        if (docDetails) {
                            sheet3[`A${nNR1}`] = { v: docDetails.typ === 'CRN' ? 'Credit Note' : docDetails.typ === 'DBN' ? 'Debit Note' : 'Tax Invoice' };
                            sheet3[`B${nNR1}`] = { v: docDetails.no };
                            sheet3[`C${nNR1}`] = { v: docDetails.dt };
                        }

                        sheet3[`D${nNR1}`] = { v: nI + 1 };
                        sheet3[`E${nNR1}`] = { v: oItem.prddesc };
                        sheet3[`F${nNR1}`] = { v: oItem.isservc === 'Y' ? 'Yes' : 'No' };
                        sheet3[`G${nNR1}`] = { v: oItem.hsncd };
                        sheet3[`I${nNR1}`] = { v: oItem.qty };
                        sheet3[`J${nNR1}`] = { v: oItem.freeqty };
                        sheet3[`K${nNR1}`] = { v: GETUNIT(oItem.unit) };
                        sheet3[`L${nNR1}`] = { v: oItem.unitprice };
                        sheet3[`M${nNR1}`] = { v: oItem.assamta };
                        sheet3[`N${nNR1}`] = { v: 0 };
                        sheet3[`O${nNR1}`] = { v: 0 };
                        sheet3[`P${nNR1}`] = { v: oItem.assamt };
                        sheet3[`Q${nNR1}`] = { v: oItem.gstrt };
                        sheet3[`R${nNR1}`] = { v: oItem.sgstamt };
                        sheet3[`S${nNR1}`] = { v: oItem.cgstamt };
                        sheet3[`T${nNR1}`] = { v: oItem.igstamt };
                        sheet3[`U${nNR1}`] = { v: oItem.cesrt };
                        sheet3[`V${nNR1}`] = { v: oItem.cesamt };
                        sheet3[`W${nNR1}`] = { v: oItem.cesnonadvlamt };
                        sheet3[`X${nNR1}`] = { v: 0 };
                        sheet3[`Y${nNR1}`] = { v: 0 };
                        sheet3[`Z${nNR1}`] = { v: 0 };
                        sheet3[`AA${nNR1}`] = { v: oItem.othchrg };
                        sheet3[`AB${nNR1}`] = { v: oItem.totitemval };

                        // Batch details
                        if (parsedJsonData?.bchdtls) {
                            sheet3[`AC${nNR1}`] = { v: parsedJsonData?.bchdtls?.nm };
                            sheet3[`AD${nNR1}`] = { v: parsedJsonData?.bchdtls?.expdt };
                            sheet3[`AE${nNR1}`] = { v: parsedJsonData?.bchdtls?.wrdt };
                        }
                    });
                }

                // Export the filled workbook
                const newWorkbook = XLSX.write(workbook, { bookType: 'xlsm', type: 'binary' });
                const buffer = new ArrayBuffer(newWorkbook.length);
                const view = new Uint8Array(buffer);
                for (let i = 0; i < newWorkbook.length; i++) {
                    view[i] = newWorkbook.charCodeAt(i) & 0xFF;
                }
                const blob = new Blob([buffer], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });
                saveAs(blob, `EFORM_C_${obj?.DataGridRow?.FIELD12}.xlsm`);
            })
    }

    const createFormD = (e) => {
        let parsedJsonData = [];
        try {
            parsedJsonData = JSON.parse(e);
            console.log("parsedJsonData", parsedJsonData);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return;
        }
        fetch(EForm_D)
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const sheet1 = workbook.Sheets[workbook.SheetNames[1]];
                const sheet2 = workbook.Sheets[workbook.SheetNames[2]];

                sheet1['C4'] = { v: parsedJsonData?.sellerdtls?.gstin };
                sheet1['G4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet1['K4'] = { v: parsedJsonData?.sellerdtls?.trdnm };
                sheet1['C5'] = { v: parsedJsonData?.sellerdtls?.addr1 };
                sheet1['G5'] = { v: parsedJsonData?.sellerdtls?.addr2 };
                sheet1['K5'] = { v: parsedJsonData?.sellerdtls?.loc };
                sheet1['C6'] = { v: GetStateNM(parsedJsonData.sellerdtls.stcd) };
                sheet1['G6'] = { v: parsedJsonData?.sellerdtls?.pin };

                let nNR1 = 4

                // Item details
                const docDetails = parsedJsonData?.docdtls;
                const itemList = parsedJsonData?.itemlist;
                if (itemList && itemList?.length > 0) {
                    itemList?.map((oItem, nI) => {
                        nNR1++;

                        // Items
                        sheet2[`AG${nNR1}`] = { v: nI + 1 };
                        sheet2[`AH${nNR1}`] = { v: oItem?.prddesc };
                        sheet2[`AI${nNR1}`] = { v: oItem?.isservc === 'Y' ? 'Yes' : 'No' };
                        sheet2[`AJ${nNR1}`] = { v: oItem?.hsncd };
                        sheet2[`AL${nNR1}`] = { v: oItem?.qty };
                        sheet2[`AM${nNR1}`] = { v: oItem?.freeqty };
                        sheet2[`AN${nNR1}`] = { v: GETUNIT(oItem?.unit) };
                        sheet2[`AO${nNR1}`] = { v: oItem?.unitprice };
                        sheet2[`AP${nNR1}`] = { v: oItem?.assamt };
                        sheet2[`AQ${nNR1}`] = { v: 0 };
                        sheet2[`AS${nNR1}`] = { v: oItem?.assamt };
                        sheet2[`AT${nNR1}`] = { v: oItem?.gstrt };
                        sheet2[`AU${nNR1}`] = { v: oItem?.sgstamt };
                        sheet2[`AV${nNR1}`] = { v: oItem?.cgstamt };
                        sheet2[`AW${nNR1}`] = { v: oItem?.igstamt };
                        sheet2[`AX${nNR1}`] = { v: oItem?.cesrt };
                        sheet2[`AY${nNR1}`] = { v: oItem?.cesamt };
                        sheet2[`AZ${nNR1}`] = { v: oItem?.cesnonadvlamt };
                        sheet2[`BA${nNR1}`] = { v: 0 };
                        sheet2[`BB${nNR1}`] = { v: 0 };
                        sheet2[`BC${nNR1}`] = { v: 0 };
                        sheet2[`BD${nNR1}`] = { v: oItem?.othchrg };
                        sheet2[`BE${nNR1}`] = { v: oItem?.totitemval };

                        // Transaction Details
                        if (parsedJsonData?.trandtls) {
                            sheet2[`A${nNR1}`] = { v: parsedJsonData?.trandtls?.suptyp };
                            sheet2[`B${nNR1}`] = { v: parsedJsonData?.trandtls?.regrev === "Y" ? 'Yes' : 'No' };
                            sheet2[`C${nNR1}`] = { v: parsedJsonData?.trandtls?.ecmgstin };
                            sheet2[`D${nNR1}`] = { v: 'No' };
                        }

                        // Document Details
                        if (docDetails) {
                            sheet2[`E${nNR1}`] = {
                                v: parsedJsonData?.docdtls?.typ === "CRN" ? "Credit Note" :
                                    parsedJsonData?.docdtls?.typ === "DBN" ? "Debit Note" : "Tax Invoice"
                            };
                            sheet2[`F${nNR1}`] = { v: parsedJsonData?.docdtls?.no };
                            sheet2[`G${nNR1}`] = { v: parsedJsonData?.docdtls?.dt };
                        }

                        // Buyer Details
                        if (parsedJsonData?.buyerdtls) {
                            sheet2[`H${nNR1}`] = { v: parsedJsonData?.buyerdtls?.gstin };
                            sheet2[`I${nNR1}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                            sheet2[`J${nNR1}`] = { v: parsedJsonData?.buyerdtls?.trdnm };
                            sheet2[`K${nNR1}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.pos) };
                            sheet2[`L${nNR1}`] = { v: parsedJsonData?.buyerdtls?.addr1 };
                            sheet2[`M${nNR1}`] = { v: parsedJsonData?.buyerdtls?.addr2 };
                            sheet2[`N${nNR1}`] = { v: parsedJsonData?.buyerdtls?.loc };
                            sheet2[`O${nNR1}`] = { v: parsedJsonData?.buyerdtls?.pin };
                            sheet2[`P${nNR1}`] = { v: GetStateNM(parsedJsonData?.buyerdtls?.stcd) };
                        }

                        // Dispatch Details
                        if (parsedJsonData?.dispdtls) {
                            sheet2[`S${nNR1}`] = { v: parsedJsonData?.dispdtls?.nm };
                            sheet2[`T${nNR1}`] = { v: parsedJsonData?.dispdtls?.addr1 };
                            sheet2[`U${nNR1}`] = { v: parsedJsonData?.dispdtls?.addr2 };
                            sheet2[`V${nNR1}`] = { v: parsedJsonData?.dispdtls?.loc };
                            sheet2[`W${nNR1}`] = { v: parsedJsonData?.dispdtls?.pin };
                            sheet2[`X${nNR1}`] = { v: GetStateNM(parsedJsonData?.dispdtls?.stcd) };
                        }

                        // Ship Details
                        if (parsedJsonData?.shipdtls) {
                            sheet2[`Y${nNR1}`] = { v: parsedJsonData?.shipdtls?.gstin };
                            sheet2[`Z${nNR1}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                            sheet2[`AA${nNR1}`] = { v: parsedJsonData?.shipdtls?.trdnm };
                            sheet2[`AB${nNR1}`] = { v: parsedJsonData?.shipdtls?.addr1 };
                            sheet2[`AC${nNR1}`] = { v: parsedJsonData?.shipdtls?.addr2 };
                            sheet2[`AD${nNR1}`] = { v: parsedJsonData?.shipdtls?.loc };
                            sheet2[`AE${nNR1}`] = { v: parsedJsonData?.shipdtls?.pin };
                            sheet2[`AF${nNR1}`] = { v: GetStateNM(parsedJsonData?.shipdtls?.stcd) };
                        }

                        // Batch details
                        if (parsedJsonData?.bchdtls) {
                            sheet2[`BI${nNR1}`] = { v: parsedJsonData.bchdtls.nm };
                            sheet2[`BJ${nNR1}`] = { v: parsedJsonData.bchdtls.expdt };
                            sheet2[`BK${nNR1}`] = { v: parsedJsonData.bchdtls.wrdt };
                        }

                        // Value Details
                        if (parsedJsonData?.valdtls) {
                            sheet2[`BN${nNR1}`] = { v: parsedJsonData?.valdtls?.assval }
                            sheet2[`BO${nNR1}`] = { v: parsedJsonData?.valdtls?.sgstval }
                            sheet2[`BP${nNR1}`] = { v: parsedJsonData?.valdtls?.cgstval }
                            sheet2[`BQ${nNR1}`] = { v: parsedJsonData?.valdtls?.igstval }
                            sheet2[`BR${nNR1}`] = { v: parsedJsonData?.valdtls?.cesval }
                            sheet2[`BS${nNR1}`] = { v: parsedJsonData?.valdtls?.stcesval }
                            sheet2[`BT${nNR1}`] = { v: parsedJsonData?.valdtls?.discount }
                            sheet2[`BU${nNR1}`] = { v: parsedJsonData?.valdtls?.othchrg }
                            sheet2[`BV${nNR1}`] = { v: parsedJsonData?.valdtls?.rndoffamt }
                            sheet2[`BW${nNR1}`] = { v: parsedJsonData?.valdtls?.totinvval }
                        }

                        // Export Details
                        if (parsedJsonData?.expdtls) {
                            sheet2[`BY${nNR1}`] = { v: parsedJsonData?.expdtls?.shipbno }
                            sheet2[`BZ${nNR1}`] = { v: parsedJsonData?.expdtls?.shipbdt }
                            sheet2[`CA${nNR1}`] = { v: parsedJsonData?.expdtls?.port }
                            sheet2[`CB${nNR1}`] = { v: 'No' }
                            sheet2[`CC${nNR1}`] = { v: parsedJsonData?.expdtls?.forcur }
                            sheet2[`CD${nNR1}`] = { v: parsedJsonData?.expdtls?.cntcode }
                            sheet2[`CE${nNR1}`] = { v: 0 }
                        }
                    });
                }

                // Export the filled workbook
                const newWorkbook = XLSX.write(workbook, { bookType: 'xlsm', type: 'binary' });
                const buffer = new ArrayBuffer(newWorkbook.length);
                const view = new Uint8Array(buffer);
                for (let i = 0; i < newWorkbook.length; i++) {
                    view[i] = newWorkbook.charCodeAt(i) & 0xFF;
                }
                const blob = new Blob([buffer], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });
                saveAs(blob, `EFORM_D_${obj?.DataGridRow?.FIELD12}.xlsm`);
            })
    }

    function GetStateNM(item) {
        let val;
        accList["P_PLSTATE"]?.map((v, i) => {
            if (v?.PLSF06 == item) {
                return val = v
            }
            return val?.PLSF02
        })
    }

    function GETUNIT(FIELD01) {
        // let val;
        // accList["P_UNTS"]?.map((v, i) => {
        //     if ((v?.FIELD01).substring(0, 3) == FIELD01) {
        //         return val = v?.FIELD01
        //     }
            console.log("val",FIELD01?.split('-')[1])
            return FIELD01?.split('-')[1]
        // })
    }
    console.log("GetStateNm", GetStateNM())
    console.log("GETUNIT", GETUNIT())

    return (
        <>
            {
                SaveLoading == true ?
                    GlobalClass.RenderLoader("dots")
                    :
                    <>
                        {
                            isLoading ? GlobalClass.RenderLoader("dots") :
                                EInvoiceData &&
                                <>
                                    <Modal
                                        opened={DrawerObj?.open}
                                        // onClose={close}
                                        withCloseButton={true}

                                        fullScreen={false}
                                        // overlayProps={{ opacity: 0.5, blur: 4 }}
                                        closeButtonProps={{
                                            onClick: (e) => {
                                                setButtonLoading({ ...buttonLoading, generate: false })
                                                if (typeof DrawerObj?.onClickCloseButton == "function") {
                                                    DrawerObj?.onClickCloseButton();
                                                }
                                                else {
                                                    setDrawerObj(dra);
                                                }
                                            },
                                        }}
                                        title={DrawerObj?.title}
                                        size={DrawerObj?.size}
                                        // centered={.Centered}
                                        closeOnClickOutside={true}
                                        // overlayProps={m.Overlay}
                                        position={DrawerObj?.position ?? 'bottom'}
                                        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                                    // scrollAreaComponent={ScrollArea.Autosize}
                                    >
                                        {DrawerObj.body}
                                    </Modal>
                                    <Paper shadow="md" radius="md" p="xs" withBorder>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={3} style={{ fontSize: 12 }}>Invoice No</Grid.Col>
                                                <Grid.Col span={9}>
                                                    <PLTextBox
                                                        disabled={true}
                                                        value={obj?.DataGridRow?.FIELD12}
                                                    />
                                                    {/* <PLDataGrid 
                                                    TmData={P_UNTS}
                                                    // data={P_STATE}
                                                    setEdit={(e) => console.log("P_STATE",e)}
                                                    /> */}
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>
                                        {
                                            oYear?.OSC._EINVDISFM == "Y" &&
                                            <Grid gutter={4}>
                                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Dispatch From</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLDataGrid
                                                            value={EinvoiceObj?.E01F07}
                                                            // p1={"P_G07DF"}
                                                            TmData={P_G07DF}
                                                            setEdit={(e) => {
                                                                setEinvoiceObj({
                                                                    ...EinvoiceObj,
                                                                    E01F07: e.FIELD01
                                                                })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid>
                                        }
                                        {
                                            oYear?.OSC._EINVDISTO == "Y" &&
                                            <Grid gutter={4}>
                                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Dispatch To</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLDataGrid
                                                            value={EinvoiceObj?.E01F12}
                                                            // p1={"P_G07DT"}
                                                            TmData={P_G07DT}
                                                            setEdit={(e) => {
                                                                setEinvoiceObj({
                                                                    ...EinvoiceObj,
                                                                    E01F12: e.FIELD01
                                                                })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid>
                                        }
                                        <Grid gutter={4}>
                                            <Grid.Col
                                                my={5}
                                                span={12}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Group>
                                                    <Button
                                                        loading={buttonLoading?.generate}
                                                        style={{ height: "30px" }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setButtonLoading({ ...buttonLoading, generate: true })
                                                            GenerateFun()
                                                        }}
                                                    >Generate</Button>
                                                    <Button
                                                        loading={buttonLoading?.upload}
                                                        disabled={oYear?.OSC?._EINVUPLOD == "N" ? true : false}
                                                        style={{ height: "30px" }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setButtonLoading({ ...buttonLoading, upload: true })
                                                            uploadInvoice()
                                                        }}
                                                    >Upload</Button>
                                                    {
                                                        oYear?.OSC?._EWBILLGST == "Y" &&
                                                        !['NC', 'ND'].includes(obj?.DataGridRow?.FIELD01.substring(0, 2)) &&
                                                        <Button
                                                            style={{ height: "30px" }}
                                                            size="sm"
                                                            onClick={() => {
                                                                EWBForm()
                                                            }}
                                                        >EWB Details</Button>
                                                    }
                                                </Group>
                                            </Grid.Col>
                                        </Grid>
                                    </Paper>
                                </>
                        }
                    </>
            }
        </>
    )
}

export default EInvoice