import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HSNCodeHelpAPI } from '../../utils/slices/ProductList';
import GlobalClass from '../../utils/GlobalClass';
import { Grid } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useBeforeUnload } from 'react-router-dom';
import gensetting from '../../utils/gensetting';
import { ModalDelete } from '../../utils/slices/ModalSlice';

const HSNCodeHelp = (props) => {

    console.log("props", props);

    const { OBJ } = props

    const dispatch = useDispatch();
    const columnHelper = createColumnHelper()
    const { HSNCodeHelpData } = useSelector(store => store?.ProductList);
    const { HSNCodeIsLoading, HSNCodeHasError, HSNCodeErrorMsg, SaveLoading } = useSelector(store => store?.ProductList);

    const [state, setState] = useState();
    const [search, setSearch] = useState();
    const [rowSelection, setRowSelection] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [columns, setColumns] = useState([]);

    let data1 = {
        FIELD01: "",
        FIELD02: "HN",
        FIELD03: "",
        FLDUNQ: 0,
        FLDAED: "A",
        FLDBRC: ""
    }

    useEffect(() => {
        dispatch(HSNCodeHelpAPI(OBJ))
    }, [])

    useEffect(() => {
        console.log("HSNCodeHelpData", HSNCodeHelpData);
        setState(HSNCodeHelpData)
        if (HSNCodeHelpData) {
            var cols1 = [];
            cols1.push(
                columnHelper.accessor("HSNF01", {
                    header: "HSN Code",
                    // id : item?.S53UID
                })
            );
            cols1.push(
                columnHelper.accessor("HSNF02", {
                    header: "Desc",
                    // id : item?.S53UID
                })
            );
            setColumns(cols1);
        }
    }, [HSNCodeHelpData])

    useEffect(() => {
        if (HSNCodeHasError && !HSNCodeIsLoading) {
            GlobalClass.Notify("error", "Error: " + HSNCodeErrorMsg.code, HSNCodeErrorMsg.message);
        }
    }, [HSNCodeHasError]);

    // useEffect(() => {
    //     let id = Object.keys(rowSelection).join();
    //     // setSelectedRowId(id)
    //     let RowSelected = Object.values(rowSelection).join()
    //     // setIsRowSelected(RowSelected)

    //     if (RowSelected == "false") {
    //         setSelectedRow({})
    //         // setBankAmount({})
    //         // setNarrationValue("")
    //         // setBalanceObj({})
    //         // setRowID("")
    //     }
    //     // else {
    //     //     T41List?.filter((item, index) => {
    //     //         if (id && index == id) {
    //     //             setBankAmount(item)
    //     //         }
    //     //     })
    //     // }

    // }, [rowSelection])

    const saveBtn = (row) => {

        if (row) {
            let data2 = { ...data1, FIELD01: row?.HSNF01, FIELD02: row?.HSNF02 }

            var data = {
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": "A",
                "cCode": "",
                // "cOPara": "",
                "cSData": JSON.stringify(data2)
            };

            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "A",
                    "cCode": "",
                    // "cOPara": "",
                });

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };

            fetch(GlobalClass.ApiUrl + GlobalClass.PostM35HSN + "?pa=" + param, requestOptions)
                .then(response => response.json())
                .then((data) => {
                    if (data.status === 'SUCCESS') {

                        GlobalClass.Notify("success", "Success", "")
                        dispatch(ModalDelete(props.index))

                        // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                        // dispatch(GetAccountList({
                        //     id: "00930001",
                        //     name: "Product List",
                        //     p0: "MT",
                        //     p1: "T_M21",
                        //     p2: "",
                        //     p3: "",
                        //     type: "A",
                        //     pagination: PaginationObj["00930001"]
                        // }))
                    }
                    else if (data.status === 'FAIL') {
                        // Promise.resolve(dispatch(DataSaveLoading(false)))
                        GlobalClass.Notify("error", data?.status, data?.message);
                    }
                })
                .catch((e) => {
                    // dispatch(DataSaveLoading(false));
                    GlobalClass.Notify("error", "Error", `${e?.message}`);
                });
        }

    }

    const table = useMantineReactTable({
        data: HSNCodeHelpData ?? [],
        columns: columns ?? [],
        // enableFilters: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableTopToolbar: false,
        enablePagination: true,
        enableColumnActions: false,
        enableSorting: false,
        enableGlobalFilter: true,
        enableGlobalFilterModes: true,
        enableColumnFilters: false,
        enableColumnDragging: false,
        mantineTableProps:{withColumnBorders:true},
        // initialState : {
        //   globalFilter : search,
        // },
        state: {
            globalFilter: search,
            rowSelection
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            onClick: () => {
                // console.log("row",row?.original);
                // setSelectedRow(row?.original)
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
            },
            onDoubleClick: () => {
                Promise.resolve(
                    setRowSelection((prev) => ({
                        [row.id]: true,
                    }))
                )
                    .then(() => {
                        saveBtn(row?.original)
                        // simulateEnterKeyPress();
                    })
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
    });


    return (
        <>
            {
                HSNCodeIsLoading == true ? GlobalClass.RenderLoader("dots") :
                HSNCodeHelpData &&
                    <Grid>
                        <Grid.Col span={12} m={0} p={0}>
                            <Grid.Col span={8} pl={1}>
                                <PLTextBox value={search}
                                    setEdit={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                />
                            </ Grid.Col>
                        </Grid.Col>
                        <Grid.Col span={12} m={0} p={0}>
                            <MantineReactTable table={table} />
                        </Grid.Col>
                    </Grid>
            }
        </>
    )
}

export default HSNCodeHelp
