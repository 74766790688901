import { Button, Grid, Group, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox'

const RateBtnForm = (props) => {

    const { formData, onChange } = props;

    const [state, setState] = useState({})

    useEffect(() => {
        if (formData) {
            setState(formData)
        }
    }, [formData])

    const handleSubmit = () => {

    }

    return (<>
        <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Purchase Rate</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLNumberBox
                        value={state?.M21?.FIELD21}
                        decimalSeparator={true}
                        precision={3}
                        setEdit={(e) => {
                            setState(prevState => ({
                                ...prevState,
                                M21: {
                                    ...prevState?.M21,
                                    FIELD21: e
                                }
                            }))
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Sales Rate</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLNumberBox
                        value={state?.M21?.FIELD22}
                        decimalSeparator={true}
                        precision={3}
                        setEdit={(e) => {
                            setState(prevState => ({
                                ...prevState,
                                M21: {
                                    ...prevState?.M21,
                                    FIELD22: e
                                }
                            }))
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12}>
                <Group position="center">
                    <Button
                        style={{ height: "30px", width: "100px" }}
                        onClick={() =>onChange(state) }
                    >Ok</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </>)
}

export default RateBtnForm