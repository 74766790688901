import React from "react";
import Store from "../../utils/store";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import StockJournalAddForm from "./StockJournalAddForm";
import { Text } from "@mantine/core";
import GlobalClass from "../../utils/GlobalClass";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import StockTransferForm from "./StockTransfer/StockTransferForm";
import { GetNullTransactionData, TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import PLRange from "../../PlusComponent/PLRange";

export function VouEntAct(props) {
  const data = JSON.parse(props);

  // console.log("props VouEntAct===============>", data);

  if (data?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position:'right',
        MBody: (i) => <StockJournalAddForm obj={data} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (data?.id && data?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position:'right',
        MBody: (i) => <StockJournalAddForm obj={data} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (data.p0 === "D" && data.id) {
    if (window.confirm("Confirm to Delete ?")) {
       Store.dispatch(TransactionDeleteApi(data));
    }
  } else if (data.p0 === "U" && data?.id) {
    console.log(data?.id, "data?.id")

    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{data?.text}</Text>,
        MAction: true,
        MSize: "50%",
        // position:'right',
        MBody: (i) => <StockJournalAddForm obj={data} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (data.p0 === 'T' && data?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
        Store.dispatch(TransactionDeleteApi(data))
    }

  }
  // else if(data.p0=="R"){
  //   Store.dispatch(
  //     ModalFunction({
  //       onclose: () => {},
  //       MTitle: <Text fw={500}>Enter Voucher No. Range</Text>,
  //       MAction: true,
  //       MSize: "98%",
  //       position:"right",
  //       MBody: (i) => <PLRange obj={data} index={i} />,
  //       MClose: true,
  //       Overlay: {
  //         backgroundOpacity: 0.8,
  //         blur: 5,
  //       },
  //     })
  //   );
  // }

  // else if (data?.id && data?.p0 == "D") {
  //   // console.log(data?.id, "data?.id");

  //   if (window.confirm("Confirm to Delete?")) {
  //     Promise.resolve(Store.dispatch(DataSaveLoading(true)))
  //       .then(
  //         Promise.resolve(
  //           GlobalClass.Notify(
  //             "info",
  //             "Info: " + "Delete Progress",
  //             "Please Wait Fetching Data"
  //           )
  //         )
  //       )
  //       .then(() => Store.dispatch(GetStockJournalEnt(data)))
  //       .then((data) => {

  //         if (data?.meta?.requestStatus == "fulfilled") {
  //           Promise.resolve(Store.dispatch(GetStockJournalGen()))
  //             .then(() => Store.dispatch(GetStockJournalTM()))
  //             .then(() =>
  //               Promise.resolve(Store.dispatch(DataSaveLoading(false)))
  //             )
  //             .then(() =>
  //               GlobalClass.Notify(
  //                 "success",
  //                 "Successful",
  //                 "Deleted Successfully"
  //               )
  //             );
  //         }
  //       });
  //   }
  // }
}
