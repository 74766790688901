import Dexie from "dexie";

let offlinePages = [
    { "T_M01": "FIELD01,FIELD02,FIELD40,FIELD22,FIELD07,FIELD08,FIELD99,FIELD36,FIELD17,FIELD37,FIELD05,FIELD16,FIELD18,M01F99CR,M01F99DB,FIELD13,FIELD06,FIELD04,GAPGRPNAME" },
    { "T_M21": "FIELD01,FIELD02,FIELD03,FIELD40,FIELD11,FIELD12,GAGRPNAME,GCCATNAME,FIELD21,FIELD22" },
    { "P_M01": "FIELD01,FIELD02,FIELD40,FIELD22,FIELD07,FIELD99,FIELD36,FIELD17,FIELD37,FIELD05,FIELD16,FIELD18,FIELD13,FIELD08,FIELD20,FIELD06,FIELD04,FIELD35" }
];

function transformArrayToObject(inputArray) {
    return inputArray.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        acc[key] = Object.values(item)[0];
        return acc;
    }, {});
}

const db = new Dexie("MasterDataDB");

if (process.env.REACT_APP_isOfflineData) {
    db.version(1).stores(transformArrayToObject(offlinePages));

    db.parseSQLCondition = (data, query) => {
        const operators = ["=", "AND", "OR", "LIKE", "!=", "IN"];
        const conditions = query.split(/(AND|OR)/).map((item) => item.trim());
        const filters = [];

        try {
            for (let i = 0; i < conditions.length; i++) {
                const conditionParts = conditions[i]
                    .replace(/\s+/g, " ")
                    .replace(/^\w+\./, "") // Remove table prefix
                    .match(/([\w]+)\s*(=|!=|LIKE|IN)\s*(.+)/i); // Match field, operator, and value

                if (!conditionParts) continue;

                const [, field, operator, value] = conditionParts;

                let cleanedValue = value.trim();
                if (operator === "IN") {
                    cleanedValue = cleanedValue
                        .replace(/^\(|\)$/g, "")
                        .split(",")
                        .map((v) => v.trim().replace(/['"]/g, ""));
                } else {
                    cleanedValue = cleanedValue.replace(/['"]/g, "");
                }

                if (operators.includes(operator)) {
                    filters.push({ field, operator, value: cleanedValue });
                }
            }

            return data.filter((item) => {
                let match = false;
                let lastCondition = null;

                filters.forEach(({ field, operator, value }, index) => {
                    let currentMatch = false;

                    if (operator === "=") currentMatch = item[field] === value;
                    else if (operator === "!=") currentMatch = item[field] !== value;
                    else if (operator === "LIKE") currentMatch = item[field]?.includes(value);
                    else if (operator === "IN") currentMatch = value.includes(item[field]);

                    if (index === 0) match = currentMatch;
                    else match = lastCondition === "OR" ? match || currentMatch : match && currentMatch;

                    lastCondition = conditions[index * 2 - 1];
                });

                return match;
            });
        } catch (error) {
            console.error("Error in parseSQLCondition:", error);
            return [];
        }
    };
}

function arrayIncludesKey(array, key) {
    return array.some((item) => key in item);
}

export const saveToIndexedDB = async (id, data) => {
    try {
        if (arrayIncludesKey(offlinePages, id)) {
            await db[id].bulkPut(data);
        }
    } catch (error) {
        console.error(`Error saving to IndexedDB (Table: ${id}):`, error);
    }
};

export const getFromIndexedDB = async (id, cWhere = "") => {
    try {
        if (arrayIncludesKey(offlinePages, id)) {
            let data = await db[id].toArray();
            console.log("getFromIndexedDB", data);
            if (cWhere) {
                return await db.parseSQLCondition(data, cWhere);
            } else {
                return data;
            }
        } else {
            console.warn(`Table ${id} not found in offlinePages.`);
            return [];
        }
    } catch (error) {
        console.error(`Error retrieving from IndexedDB (Table: ${id}):`, error);
        return [];
    }
};

export default db;
