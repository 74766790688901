import React from "react";
import Store from "../../utils/store";
import { Text } from "@mantine/core";
// import { notifications } from "@mantine/notifications";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
// import OpeningStockEditForm from "./OpeningStockEditForm";
import OpeningStockAddForm from "./OpeningStockForm";
// import { GetOpeningStockEnt } from "../../utils/TransactionSlices/openingStockEntSlice";

// import { GetOpeningStockTM } from "../../utils/TransactionSlices/openingStockSliceTM";
// import { GetOpeningStockGen } from "../../utils/TransactionSlices/openingStockSliceGen";
// import { setToggleButton } from "../../utils/TransactionSlices/openingStockEntSlice";
// import { useSelector } from "react-redux";
import GlobalClass from "../../utils/GlobalClass";
import { GetNullTransactionData, TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";
import { GetExpenseDataNull, GetInvTypeDropDown, GetPendingOrderDataNull, GetPendingQuotationDataNull, GetSingleOrderRowNull, GetSingleSelectedOrderRowNull } from "../../utils/TransactionSlices/TSalesSlices";
import PLRange from "../../PlusComponent/PLRange";
import { GetAccountList, getRangeBtnState } from "../../utils/slices/AccountListSlice";

export function VouEntAct(props) {
  const data = JSON.parse(props);
  if (data.id && data?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position:"right",
        MBody: (i) => <OpeningStockAddForm obj={data} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (data?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position:"right",
        MBody: (i) => <OpeningStockAddForm obj={data} index={i} />,
        // MBody: (i) => <OpeningStockForm/>,
        onClickCloseButton: () => {
        },
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (data?.id && data?.p0 == "D") {
    if (window.confirm("Are you sure to delete selected data?")) {
       Store.dispatch(TransactionDeleteApi(data));
    } else {
      return null;
    }
  }else if (data.p0 === 'T' && data?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
        Store.dispatch(TransactionDeleteApi(data))
    }
}else if (data.id && data.p0 === "P") { // Print 
    // eval('TM_PRINTVF('+OBJ+')')
    TM_PRINTVF(props);
}else if (data.p0 === 'U' && data?.id) { // UserField
  Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{data?.text}</Text>,
      MAction: true,
      MSize: "98%",
      // position:'right',
      MBody: (i) => (<OpeningStockAddForm obj={data} index={i} />),
      MClose: true,
      onclose: () => { },
      onClickCloseButton: () => {
        Store.dispatch(ModalDelete()) 
      }
  }))
}else if(data.p0=="R"){
  // const dateFormater2 = (inputDate) => {
  //   // Yasvi Patel
  //   const date = new Date(inputDate);
  //   console.log("date inside", inputDate);
  //   // Extract components
  //   const year = date.getUTCFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");
  //   // Get the timezone offset
  //   const timezoneOffset = -date.getTimezoneOffset(); // in minutes
  //   const offsetHours = String(
  //     Math.floor(Math.abs(timezoneOffset) / 60)
  //   ).padStart(2, "0");
  //   const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(
  //     2,
  //     "0"
  //   );
  //   const timezoneString =
  //     (timezoneOffset >= 0 ? "+" : "-") + offsetHours + ":" + offsetMinutes;

  //   // Construct the final output
  //   const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00${timezoneString}`;

  //   return formattedDate;
  // };
  // const state = Store.getState();
  // const rangeBtnState = state?.AccountList?.rangeBtnState;
  // const currentDate = state?.AccountList?.currentDate;
  // console.log("date here",data)
  // console.log("currentDate",currentDate)
  // if(currentDate?.[data?.page]!=undefined && rangeBtnState?.[data?.page] == false){
  //   Promise.resolve(
  //     Store.dispatch(
  //       getRangeBtnState({ id: [data.page], rangeBtnState: true })
  //     )
  //   )
  //   .then(() => {
  //     Store.dispatch(
  //       GetAccountList({
  //         ...data?.BtnPnlObj,
  //         StrtDt: dateFormater2(currentDate?.[data?.page]?.From), // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
  //         EndDt: dateFormater2(currentDate?.[data?.page]?.To),
  //       })
  //     );
  //   });
  // }else{
  Store.dispatch(
    ModalFunction({
      onclose: () => {},
      MTitle: <Text fw={500}>Enter Voucher No. Range</Text>,
      MAction: true,
      MSize: "98%",
      position:"right",
      MBody: (i) => <PLRange obj={data} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
// }
}
}
