import { Textarea } from "@mantine/core";
import React from "react";

const PLTextarea = (props) => {
  return (
    <Textarea
      id={props.id} // deval
      minRows={props?.minRows}
      value={props?.value}
      onChange={(e) => props?.onChange(e)}
      onKeyDown={(e) =>
        props?.onKeyDown && typeof props?.onKeyDown == "function"
          ? props?.onKeyDown(e)
          : null
      }
      size={props?.size ? props?.size : 'xs'} // deval
      onFocus={(e) => {
        // console.log(e, "onFocusIn");
        if (props?.onFocus && typeof props?.onFocus == "function")
          //   ?
          props?.onFocus(e);
        //   :
        else return null;
      }}
    />
  );
};

export default PLTextarea;
