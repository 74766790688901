import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";

export const GetTDSMasterGroup = createAsyncThunk(
  "TDSMasterGroup/GetTDSMasterGroup",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: "P_M53DT",
        cFmtID: obj?.fmtId,
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetGenData + "?pa=" + param
      );
      // console.log("response",response);
      return response;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
const TDSMasterGroupSlice = createSlice({
  name: "TDSMasterGroup",
  initialState: {
    TDSMasterGroupData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetTDSMasterGroup.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.TDSMasterGroupData = [];
      })
      .addCase(GetTDSMasterGroup.fulfilled, (state, action) => {
        state.TDSMasterGroupData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetTDSMasterGroup.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
  },
});
export default TDSMasterGroupSlice.reducer;
