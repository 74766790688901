import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import gensetting from '../gensetting';
import GlobalClass from '../GlobalClass';
import axios from 'axios';

// voucher setup/sales invoice/entry options/cash party detail
export const GetCashPartyDetail = createAsyncThunk(
    "VoucherSetupPopUp/GetCashPartyDetail",
    async () => {
        try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(
                GlobalClass.ApiUrl + GlobalClass.GetF02CashPartyDetails + "?pa=" + param
            );
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error };
        }
    }
)

// voucher setup/sales invoice/entry options/GST Rules to check for
export const GetGSTRulesCheck = createAsyncThunk(
    "VoucherSetupPopUp/GetGSTRulesCheck",
    async () => {
        try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(
                GlobalClass.ApiUrl + GlobalClass.GetF02GSTRules + "?pa=" + param
            );
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error };
        }
    }
)

// voucher setup/sales invoice/other options/PLUSM471
export const GetPLUSM471 = createAsyncThunk(
    "VoucherSetupPopUp/GetPLUSM471",
    async () => {
        try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "PP,SS",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(
                GlobalClass.ApiUrl + GlobalClass.GetF02PendingVoucherList + "?pa=" + param
            );
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error };
        }
    }
)

// setup/voucher setup/sales invoice/product entry option/applicable % of tax rate (65%)
export const GetApplicableTaxRate = createAsyncThunk(
    "VoucherSetupPopUp/GetApplicableTaxRate",
    async () => {
        try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "SS",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(
                GlobalClass.ApiUrl + GlobalClass.GetF02ProductHistoryDetails + "?pa=" + param
            );
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error };
        }
    }
)

// setup/voucher setup/sales invoice/entry options/group filter in party
export const GetGroupFilterParty = createAsyncThunk(
    "VoucherSetupPopUp/GetGroupFilterParty",
    async () => {
        try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "",
                cCode: "P_M11",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(
                GlobalClass.ApiUrl + GlobalClass.GetF02PIDData + "?pa=" + param
            );
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error };
        }
    }
)

const VoucherSetupPopUpSlice = createSlice({
    name: "VoucherSetupPopUp",
    initialState: {
        CashPartyDetailData: [],
        GSTRulesCheckData: [],
        PLUSM471Data: [],
        ApplicableTaxRate: [],
        GroupFilterPartyData: [],
        isLoading: false,
        hasError: false,
        successMsg: "",
        ErrorMsg: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetCashPartyDetail.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetCashPartyDetail.fulfilled, (state, action) => {
                state.CashPartyDetailData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetCashPartyDetail.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })

            .addCase(GetGSTRulesCheck.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetGSTRulesCheck.fulfilled, (state, action) => {
                state.GSTRulesCheckData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetGSTRulesCheck.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })

            .addCase(GetPLUSM471.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetPLUSM471.fulfilled, (state, action) => {
                state.PLUSM471Data = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetPLUSM471.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })

            .addCase(GetApplicableTaxRate.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetApplicableTaxRate.fulfilled, (state, action) => {
                state.ApplicableTaxRate = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetApplicableTaxRate.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })

            .addCase(GetGroupFilterParty.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetGroupFilterParty.fulfilled, (state, action) => {
                state.GroupFilterPartyData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetGroupFilterParty.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
    }
});

export const { } = VoucherSetupPopUpSlice.actions

export default VoucherSetupPopUpSlice.reducer