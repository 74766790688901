import React from "react";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import GlobalClass from "../../../utils/GlobalClass";
import { Loader, Text } from "@mantine/core";
import RIPForm from "../RIPForm/RIPForm";
import { GetNullTransactionData, TransactionDeleteApi } from "../../../utils/TransactionSlices/Transaction";
import { RefreshIOrderPopUp, RefreshIssuePopUp } from "../../../utils/TransactionSlices/PendingIssueSlice";

export function VouEntAct(props) {

  const data = JSON.parse(props);
  console.log(data, "VouEntAct--");

  if (data?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "100%",
        MBody: (i) => (
          <RIPForm obj={data} index={i} No="Voucher No" date="Voucher Date" />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([Store.dispatch(RefreshIssuePopUp({})), Store.dispatch(RefreshIOrderPopUp({}))]).then(()=>Store.dispatch(GetNullTransactionData()));
      }
      })
    );
  } else if (data?.id && data?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "100%",
        MBody: (i) => <RIPForm obj={data} index={i} No="Voucher No" date="Voucher Date"/>,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([Store.dispatch(RefreshIssuePopUp({})), Store.dispatch(RefreshIOrderPopUp({}))]).then(()=>Store.dispatch(GetNullTransactionData()));
      }
      })
    );
  } else if (data.p0 === "D" && data.id) {
    if (window.confirm("Confirm to Delete ?")) {
      Promise.resolve(
        GlobalClass.Notify(
          "info",
          "Delete In Progress",
          "Please Wait Fetching Data"
        )
      ).then(() => Store.dispatch(TransactionDeleteApi(data)));
    }
  } else if (data.p0 === "U" && data?.id) {
    console.log(data?.id, "data?.id")

    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{data?.text}</Text>,
        MAction: true,
        MSize: "100%",
        // position:'right',
        MBody: (i) => <RIPForm obj={data} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          
          Promise.all([
            Store.dispatch(RefreshIssuePopUp({})),
            Store.dispatch(RefreshIOrderPopUp({})),
          ]).then(()=>Store.dispatch(GetNullTransactionData()));        }
      })
    );
  } else if (data.p0 === 'T' && data?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
        Store.dispatch(TransactionDeleteApi(data))
    }
}
}
