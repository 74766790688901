import React from "react";

import {
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import appInfo from "../../MyComponent/app-info"; //app-info
import { modals } from "@mantine/modals";
import Store from "../../utils/store";
import { GetM01List } from "../../utils/slices/M01FormSlice";
import M01Forms from "./M01Forms";
import { Text } from "@mantine/core";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";
import { TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";
import { TabAdd } from "../../utils/slices/TabList";

// import appInfo, { callApi } from '../app-info';

// import gensetting from '../utils/gensetting';
// import APIInterface from '../utils/APIInterface';
const M01 = (data) => { };

export async function M01AED(data, Skey) {
  const OBJ = JSON.parse(data);
  console.log("OBJJ M01AED=>", OBJ);
  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position: 'right',
        MBody: (i) => <M01Forms OBJ={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if ((OBJ.p0 === "E" || OBJ?.p0 == "R") && OBJ.id && OBJ.id !== "") {
    Store.dispatch(
      ModalFunction({
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: OBJ?.page=="07890783" && OBJ?.text=="Edit" ? "md" : "xl",
        position: 'right',
        MBody: (i) =>
          OBJ?.DataGridRow?.FIELD01?.charAt(0) == "A" || OBJ?.page =="07890783" ? (
            <M01Forms OBJ={OBJ} index={i} />
          ) : (
            GlobalClass.Alert("info", i, true, OBJ?.p0)
          ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 === "D" && OBJ.id && OBJ.id !== "") {
    // Store.dispatch(GetM01List(OBJ.p0, Skey))
    // let param = JSON.stringify({
    //     "CorpID": gensetting.getcorpid(),
    //     "cUser": gensetting.getcuser(),
    //     "cPass": gensetting.getcpass(),
    //     "CmpNo": gensetting.getcmpno(),
    //     "cAction": 'D',
    //     "cCode": Skey
    // });
    // console.log("params delete__", param);
    // const data = await callApi(APIInterface.GetM01, param, 'GET');
    // console.log('data', data);
    // if (data.status === 'SUCCESS') {
    //     console.log('Success:', data);
    //     notify("Record Delete Successfully");
    // } else {
    //     console.log('Failed', data.status);
    //     notify(data.message);
    // }

    if (window.confirm("Confirm to Delete ?")) {
      // Store.dispatch(deleteUserData({ action: OBJ.p0, code: OBJ.M41UID }));
      // Skey: 'AHUHBIZ3', OBJ: 'E'
      OBJ?.DataGridRow?.FIELD01?.charAt(0) == "A" ? (

        Store.dispatch(GetM01List({ Skey: OBJ.id, OBJ: OBJ.p0 }))
      ) : (
        Store.dispatch(
          ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "xl",
            position: 'right',
            MBody: (i) => (
              GlobalClass.Alert("info", i, true, OBJ?.p0)
            ),
            MClose: true,
            Overlay: {
              backgroundOpacity: 0.8,
              blur: 5,
            },
          })
        )
      )
    }
  } else if (OBJ.p0 === 'F' && OBJ?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
      Store.dispatch(GetM01List({ Skey: OBJ.id, OBJ: OBJ.p0 }));
    }
  }else if (OBJ.id && OBJ.p0 === "P") {
    // eval('TM_PRINTVF('+OBJ+')')
    TM_PRINTVF(data);
  }else if(OBJ.p0 === 'L' && OBJ?.id){
    let obj;
    obj = {
        name: OBJ?.DataGridRow?.FIELD02,
        type: "A",
        id: OBJ.id,
        p0: "ASLEDGER",
        p1: "ASLEDGER",
        p2: "TFORM0000001",
        OParam: "A" + "CODE~K~" + OBJ?.DataGridRow?.FIELD01
    }
    Store.dispatch(TabAdd(obj));
  }
}

function M01ADD(data) {
  const history = useNavigate();
  if (data === "A") {
    //history.location = "http://localhost:3000/#/m01form";
    //    return <Redirect to={'/M01Form'} />
    //window.open("http://localhost:3000/#/m01form");
    history({
      pathname: "/home",
      state: { popupVisible: true },
    });
    //history('/m01form');
    //history.goBack();
    //return <Redirect to={'/home'} />
    //  history("/M01Form");
    //return "/M01Form";
  } else {
    // notify(data);
  }
}

// export async function TM_PRINT(data,Skey){
//     console.log("hello from print function");
//     console.log("data",data);

// }

export default M01;
