import {
  Button,
  Center,
  Divider,
  Drawer,
  Grid,
  Group,
  Paper,
  Table,
  Text,
  Textarea,
} from "@mantine/core";
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useState } from "react";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import QueryGenerator from "./QueryGenerator";
import useWindowDimensions from "../../../utils/UseWindowDimensions";

export default function UserFieldEquEntry() {
  const [valueEq, setValueEq] = useState(false);
  const [preEq, setPreEq] = useState(false);
  const [postEq, setPostEq] = useState(false);
  const { height } = useWindowDimensions();

  let columns = [
    {
      accessor: "Field ID", // Use 'accessor' instead of 'accessorKey'
      header: "Field ID",
      Cell: ({ row }) => {
        // console.log(row.original.PrName, "getVal");
        return row?.original?.PrName;
      },
    },
    {
      accessor: "Description", // Convert index to string
      header: "Description",
      Cell: ({ row }) => {
        // console.log(row.original, "row.orginal");
        // return (
        //   <Button onClick={() => generateBill(row?.original)}>
        //     Generate Bill
        //   </Button>
        // );
      },

      maxSize: 100,
    },
  ];

  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: [],
    columns: columns,
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    initialState: {
      // density: "xs",
      // expanded: true,
    },
    mantineTableProps:{withColumnBorders:true},
    state:{
      expanded: true,
    },
    enableBottomToolbar: false, //pagination remove
    enableRowVirtualization: true,
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableContainerProps: {
      sx: { height: height * 0.62 , maxHeight: "600px" },
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {},
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      height: 50,
    }),
  });
  return (
    <>
      <Paper shadow="md" radius="md" p="xs" withBorder>
        <Grid style={{ marginTop: "1px", padding: "0px" }}>
          <Grid.Col span={12} style={{ display: "flex" }}>
            <Grid.Col span={5}>
              <Divider
                my="xs"
                label="Select User Fields"
                labelPosition="left"
                labelProps={{ component: "strong" }}
              />
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>
              <Center>
                <Group>
                  <Button size="xs" compact>
                    Edit
                  </Button>
                  <Button size="xs" compact>
                    Notes
                  </Button>
                  <Button size="xs" compact>
                    Filter
                  </Button>
                  <Button size="xs" compact>
                    Delete
                  </Button>
                </Group>
              </Center>
            </Grid.Col>
            <Grid.Col span={7}>
              <Divider
                my="xs"
                label="Value Equation"
                labelPosition="left"
                labelProps={{ component: "strong" }}
              />
              {valueEq ? (
                <QueryGenerator />
              ) : (
                <Textarea
                  onFocus={() => {
                    setValueEq(true);
                    setPreEq(false);
                    setPostEq(false);
                  }}
                  minRows={3}
                  maxRows={4}
                />
              )}
              <Divider my="xs" />
              <Divider
                my="xs"
                label="Pre-Valid Equation"
                labelPosition="left"
                labelProps={{ component: "strong" }}
              />
              {preEq ? (
                <QueryGenerator />
              ) : (
                <Textarea
                  onFocus={() => {
                    setPreEq(true);
                    setValueEq(false);
                    setPostEq(false);
                  }}
                  minRows={3}
                  maxRows={4}
                />
              )}
              <Divider my="xs" />
              <Divider
                my="xs"
                label="Post-Valid Equation"
                labelPosition="left"
                labelProps={{ component: "strong" }}
              />
              {postEq ? (
                <QueryGenerator />
              ) : (
                <Textarea
                  onFocus={() => {
                    setPostEq(true);
                    setValueEq(false);
                    setPreEq(false);
                  }}
                  minRows={3}
                  maxRows={4}
                />
              )}
              <Divider my="xs" />
              <Center mt={5}>
                <Group>
                  <Button size="xs" compact>
                    Save
                  </Button>
                  <Button size="xs" compact>
                    Cancel
                  </Button>
                </Group>
              </Center>
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
}
