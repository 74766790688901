import { createColumnHelper } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import React, { useEffect, useState } from 'react'

const FinYearDelete = (props) => {
    const { OBJ } = props
    console.log('Fin.Year Delete OBJ==>>', OBJ)

    const [columns, setColumns] = useState([])

    const columnName = [
        {
            key:'no.',
            header : 'No.'
            },
            {
                key:'from date',
                header : 'From Date'
            },
            {
                key:'to date',
                header : 'To Date'
            }
    ]
    const [data, setData] = useState([])

    const columnHelper = createColumnHelper();

    useEffect(() => {
        if (columnName) {
            let cols = []
            columnName.map((v,i) => {
                cols.push(
                    columnHelper.accessor(v.key, {
                        header: v.header,
                    })
                )
            })
            return setColumns(cols)
        }
    }, [])

    const table = useMantineReactTable({
        columns,
        data:[],
        mantineTableProps:{withColumnBorders:true},
        enableTopToolbar: false,
        enableColumnActions: false,
        enableSorting: false,
        enableStickyHeader: true,
        enableMultiRowSelection: false,
        enableSelectAll: false,
    })

    return (
       <>
       <MantineReactTable table={table} />
       </>
    )
}

export default FinYearDelete