import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetHeaderData, GetM24PriceList, GetMultiSelectHeaderData, M25List } from '../../utils/slices/PriceListSlice';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { ActionIcon, Button, Flex, Grid, Group, Input, Paper, Text, Tooltip } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import GlobalClass from '../../utils/GlobalClass';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import gensetting from '../../utils/gensetting';

const M25DataTable = (props) => {
  const { obj } = props
  // console.log('M25Datatable props', obj)
  const M25Data = useSelector((state) => state.M24PriceList?.M25PriceListData)
  const M25tbl = useSelector((state) => state.M24PriceList?.M25PriceListData?.M25tbl)
  const DTPR = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.DTPR
  );
  // console.log('DTPR', DTPR)
  const DTPD = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.DTPD
  );
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.M24PriceList
  );
  // console.log('DTPD', DTPD)
  const headerData = useSelector((state) => state.M24PriceList?.headerData)
  const multiSelectHeaderData = useSelector((state) => state.M24PriceList?.multiSelectHeaderData)


  const [tableData, setTableData] = useState([])
  const [columns, setColumns] = useState([])
  const [rowSelection, setRowSelection] = useState({ 0: true })
  const [PrtArr, setPrtArr] = useState([]);
  const [prdArr, setPrdArr] = useState([]);
  const [curMode, setCurMode] = useState('PD'); // Default to Product Mode
  const [updatedData, setUpdatedData] = useState([])
  const [level, setLevel] = useState(1); // Initial level
  const [buttonCaptions, setButtonCaptions] = useState({
    button1: '',
    button2: '',
    button3: '',
  });
  const [partyButtonCaption, setPartyButtonCaption] = useState({
    button4: '',
    button5: '',
    button6: ''
  })
  const [headerProductName, setHeaderProductName] = useState('')
  const [headerpPartyName, setHeaderPartyName] = useState('')
  const [disableInput, setDisableInput] = useState(true)
  const [rowData, setRowData] = useState({
    "FIELD01": "",
    "FIELD02": "",
    "FIELD03": 0.0000,
    "FIELD04": 0.0000,
    "FIELD05": 0.0000,
    "FIELD06": 0.0000,
    "FIELD21": 0.000,
    "FIELD22": 0.000,
    "FIELD23": 0.000,
    "FIELD24": "",
    "FIELD25": "",
    "FIELD31": "",
    "FIELD42": "",
    "FLDUNQ": null,
    "FLDAED": "",
    "FLDBRC": ""
  })
  const [comboData, setComboData] = useState([])
  const [show, setShow] = useState(true)
  const [defaultInputVal, setDefaultInputVal] = useState(null)
  // console.log('defaultInputVal', defaultInputVal)
  const [hideButton, setHideButton] = useState(false)
  const [PrtLevel, setPrtLevel] = useState(1);
  const [PrdLevel, setPrdLevel] = useState(1);

  const dispatch = useDispatch()

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (obj) {
      Promise.resolve(dispatch(M25List({ action: '', code: obj.id })))
        .then(() => {
          dispatch(GetM24PriceList())
        })
    }
  }, [obj])

  useEffect(() => {
    // console.log('hideButton', hideButton)
    console.log('partyArr', PrtArr)
    console.log('prdArr', prdArr)
    console.log('level', level)
    console.log('curMode', curMode)
    console.log('prtLevel', PrtLevel)
    console.log('prdLevel', PrdLevel)
    console.log('buttonCaptions', buttonCaptions)
    // console.log('tableData', tableData)
    // console.log('headerData', headerData)
    // console.log('M25tbl', M25tbl)
    // console.log('show', show)
    // console.log('updatedData', updatedData)
    // console.log('defaultInputVal', defaultInputVal)
    // console.log('M25Data==>', M25Data)
    // console.log('multiSelectHeaderData', multiSelectHeaderData)
    // console.log('rowSelection', rowSelection)
    console.log('rowData', rowData)
  }, [rowSelection, rowData, PrtLevel, PrdLevel, defaultInputVal, level, curMode, show, hideButton, buttonCaptions, tableData, headerData, M25tbl, updatedData, PrtArr, prdArr, M25Data, multiSelectHeaderData])

  let value = obj?.DataGridRow?.FIELD22
  // console.log('value', value)
  let FIELD28 = obj.DataGridRow?.FIELD28

  useEffect(() => {
    let partyVal;
    let productVal;
    const populateVal = value.split("#")
    partyVal = populateVal[0].split(',')
    productVal = populateVal[1].split(',')
    console.log('populateVal', populateVal)
    console.log('partyVal', partyVal)
    console.log('productVal', productVal)
    if (DTPR) {
      const filteredPrtArr = DTPR.filter((item) => partyVal.includes(item?.CTBLNM))
      setPrtArr(filteredPrtArr)
    }
    if (DTPD) {
      const filteredPrdArr = DTPD.filter((item) => productVal.includes(item?.CTBLNM))
      setPrdArr(filteredPrdArr)
    }
  }, [value, DTPR, DTPD])

  // default value set
  useEffect(() => {
    if (FIELD28 == "M") {
      if (curMode == "PD") {
        if (level > PrtArr.length && level > prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0) {
          setDefaultInputVal(M25Data[prdArr[level - 3]?.TBLKEY]?.[0])
        } else if (level < prdArr.length && level < PrtArr.length && multiSelectHeaderData?.productGroupData?.length == 0) {
          setDefaultInputVal(M25Data?.[prdArr[level]?.TBLKEY]?.[0])
        } else if (level == PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0) {
          setDefaultInputVal(M25Data?.[prdArr[level]?.TBLKEY]?.[0])
        } else if (level < PrtArr?.length && level == prdArr.length && multiSelectHeaderData?.productData?.length == 0) {
          setDefaultInputVal(M25Data?.[prdArr[level - 1]?.TBLKEY]?.[0])
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 && multiSelectHeaderData?.productData?.length == 0) {
          setDefaultInputVal(M25Data[prdArr[level - 1]?.TBLKEY]?.[0])
        } else if (level == PrtArr.length && level == prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0) {
          setDefaultInputVal(M25Data?.[prdArr[level - 2]?.TBLKEY]?.[0])
        }
      } else if (curMode == "PR") {
        if (level > PrtArr.length && level > prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0) {
          setDefaultInputVal(M25Data[PrtArr[level - 3]?.TBLKEY]?.[0])
        } else if (level == PrtArr?.length && level > prdArr.length && multiSelectHeaderData?.productData?.length == 0) {
          setDefaultInputVal(M25Data?.[prdArr[level - 2]?.TBLKEY]?.[0])
        } else if (level == PrtArr.length && level == prdArr.length && multiSelectHeaderData?.productData?.length == 0 && multiSelectHeaderData?.productGroupData?.length == 0) {
          setDefaultInputVal(M25Data[prdArr[level - 2]?.TBLKEY]?.[0])
        }
      } else if (curMode == "GRP") {
        if (level == PrtArr?.length && level == prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0) {
          setDefaultInputVal(M25Data?.[prdArr[level - 1]?.TBLKEY]?.[0])
        }
      }
    }
  }, [FIELD28, PrtArr, prdArr, curMode, level, multiSelectHeaderData])

  // show button set
  useEffect(() => {
    if (FIELD28 == "S") {
      if (curMode == "PD") {
        if (level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          return setShow(!show)
        } else if (level > prdArr.length && level == PrtArr.length) {
          return setShow(!show)
        } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          console.log('else if second')
          return setShow(!show)
        }
      }
    } else {
      if (curMode == "PD") {
        if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          return setShow(!show)
        } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          return setShow(!show)
        }
      }
    }
  }, [FIELD28,
    curMode,
    // level,
    // PrtArr,
    // prdArr,
    // M25Data,
    //  level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE, 
    level > prdArr.length && level == PrtArr.length,
    level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE,
    level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE,
  ])

  // Update button captions based on curMode and level
  useEffect(() => {
    if (FIELD28 == "M") {

      if (curMode === 'PD') {

        if (level < prdArr.length && level < PrtArr.length) {
          console.log('level < prdArr.length && level < PrtArr.length')
          setHeaderPartyName(PrtArr[level]?.DISPNM)
          setHeaderProductName(prdArr[level]?.DISPNM)
          setHideButton(true)
          setButtonCaptions({ // product level multiselect and partylevel multiselect
            button1: `${PrtArr[level - 1]?.DISPNM}wise`,
            button2: `Change ${prdArr[level]?.DISPNM}`,
            button3: `${prdArr[level]?.DISPNM}wise`,
          });
          setPartyButtonCaption({
            button4: `${PrtArr[level - 1]?.DISPNM}wise`,
          })
        } else if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setHeaderProductName("Party")
        } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setHeaderProductName("Product")
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setHeaderProductName(prdArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${prdArr[level - 1]?.DISPNM}`,
            button3: `${prdArr[level - 1]?.DISPNM}wise`
          })
        } else if (level == prdArr.length && level == PrtArr.length) {
          console.log('level == prdArr.length && level == PrtArr.length')
          setHeaderPartyName(prdArr[level - 2]?.DISPNM)
          setHeaderProductName(PrtArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${PrtArr[level - 1]?.DISPNM}`,
            button3: `${PrtArr[level - 1]?.DISPNM}wise`
          })
        } else if (level > prdArr.length && level > PrtArr.length) {
          setHeaderPartyName(PrtArr[level - 3]?.DISPNM)
          setHeaderProductName(prdArr[level - 3]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${prdArr[level - 3]?.DISPNM}`,
            button3: `${prdArr[level - 3]?.DISPNM}wise`
          })
        } else if (level > PrtArr.length && level < prdArr.length) {
          setHeaderProductName('Party')
          setHeaderPartyName(prdArr[level]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${prdArr[level - 1]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level > prdArr.length) {
          setHeaderProductName('Product')
          setHeaderPartyName(PrtArr[level]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${PrtArr[level - 1]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && level < prdArr.length && prdArr?.length == 3) {
          setHeaderProductName("Party")
          setHeaderPartyName(prdArr[level]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${prdArr[level + 1]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setHeaderProductName("Party")
          setHeaderPartyName(prdArr[level]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${prdArr[level - 1]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && level < prdArr.length) {
          setHeaderProductName(prdArr[level]?.DISPNM)
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${prdArr[level]?.DISPNM}`,
            button3: `${prdArr[level]?.DISPNM}wise`
          });
        } else if (level > PrtArr.length && level == prdArr.length) {
          setHeaderProductName(PrtArr[level - 2]?.DISPNM)
          setHeaderPartyName(prdArr[level - 2]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${PrtArr[level - 2]?.DISPNM}`,
            button3: `${PrtArr[level - 2]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
          setHeaderProductName("Product")
          setHeaderPartyName(PrtArr[level]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${PrtArr[level + 1]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setHeaderProductName("Product")
          setHeaderPartyName(PrtArr[level]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${PrtArr[level - 1]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level == prdArr.length) { // party,city + product
          setHeaderProductName(prdArr[level - 1]?.DISPNM)
          setHeaderPartyName(PrtArr[level]?.DISPNM)
          setButtonCaptions({
            button1: `${PrtArr[level - 1]?.DISPNM}wise`,
            button2: `Change ${prdArr[level - 1]?.DISPNM}`,
            button3: `${prdArr[level - 1]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && level > prdArr.length) {
          setHeaderProductName(PrtArr[level - 2]?.DISPNM)
          setHeaderPartyName(prdArr[level - 2]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${PrtArr[level - 2]?.DISPNM}`,
            button3: `${PrtArr[level - 2]?.DISPNM}wise`
          });
        }

      } else if (curMode === 'PR') {

        if (level < PrtArr.length && level < prdArr.length) {
          console.log('level < PrtArr.length')
          setHeaderPartyName(prdArr[level]?.DISPNM)
          setHeaderProductName(PrtArr[level]?.DISPNM)
          setHideButton(false)
          setButtonCaptions({ // partylevel and productlevel multiselect 
            button1: `${prdArr[level - 1]?.DISPNM}wise`,
            button2: `Change ${PrtArr[level]?.DISPNM}`,
            button3: `${PrtArr[level]?.DISPNM}wise`,
          });
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setHeaderProductName(PrtArr[level - 1]?.DISPNM)
          // setHideButton(false)
          setButtonCaptions({ // partylevel and productlevel multiselect 
            button1: '',
            button2: `Change ${PrtArr[level - 1]?.DISPNM}`,
            button3: `${PrtArr[level - 1]?.DISPNM}wise`,
          });
        } else if (level == PrtArr.length && level == prdArr.length) {
          console.log('level == PrtArr.length && level == prdArr.length')
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setHeaderProductName(prdArr[level - 2]?.DISPNM)
          setButtonCaptions({
            button1: `${PrtArr[level - 2]?.DISPNM}wise`,
            button2: `Change ${prdArr[level - 2]?.DISPNM}`,
            button3: `${prdArr[level - 2]?.DISPNM}wise`
          })
        } else if (level > PrtArr.length && level > prdArr.length) {
          setHeaderPartyName(prdArr[level - 3]?.DISPNM)
          setHeaderProductName(PrtArr[level - 3]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${PrtArr[level - 3]?.DISPNM}`,
            button3: `${PrtArr[level - 3]?.DISPNM}wise`
          })
        } else if (level > PrtArr.length && level < prdArr.length) {
          setHeaderProductName('Party')
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${prdArr[level]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level > prdArr.length) {
          setHeaderProductName('Product')
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${PrtArr[level]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && prdArr?.length == 3 && level < prdArr.length) {
          setHeaderProductName("Party")
          setHeaderPartyName(prdArr[level + 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${prdArr[level - 1]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setHeaderProductName("Party")
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${prdArr[level]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && level < prdArr.length) {
          setHeaderProductName(PrtArr[level - 1]?.DISPNM)
          setHeaderPartyName(prdArr[level]?.DISPNM)
          setButtonCaptions({
            button1: `${prdArr[level - 1]?.DISPNM}wise`,
            button2: `Change ${PrtArr[level - 1]?.DISPNM}`,
            button3: `${PrtArr[level - 1]?.DISPNM}wise`
          });
        } else if (level > PrtArr.length && level == prdArr.length) {
          setHeaderProductName(prdArr[level - 2]?.DISPNM)
          setHeaderPartyName(PrtArr[level - 2]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${prdArr[level - 2]?.DISPNM}`,
            button3: `${prdArr[level - 2]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
          setHeaderProductName("Product")
          setHeaderPartyName(PrtArr[level + 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${PrtArr[level - 1]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setHeaderProductName("Product")
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${PrtArr[level]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level == prdArr.length) { // party,city + product
          setHeaderProductName(PrtArr[level]?.DISPNM)
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${PrtArr[level]?.DISPNM}`,
            button3: `${PrtArr[level]?.DISPNM}wise`
          });
        } else if (level == PrtArr.length && level > prdArr.length) {
          setHeaderProductName(prdArr[level - 2]?.DISPNM)
          setHeaderPartyName(PrtArr[level - 2]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${prdArr[level - 2]?.DISPNM}`,
            button3: `${prdArr[level - 2]?.DISPNM}wise`
          });
        }

      } else if (curMode == "GRP") {
        // console.log('test')
        if (level == prdArr.length && level == PrtArr.length) {
          setHeaderPartyName(PrtArr[level - 2]?.DISPNM)
          setHeaderProductName(prdArr[level - 1]?.DISPNM)
          setButtonCaptions({ // product level multiselect and partylevel multiselect
            button1: '',
            button2: `Change ${prdArr[level - 1]?.DISPNM}`,
            button3: `${prdArr[level - 1]?.DISPNM}wise`,
          });
        } else if (level < PrtArr.length && level < prdArr.length) {
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setHeaderProductName(PrtArr[level - 1]?.DISPNM)
          setButtonCaptions({ // product level multiselect and partylevel multiselect
            button1: '',
            button2: `Change ${PrtArr[level - 1]?.DISPNM}`,
            button3: `${PrtArr[level - 1]?.DISPNM}wise`,
          });
        } else if (level > PrtArr.length && prdArr?.length == 3 && level < prdArr.length) {
          setHeaderProductName("Party")
          setHeaderPartyName(prdArr[level - 2]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${prdArr[level - 1]?.DISPNM}wise`
          });
        } else if (level < PrtArr.length && level > prdArr.length && PrtArr.length == 3) {
          setHeaderProductName("Product")
          setHeaderPartyName(PrtArr[level - 2]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: '',
            button3: `${PrtArr[level - 1]?.DISPNM}wise`
          });
        }
      } else if (curMode == "CT") {
        if (level == prdArr.length && level == PrtArr.length) {
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setHeaderProductName(PrtArr[level - 2]?.DISPNM)
          setButtonCaptions({ // product level multiselect and partylevel multiselect
            button1: `${prdArr[level - 2]?.DISPNM}wise`,
            button2: `Change ${PrtArr[level - 2]?.DISPNM}`,
            button3: `${PrtArr[level - 2]?.DISPNM}wise`,
          });
        } else if (level < PrtArr.length && level < prdArr.length) {
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setHeaderProductName(prdArr[level - 1]?.DISPNM)
          setButtonCaptions({ // product level multiselect and partylevel multiselect
            button1: '',
            button2: `Change ${prdArr[level - 1]?.DISPNM}`,
            button3: `${prdArr[level - 1]?.DISPNM}wise`,
          });
        }
      }

    } else {

      if (curMode == "PD") {
        if (level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          console.log('if condition')
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setHeaderProductName("Party")
        } else if (level == PrtArr.length && level > prdArr.length) {
          console.log('else if')
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setHeaderProductName("Product")
        } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          console.log('else if condition')
          // setShow(!show)
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setHeaderProductName("Product")
        } else {
          console.log('else condition')
          setHeaderPartyName(PrtArr[level - 1]?.DISPNM)
          setHeaderProductName(prdArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${prdArr[level - 1]?.DISPNM}`,
            button3: `${prdArr[level - 1]?.DISPNM}wise`
          })
        }
      } else if (curMode == "PR") {
        if (level > PrtArr.length && level == prdArr.length) {

        } else {
          setHeaderPartyName(prdArr[level - 1]?.DISPNM)
          setHeaderProductName(PrtArr[level - 1]?.DISPNM)
          setButtonCaptions({
            button1: '',
            button2: `Change ${PrtArr[level - 1]?.DISPNM}`,
            button3: `${PrtArr[level - 1]?.DISPNM}wise`
          })
        }
      }

    }

  }, [curMode, level, PrtArr, prdArr, FIELD28]);

  // comboBox Data
  useEffect(() => {
    if (FIELD28 == "M") {

      if (curMode === 'PD') {

        if (level < prdArr.length && level < PrtArr.length) {
          setComboData(M25Data?.[prdArr[level]?.TBLKEY])
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setComboData(M25Data?.[prdArr[level - 1]?.TBLKEY])
        } else if (level == prdArr.length && level == PrtArr.length) {
          setComboData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
        } else if (level > prdArr.length && level > PrtArr.length) {
          setComboData(M25Data?.[prdArr[level - 3]?.TBLKEY])
        } else if (level == PrtArr.length && level < prdArr.length) {
          setComboData(M25Data?.[prdArr[level]?.TBLKEY])
        } else if (level > PrtArr.length && level == prdArr.length) {
          setComboData(M25Data?.[PrtArr[level - 2]?.TBLKEY])
        } else if (level < PrtArr.length && level == prdArr.length) {
          setComboData(M25Data?.[prdArr[level - 1]?.TBLKEY])
        } else if (level == PrtArr.length && level > prdArr.length) {
          setComboData(M25Data?.[PrtArr[level - 2]?.TBLKEY])
        }

      } else if (curMode === 'PR') {

        if (level < prdArr.length && level < PrtArr.length) {
          setComboData(M25Data?.[PrtArr[level]?.TBLKEY])
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setComboData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
        } else if (level == prdArr.length && level == PrtArr.length) {
          setComboData(M25Data?.[prdArr[level - 2]?.TBLKEY])
        } else if (level > prdArr.length && level > PrtArr.length) {
          setComboData(M25Data?.[PrtArr[level - 3]?.TBLKEY])
        } else if (level == PrtArr.length && level < prdArr.length) {
          setComboData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
        } else if (level > PrtArr.length && level == prdArr.length) {
          setComboData(M25Data?.[prdArr[level - 2]?.TBLKEY])
        } else if (level < PrtArr.length && level == prdArr.length) {
          setComboData(M25Data?.[PrtArr[level]?.TBLKEY])
        } else if (level == PrtArr.length && level > prdArr.length) {
          setComboData(M25Data?.[prdArr[level - 2]?.TBLKEY])
        }

      } else if (curMode == "GRP") {
        if (level == prdArr.length && level == PrtArr.length) {
          setComboData(M25Data?.[prdArr[level - 1]?.TBLKEY])
        } else if (level < PrtArr.length && level < prdArr.length) {
          setComboData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
        }
      } else if (curMode == "CT") {
        if (level == prdArr.length && level == PrtArr.length) {
          setComboData(M25Data?.[PrtArr[level - 2]?.TBLKEY])
        } else if (level < PrtArr.length && level < prdArr.length) {
          setComboData(M25Data?.[prdArr[level - 1]?.TBLKEY])
        }
      }

    }
  }, [FIELD28, curMode, level, M25Data, prdArr, PrtArr])

  const columnHelper = createColumnHelper();

  // columns
  useEffect(() => {
    let col = [];
    if (FIELD28 == "M") {

      if (curMode == "PD") {
        // selected value 2,2
        if (level < prdArr.length && level < PrtArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 1]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == prdArr.length && level == PrtArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 2]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > PrtArr.length && level > prdArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level - 3]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > PrtArr.length && level < prdArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData?.FIELD04}
                />
              },

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level > prdArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level < prdArr.length) { // party + product, group
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level < prdArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > PrtArr.length && level == prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 2]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level == prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level > prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 2]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        }

      } else if (curMode == "PR") {
        // selected value 2,2
        if (level < prdArr.length && level < PrtArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 1]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == prdArr.length && level == PrtArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > PrtArr.length && level > prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 3]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 3]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > PrtArr.length && level < prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 1]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level > prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level < prdArr.length && prdArr?.length == 3) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level + 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 1]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level < prdArr.length) { // party + product, group
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > PrtArr.length && level == prdArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }

            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level + 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level == prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 1]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }

          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level == PrtArr.length && level > prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        }

      } else if (curMode == "GRP") {
        if (level == prdArr.length && level == PrtArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level < prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 1]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > PrtArr.length && prdArr?.length == 3 && level < prdArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          if (prdArr[level - 2]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level > prdArr.length && PrtArr.length == 3) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 2]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        }
      } else if (curMode == "CT") {
        if (level == prdArr.length && level == PrtArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: prdArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level < PrtArr.length && level < prdArr.length) {
          col.push(
            columnHelper.accessor('DSFLD', {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: 'Discount %',
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: 'Rate',
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        }
      }
    } else {
      if (curMode == "PD") {

        if (level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          console.log('if condition')
          col.push(
            columnHelper.accessor("DSFLD", {
              header: prdArr[0]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false
            })
          )
          if (prdArr[0]?.TBLKEY == "M21FIELD01") {
            col.push(
              columnHelper.accessor("MRATE", {
                header: "MRP",
                enableEditing: false,
                enableColumnFilter: false,
                enableSorting: false
              })
            )
          }
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    // console.log('e=>>', e)
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else if (level > prdArr.length && level == PrtArr.length) {
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    // console.log('e=>>', e)
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        } else {
          console.log('else condition')
          col.push(
            columnHelper.accessor("DSFLD", {
              header: PrtArr[level - 1]?.DISPNM,
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false
            })
          )
          col.push(
            columnHelper.accessor('FIELD04', {
              header: "Discount %",
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue()
                return <PLNumberBox
                  decimalSeparator={true}
                  precision={3}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if(e.key == "Enter") {
                      handleSaveData()
                    }
                  }}
                  setEdit={(e) => {
                    // console.log('e=>>', e)
                    setRowData({ ...rowData, FIELD04: e })
                  }}
                  value={val ?? rowData.FIELD04}
                />
              }
            })
          )
          col.push(
            columnHelper.accessor('FIELD03', {
              header: "Rate",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false,
            })
          )
          return setColumns(col)
        }

      } else if (curMode == "PR") {
        col.push(
          columnHelper.accessor("DSFLD", {
            header: prdArr[0]?.DISPNM,
            enableEditing: false,
            enableColumnFilter: false,
            enableSorting: false,
          })
        )
        if (prdArr[0]?.TBLKEY == "M21FIELD01") {
          col.push(
            columnHelper.accessor("MRATE", {
              header: "MRP",
              enableEditing: false,
              enableColumnFilter: false,
              enableSorting: false
            })
          )
        }

        col.push(
          columnHelper.accessor('FIELD04', {
            header: "Discount %",
            Edit: ({ cell, column, table, row }) => {
              let val = cell.getValue()
              return <PLNumberBox
                decimalSeparator={true}
                precision={3}
                autoFocus={true}
                onKeyDown={(e) => {
                  if(e.key == "Enter") {
                    handleSaveData()
                  }
                }}
                setEdit={(e) => {
                  setRowData({ ...rowData, FIELD04: e })
                }}
                value={val ?? rowData.FIELD04}
              />
            }
          })
        )
        col.push(
          columnHelper.accessor('FIELD03', {
            header: "Rate",
            enableEditing: false,
            enableColumnFilter: false,
            enableSorting: false,
          })
        )
        return setColumns(col)
      }
    }
  }, [curMode, level, FIELD28, rowData, PrtArr, prdArr])

  // setData
  useEffect(() => {
    if (FIELD28 == "M") {

      if (curMode == "PD") {
        // selected value 2,2
        if (level < prdArr.length && level < PrtArr.length) {
          setTableData(M25Data?.[PrtArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setTableData(M25Data?.[prdArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == prdArr.length && level == PrtArr.length) { // product grp

          let filterData = []
          M25Data?.[prdArr[level - 2]?.TBLKEY]?.filter((v) =>
            v[prdArr[level - 1]?.MCDFLD] == (multiSelectHeaderData?.productGroupData?.length == 0 ? M25Data?.[prdArr[level - 1]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.productGroupData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
          // setTableData(M25Data?.[prdArr[level - 2]?.TBLKEY])
        } else if (level > PrtArr.length && level > prdArr.length) {
          let filterData = []
          M25Data?.[PrtArr[level - 3]?.TBLKEY]?.filter((v) =>
            v[PrtArr[level - 2]?.MCDFLD] == (multiSelectHeaderData?.cityData?.length == 0 ? M25Data?.[PrtArr[level - 2]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.cityData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
          // setTableData(M25Data?.[PrtArr[level - 3]?.TBLKEY])
        } else if (level > PrtArr.length && level < prdArr.length) {
          setTableData(M25Data?.[prdArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level > prdArr.length) {
          setTableData(M25Data?.[PrtArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setTableData(M25Data?.[prdArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level < prdArr.length) {
          setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level > PrtArr.length && level == prdArr.length) {
          let filterData = []
          M25Data?.[prdArr[level - 2]?.TBLKEY]?.filter((v) =>
            v[prdArr[level - 1]?.MCDFLD] == (multiSelectHeaderData?.productGroupData?.length == 0 ? M25Data?.[prdArr[level - 1]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.productGroupData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
          setTableData(M25Data?.[PrtArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level == prdArr.length) {
          setTableData(M25Data?.[PrtArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level > prdArr.length) {
          setTableData(M25Data?.[prdArr[level - 2]?.TBLKEY])
          setRowSelection({ 0: true })
        }

      } else if (curMode == "PR") {
        // selected value 2,2
        if (level < prdArr.length && level < PrtArr.length) {
          setTableData(M25Data?.[prdArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setTableData(M25Data?.[prdArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == prdArr.length && level == PrtArr.length) {
          setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level > PrtArr.length && level > prdArr.length) { // product grp
          // setTableData(M25Data?.[prdArr[level - 3]?.TBLKEY])
          let filterData = []
          M25Data?.[prdArr[level - 3]?.TBLKEY]?.filter((v) =>
            v[prdArr[level - 2]?.MCDFLD] == (multiSelectHeaderData?.productGroupData?.length == 0 ? M25Data?.[prdArr[level - 2]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.productGroupData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
        } else if (level > PrtArr.length && level < prdArr.length) { // All Party
          let filterData = [] // category
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.filter((v) =>
            v[prdArr[level]?.MCDFLD] == (multiSelectHeaderData?.categoryData?.length == 0 ? M25Data?.[prdArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.categoryData[0]?.CDFLD)
          )?.map((s) =>
            filterData.push(s)
          )
          setTableData(filterData)
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level > prdArr.length) {
          setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level < prdArr.length && prdArr?.length == 3 && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          let filterData = [] // category
          let newFilterData = []
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.filter((v) =>
            v[prdArr[level]?.MCDFLD] == (multiSelectHeaderData?.productGroupData?.length == 0 ? M25Data?.[prdArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.productGroupData[0]?.CDFLD)
          )?.map((s) =>
            filterData.push(s)
          )
          filterData?.map((v) => {
            M25Data?.[prdArr[level + 1]?.TBLKEY]?.map((s) => {
              if (v[prdArr[level + 1]?.MCDFLD] == s?.CDFLD) {
                return newFilterData.push(s)
              }
            })
            return newFilterData
          })
          setTableData(newFilterData)
          // setTableData(M25Data?.[prdArr[level + 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) { // All Party
          let filterData = []
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.filter((v) =>
            v[prdArr[level]?.MCDFLD] == (multiSelectHeaderData?.categoryData?.length == 0 ? M25Data?.[prdArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.categoryData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level < prdArr.length) {
          setTableData(M25Data?.[prdArr[level]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level > PrtArr.length && level == prdArr.length) {
          setTableData(M25Data?.[PrtArr[level - 2]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3 && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          let filterData = []
          let newFilterData = []
          M25Data?.[PrtArr[level - 1]?.TBLKEY]?.filter((v) =>
            v[PrtArr[level]?.MCDFLD] == (multiSelectHeaderData?.cityData?.length == 0 ? M25Data?.[PrtArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.cityData[0]?.CDFLD)
          )?.map((s) =>
            filterData.push(s)
          )
          console.log('filterData', filterData)
          filterData?.map((v) => {
            M25Data?.[PrtArr[level + 1]?.TBLKEY]?.map((s) => {
              if (v[PrtArr[level + 1]?.MCDFLD] == s?.CDFLD) {
                return newFilterData.push(s)
              }
            })
            return newFilterData
          })
          console.log('newFilterData', newFilterData)
          setTableData(newFilterData)
          // setTableData(M25Data?.[PrtArr[level + 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          let filterData = []
          M25Data?.[PrtArr[level - 1]?.TBLKEY]?.filter((v) =>
            v[PrtArr[level]?.MCDFLD] == (multiSelectHeaderData?.cityData?.length == 0 ? M25Data?.[PrtArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.cityData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
          // setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
        } else if (level < PrtArr.length && level == prdArr.length) {
          setTableData(M25Data?.[prdArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level > prdArr.length) {
          let filterData = []
          M25Data?.[PrtArr[level - 2]?.TBLKEY]?.filter((v) =>
            v[PrtArr[level - 1]?.MCDFLD] == (multiSelectHeaderData?.cityData?.length == 0 ? M25Data?.[PrtArr[level - 1]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.cityData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
        }

      } else if (curMode == "GRP") {
        if (level == prdArr.length && level == PrtArr.length) {
          let filterData = [] // City
          M25Data?.[PrtArr[level - 2]?.TBLKEY]?.filter((v) =>
            v[PrtArr[level - 1]?.MCDFLD] == (multiSelectHeaderData?.cityData?.length == 0 ? M25Data?.[PrtArr[level - 1]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.cityData[0]?.CDFLD)
          )?.map((s) =>
            filterData.push(s)
          )
          setTableData(filterData)
          setRowSelection({ 0: true })
          // setTableData(M25Data?.[PrtArr[level - 2]?.TBLKEY])
        } else if (level < PrtArr.length && level < prdArr.length) {
          let filterData = [] // product grp
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.filter((v) =>
            v[prdArr[level]?.MCDFLD] == (multiSelectHeaderData?.productGroupData?.length == 0 ? M25Data?.[PrtArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.productGroupData[0]?.CDFLD)
          )?.map((s) =>
            filterData.push(s)
          )
          setTableData(filterData)
          setRowSelection({ 0: true })
          // setTableData(M25Data?.[prdArr[level - 1]?.TBLKEY])
        } else if (level > PrtArr.length && level < prdArr.length && prdArr?.length == 3) {
          let filterData = [] // category
          M25Data?.[prdArr[level - 2]?.TBLKEY]?.filter((v) =>
            v[prdArr[level]?.MCDFLD] == (multiSelectHeaderData?.categoryData?.length == 0 ? M25Data?.[prdArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.categoryData[0]?.CDFLD)
          )?.map((s) =>
            filterData.push(s)
          )
          setTableData(filterData)
          // setTableData(M25Data?.[prdArr[level - 2]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level > prdArr.length && PrtArr.length == 3) {
          let filterData = [] // category
          M25Data?.[PrtArr[level - 2]?.TBLKEY]?.filter((v) =>
            v[PrtArr[level]?.MCDFLD] == (multiSelectHeaderData?.partyGroupData?.length == 0 ? M25Data?.[PrtArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.partyGroupData[0]?.CDFLD)
          )?.map((s) =>
            filterData.push(s)
          )
          setTableData(filterData)
          // setTableData(M25Data?.[prdArr[level - 2]?.TBLKEY])
          setRowSelection({ 0: true })
        }
      } else if (curMode == "CT") {
        if (level == prdArr.length && level == PrtArr.length) {
          setTableData(M25Data?.[prdArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level < PrtArr.length && level < prdArr.length) {
          let filterData = []
          M25Data?.[PrtArr[level - 1]?.TBLKEY]?.filter((v) =>
            v[PrtArr[level]?.MCDFLD] == (multiSelectHeaderData?.cityData?.length == 0 ? M25Data?.[PrtArr[level]?.TBLKEY]?.[0]?.CDFLD : multiSelectHeaderData?.cityData[0]?.CDFLD)
          )?.map((s) => filterData.push(s))
          setTableData(filterData)
          setRowSelection({ 0: true })
          // setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
        }
      }

    } else {

      if (curMode == "PD") {
        if (level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setTableData(M25Data?.[prdArr[0]?.TBLKEY])
          setRowSelection({ 0: true })
        } else if (level == PrtArr.length && level > prdArr.length) {
          setTableData(M25Data?.[PrtArr[level - 1]?.TBLKEY])
          setRowSelection({ 0: true })
        } else {
          setTableData(M25Data?.[PrtArr[0]?.TBLKEY])
          setRowSelection({ 0: true })
        }
      } else if (curMode == "PR") {
        setTableData(M25Data?.[prdArr[0]?.TBLKEY])
        setRowSelection({ 0: true })
      }

    }
  }, [FIELD28, curMode, level, M25Data, prdArr, PrtArr])

  // For All party setlevel
  useEffect(() => {
    if (PrtArr[0]?.TBLKEY == "TBLNONE") {
      setPrtLevel(0)
    } else {
      setPrtLevel(PrtLevel)
    }
  }, [PrtArr])

  // For All product setlevel
  useEffect(() => {
    if (prdArr[0]?.TBLKEY == "TBLNONE") {
      setPrdLevel(0)
    } else {
      setPrdLevel(PrdLevel)
    }
  }, [prdArr])

  // Button 3
  const handlePartyButton = () => {
    table.setGlobalFilter(null)
    // setRowSelection({})
    if (FIELD28 == "M") {

      if (curMode == 'PD') {

        if (level < prdArr.length && level < PrtArr.length) {
          setCurMode('PR');
          setLevel(level);
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setCurMode('PR');
          setLevel(level);
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level == PrtArr.length && level == prdArr.length) {
          setCurMode('PR');
          setLevel(level);
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level > PrtArr.length && level > prdArr.length) {
          setCurMode('PR')
          setLevel(level)
          setPrdLevel(PrdLevel + 1)
          setPrtLevel(PrtLevel + 1)
        } else if (level > PrtArr.length && level < prdArr.length) {
          setCurMode('PR')
          setPrtLevel(PrtLevel)
          setPrdLevel(PrdLevel + 1)
          // setShow(!show)
        } else if (level < PrtArr.length && level > prdArr.length) {
          setCurMode('PR')
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel)
          // setShow(!show)
        } else if (level == PrtArr?.length && level < prdArr?.length && prdArr?.length == 3) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel)
          setPrdLevel(PrdLevel + 1)
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel)
          setPrdLevel(PrdLevel + 1)
        } else if (level == PrtArr.length && level < prdArr.length) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level > PrtArr.length && level == prdArr.length) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr?.length == 3) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel)
        } else if (level < PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel)
        } else if (level < PrtArr.length && level == prdArr.length) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level == PrtArr.length && level > prdArr.length) {
          setCurMode('PR')
          setLevel(level)
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel - 1)
        }

      } else if (curMode === 'PR') {

        if (level < PrtArr.length && level < prdArr.length) {
          setCurMode('PD');
          setLevel(level);
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel - 1)
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          setLevel(level)
          setCurMode('PD')
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel - 1)
        } else if (level == prdArr.length && level == PrtArr.length) {
          setCurMode('PD')
          setLevel(level)
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel - 1)
        } else if (level > prdArr.length && level > PrtArr.length) {
          setLevel(level)
          setCurMode('PD')
          setPrdLevel(PrdLevel - 1)
          setPrtLevel(PrtLevel - 1)
        } else if (level == PrtArr.length && level < prdArr.length && prdArr?.length == 3) {
          setLevel(level + 1)
          setCurMode('GRP')
          setPrtLevel(PrtLevel)
          setPrdLevel(PrdLevel + 1)
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setCurMode('PD')
          setLevel(level)
          setPrtLevel(PrtLevel)
          setPrdLevel(PrdLevel - 1)
        } else if (level == PrtArr.length && level < prdArr.length) {
          setCurMode('PD')
          setLevel(level)
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel - 1)
        } else if (level > PrtArr.length && level == prdArr.length) {
          setCurMode('PD')
          setLevel(level)
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel - 1)
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
          setCurMode('GRP')
          setLevel(level + 1)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel)
        } else if (level < PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          setCurMode('PD')
          setLevel(level)
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel)
        } else if (level < PrtArr.length && level == prdArr.length) {
          setCurMode('PD')
          setLevel(level)
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel - 1)
        } else if (level == PrtArr.length && level > prdArr.length) {
          setCurMode('PD')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level > PrtArr.length && level < prdArr.length) {
          setCurMode("PD")
          setPrtLevel(PrtLevel)
          setPrdLevel(PrdLevel - 1)
        } else if (level < PrtArr.length && level > prdArr.length) {
          setCurMode("PD")
          setPrtLevel(PrtLevel - 1)
          setPrdLevel(PrdLevel)
          // setShow(!show)
        }

      } else if (curMode == "GRP") {
        if (level == prdArr.length && level == PrtArr.length) {
          setCurMode('CT')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level < PrtArr.length && level < prdArr.length) {
          setCurMode('CT')
          setLevel(level)
          setPrtLevel(PrtLevel + 1)
          setPrdLevel(PrdLevel + 1)
        } else if (level > PrtArr.length && level < prdArr.length && prdArr?.length == 3) {
          setCurMode('PD')
          setLevel(level - 1)
          setPrtLevel(PrtLevel)
          setPrdLevel(PrdLevel - 2)
        } else if (level < PrtArr.length && level > prdArr.length && PrtArr.length == 3) {
          setLevel(level - 1)
          setCurMode("PD")
          setPrtLevel(PrtLevel - 2)
          setPrdLevel(PrdLevel)
        }
      } else if (curMode == "CT") {
        setCurMode("GRP")
        setLevel(level)
        setPrtLevel(PrtLevel - 1)
        setPrdLevel(PrdLevel - 1)
      }
    } else {

      if (curMode == "PR") {
        setCurMode("PD")
        setPrtLevel(PrtLevel - 1)
        setPrdLevel(PrdLevel - 1)
      } else if (curMode == "PD") {
        setCurMode("PR")
        setPrtLevel(PrtLevel + 1)
        setPrdLevel(PrdLevel + 1)
      }

    }
  };

  const handleInputChange = (e) => {
    console.log('handleInputChange event', e)
    console.log('clicked')

    if (FIELD28 == "M") {

      if (curMode == "PD") {

        if (level < prdArr.length && level < PrtArr.length) {
          M25Data?.[prdArr[level]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [v] }))
            }
          })
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [v] }))
            }
          })
        } else if (level == prdArr.length && level == PrtArr.length) {
          M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [v] }))
            }
          })
        } else if (level > PrtArr.length && level > prdArr.length) {
          M25Data?.[prdArr[level - 3]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [v] }))
            }
          })
        } else if (level == PrtArr.length && level < prdArr.length) {
          M25Data?.[prdArr[level]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [v] }))
            }
          })
        } else if (level > PrtArr.length && level == prdArr.length) {
          M25Data?.[PrtArr[level - 2]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [v] }))
            }
          })
        } else if (level < PrtArr.length && level == prdArr.length) {
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [v] }))
            }
          })
        } else if (level == PrtArr.length && level > prdArr.length) {
          M25Data?.[PrtArr[level - 2]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [v] }))
            }
          })
        }

      } else if (curMode == "PR") {

        if (level < prdArr.length && level < PrtArr.length) {
          M25Data?.[PrtArr[level]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [v] }))
            }
          })
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [v] }))
            }
          })
        } else if (level == prdArr.length && level == PrtArr.length) {
          M25Data?.[prdArr[level - 2]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [v] }))
            }
          })
        } else if (level > PrtArr.length && level > prdArr.length) {
          M25Data?.[PrtArr[level - 3]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [v] }))
            }
          })
        } else if (level == PrtArr.length && level < prdArr.length) {
          M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [v] }))
            }
          })
        } else if (level > PrtArr.length && level == prdArr.length) {
          M25Data?.[prdArr[level - 2]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [v] }))
            }
          })
        } else if (level < PrtArr.length && level == prdArr.length) {
          M25Data?.[PrtArr[level]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [v] }))
            }
          })
        } else if (level == PrtArr.length && level > prdArr.length) {
          M25Data?.[prdArr[level - 2]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [v] }))
            }
          })
        }

      } else if (curMode == "GRP") {
        if (level == prdArr.length && level == PrtArr.length) {
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [v] }))
            }
          })
        } else if (level < PrtArr.length && level < prdArr.length) {
          M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [v] }))
            }
          })
        }
      } else if (curMode == "CT") {
        if (level == prdArr.length && level == PrtArr.length) {
          M25Data?.[PrtArr[level - 2]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [v] }))
            }
          })
        } else if (level < PrtArr?.length && level < prdArr?.length) {
          M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((v, i) => {
            if (v.CDFLD === e) {
              console.log('v.CDFLD === e', v.CDFLD === e)
              dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [v] }))
            }
          })
        }
      }
    } else {
      if (curMode == "PD") {
        M25Data?.[prdArr[0]?.TBLKEY]?.map((v, i) => {
          if (v.CDFLD === e) {
            console.log('v.CDFLD === e', v.CDFLD === e)
            dispatch(GetHeaderData({ ...headerData, productData: [v] }))
          }
        })
      } else {
        M25Data[PrtArr[0]?.TBLKEY]?.map((v, i) => {
          if (v.CDFLD === e) {
            // dispatch(SelectedInputVal(v))
            dispatch(GetHeaderData({ ...headerData, partyData: [v] }))
          }
        })
      }
    }
    setDisableInput(!disableInput)
  };

  // Button 2
  const ChangeButton = () => {
    setDisableInput(!disableInput)
  }

  // Button 1
  const handleChangeButton = () => {
    table.setGlobalFilter(null)
    // Implement change logic if needed
    console.log('Change button clicked');
    if (curMode === 'PR') {

      if (level < PrtArr.length && level < prdArr.length) {
        setLevel(level + 1)
        setCurMode('PD')
        setPrtLevel(PrtLevel)
        setPrdLevel(PrdLevel + 1)
      } else if (level == PrtArr.length && level == prdArr.length) {
        setLevel(level + 1)
        setCurMode('PD')
        setPrdLevel(PrdLevel)
        setPrtLevel(PrtLevel + 1)
      } else if (level == PrtArr.length && level < prdArr.length) {
        setLevel(level + 1)
        setCurMode('PD')
        setPrtLevel(PrtLevel)
        setPrdLevel(PrdLevel + 1)
      }

    } else if (curMode == 'PD') {

      if (level < prdArr.length && level < PrtArr.length) {
        setLevel(level + 1)
        setCurMode('PR')
      } else if (level == PrtArr.length && level == prdArr.length) {
        setLevel(level)
        setCurMode('PR')
      } else if (level < PrtArr.length && level == prdArr.length) {
        setLevel(level + 1)
        setCurMode('PR')
        setPrtLevel(PrtLevel + 1)
        setPrdLevel(PrdLevel)
      }
    } else if (curMode == "CT") {
      if (level == PrtArr.length && level == prdArr.length) {
        setCurMode("GRP")
        setLevel(level - 1)
        setPrtLevel(PrtLevel)
        setPrdLevel(PrdLevel + 1)
      }
    }

  };

  const handleHideButton = () => {
    table.setGlobalFilter(null)
    console.log('test')
    setPrtLevel(PrtLevel + 1)
    setPrdLevel(PrdLevel)
    setLevel(level + 1)
    setCurMode('GRP')
    setHideButton(false)
    // setShow(false)
  }

  const handleSaveData = () => {

    console.log('rowData', rowData)
    let newData = { ...rowData }
    console.log('newData', newData)
    dispatch(DataSaveLoading(true))
    GlobalClass.Notify(
      "info",
      "Editing",
      "Please wait while we process your data"
    );
    if (FIELD28 == "M") {

      if (curMode == "PD") {
        if (level < prdArr.length && level < PrtArr.length && multiSelectHeaderData?.productGroupData?.length == 0) {
          console.log('if condition')
          newData.FIELD01 = defaultInputVal?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = defaultInputVal?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = defaultInputVal?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3) {
          newData.FIELD01 = multiSelectHeaderData?.productGroupData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productGroupData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          newData.FIELD01 = ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.partyData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.partyData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          newData.FIELD01 = multiSelectHeaderData?.categoryData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.categoryData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.categoryData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0) {
          newData.FIELD01 = defaultInputVal?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = defaultInputVal?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = defaultInputVal?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
          newData.FIELD01 = ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.cityData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.cityData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level == prdArr.length && multiSelectHeaderData?.productData?.length == 0) {
          newData.FIELD01 = defaultInputVal?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = defaultInputVal?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = defaultInputVal?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < prdArr.length && level < PrtArr.length) {
          console.log('else if condition')
          newData.FIELD01 = multiSelectHeaderData?.productGroupData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productGroupData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == prdArr.length && level == PrtArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level > prdArr.length && level > PrtArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level > PrtArr.length && level < prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.categoryData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.categoryData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.categoryData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level > prdArr.length) {
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD01 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.cityData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.cityData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level < prdArr.length) { // party + product, group
          newData.FIELD01 = multiSelectHeaderData?.productGroupData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productGroupData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level > PrtArr.length && level == prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level == prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level > prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        }

      } else if (curMode == "PR") {
        if (level == PrtArr?.length && level > prdArr.length && multiSelectHeaderData?.productData?.length == 0) {
          newData.FIELD01 = defaultInputVal?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = defaultInputVal?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = defaultInputVal?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < prdArr.length && level < PrtArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productGroupData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`

          let DiscountRate = multiSelectHeaderData?.productGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productGroupData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 && multiSelectHeaderData?.productData?.length == 0) {
          newData.FIELD01 = defaultInputVal?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = defaultInputVal?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = defaultInputVal?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == prdArr.length && level == PrtArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level > prdArr.length && level > PrtArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level > PrtArr.length && level < prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level > prdArr.length) {
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD01 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.partyData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.partyData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3) {
          newData.FIELD01 = multiSelectHeaderData?.categoryData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.categoryData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.categoryData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level < prdArr.length) { // party + product, group
          newData.FIELD01 = multiSelectHeaderData?.productGroupData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productGroupData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level > PrtArr.length && level == prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3 && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          newData.FIELD01 = ''
          newData.FIELD02 = multiSelectHeaderData?.partyGroupData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.partyGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.partyGroupData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
          newData.FIELD01 = ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.partyData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.partyData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level == prdArr.length) { // party, city + product
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.cityData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr.length && level > prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          console.log('DiscountRate=>>', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        }

      } else if (curMode == "GRP") {
        if (level == prdArr.length && level == PrtArr.length && multiSelectHeaderData?.productGroupData?.length > 0) {
          newData.FIELD01 = multiSelectHeaderData?.productGroupData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productGroupData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level == PrtArr?.length && level == prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0) {
          newData.FIELD01 = defaultInputVal?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = defaultInputVal?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = defaultInputVal?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level < prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level > PrtArr.length && level < prdArr.length && prdArr.length == 3) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level > prdArr.length && PrtArr.length == 3) {
          newData.FIELD01 = ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.partyData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.partyData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        }
      } else if (curMode == "CT") {
        if (level == prdArr.length && level == PrtArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productGroupData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productGroupData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productGroupData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        } else if (level < PrtArr.length && level < prdArr.length) {
          newData.FIELD01 = multiSelectHeaderData?.productData[0]?.CDFLD ?? ''
          newData.FIELD02 = multiSelectHeaderData?.partyData[0]?.CDFLD ?? ''
          newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
          let DiscountRate = multiSelectHeaderData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
          // console.log('DiscountRate', DiscountRate)
          let finalRate = multiSelectHeaderData?.productData[0]?.MRATE - DiscountRate
          // console.log('finalRate=>>', finalRate)
          newData.FIELD03 = finalRate
          setRowData(newData)
        }
      }

    } else {

      if (headerData?.productData?.length == 0 && level == PrtArr.length && level == prdArr.length && curMode == "PD") {
        console.log('if')
        newData.FIELD01 = M25Data?.[prdArr[0]?.TBLKEY]?.[0]?.CDFLD ?? ''
        newData.FIELD02 = headerData?.partyData[0]?.CDFLD ?? ''
        newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
        let DiscountRate = M25Data?.[prdArr[0]?.TBLKEY]?.[0]?.MRATE * rowData?.FIELD04 / 100
        console.log('DiscountRate=>>', DiscountRate)
        let finalRate = M25Data?.[prdArr[0]?.TBLKEY]?.[0]?.MRATE - DiscountRate
        console.log('finalRate=>>', finalRate)
        newData.FIELD03 = finalRate
        setRowData(newData)
      } else if (headerData?.productData?.length > 0 && level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE && curMode == "PD") {
        console.log('else if')
        newData.FIELD01 = headerData?.productData[0]?.CDFLD ?? ''
        newData.FIELD02 = ""
        newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
        let DiscountRate = headerData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
        console.log('DiscountRate=>>', DiscountRate)
        let finalRate = headerData?.productData[0]?.MRATE - DiscountRate
        console.log('finalRate=>>', finalRate)
        newData.FIELD03 = finalRate
        setRowData(newData)
      } else if (headerData?.partyData?.length > 0 && level == PrtArr.length && level > prdArr.length && curMode == "PD") {
        // console.log('level == PrtArr.length && level > prdArr.length && curMode == "PD"')
        console.log('else if 1')
        newData.FIELD02 = headerData?.partyData[0]?.CDFLD ?? ''
        newData.FIELD01 = ""
        newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
        let DiscountRate = headerData?.partyData[0]?.MRATE * rowData?.FIELD04 / 100
        console.log('DiscountRate=>>', DiscountRate)
        let finalRate = headerData?.partyData[0]?.MRATE - DiscountRate
        console.log('finalRate=>>', finalRate)
        newData.FIELD03 = finalRate
        setRowData(newData)
      } else {
        console.log('else')
        newData.FIELD01 = headerData?.productData[0]?.CDFLD ?? ''
        newData.FIELD02 = headerData?.partyData[0]?.CDFLD ?? ''
        newData.FIELD24 = `${PrtLevel}` + `${PrdLevel}`
        let DiscountRate = headerData?.productData[0]?.MRATE * rowData?.FIELD04 / 100
        console.log('DiscountRate=>>', DiscountRate)
        let finalRate = headerData?.productData[0]?.MRATE - DiscountRate
        console.log('finalRate=>>', finalRate)
        newData.FIELD03 = finalRate
        console.log('newData', newData)
        setRowData(newData)
      }
      // setRowData(newData)


      // if (headerData?.partyData?.length == 0 && level == PrtArr.length && level == prdArr.length) {
      //   rowData.FIELD01 = headerData?.productData[0]?.CDFLD ?? ''
      //   rowData.FIELD02 = M25Data?.[PrtArr[0]?.TBLKEY]?.[0]?.CDFLD ?? ''
      // }

    }

    const { CDFLD, DSFLD, FIELD05, FIELD16, FIELD18, FIELD11, FIELD12, FIELD31, FIELD51, MRATE, UKYNO, ...filteredObj } = newData
    console.log('filteredObj', filteredObj)
    var jData = [{
      ...filteredObj,
      FIELD31: obj.id,
      "FIELD05": 0.0000,
      "FIELD06": 0.0000,
      "FIELD21": 0.000,
      "FIELD22": 0.000,
      "FIELD23": 0.000,
      "FIELD25": "",
      "FIELD42": "",
      "FLDUNQ": null,
      "FLDAED": "",
      "FLDBRC": ""
    }]
    console.log('jData=>', jData)
    var data = {
      "CorpID": gensetting.getcorpid(),
      "cUser": gensetting.getcuser(),
      "cPass": gensetting.getcpass(),
      "CmpNo": gensetting.getcmpno(),
      "cAction": 'E',
      "cCode": obj.id,
      "cSData": JSON.stringify(jData)
    };
    let param =
      JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": 'E',
        "cCode": obj.id,
      });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    fetch(GlobalClass.ApiUrl + GlobalClass.PostM25Ent + "?pa=" + param, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log('api data', data);
        // console.log('api data status', data?.status);
        if (data.status == 'SUCCESS') {
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => dispatch(M25List({ action: '', code: obj.id })))
            .then(() => GlobalClass.Notify(
              "success",
              obj.p0 == "A" ? "Added" : "Edited",
              obj.p0 == "A" ? "Added successfully" : "Edited successfully"
            ))
        } else {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() => GlobalClass.Notify("error", data?.status, data?.message))
        }
      }).catch((e) => {
        GlobalClass.Notify("error", "Error", `${e.message}`);
        dispatch(DataSaveLoading(false));
      });
    console.log('data', data)

    table.setEditingRow(null) // exit editing mode

    setRowData(
      {
        "FIELD01": "",
        "FIELD02": "",
        "FIELD03": 0.0000,
        "FIELD04": 0.0000,
        "FIELD05": 0.0000,
        "FIELD06": 0.0000,
        "FIELD21": 0.000,
        "FIELD22": 0.000,
        "FIELD23": 0.000,
        "FIELD24": "",
        "FIELD25": "",
        "FIELD31": "",
        "FIELD42": "",
        "FLDUNQ": null,
        "FLDAED": "",
        "FLDBRC": ""
      }
    )
  }

  // Merge Data
  useEffect(() => {

    if (FIELD28 == "M") {
      if (M25tbl) {
        let final = [];
        if (curMode == "PD") {

          if (level < prdArr.length && level < PrtArr.length) {
            M25Data?.[PrtArr[level]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD02) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD02) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD01) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
            M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD02) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            M25Data?.[prdArr[level]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD01) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level == prdArr.length && level == PrtArr.length) {
            M25Data?.[prdArr[level - 2]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD01) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level > prdArr.length && level > PrtArr.length) {
            M25Data?.[PrtArr[level - 3]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD02) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level > PrtArr.length && level < prdArr.length) {
            M25Data?.[prdArr[level]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD01) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level < PrtArr.length && level > prdArr.length) {
            M25Data?.[PrtArr[level]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD02) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3) {
            M25Data?.[prdArr[level]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD01) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == PrtArr.length && level < prdArr.length) {
            M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level > PrtArr.length && level == prdArr.length) {
            M25Data?.[prdArr[level - 2]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD01) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level < PrtArr.length && level == prdArr.length) {
            M25Data?.[PrtArr[level]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level == PrtArr.length && level > prdArr.length) {
            M25Data?.[prdArr[level - 2]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD01) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          }

        } else if (curMode == "PR") {

          if (level < prdArr.length && level < PrtArr.length) {
            M25Data?.[prdArr[level]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD01) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD01) {
                  final.push({ ...v, ...d })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == prdArr.length && level == PrtArr.length) {
            M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD02) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD01) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level > prdArr.length && level > PrtArr.length) {
            M25Data?.[prdArr[level - 3]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD01) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level > PrtArr.length && level < prdArr.length) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((e) => {
                if (p.CDFLD == e.FIELD01) {
                  final.push({ ...p, ...e })
                }
              })
            })
            setUpdatedData(final)
          } else if (level < PrtArr.length && level > prdArr.length) {
            M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD02) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3) {
            M25Data?.[prdArr[level + 1]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD01) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level == PrtArr.length && level < prdArr.length) {
            M25Data?.[prdArr[level]?.TBLKEY]?.map((g) => {
              M25tbl?.map((d) => {
                if (g.CDFLD == d.FIELD01) {
                  final.push({ ...g, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level > PrtArr.length && level == prdArr.length) {
            M25Data?.[PrtArr[level - 2]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3 && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            M25Data?.[PrtArr[level + 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level < PrtArr.length && level == prdArr.length) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD01) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level == PrtArr.length && level > prdArr.length) {
            M25Data?.[PrtArr[level - 2]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          }
        } else if (curMode == "GRP") {
          if (level == prdArr.length && level == PrtArr.length) {
            M25Data?.[PrtArr[level - 2]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level < PrtArr.length && level < prdArr.length) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD01) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level > PrtArr.length && level < prdArr.length && prdArr.length == 3) {
            M25Data?.[prdArr[level - 2]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD01) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          } else if (level < PrtArr.length && level > prdArr.length && PrtArr?.length == 3) {
            M25Data?.[PrtArr[level - 2]?.TBLKEY]?.map((c) => {
              M25tbl?.map((e) => {
                if (c.CDFLD == e.FIELD02) {
                  final.push({ ...c, ...e })
                }
              })
            })
            return setUpdatedData(final)
          }
        } else if (curMode == "CT") {
          if (level == prdArr.length && level == PrtArr.length) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD01) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level < PrtArr.length && level < prdArr.length) {
            M25Data?.[PrtArr[level - 1]?.TBLKEY]?.map((p) => {
              M25tbl?.map((d) => {
                if (p.CDFLD == d.FIELD02) {
                  final.push({ ...p, ...d })
                }
              })
            })
            setUpdatedData(final)
          }
        }

      }
    } else {
      if (M25tbl) {
        let final = []
        if (curMode == "PD") {
          if (level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            M25Data?.[prdArr[level - 1]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD01) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else if (level == PrtArr.length && level > prdArr.length) {
            M25Data?.[PrtArr[0]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD02) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          } else {
            M25Data?.[PrtArr[0]?.TBLKEY]?.map((v) => {
              M25tbl?.map((d) => {
                if (v.CDFLD == d.FIELD02) {
                  final.push({ ...v, ...d })
                }
              })
            })
            setUpdatedData(final)
          }
        } else {
          M25Data?.[prdArr[0]?.TBLKEY]?.map((e) => {
            M25tbl?.map((r) => {
              if (e.CDFLD === r.FIELD01) {
                // console.log('v.CDFLD === r.FIELD01', v.CDFLD === r.FIELD01)
                final.push({ ...e, ...r })
              }
            })
          })
          setUpdatedData(final)
        }
      }
    }
  }, [FIELD28, M25tbl, curMode, PrtArr, prdArr, level])

  // show Edited Data Single Select
  useEffect(() => {
    if (FIELD28 == "S") {
      if (updatedData && updatedData?.length > 0 && tableData?.length > 0) {
        let finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
          &&
          (
            curMode == 'PD' ?
              // level > PrtArr.length && level == prdArr.length ? M25Data?.[prdArr[0]?.TBLKEY]?.[0]?.CDFLD == u?.FIELD01 :
              (headerData?.productData?.length == 0 ? M25Data?.[prdArr[0]?.TBLKEY]?.[0]?.CDFLD == u?.FIELD01 : headerData?.productData[0]?.CDFLD == u?.FIELD01) :
              (headerData?.partyData?.length == 0 ? M25Data?.[PrtArr[0]?.TBLKEY]?.[0]?.CDFLD == u?.FIELD02 : headerData?.partyData[0]?.CDFLD == u?.FIELD02)
          )
        ) || obj);
        console.log('finalone', finalOne)
        setTableData(finalOne)
      }
    }
  }, [FIELD28,
    updatedData,
    // headerData?.productData[0]?.CDFLD,
    // headerData?.partyData[0]?.CDFLD,
    M25Data?.[prdArr[0]?.TBLKEY]?.[0]?.CDFLD,
    M25Data?.[PrtArr[0]?.TBLKEY]?.[0]?.CDFLD
  ])

  // single level
  useEffect(() => {
    if (FIELD28 == "S") {
      if (level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
        if (updatedData && updatedData?.length > 0 && tableData?.length > 0) {
          let finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            &&
            obj?.CDFLD == u?.FIELD01)
            || obj)
          setTableData(finalOne)
        }
      }
    }
  }, [FIELD28, updatedData, level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE])

  // single level
  useEffect(() => {
    if (FIELD28 == "S") {
      if (level == PrtArr.length && level > prdArr.length) {
        if (updatedData && updatedData?.length > 0 && tableData?.length > 0) {
          let finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            &&
            obj?.CDFLD == u?.FIELD02)
            || obj)
          setTableData(finalOne)
        }
      }
    }
  }, [FIELD28, updatedData, level == PrtArr.length && level > prdArr.length])

  // All Party multi level
  useEffect(() => {
    if (FIELD28 == "M") {
      if ((level > PrtArr.length && level < prdArr.length && prdArr?.length < 3) || (level == PrtArr.length && level < prdArr.length && prdArr?.length < 3)) {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      }
    }
  }, [updatedData, FIELD28, level, prdArr, PrtArr])

  // All Product
  useEffect(() => {
    if (level < PrtArr?.length && level > prdArr?.length && PrtArr?.length < 3) {
      let finalOne = []
      if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
        Promise.resolve(
          finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
          ) || obj)
        ).then(() => setTableData(finalOne))

      }
    } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
      let finalOne = []
      if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
        Promise.resolve(
          finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
          ) || obj)
        ).then(() => setTableData(finalOne))
      }
    }
  }, [updatedData, level, prdArr, PrtArr])

  useEffect(() => {
    if (FIELD28 == "M") {
      if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE && curMode == "PD") {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE && curMode == "PR") {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      } else if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3 && curMode == "PD") {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3 && curMode == "PR") {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      } else if (level > PrtArr.length && level < prdArr.length && prdArr.length == 3 && curMode == "GRP") {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      } else if (level < PrtArr.length && level > prdArr.length && PrtArr?.length == 3 && curMode == "GRP") {
        let finalOne = []
        if (updatedData && updatedData.length > 0 && tableData?.length > 0) {
          Promise.resolve(
            finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            ) || obj)
          ).then(() => setTableData(finalOne))
        }
      }
    }
  }, [FIELD28, updatedData])

  const isTBLNONEExcluded = (key) => M25Data[key] !== M25Data?.TBLNONE;
  // console.log('isTBLNONEExcluded',isTBLNONEExcluded())

  // party 2 value Select and product 2 value select
  useEffect(() => {
    if (FIELD28 == "M") {
      if (isTBLNONEExcluded() == false) {
        if (updatedData && updatedData?.length > 0 && tableData?.length > 0) {
          let finalOne = tableData?.map(obj => updatedData.find(u => u.CDFLD === obj.CDFLD
            &&
            (
              curMode == 'PD' ?
                (
                  level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? multiSelectHeaderData?.categoryData[0]?.CDFLD == u?.FIELD01 :
                    level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 && (M25Data[prdArr[level - 1]?.TBLKEY] !== M25Data?.TBLNONE || M25Data[PrtArr[level - 1]?.TBLKEY] !== M25Data?.TBLNONE) && multiSelectHeaderData?.productData?.length == 0 ? defaultInputVal?.CDFLD == u?.FIELD01 :
                      // level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? multiSelectHeaderData?.cityData[0]?.CDFLD == u?.FIELD02 :
                      level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 && (M25Data[prdArr[level - 1]?.TBLKEY] !== M25Data?.TBLNONE || M25Data[PrtArr[level - 1]?.TBLKEY] !== M25Data?.TBLNONE) ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 :
                        level == PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 ? defaultInputVal?.CDFLD == u?.FIELD01 :
                          level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 ? defaultInputVal?.CDFLD == u?.FIELD01 :
                            level < PrtArr.length && level == prdArr.length && multiSelectHeaderData?.productData?.length == 0 ? defaultInputVal?.CDFLD == u?.FIELD01 :
                              level < PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.productGroupData[0]?.CDFLD == u?.FIELD01 :
                                level == PrtArr.length && level == prdArr.length && (M25Data[prdArr[level - 1]?.TBLKEY] !== M25Data?.TBLNONE || M25Data[PrtArr[level - 1]?.TBLKEY] !== M25Data?.TBLNONE) ? multiSelectHeaderData?.cityData[0]?.CDFLD == u?.FIELD02 :
                                  level > PrtArr.length && level > prdArr.length ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 :
                                    level == PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.productGroupData[0]?.CDFLD == u?.FIELD01 :
                                      level > PrtArr.length && level == prdArr.length ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 :
                                        level < PrtArr.length && level == prdArr.length ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 :
                                          level == PrtArr.length && level > prdArr.length ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 :
                                            ''
                ) :
                curMode == "GRP" ?
                  (
                    level == PrtArr?.length && level == prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 ? defaultInputVal?.CDFLD == u?.FIELD01 :
                      level == prdArr.length && level == PrtArr.length ? multiSelectHeaderData?.productGroupData[0]?.CDFLD == u?.FIELD01 :
                        level < PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 : ''
                  ) :
                  curMode == "CT" ?
                    (
                      level == prdArr.length && level == PrtArr.length ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 :
                        level < PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 : ''
                    ) :
                    curMode == "PR" ?
                      (
                        level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 :
                          // level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 :
                          level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 :
                            level == PrtArr.length && level > prdArr.length && multiSelectHeaderData?.productData?.length == 0 ? defaultInputVal?.CDFLD == u?.FIELD01 :
                              level == prdArr.length && level == PrtArr.length ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 :
                                level < PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.cityData[0]?.CDFLD == u?.FIELD02 :
                                  level == PrtArr.length && level == prdArr.length ? multiSelectHeaderData?.cityData[0]?.CDFLD == u?.FIELD01 :
                                    level > PrtArr.length && level > prdArr.length ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 :
                                      level == PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.partyData[0]?.CDFLD == u?.FIELD02 :
                                        level > PrtArr.length && level == prdArr.length ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 :
                                          level < PrtArr.length && level == prdArr.length ? multiSelectHeaderData?.cityData[0]?.CDFLD == u?.FIELD02 :
                                            level == PrtArr.length && level > prdArr.length ? multiSelectHeaderData?.productData[0]?.CDFLD == u?.FIELD01 :
                                              ''
                      ) : ''
            )
          ) || obj);
          console.log('finalone', finalOne)
          setTableData(finalOne)
        }
      }
    }
  }, [
    // PrtArr,
    // prdArr,
    // level,
    M25Data,
    defaultInputVal,
    FIELD28,
    updatedData,
    // multiSelectHeaderData
  ])

  const handleRowNull = () => {
    setRowData({
      "FIELD01": "",
      "FIELD02": "",
      "FIELD03": 0.0000,
      "FIELD04": 0.0000,
      "FIELD05": 0.0000,
      "FIELD06": 0.0000,
      "FIELD21": 0.000,
      "FIELD22": 0.000,
      "FIELD23": 0.000,
      "FIELD24": "",
      "FIELD25": "",
      "FIELD31": "",
      "FIELD42": "",
      "FLDUNQ": null,
      "FLDAED": "",
      "FLDBRC": ""
    })
  }

  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      table.setEditingRow(row)
      // setTableEditingRow(row)
      setRowData(row?.original)
    }
  }

  const table = useMantineReactTable({
    columns,
    data: tableData ? tableData : [],
    // enableTopToolbar: false,
    // enableFilters:false,
    enableHiding: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableSorting: false,
    enableEditing: true,
    editDisplayMode: "row",
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    enableSelectAll: false,
    positionToolbarAlertBanner: false,
    mantineTableProps:{withColumnBorders:true},
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      onClick: () =>
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        })),
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    state: {
      density: "0px",
      rowSelection,
    },
    onEditingRowCancel: handleRowNull,
    onEditingRowSave: handleSaveData,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              table.setEditingRow(row)
              setRowData(row.original)
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  })

  let DataGridRow = { FIELD02: obj?.DataGridRow?.FIELD02, FIELD11: obj?.DataGridRow?.FIELD11, FIELD12: obj?.DataGridRow?.FIELD12 }
  let selectedRow = table.getSelectedRowModel().flatRows[0]?.original
  // console.log('selectedRow', selectedRow)
  // console.log('table.getSelectedRowModel()', table.getSelectedRowModel().flatRows[0]?.original)

  // useEffect(() => {
  //   tableData?.map((t, i) => {
  //     if (i in rowSelection) {
  //       document.addEventListener('keydown', (e) => {
  //         if (e.key == "Enter") {
  //           Promise.resolve().then(() => {
  //             table.setEditingRow(table.getSelectedRowModel().flatRows[0])
  //             setRowData(t)
  //           })
  //         }
  //       })
  //     }
  //   })
  // }, [rowSelection, tableData])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key == "Escape") {
        console.log('e.key', e)
        return Promise.resolve(handleRowNull).then(() => table.setEditingRow(false))
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [])

  useEffect(() => {
    if (selectedRow) {
      if (FIELD28 == "M") {

        if (curMode == 'PD') {

          if (level < prdArr.length && level < PrtArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [selectedRow] }))
          } else if (level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level == prdArr.length && level == PrtArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level > PrtArr.length && level > prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [selectedRow] }))
          } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, categoryData: [selectedRow] }))
          } else if (level > PrtArr.length && level < prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, categoryData: [selectedRow] }))
          } else if (level < PrtArr.length && level > prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [selectedRow] }))
          } else if (level == PrtArr.length && level < prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level > PrtArr.length && level == prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [selectedRow] }))
          } else if (level < PrtArr.length && level == prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [selectedRow] }))
          } else if (level == PrtArr.length && level > prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          }

        } else if (curMode == 'PR') {

          if (level < prdArr.length && level < PrtArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [selectedRow] }))
          } else if (level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level == prdArr.length && level == PrtArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, cityData: [selectedRow] }))
          } else if (level > PrtArr.length && level > prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level > PrtArr.length && level < prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level < PrtArr.length && level > prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level == PrtArr.length && level < prdArr.length && prdArr.length == 3) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, categoryData: [selectedRow] }))
          } else if (level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level == PrtArr.length && level < prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [selectedRow] }))
          } else if (level > PrtArr.length && level == prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level < PrtArr.length && level == prdArr.length && PrtArr.length == 3) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyGroupData: [selectedRow] }))
          } else if (level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level < PrtArr.length && level == prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level == PrtArr.length && level > prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          }

        } else if (curMode == "GRP") {
          if (level == PrtArr.length && level == prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          } else if (level < PrtArr.length && level < prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level > PrtArr.length && level < prdArr.length && prdArr?.length == 3) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productData: [selectedRow] }))
          } else if (level < PrtArr.length && level > prdArr.length && PrtArr.length == 3) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          }
        } else if (curMode == "CT") {
          if (level == prdArr.length && level == PrtArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [selectedRow] }))
          } else if (level < PrtArr.length && level < prdArr.length) {
            dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, partyData: [selectedRow] }))
          }
        }

      } else {
        if (curMode == 'PD') {
          if (level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE) {
            dispatch(GetHeaderData({ ...headerData, productData: [selectedRow] }))
          } else if (level == PrtArr.length && level > prdArr.length) {
            dispatch(GetHeaderData({ ...headerData, partyData: [selectedRow] }))
          } else {
            dispatch(GetHeaderData({ ...headerData, partyData: [selectedRow] }))
          }
          // dispatch(SelectedRow(selectedRow))
        } else {
          dispatch(GetHeaderData({ ...headerData, productData: [selectedRow] }))
        }
      }
    }
  }, [selectedRow, FIELD28])

  // useEffect(() => {
  //   if (FIELD28 == "M") {
  //     if (multiSelectHeaderData?.productGroupData?.length == 0 && level < prdArr.length && level < PrtArr.length && curMode == "PD") {
  //       dispatch(GetMultiSelectHeaderData({ ...multiSelectHeaderData, productGroupData: [] }))
  //     }
  //   }
  // }, [FIELD28])

  // console.log('M25Data?.[prdArr[level]?.TBLKEY]?.[0]', M25Data?.[prdArr[level]?.TBLKEY]?.[0])


  return (
    <>
      {
          hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (obj && obj.id != "") {
              Promise.resolve(dispatch(M25List({ action: '', code: obj.id })))
              .then(() => {
                dispatch(GetM24PriceList())
              })
            }
          }}>Retry</Button>
        </> :
        isLoading ? GlobalClass.RenderLoader('dots') :
          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: 'flex', padding: 0, justifyContent: "center", alignItems: 'center' }}>
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>PriceList</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input component='button' size="xs">
                    <Text fz="sm"> {DataGridRow?.FIELD02} </Text>
                  </Input>
                </Grid.Col>
                <Grid.Col span={6} style={{ display: 'flex', flexDirection: 'row', padding: 0, alignItems: 'center', justifyContent: 'center' }}>
                  <Text fz="sm"> {DataGridRow?.FIELD11.slice(6, 8) + "/" + DataGridRow?.FIELD11.slice(4, 6) + "/" + DataGridRow?.FIELD11.slice(0, 4) + " To " + DataGridRow?.FIELD12.slice(6, 8) + "/" + DataGridRow?.FIELD12.slice(4, 6) + "/" + DataGridRow?.FIELD12.slice(0, 4)} </Text>
                </Grid.Col>
              </Grid.Col>
            </Grid>

            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{headerProductName}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  {
                    FIELD28 == "M" ?
                      <>
                        {
                          curMode == 'PD' ?
                            (
                              disableInput ?
                                <Text fz="sm">
                                  {
                                    level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Product" :
                                      level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Party" :
                                        level == prdArr?.length && level == PrtArr?.length && PrtArr?.length == 1 && prdArr?.length == 1 && multiSelectHeaderData?.productData?.length == 0 ? (defaultInputVal?.DSFLD ?? "") + ((prdArr[0]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + defaultInputVal?.MRATE + ")" : '') ?? "") :
                                          level == prdArr?.length && level == PrtArr?.length && PrtArr?.length == 1 && prdArr?.length == 1 ? (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') + ((prdArr[0]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') :
                                            level == PrtArr?.length && level < prdArr?.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Party" :
                                              level < PrtArr.length && level == prdArr.length && PrtArr.length == 3 ? "All Product" :
                                                level < PrtArr?.length && level == prdArr?.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Product" :
                                                  level > PrtArr?.length && level > prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ? (M25Data[prdArr[level - 2]?.TBLKEY]?.[0]?.DSFLD ?? '') + " => " + (defaultInputVal?.DSFLD ?? "") + ((prdArr[level - 1]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + defaultInputVal?.MRATE + ")" : '') ?? '') :
                                                    level < prdArr?.length && level < PrtArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 ? defaultInputVal?.DSFLD ?? '' :
                                                      level == PrtArr?.length && level < prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 ? defaultInputVal?.DSFLD ?? '' :
                                                        level < PrtArr?.length && level == prdArr?.length && multiSelectHeaderData?.productData?.length == 0 ? (defaultInputVal?.DSFLD ?? "") + ((prdArr[level - 1]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + defaultInputVal?.MRATE + ")" : '') ?? '') :
                                                          level < PrtArr?.length && level < prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') : // city,party + product,group
                                                            level == PrtArr?.length && level == prdArr?.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? "") :
                                                              level > PrtArr?.length && level > prdArr?.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? "") + ((prdArr[0]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') :
                                                                level > PrtArr?.length && level < prdArr?.length ? "All Party" :
                                                                  level < PrtArr?.length && level > prdArr?.length ? "All Product" :
                                                                    level == PrtArr?.length && level < prdArr?.length ? multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '' : // party + group, product
                                                                      level > PrtArr?.length && level == prdArr?.length ? multiSelectHeaderData?.partyData[0]?.DSFLD ?? '' :
                                                                        level < PrtArr?.length && level == prdArr?.length ? (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') + ((prdArr[level - 1]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') : // party,city + product
                                                                          level == PrtArr?.length && level > prdArr?.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                                                            ''
                                  }
                                </Text> :
                                <PLComboBox
                                  data={comboData}
                                  dispexpr="DSFLD"
                                  valexpr="CDFLD"
                                  setEdit={(e) => {
                                    // console.log("selected value", e)
                                    handleInputChange(e)
                                  }}
                                  value={
                                    level == prdArr?.length && level == PrtArr?.length && PrtArr?.length == 1 && prdArr?.length == 1 && multiSelectHeaderData?.productData?.length == 0 ?
                                      defaultInputVal?.CDFLD :
                                      level == prdArr?.length && level == PrtArr?.length && PrtArr?.length == 1 && prdArr?.length == 1 ?
                                        multiSelectHeaderData?.productData[0]?.CDFLD :
                                        level > PrtArr?.length && level > prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ?
                                          defaultInputVal?.CDFLD :
                                          level < prdArr?.length && level < PrtArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 ?
                                            defaultInputVal?.CDFLD :
                                            level == PrtArr?.length && level < prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 ?
                                              defaultInputVal?.CDFLD :
                                              level < PrtArr?.length && level == prdArr?.length && multiSelectHeaderData?.productData?.length == 0 ?
                                                defaultInputVal?.CDFLD :
                                                level < PrtArr?.length && level < prdArr?.length ?
                                                  multiSelectHeaderData?.productGroupData[0]?.CDFLD :
                                                  level == PrtArr?.length && level == prdArr?.length ?
                                                    multiSelectHeaderData?.cityData[0]?.CDFLD :
                                                    level > PrtArr?.length && level > prdArr?.length ?
                                                      multiSelectHeaderData?.productData[0]?.CDFLD :
                                                      level == PrtArr?.length && level < prdArr?.length ?
                                                        multiSelectHeaderData?.productGroupData[0]?.CDFLD :
                                                        level > PrtArr?.length && level == prdArr?.length ?
                                                          multiSelectHeaderData?.partyData[0]?.CDFLD :
                                                          level < PrtArr?.length && level == prdArr?.length ?
                                                            multiSelectHeaderData?.productData[0]?.CDFLD :
                                                            level == PrtArr?.length && level > prdArr?.length ?
                                                              multiSelectHeaderData?.partyData[0]?.CDFLD :
                                                              ''
                                  }
                                />
                            ) :
                            curMode == "GRP" ?
                              (disableInput ? <Text fz="sm">
                                {
                                  level < PrtArr.length && level > prdArr.length && PrtArr.length == 3 ? "All Product" :
                                    level > PrtArr.length && level < prdArr.length && prdArr?.length == 3 ? "All Party" :
                                      level == PrtArr?.length && level == prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 ? defaultInputVal?.DSFLD ?? '' :
                                        level == prdArr.length && level == PrtArr.length ? multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '' :
                                          level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.partyData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (M25Data[PrtArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                            level < PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? "") + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                              ''
                                }
                              </Text> :
                                <PLComboBox
                                  data={comboData}
                                  dispexpr="DSFLD"
                                  valexpr="CDFLD"
                                  setEdit={(e) => {
                                    // console.log("selected value", e)
                                    handleInputChange(e)
                                  }}
                                  value={
                                    level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.partyData?.length == 0 ? M25Data[PrtArr[level - 1]?.TBLKEY]?.[0]?.CDFLD :
                                      level == PrtArr?.length && level == prdArr?.length && multiSelectHeaderData?.productGroupData?.length == 0 ?
                                        defaultInputVal?.CDFLD :
                                        level == prdArr.length && level == PrtArr.length ?
                                          multiSelectHeaderData?.productGroupData[0]?.CDFLD :
                                          level < PrtArr.length && level < prdArr.length ?
                                            multiSelectHeaderData?.partyData[0]?.CDFLD :
                                            ''
                                  }
                                />) :
                              curMode == "CT" ?
                                (disableInput ? <Text fz="sm">
                                  {
                                    level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ? (M25Data[prdArr[level]?.TBLKEY]?.[0]?.DSFLD ?? "") + " => " + (M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') + ((prdArr[level - 1]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.MRATE + ")" : '') ?? '') :
                                      level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length > 0 ? (M25Data[prdArr[level]?.TBLKEY]?.[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') + ((prdArr[level - 1]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') :
                                        level == prdArr.length && level == PrtArr.length && multiSelectHeaderData?.partyData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (M25Data[PrtArr[level - 2]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                          level == prdArr.length && level == PrtArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                            level < PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') + ((prdArr[level - 1]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') :
                                              ''
                                  }
                                </Text> :
                                  <PLComboBox
                                    data={comboData}
                                    dispexpr="DSFLD"
                                    valexpr="CDFLD"
                                    setEdit={(e) => {
                                      // console.log("selected value", e)
                                      handleInputChange(e)
                                    }}
                                    value={
                                      level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ?
                                        M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.CDFLD :
                                        level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length > 0 ?
                                          multiSelectHeaderData?.productData[0]?.CDFLD :
                                          level == prdArr.length && level == PrtArr.length && multiSelectHeaderData?.partyData?.length == 0 ?
                                            M25Data[PrtArr[level - 2]?.TBLKEY]?.[0]?.CDFLD :
                                            level == prdArr.length && level == PrtArr.length && multiSelectHeaderData?.partyData?.length > 0 ?
                                              multiSelectHeaderData?.partyData[0]?.CDFLD :
                                              level < PrtArr.length && level < prdArr.length ?
                                                multiSelectHeaderData?.productData[0]?.CDFLD :
                                                ''
                                    }
                                  />) :
                                (
                                  disableInput ? <Text fz="sm">{
                                    level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 ? multiSelectHeaderData?.partyData[0]?.DSFLD ?? '' :
                                      level == PrtArr.length && level < prdArr.length && M25Data?.[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Party" :
                                        level > PrtArr.length && level > prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (defaultInputVal?.DSFLD ?? '') :
                                          level == PrtArr.length && level == prdArr.length && multiSelectHeaderData?.productData?.length == 0 && multiSelectHeaderData?.productGroupData?.length == 0 ? (M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') + " => " + (defaultInputVal?.DSFLD ?? '') + ((prdArr[level - 2]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + defaultInputVal?.MRATE + ")" : '') ?? '') :
                                            level == PrtArr?.length && level > prdArr.length && multiSelectHeaderData?.productData?.length == 0 ? (defaultInputVal?.DSFLD ?? '') + ((prdArr[level - 2]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + defaultInputVal?.MRATE + ")" : '') ?? '') :
                                              level < PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') :
                                                level == PrtArr.length && level == prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') + ((prdArr[0]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') :
                                                  level > PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                                    level > PrtArr.length && level < prdArr.length ? "All Party" :
                                                      level < PrtArr.length && level > prdArr.length ? "All Product" :
                                                        level < PrtArr.length && level == prdArr.length && PrtArr.length == 3 ? "All Product" :
                                                          level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Product" :
                                                            level == PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                                              level > PrtArr.length && level == prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') + ((prdArr[level - 2]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') :
                                                                level < PrtArr.length && level == prdArr.length ? multiSelectHeaderData?.cityData[0]?.DSFLD ?? '' : // party,city + product
                                                                  level == PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') + ((prdArr[level - 2]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + multiSelectHeaderData?.productData[0]?.MRATE + ")" : '') ?? '') :
                                                                    ''
                                  }
                                  </Text>
                                    :
                                    <PLComboBox
                                      data={comboData}
                                      dispexpr="DSFLD"
                                      valexpr="CDFLD"
                                      setEdit={(e) => {
                                        // console.log("selected value", e)
                                        handleInputChange(e)
                                      }}
                                      value={
                                        level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 ?
                                          multiSelectHeaderData?.partyData[0]?.CDFLD :
                                          level > PrtArr.length && level > prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ?
                                            defaultInputVal?.CDFLD :
                                            level == PrtArr.length && level == prdArr.length && multiSelectHeaderData?.productData?.length == 0 && multiSelectHeaderData?.productGroupData?.length == 0 ?
                                              defaultInputVal?.CDFLD :
                                              level == PrtArr?.length && level > prdArr.length && multiSelectHeaderData?.productData?.length == 0 ?
                                                defaultInputVal?.CDFLD :
                                                level < PrtArr.length && level < prdArr.length ?
                                                  multiSelectHeaderData?.cityData[0]?.CDFLD :
                                                  level == PrtArr.length && level == prdArr.length ?
                                                    multiSelectHeaderData?.productData[0]?.CDFLD :
                                                    level > PrtArr.length && level > prdArr.length ?
                                                      multiSelectHeaderData?.partyData[0]?.CDFLD :
                                                      level == PrtArr.length && level < prdArr.length ?
                                                        multiSelectHeaderData?.partyData[0]?.CDFLD :
                                                        level > PrtArr.length && level == prdArr.length ?
                                                          multiSelectHeaderData?.productData[0]?.CDFLD :
                                                          level < PrtArr.length && level == prdArr.length ?
                                                            multiSelectHeaderData?.cityData[0]?.CDFLD :
                                                            level == PrtArr.length && level > prdArr.length ?
                                                              multiSelectHeaderData?.productData[0]?.CDFLD :
                                                              ''
                                      }
                                    />
                                )
                        }
                      </> :
                      <>
                        {
                          curMode == 'PD' ?
                            (
                              disableInput ? <Text fz="sm">{
                                level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Product" :
                                  level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? "All Party" :
                                    level == PrtArr.length && level > prdArr.length ? "All Product" :
                                      headerData?.productData.length == 0 ?
                                        (M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') + ((prdArr[0]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + tableData?.[0]?.MRATE + ")" : '') ?? '') :
                                        (headerData?.productData[0]?.DSFLD ?? '') + ((prdArr[0]?.TBLKEY == "M21FIELD01" ? " (" + "MRP:" + headerData?.productData[0]?.MRATE + ")" : '') ?? '')

                              }</Text>
                                :
                                <PLComboBox
                                  data={M25Data[prdArr[0]?.TBLKEY]}
                                  dispexpr="DSFLD"
                                  valexpr="CDFLD"
                                  setEdit={(e) => {
                                    // console.log("selected value", e)
                                    handleInputChange(e)
                                  }}
                                  value={headerData?.productData.length == 0 ? M25Data[prdArr[0]?.TBLKEY]?.[0]?.CDFLD : headerData?.productData[0]?.CDFLD}
                                />
                            ) : (
                              disableInput ? <Text fz="sm">{headerData?.partyData.length == 0 ? (M25Data[PrtArr[0]?.TBLKEY]?.[0]?.DSFLD ?? '') : (headerData?.partyData[0]?.DSFLD ?? '')}</Text>
                                :
                                <PLComboBox
                                  data={M25Data[PrtArr[0]?.TBLKEY]}
                                  dispexpr="DSFLD"
                                  valexpr="CDFLD"
                                  setEdit={(e) => {
                                    // console.log("selected value", e)
                                    handleInputChange(e)
                                  }}
                                  value={headerData?.partyData.length == 0 ? M25Data[PrtArr[0]?.TBLKEY]?.[0]?.CDFLD : headerData?.partyData[0]?.CDFLD}
                                />
                            )
                        }
                      </>
                  }
                </Grid.Col>
              </Grid.Col>
            </Grid>

            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: '15px' }}>{headerpPartyName}</Text>
                </Grid.Col>
                <Grid.Col span={10}>

                  <Text fz="sm">
                    {
                      FIELD28 == "M" ?
                        (
                          curMode == "PD" ? (
                            level == PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? multiSelectHeaderData?.partyData[0]?.DSFLD ?? '' :
                              level == prdArr.length && level == PrtArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? multiSelectHeaderData?.productData[0]?.DSFLD ?? '' :
                                level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 ? multiSelectHeaderData?.partyData[0]?.DSFLD ?? '' :
                                  level == PrtArr.length && level < prdArr.length && prdArr.length == 3 ? multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '' :
                                    level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? multiSelectHeaderData?.categoryData[0]?.DSFLD ?? '' :
                                      level > PrtArr.length && level > prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (M25Data[PrtArr[level - 3]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                        level == PrtArr.length && level == prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ? (M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') + " => " + (defaultInputVal?.DSFLD ?? '') :
                                          level < PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.cityData[0]?.DSFLD ?? '' :
                                            level == PrtArr.length && level == prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                              level > PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                                level > PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.categoryData?.length == 0 ? (M25Data?.[prdArr[level]?.TBLKEY][0]?.DSFLD ?? '') : multiSelectHeaderData?.categoryData[0]?.DSFLD) : // all party
                                                  level < PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') :
                                                    level == PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') : // party + product, group
                                                      level > PrtArr.length && level == prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                                        level < PrtArr.length && level == prdArr.length && PrtArr.length == 3 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') :
                                                          level < PrtArr.length && level == prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') :
                                                            level == PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                                              ''
                          ) : curMode == "GRP" ? (
                            level < PrtArr.length && level > prdArr.length && PrtArr.length == 3 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                              level > PrtArr.length && level < prdArr.length && prdArr?.length == 3 ? multiSelectHeaderData?.productGroupData[0]?.DSFLD + " => " + (multiSelectHeaderData?.categoryData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ? (M25Data[prdArr[level]?.TBLKEY]?.[0]?.DSFLD ?? '') + " => " + (M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                  level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length > 0 ? (M25Data[prdArr[level]?.TBLKEY]?.[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                    level == PrtArr.length && level == prdArr.length && multiSelectHeaderData?.partyData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (M25Data[PrtArr[level - 2]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                      level == PrtArr.length && level == prdArr.length && multiSelectHeaderData?.partyData?.length > 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                        level < PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                          ''
                          ) : curMode == "CT" ? (
                            level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.cityData?.length == 0 && multiSelectHeaderData?.partyData?.length == 0 ? (M25Data[PrtArr[level]?.TBLKEY]?.[0]?.DSFLD ?? '') + (M25Data[PrtArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                              level < PrtArr.length && level < prdArr.length && multiSelectHeaderData?.cityData?.length > 0 && multiSelectHeaderData?.partyData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (M25Data[PrtArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                level == prdArr.length && level == PrtArr.length && multiSelectHeaderData?.productGroupData?.length == 0 ? (M25Data[prdArr[level - 1]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                  level == prdArr.length && level == PrtArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') :
                                    level < PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                      ''
                          ) :
                            level == prdArr.length && level == PrtArr.length && PrtArr?.length == 1 && prdArr?.length == 1 ? (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                              level == PrtArr.length && level < prdArr.length && prdArr.length == 3 ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.categoryData[0]?.DSFLD ?? '') :
                                level == PrtArr.length && level < prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE && multiSelectHeaderData?.productData?.length == 0 ? (multiSelectHeaderData?.categoryData[0]?.DSFLD ?? '') + " => " + (tableData[0]?.DSFLD ?? '') :
                                  level == PrtArr?.length && level < prdArr?.length && M25Data?.[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? (multiSelectHeaderData?.categoryData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                    level < PrtArr.length && level == prdArr.length && PrtArr.length == 3 && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyGroupData[0]?.DSFLD ?? '') :
                                      level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE && multiSelectHeaderData?.partyData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (tableData[0]?.DSFLD ?? '') :
                                        level < PrtArr.length && level == prdArr.length && M25Data[prdArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                          level > PrtArr.length && level > prdArr.length && multiSelectHeaderData?.productGroupData?.length == 0 && multiSelectHeaderData?.productData?.length == 0 ? (M25Data[prdArr[level - 2]?.TBLKEY]?.[0]?.DSFLD ?? "") + " => " + (M25Data[prdArr[level - 3]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                            (level < PrtArr.length && level < prdArr.length ? multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '' :
                                              level == PrtArr.length && level == prdArr.length ? multiSelectHeaderData?.cityData[0]?.DSFLD ?? '' :
                                                level > PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                                  level > PrtArr.length && level < prdArr.length ? (
                                                    multiSelectHeaderData?.categoryData?.length == 0 && tableData.length == 0 ? (M25Data?.[prdArr[level]?.TBLKEY]?.[0]?.DSFLD ?? '') + "=>" :
                                                      multiSelectHeaderData?.categoryData?.length > 0 && tableData.length > 0 && multiSelectHeaderData?.productData?.length == 0 ?
                                                        (multiSelectHeaderData?.categoryData[0]?.DSFLD ?? '') + " => " + (tableData[0]?.DSFLD ?? '') :
                                                        multiSelectHeaderData?.categoryData?.length > 0 && tableData.length > 0 && multiSelectHeaderData?.productData.length > 0 ?
                                                          (multiSelectHeaderData?.categoryData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                                          multiSelectHeaderData?.categoryData?.length == 0 && tableData?.length > 0 && multiSelectHeaderData?.productData?.length == 0 ?
                                                            (M25Data?.[prdArr[level]?.TBLKEY]?.[0]?.DSFLD ?? '') + " => " + (tableData[0]?.DSFLD ?? '') :
                                                            '') :
                                                    level < PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                                      level == PrtArr.length && level < prdArr.length ? (multiSelectHeaderData?.productGroupData[0]?.DSFLD ?? '') : // party + product, group
                                                        level > PrtArr.length && level == prdArr.length ? (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                                          level < PrtArr.length && level == prdArr.length ? (multiSelectHeaderData?.productData[0]?.DSFLD ?? '') :
                                                            level == PrtArr.length && level > prdArr.length && multiSelectHeaderData?.partyData?.length == 0 ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (M25Data[PrtArr[level - 2]?.TBLKEY]?.[0]?.DSFLD ?? '') :
                                                              level == PrtArr.length && level > prdArr.length ? (multiSelectHeaderData?.cityData[0]?.DSFLD ?? '') + " => " + (multiSelectHeaderData?.partyData[0]?.DSFLD ?? '') :
                                                                ''
                                            )
                        ) :
                        // FIELD28 == S
                        (
                          curMode == "PD" && level == PrtArr.length && level == prdArr.length && M25Data[PrtArr[level - 1]?.TBLKEY] == M25Data?.TBLNONE ? headerData?.productData[0]?.DSFLD ?? '' :
                            curMode == "PD" && headerData.partyData.length == 0 ? M25Data[PrtArr[0]?.TBLKEY]?.[0]?.DSFLD ?? '' :
                              curMode == "PD" ? headerData?.partyData[0]?.DSFLD ?? '' : headerData?.productData?.[0]?.DSFLD ?? '')
                    }
                  </Text>

                </Grid.Col>
              </Grid.Col>
            </Grid>

            <Grid gutter={4}>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>
            </Grid>

            <Group style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {
                hideButton && partyButtonCaption.button4 !== '' ?
                  <Button onClick={() => {
                    handleHideButton()
                  }}>
                    {partyButtonCaption.button4}
                  </Button> :
                  show &&
                    buttonCaptions.button1 !== '' ?

                    <Button onClick={() => {
                      handleChangeButton()
                    }}>
                      {buttonCaptions.button1}
                    </Button>
                    : ''
              }

              {
                show && buttonCaptions.button2 !== '' ?
                  <Button onClick={() => {
                    ChangeButton()
                  }} >
                    {buttonCaptions.button2}
                  </Button> : ''
              }
              {
                buttonCaptions.button3 !== '' && show ?
                  <Button onClick={() => {
                    handlePartyButton()
                  }}>
                    {buttonCaptions.button3}
                  </Button>
                  : ''
              }
              <Button>Print</Button>
            </Group>

          </Paper>
      }
    </>
  );
};
export default M25DataTable