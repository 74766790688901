import { Alert, Button, Center, Divider, Grid, Group, Modal, SimpleGrid, Text } from "@mantine/core";
import { useState } from "react";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { IconAlertCircle } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

export default function CheckGst(props){
    const {gst,copyGstDetails} = props;
    let params =JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: gst,
      cOPara: "",
      Custid:"PL-P-4486"
    });
    const [gstDetails,setGstDetails]=useState({
      GSTIN:"",
      LegalName:"",
      TradeName:"",
      Address1:"",
      Address2:"",
      State:"",
      City:"",
      Pincode:"",
      Status:"",
      TaxType:"",
      RegisterDate:"",
      CancelDate:""
    });
    const [Modalopen,setModal] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [Error,setError]=useState("");
    const [RetModalopen,setRetModal] = useState(false)
    const [RetisLoading,setRetIsLoading] = useState(false)
    const [RetError,setRetError]=useState("");
    const columnHelper = createColumnHelper();
    var Columns=[
      columnHelper.accessor("mof", {
        header: "Mode",
        size:100
      }),
      columnHelper.accessor("dof", {
        header: "Date Of Filing",
        size:130
      }),
      columnHelper.accessor("rtntype", {
        header: "Return Type",
        size:110
      }),
      columnHelper.accessor("ret_prd", {
        header: "Ret. Prd.",
        size:100,
        Cell: ({ cell, renderedCellValue }) =>(cell.getValue()?cell.getValue().substring(0,2)+"/"+cell.getValue().substring(2,6):""),
      }),
      columnHelper.accessor("arn", {
        header: "ARN",
        size:150
      }),
      columnHelper.accessor("status", {
        header: "Status",
        size:100
      })
    ]
    const [RetDetails,setRetDetails]=useState([]);
    const tabled = useMantineReactTable({
      data: RetDetails,
      columns:Columns,
      enableColumnOrdering: false,
      enableStickyHeader: true,
      enableColumnResizing: false,
      columnResizeMode: "onEnd",
      enableGrouping: false,
      enableEditing:false,
      editDisplayMode: "row",
      enableDensityToggle: false,
      enablePagination: false,
      enableRowVirtualization: false,
      mantineTableProps:{withColumnBorders:true},
      initialState: {
        density: "5px",
      },
      state: {
        density: "5px",
      },
      enableTopToolbar: false,
      enableBottomToolbar:false,
      enableColumnFilters: false,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableGlobalFilter: false,
      enableFullScreenToggle:false,
      enableHiding:false,
      enableGlobalFilterModes: false,
      enableTableFooter: false,
      enableStickyFooter:false,
      enableColumnFilterModes:false,
      enableSelectAll: false,
      enableMultiRowSelection: false,
      layoutMode: "semantic",
      mantineTableContainerProps: {
        sx: { height: 350  },
      },
    });
    const getRetDetails= () =>{
      setRetIsLoading(true);
        fetch(GlobalClass.ApiUrl+'M01/ReturnStatus?pa='+params)
        .then((res)=>res.json())
        .then((e)=>{
          console.log(e);
          if(e.data?.jsonResult?.Status=="SUCCESS" && e.data?.jsonResult?.success=="true"){
            setRetError("");
            var dt = e.data?.jsonResult?.Data?.EFiledlist;
            if(dt && dt.length > 0){
              setRetDetails(dt);
            }
            else{
              setRetDetails([]);
            }
          }
          else{
            setRetError(e.data?.jsonResult?.message);
          }
          setRetIsLoading(false)
          setRetModal(true)
        }).catch((e)=>{
          setRetError("Network Error Occured!!");
          setRetIsLoading(false)
          setRetModal(true)
        })
    }
    const getDetails = () =>{
        setIsLoading(true);
        fetch(GlobalClass.ApiUrl+'M01/CheckGstin?pa='+params)
        .then((res)=>res.json())
        .then((e)=>{
          console.log(e);
          if(e.data?.jsonResult?.Status=="SUCCESS" && e.data?.jsonResult?.success=="true"){
            setError("");
            var dt = e.data?.jsonResult?.Data;
            setGstDetails({
              GSTIN:dt?.gstin??"",
              LegalName:dt?.lgnm??"",
              TradeName:dt?.tradeNam??"",
              Address1:dt?.pradr?.addr?.bno??"",
              Address2:(dt?.pradr?.addr?.bnm??"") + (dt?.pradr?.addr?.st ? (", "+dt?.pradr?.addr?.st):"") + (dt?.pradr?.addr?.loc ? (", "+dt?.pradr?.addr?.loc):""),
              State:dt?.pradr?.addr?.stcd??"",
              City:(dt?.pradr?.addr?.city?dt?.pradr?.addr?.city:(dt?.pradr?.addr?.dst??"")),
              Pincode:dt?.pradr?.addr?.pncd,
              Status:dt?.sts,
              TaxType:dt?.dty,
              RegisterDate:dt?.rgdt,
              CancelDate:dt?.cxdt
            });
          }
          else{
            setError(e.data?.jsonResult?.message);
          }
          setIsLoading(false)
          setModal(true)
        }).catch((e)=>{
          setError("Network Error Occured!!");
          setIsLoading(false)
          setModal(true)
        })
    }
    const RenderBtn = () =>{
      return (<Group
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <Button autoFocus variant="outline" onClick={() => {
          setModal(false)
          setRetModal(false)
        }}>
          ok
        </Button>
        <Button variant="outline" onClick={() => {
          copyGstDetails(gstDetails);
          setModal(false);
        }}>
          Copy
        </Button>
        <Button variant="outline" loading={RetisLoading} onClick={() => {getRetDetails()}}>
          Return Status
        </Button>
      </Group>)
    }
    return (<>
    <Modal
        title={<Text fw={600}>Search Result Based On GSTIN/UIN: {gst}</Text>}
        opened={Modalopen}
        onClose={()=>{setModal(false)}}
        closeOnClickOutside={false}
        fullScreen={false}
        closeOnEscape={false}
        centered={true}
        keepMounted={true}
        withCloseButton={true}
        size={'xl'}
      ><Center mx={"auto"}>
    {isLoading?
    GlobalClass.RenderLoader('dots'):
    Error?(<Alert icon={<IconAlertCircle size="1rem" />} title={Error} color="red"></Alert>):
    (<Grid p={5} mt={4} gutter={4}>
      <Grid.Col span={4}><Text fw={'bold'}>GSTIN/UIN:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.GSTIN}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Legal Name:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.LegalName}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Trade Name:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.TradeName}</Grid.Col>

      <Grid.Col span={12} m={5}><Divider/></Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Address - 1:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.Address1}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Address - 2:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.Address2}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>State:</Text></Grid.Col>
      <Grid.Col span={8}>{(gstDetails?.State)}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>City:</Text></Grid.Col>
      <Grid.Col span={8}>{(gstDetails?.City)}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Pincode:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.Pincode}</Grid.Col>

      <Grid.Col span={12} m={5}><Divider/></Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Status:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.Status}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Tax Type:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.TaxType}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Register Date:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.RegisterDate}</Grid.Col>

      <Grid.Col span={4}><Text fw={'bold'}>Cancle Date:</Text></Grid.Col>
      <Grid.Col span={8}>{gstDetails?.CancelDate}</Grid.Col>

      <Grid.Col span={12}>
        {RenderBtn()}
      </Grid.Col>
    </Grid>)
    }
    </Center>
      </Modal>
      <Modal
        title={<Text fw={600}>{gst}: Return Status</Text>}
        opened={RetModalopen}
        onClose={()=>{setRetModal(false)}}
        closeOnClickOutside={false}
        fullScreen={false}
        closeOnEscape={false}
        centered={true}
        keepMounted={true}
        withCloseButton={true}
        size={'auto'}
      ><Center mx={"auto"}>
{RetisLoading?
    GlobalClass.RenderLoader('dots'):
    RetError?(<Alert icon={<IconAlertCircle size="1rem" />} title={RetError} color="red"></Alert>):
    RetDetails &&<Grid>
          
    <Grid.Col
      span={12}
      m={0}
      p={0}> <MantineReactTable table={tabled}/></Grid.Col></Grid>
    }
        </Center></Modal>
    <Button variant="outline" loading={isLoading} size="xs" onClick={()=>{
        // setModal(true);
        getDetails()
    }}>Check GST</Button></>)

}