import { Button, Drawer, Grid, Modal, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { createColumnHelper } from "@tanstack/react-table";
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountListForPrint } from "../../utils/PrintSlice/PrintSlice";
import GlobalClass from "../../utils/GlobalClass";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import DataGrid from "../../components/DataGrid";
import PLModal from "./PLModal";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { getFilterStatus, getFiltersName } from "../../utils/FilterSlice/FilterSlice";


let P1Value = "";
export const PLFilter = (props) => {

    
    console.log("PLFilter",props);
    const { ary } = props;

    const dispatch = useDispatch();
    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)
    const AccountListData = useSelector(store => store?.PrintList?.PrintData);
    const isLoading = useSelector(store => store?.PrintList?.isLoading);
    const FiltersList = useSelector(store=>store?.FilterSlice?.FiltersName)

    const [PLDataGridSelectedValues, setPLDataGridSelectedValues] = useState([]);
    const [filterName, setFilterName] = useState("");
    const [ruleID,setruleID] = useState();
    const [queryState, setQueryState] = useState({});
    const [isFilterSave,setIsFilterSave] = useState(false);
    const [condition,setCondition] = useState("and");

    // const [P1Value,setP1Value] = useState("")

    
   
    useEffect(() => {
console.log("PLDataGridSelectedValues",PLDataGridSelectedValues)
    },[PLDataGridSelectedValues])

    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);

    const transformDataForQueryBuilder = (data) => {
        // const queryData = {
        //     condition: data.group0_condition,
        //     rules: Object.keys(data)
        //         .filter(key => key.includes('_rule'))
        //         .map(key => {
        //             const ruleId = key.split('_')[1];
        //             const ruleData = data[key];
        //             return {
        //                 label: ruleData.field,
        //                 field: ruleData.field,
        //                 type: 'string', // You may need to adjust this based on your data types
        //                 operator: ruleData.operator,
        //                 value: ruleData.value
        //             };
        //         })
        // };
        // return queryData;
       
       
        // const queryData = {
        //     condition: "and", // Default condition
        //     rules: []
        // };
    
        // let currentGroup = null;
    
        // Object.keys(data).forEach(key => {
        //     if (key.includes('_rule')) {
        //         const ruleId = key.split('_')[1];
        //         const ruleData = data[key];
        //         const ruleObj = {
        //             label: ruleData.field,
        //             field: ruleData.field,
        //             type: 'string', // You may need to adjust this based on your data types
        //             operator: ruleData.operator,
        //             value: ruleData.value
        //         };
    
        //         if (currentGroup) {
        //             // If a rule belongs to a group, add it to the group's rules
        //             currentGroup.rules.push(ruleObj);
        //         } else {
        //             // If a rule doesn't belong to any group, add it directly to queryData
        //             queryData.rules.push(ruleObj);
        //         }
        //     } else if (key.endsWith('_condition')) {
        //         // Set the condition for the current group
        //         const groupId = key.split('_')[0];
        //         if (queryData.rules.length > 0 && groupId in queryData.rules[queryData.rules.length - 1]) {
        //             // If there are rules in the queryData and the current group matches the last rule
        //             queryData.rules[queryData.rules.length - 1].condition = data[key];
        //         } else {
        //             // If the condition belongs to a new group
        //             currentGroup = {
        //                 condition: data[key],
        //                 rules: []
        //             };
        //             queryData.rules.push(currentGroup);
        //         }
        //     }
        // });
    
        // return queryData;

        // const queryData = {
        //     condition: "and", // Default condition
        //     rules: []
        // };
    
        // let currentGroup = null;
    
        // Object.keys(data).forEach(key => {
        //     if (key.includes('_rule')) {


        //         console.log("queryData IF",queryData);

        //         const ruleId = key.split('_')[1];
        //         const ruleData = data[key];
        //         const ruleObj = {
        //             label: ruleData.field,
        //             field: ruleData.field,
        //             type: 'string', // You may need to adjust this based on your data types
        //             operator: ruleData.operator,
        //             value: ruleData.value
        //         };
    
        //         if (currentGroup) {
        //             // If a rule belongs to a group, add it to the group's rules
        //             currentGroup.rules.push(ruleObj);
        //         } else {
        //             // If a rule doesn't belong to any group, add it directly to queryData
        //             queryData.rules.push(ruleObj);
        //         }
        //     } else if (key.endsWith('_condition')) {
        //         // Set the condition for the current group
        //         console.log("queryData ELSE",queryData);
        //         const groupId = key.split('_')[0];
        //         if (queryData.rules.length > 0 && groupId in queryData.rules[queryData.rules.length - 1]) {
        //             // If there are rules in the queryData and the current group matches the last rule
        //             queryData.rules[queryData.rules.length - 1].condition = data[key];
        //         } else {
        //             // If the condition belongs to a new group
        //             currentGroup = {
        //                 condition: data[key],
        //                 rules: []
        //             };
        //             queryData.rules.push(currentGroup);
        //         }
        //     }
        // });
    
        // return queryData;


        const queryData = {
            condition: "and", // Default condition
            rules: []
        };
        
        let currentGroup = null;
        
        Object.keys(data).forEach(key => {
            if (key.includes('_rule')) {
                const ruleId = key.split('_')[1];
                const ruleData = data[key];
                const ruleObj = {
                    label: ruleData.field,
                    field: ruleData.field,
                    type: 'string', // You may need to adjust this based on your data types
                    operator: ruleData.operator,
                    value: ruleData.value
                };
        
                if (currentGroup) {
                    // If a rule belongs to a group, add it to the group's rules
                    currentGroup.rules.push(ruleObj);
                } else {
                    // If a rule doesn't belong to any group, add it directly to queryData
                    queryData.rules.push(ruleObj);
                }
            } else if (key.endsWith('_condition')) {
                // Set the condition for the current group
                const groupId = key.split('_')[0];
                if (currentGroup) {
                    // If there's already a current group, join it with the previous group based on the condition
                    const joinCondition = currentGroup.condition === 'or' ? 'or' : 'and';
                    queryData.rules.push({ condition: joinCondition, rules: currentGroup.rules });
                }
                currentGroup = {
                    condition: data[key],
                    rules: []
                };
            }
        });
        
        // If there's a current group, add it to the queryData
        if (currentGroup) {
            const joinCondition = currentGroup.condition === 'or' ? 'or' : 'and';
            queryData.rules.push({ condition: joinCondition, rules: currentGroup.rules });
        }
        
        return queryData;
        

    };
    
    const queryData = ary && transformDataForQueryBuilder(ary);
    // console.log(queryData , "queryData");

    const dropdownTemplate = () => {
        return (
            <PLDataGrid
                position="target"
                pid={P1Value}
                value={PLDataGridSelectedValues?.[ruleID]}
                isMulti={true}
                width="500px"
                valexpr="FIELD01"
                dispexpr="FIELD02"
                p1={P1Value}
                setEdit={(e) => {
                    let selectedRow = "";
                    if(e?.length > 0)
                    {
                      selectedRow =  e?.map((item) => item?.original?.FIELD01).join();
                    }
                    // let selectedRow = e?.map((item) => item?.original?.FIELD01).join();
                    setPLDataGridSelectedValues({...PLDataGridSelectedValues,[ruleID]:selectedRow});
                    handleRuleChange({type:'value',value:selectedRow,ruleID:ruleID})
                }}
            />
        )
    }

    let columnData = [
        {
            field: 'Account Name', label: 'Account Name', operators: [{ key: 'Contains', value:'Contains' },
            { key: 'Not Contains', value: 'Not Contains' }], template: dropdownTemplate,
        },
        {
            field: 'Account Group', label: 'Account Group', operators: [{ key: 'Contains', value: 'Contains' },
            { key: 'Not Contains', value: 'Not Contains' }], template: dropdownTemplate
        },
        {
            field: 'City', label: 'City', operators: [{ key: 'Contains', value: 'Contains' },
            { key: 'Not Contains', value: 'Not Contains' }], template: dropdownTemplate,
        },

    ];

    useEffect(() => {
        // console.log("condition",condition);
        // console.log(PLDataGridSelectedValues,"PLDataGridSelectedValues");
        setPLDataGridSelectedValues({})

    },[condition])

    // useEffect(() => {
    //     console.log(PLDataGridSelectedValues,"PLDataGridSelectedValues");
    // },[PLDataGridSelectedValues])

    const handleRuleChange = (args) => {
        console.log("handleRuleChange",args);
        
        const { type, groupID, field, value, operator, condition,ruleID,childGroupID} = args;

        if(type == "condition")
        {
            setCondition(value)
        }
        switch(value){
                case "Account Name":
                    // setP1Value("P_M01")
                    P1Value = "P_M01"; 
                    break;
                case "Account Group":
                    // setP1Value("P_M11")
                    P1Value = "P_M11";
                    break;
                case "City":
                    // setP1Value("P_M31T")
                    P1Value = "P_M31T";
                    break;  
                default:
                    break;
        }
           ruleID && setruleID(ruleID)
    
            switch (type) {
                case "insertRule":
                    // Adding Condition
                    setQueryState(prevState => ({
                        ...prevState,
                            [ruleID]: {
                                field: undefined,
                                operator: "Contains",
                                value: undefined,
                            },
                     
                    }));
                    break;
                case "insertGroup":
                    // Adding Group
                    
                    setQueryState(prevState => ({
                        ...prevState,
                             [ruleID]: {
                                field: undefined,
                                operator: "Contains",
                                value: undefined,
                            },
                    }));
                    break;
                
                case "deleteRule":
                    // Removing Condition
                    setQueryState(prevState => {
                        const newState = { ...prevState };
                        if (newState[ruleID]) {
                            delete newState[ruleID];
                        }
                        return newState;
                    });
                    setPLDataGridSelectedValues(prevState => {
                        const newState = { ...prevState };
                        if(newState[ruleID]) 
                        {
                            delete newState[ruleID]
                        }
                        return newState;
                    })
                  
                    break;
                case "deleteGroup":
                    // Removing Group
                    setQueryState(prevState => {
                        const newState = { ...prevState };
                        if (newState[ruleID]) {
                            delete newState[ruleID];
                        }
                        return newState;
                    });
                    break;
                case "field":
                    setQueryState(prevState => ({
                        ...prevState,
                       [ruleID]: {
                            ...prevState?.[ruleID],
                            field : value
                        }
                    }));
                    break;
                case "value":
                    setQueryState(prevState => ({
                        ...prevState,
                        [ruleID]: {
                             ...prevState?.[ruleID],
                             value : value
                         }
                    }));
                    break;
                case "operator":
                    setQueryState(prevState => ({
                        ...prevState,
                        [ruleID]: {
                             ...prevState?.[ruleID],
                             operator : value
                         }
                    }));
                    break;
                case "condition":
                    setQueryState(prevState => ({
                        ...prevState,
                        [`${groupID}_condition`]: value
                    }));
                    break;
                default:
                    break;
            }

            // switch (type) {
            //     case "field":
            //         setQueryState(prevState => ({
            //             ...prevState,
            //             fields: {
            //                 ...prevState.fields,
            //                 [groupID]: value
            //             }
            //         }));
            //         break;
            //     case "value":
            //         setQueryState(prevState => ({
            //             ...prevState,
            //             values: {
            //                 ...prevState.values,
            //                 [groupID]: value
            //             }
            //         }));
            //         break;
            //     case "operator":
            //         setQueryState(prevState => ({
            //             ...prevState,
            //             operators: {
            //                 ...prevState.operators,
            //                 [groupID]: value
            //             }
            //         }));
            //         break;
            //     case "condition":
            //         setQueryState(prevState => ({
            //             ...prevState,
            //             conditions: {
            //                 ...prevState.conditions,
            //                 [groupID]: value
            //             }
            //         }));
            //         break;
            //     default:
            //         break;
            // }
        
    
    };

    useEffect(() => {
        if(isFilterSave)
        {
            console.log("filterName",filterName);

            // for (let key in filterName) {
            //     if (!key.includes("undefined")) { // Check if key contains "undefined"
            //         let groupName = key.split("_")[0]; // Extract group name (e.g., "group0")

            //         if (!filteredObject[groupName]) {
            //             filteredObject[groupName] = {};
            //             groupCount++;
            //         }

            //         if (Object.keys(filteredObject[groupName]).length < 2) {
            //             filteredObject[groupName][key] = filterName[key];
            //         }

            //         if (groupCount >= 2) { // Limit to 2 groups
            //             break;
            //         }
            //     }
            // }

            let filteredObject = {};
            let groupCount = 0;

            for (let key in filterName) {
                let abc = filterName[key];

                for(let ele in abc)
                { 
                    if (!ele.includes("undefined")) {
                        let groupName = ele.split("_")[0];
                        if (groupName == "group0" || groupName == "group1")
                        { 

                            
                        }

                    }
                }
    
            }

            console.log("filteredObject",filteredObject);

                       
            Promise.resolve(dispatch(getFiltersName(filterName)))
            .then(() => {
                setDrawerObj({ ...DrawerObj, open: false });
                dispatch(ModalDelete(props.index))
            })
        }
    },[isFilterSave,filterName])    

    const saveBtn = () => {

       const isEmptyObject = Object.keys(PLDataGridSelectedValues)
    //    console.log("isEmptyObject",isEmptyObject,isEmptyObject.length);

       if(isEmptyObject && isEmptyObject.length > 0)
       {
           setDrawerObj({
               ...dra,
               title: <Text fw={700}>Save Filter</Text>,
               body: (
                   <>
                       <Grid>
                           <Grid.Col span={12} style={{ display: 'flex' }}>
                               <Grid.Col span={4}>
                                   <Text fw={700}>Filter Name</Text>
                               </Grid.Col>
                               <Grid.Col span={8}>
                                   <PLTextBox
                                       setEdit={(e) => {
                                           setFilterName({[e.target.value]:queryState})
                                       }}
                                   />
                               </Grid.Col>
                           </Grid.Col>
                           <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                               <Button onClick={() => setIsFilterSave(true)} >Ok</Button>
                           </Grid.Col>
                       </Grid>
                   </>
               ),
               open: true,
               size: "md",
               position: "right",
           });
       }
       else{
        GlobalClass.Notify("warning","Please select any value from dropdown")
       }

    }

    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => { }}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: () => {
                        setDrawerObj(dra);
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            // scrollAreaComponent={ScrollArea.Autosize}
            >
                {DrawerObj.body}
            </Modal>

                    <Grid>
                        <Grid.Col span={12}>
                            {
                            ary ? 
                            (
                                <QueryBuilderComponent width="100%"
                                // dataSource={datamanager}
                                columns={columnData??[]}
                                rule={queryData}
                                // ruleChange={handleRuleChange}
                                change={handleRuleChange}
                             >
                                
                             </QueryBuilderComponent>   
                            ) 
                            : (
                                // AccountListData[props?.OBJ?.BtnPnlObj?.id] &&
                             
                                    <QueryBuilderComponent
                                    width="100%"
                                    // dataSource={datamanager}
                                    columns={columnData??[]}
                                    // ruleChange={handleRuleChange}
                                    change={handleRuleChange}
                                    // actionBegin={handleRuleChange1}

                                    >
                                
                                    </QueryBuilderComponent>
                               
                            )
                            }
                        </Grid.Col>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={saveBtn} style={{ margin: '10px' }}>Save</Button>
                            <Button style={{ margin: '10px' }}>Run</Button>
                            <Button style={{ margin: '10px' }}>Delete</Button>
                            <Button style={{ margin: '10px' }}>Copy</Button>
                        </Grid.Col>

                    </Grid>          
        </>
    )
    
}


export function TM_EXPORT(data) {
    const OBJ = JSON.parse(data);
    console.log("OBJ", OBJ);

    const state = Store.getState();
    const FiltersName = state?.FilterSlice?.FiltersName;
    const isFilterApplied = state?.FilterSlice?.isFilterApplied;   

     let isAnySavedFilter = FiltersName &&  Object.keys(FiltersName).length 
    //  console.log("isAnySavedFilter",isAnySavedFilter);


      if(isFilterApplied)
      {
        // Store.dispatch((GetAccountListForPrint({...OBJ,pagination:{pageIndex: 0,pageSize: 25}})))
        Store.dispatch(GetAccountList({...OBJ?.BtnPnlObj,pagination:{pageIndex: 0,pageSize: 25}}))
        Store.dispatch((getFilterStatus(false)))
      }
      else{
        if(isAnySavedFilter === 0)
        {
           Store.dispatch(
               ModalFunction({
                   onclose: (e) => { },
                   MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                   MAction: true,
                   MSize: "75%",
                   position: 'bottom',
                   MBody: (i) =>  <PLFilter OBJ={OBJ} index={i} />,
                   MClose: true,
                   Overlay: {
                       backgroundOpacity: 0.8,
                       blur: 5,
                   },
               })
           );
        }
        else{
           Store.dispatch(
               ModalFunction({
               onclose: () => { },
               MTitle: <Text fw={700}>Select Filter to Apply</Text>,
               MAction: true,
               MSize: "xl",
               // MHeight :100,
               MBody: (i) => <PLModal OBJ={OBJ} index={i} />,
               MClose: true,
               Overlay: {
                   backgroundOpacity: 0.8,
                   blur: 5,
               }
           })
       );
        }
      }
     


}





// const PLFilter = (props) => {

//     const dispatch = useDispatch();
//     const AccountListData = useSelector(store => store?.PrintList?.PrintData);
//     const isLoading = useSelector(store => store?.PrintList?.isLoading);

//     const [selectedValues, setSelectedValues] = useState([]);
//     const [filterName, setFilterName] = useState("");
//     const [filterConditions, setFilterConditions] = useState(null);

//     let dra = {
//         title: "",
//         size: "md",
//         body: "",
//         open: false,
//     };
//     const [DrawerObj, setDrawerObj] = useState(dra);

//     useEffect(() => {
//         dispatch(GetAccountListForPrint(props?.OBJ?.BtnPnlObj))
//     }, [])

//     // useEffect(() => {
//     //     console.log("AccountListData", AccountListData[props?.OBJ?.BtnPnlObj?.id]);
//     // }, [AccountListData])


//     let qbObj = useRef(null);
//     let gridObj = useRef(null);
//     let datamanager = new DataManager(AccountListData[props?.OBJ?.BtnPnlObj?.id]??[]);
//     let query = new Query().select([
//         "Account Name",
//         "Account Group",
//         "City",
//     ]);
//     const updateRule = (args) => {
//         console.log("args", args)

//         if (gridObj.current && gridObj.current.query) {
//             let predicate = qbObj?.current?.getPredicate(args?.rule);
//             if (isNullOrUndefined(predicate)) {
//                 gridObj.current.query = new Query().select([
//                     "Account Name",
//                     "Account Group",
//                     "City",
//                 ]);
//             }
//             else {
//                 gridObj.current.query = new Query()
//                     .select([
//                         "Account Name",
//                         "Account Group",
//                         "City",
//                     ])
//                     .where(predicate);
//             }
//             gridObj.current.refresh();
//         }
//     };
//     const onGridCreated = () => {
//         console.log("qbObj", qbObj)
//         updateRule({ rule: qbObj?.current?.getValidRules(qbObj?.current?.rule) });
//     };

//     const dropdownTemplate = () => {
//         return (
//             <PLDataGrid
//                 pid={props?.OBJ?.BtnPnlObj?.id}
//                 value={selectedValues}
//                 isMulti={true}
//                 width="500px"
//                 valexpr="FIELD01"
//                 dispexpr="FIELD02"
//                 p1={props?.OBJ?.BtnPnlObj?.p1}
//                 setEdit={(e) => {
//                     // console.log("setEdit", e);
//                     let selectedRowPrint = e?.map((item) => item?.original?.FIELD01).join();
//                     // console.log("selectedRowPrint", selectedRowPrint);
//                     setSelectedValues(selectedRowPrint);
//                     // setSelectedValues(e?.FIELD01)
//                 }}
//             // data={data}
//             // TmData={TmData}
//             />
//         )
//     }

//     let columnData = [
//         {
//             field: 'Account Name', label: 'Account Name', operators: [{ key: 'Contains', value:'Contains' },
//             { key: 'Not Contains', value: 'Not Contains' }], template: dropdownTemplate,
//         },
//         {
//             field: 'Account Group', label: 'Account Group', operators: [{ key: 'Contains', value: 'Contains' },
//             { key: 'Not Contains', value: 'Not Contains' }], template: dropdownTemplate
//         },
//         {
//             field: 'City', label: 'City', operators: [{ key: 'Contains', value: 'Contains' },
//             { key: 'Not Contains', value: 'Not Contains' }], template: dropdownTemplate,
//         },

//     ];

//     // let importRules = {
//     //     condition: "or",
//     //     rules: [
//     //         {
//     //             label: "Account Name",
//     //             field: "Account Name",
//     //             type: "string",
//     //             operator:"Contains",
//     //             // value:"aa"
//     //         },
//     //         {
//     //             label: "Account Group",
//     //             field: "Account Group",
//     //             type: "string",
//     //             operator:"Contains",
//     //             // value:"ab"
//     //         },
//     //     ],

//     // };

//     const saveBtn = () => {

//         setDrawerObj({
//             ...dra,
//             title: <Text fw={700}>Save Filter</Text>,
//             body: (
//                 <>
//                     <Grid>
//                         <Grid.Col span={12} style={{ display: 'flex' }}>
//                             <Grid.Col span={4}>
//                                 <Text fw={700}>Filter Name</Text>
//                             </Grid.Col>
//                             <Grid.Col span={8}>
//                                 <PLTextBox
//                                     setEdit={(e) => {
//                                         setFilterName(e.target.value)
//                                     }}
//                                 />
//                             </Grid.Col>
//                         </Grid.Col>
//                         <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
//                             <Button onClick={() => setDrawerObj({ ...DrawerObj, open: false })} >Ok</Button>
//                         </Grid.Col>
//                     </Grid>
//                 </>
//             ),
//             open: true,
//             size: "md",
//             position: "right",
//         });

//         // Store.dispatch(
//         //     ModalFunction({
//         //         onclose: (e) => { },
//         //         MTitle: <Text fw={700}>Save Filter</Text>,
//         //         MAction: true,
//         //         MSize: "md",
//         //         position: 'right',
//         //         MBody: (i) => (
//         //             <>
//         //                 <Grid>
//         //                     <Grid.Col span={12} style={{ display: 'flex' }}>
//         //                         <Grid.Col span={4}>
//         //                             <Text fw={700}>Filter Name</Text>
//         //                         </Grid.Col>
//         //                         <Grid.Col span={8}>
//         //                             <PLTextBox
//         //                                 setEdit={(e) => {
//         //                                     setFilterName(e.target.value)
//         //                                 }}
//         //                             />
//         //                         </Grid.Col>
//         //                     </Grid.Col>
//         //                     <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
//         //                         <Button onClick={() => setDrawerObj({ ...DrawerObj, open: false })} >Ok</Button>
//         //                     </Grid.Col>
//         //                 </Grid>
//         //             </>
//         //         ),
//         //         MClose: true,
//         //         Overlay: {
//         //             backgroundOpacity: 0.8,
//         //             blur: 5,
//         //         },
//         //     })
//         // );
//     }

//     return (
//         <>
//             <Modal
//                 opened={DrawerObj?.open}
//                 // onClose={close}
//                 withCloseButton={() => { }}
//                 fullScreen={false} //
//                 // overlayProps={{ opacity: 0.5, blur: 4 }}
//                 closeButtonProps={{
//                     onClick: () => {
//                         setDrawerObj(dra);
//                     },
//                 }}
//                 title={DrawerObj.title}
//                 size={DrawerObj.size}
//                 // centered={.Centered}
//                 closeOnClickOutside={true}
//                 // overlayProps={m.Overlay}
//                 position={DrawerObj?.position ?? "bottom"}
//                 onClose={
//                     typeof DrawerObj?.onclose == "function"
//                         ? DrawerObj?.onclose
//                         : () => {
//                             setDrawerObj(dra);
//                         }
//                 }
//             // scrollAreaComponent={ScrollArea.Autosize}
//             >
//                 {DrawerObj.body}
//             </Modal>

//             {
//                 isLoading ? GlobalClass.RenderLoader("dots") :
//                     <Grid>
//                         <Grid.Col span={12}>
//                             {
//                                 AccountListData[props?.OBJ?.BtnPnlObj?.id] &&
//                                 <>
//                                     <QueryBuilderComponent width="100%" dataSource={datamanager} columns={columnData??[]} ruleChange={updateRule} ref={qbObj}></QueryBuilderComponent>
// {/* 
//                                     <GridComponent
                                   
//                                   width="100%" ref={gridObj} query={query} created={onGridCreated}
//                                      >
//                                         <ColumnsDirective>
//                                             <ColumnDirective field="Account Name" headerText="Account Name" width="120" textAlign="Right" />
//                                             <ColumnDirective field="Account Group" headerText="Account Group" width="140" />
//                                             <ColumnDirective field="City" headerText="City" width="140" textAlign="Right" />
//                                         </ColumnsDirective>
//                                         <Inject services={[Page]} />
//                                     </GridComponent> */}
//                                 </>
//                             }
//                         </Grid.Col>
//                         <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
//                             <Button onClick={saveBtn} style={{ margin: '10px' }}>Save</Button>
//                             <Button style={{ margin: '10px' }}>Run</Button>
//                             <Button style={{ margin: '10px' }}>Delete</Button>
//                             <Button style={{ margin: '10px' }}>Copy</Button>
//                         </Grid.Col>

//                     </Grid>
//             }              
//         </>
//     )

// }