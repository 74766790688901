import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettings,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Toolbar,
  dataStateChange,
  row,
} from "@syncfusion/ej2-react-grids";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountList } from "../utils/slices/AccountListSlice";
import { GetPaginationObj } from "../utils/slices/DataSaveLoadingSlice";
import { Grid, Text } from "@mantine/core";
import { Ajax } from "@syncfusion/ej2-base";
import Store from "../utils/store";
import {
  GetAccountListForPrint,
  GetTMFormat,
} from "../utils/PrintSlice/PrintSlice";
import GlobalClass from "../utils/GlobalClass";
import ButtonPanel from "../PlusComponent/ButtonPanel";

function NewDataGrid(props) {
  const { tag, obj, form, id } = props;
  // let grid;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const TableRef = useRef();
  const [manualPagination, setManualPagination] = useState(true);
  const {
    hasError,
    ErrorMsg,
    isLoading,
    PrintData: accList,
    TMFormatData: tmList,
    rowCount,
    isRefetching,
  } = useSelector((state) => state.PrintList);
  const [acTM, setacTM] = useState({
    AccountListData: [],
    TMFormatData: [],
  });
  const [cfmtid, setcfmtid] = useState("");
  const [columns, setColumns] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const ReportIdArray = useSelector(
    (state) => state.DataSaveLoadingSlice.ReportIdArray
  );
  const TransactionReportIdArray = useSelector(
    (store) => store.DataSaveLoadingSlice.TransactionReportIdArray
  );

  const PaginationObj1 = useSelector((state) => state);
  console.log("PaginationObj", PaginationObj1);
  const GetGeneral = useSelector((state) => state.Language.General);
  const [date, setDate] = useState({});
  const [minimunDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();
  const [lSearchCol, setlSearchCol] = useState();
  const [aSrcDic, setaSrcDic] = useState();
  const [searchColumns, setSearchColumns] = useState();
  const [StrtDt, setStrtDt] = useState();
  const [EndDt, setEndDt] = useState();
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");

  const dateFormater = (date, flag) => {
    let result;
    if (flag === 0) {
      var paraArr = date.split("T");
      var paraArr1 = new Date(paraArr);
      result = paraArr1;
      // var endDate = date.setDate(date.getDate() + 1);
      // result = endDate
    } else if (flag === 1) {
      let year = date.toLocaleString("en-IN", { year: "numeric" });
      let month = date.toLocaleString("en-IN", { month: "2-digit" });
      let day = date.toLocaleString("en-IN", { day: "2-digit" });
      let date1 = year + month + day;
      const date2 =
        date1?.slice(0, 4) +
        "-" +
        date1?.slice(4, 6) +
        "-" +
        date1?.slice(6, 8);
      result = date2;
    }
    return result;
  };
  // const BASE_URL = '';
  const rendereComplete = () => {
    let state = {
      skip: 0,
      take: 10,
    };
    dataStateChange(state);
  };

  const dataStateChange = (state) => {
    console.log("data State changed");
    // dispatch(
    //   GetAccountListForPrint({
    //     ...obj,
    //     StrtDt: StrtDt ?? GetGeneral?.dYSDate,
    //     EndDt: EndDt ?? GetGeneral?.dYEDate,
    //   })
    // );
    // dispatch(
    //   GetPaginationObj({
    //     id: obj.id,
    //     pagination,
    //   })
    // );

    // const pages = Store?.getState()?.DataSaveLoadingSlice?.PaginationObj?.[obj?.id];
    // state.skip = pages?.pageIndex;
    // state.take = pages?.pageSize;
    // console.log("pages",state);

    // const accList = Store.getState()?.PrintList?.PrintData[obj.id];
    // console.log("accList", accList);

    // setData(accList);
  };

  // const ajax = new Ajax({
  //   type: 'GET', mode: true,
  //   onFailure: (e) => { return false; }
  // });
  // function execute(state) {
  //   return getData(state);
  // }
  // function getData(state) {
  //     console.log("getData function started",state)
  // }
  // useEffect(()=>{

  // },[data])
  useEffect(() => {
    rendereComplete();
  }, []);
  useEffect(() => {
    // console.log("GetGeneral", GetGeneral?.dYEDate, GetGeneral?.dYEDate);
    const strtDate = GetGeneral?.dYSDate;
    const endDate = GetGeneral?.dYEDate;
    setDate({ From: dateFormater(strtDate, 0), To: dateFormater(endDate, 0) });
    setMinimumDate(dateFormater(strtDate, 0));
    setMaximumDate(dateFormater(endDate, 0));
  }, [GetGeneral]);
  useEffect(() => {
    var newobj = { ...obj, p2: cfmtid };
    if (props.TmData) {
      setacTM({ TMFormatData: props.TmData, AccountListData: props.data });
    } else {
      dispatch(
        GetTMFormat({
          ...newobj,
          StrtDt: GetGeneral?.dYSDate,
          EndDt: GetGeneral?.dYEDate,
        })
      );
    }
  }, [cfmtid]);

  useEffect(() => {
    if (props.data) {
      setManualPagination(true);
    } else {
      dispatch(
        GetAccountListForPrint({
          ...obj,
          StrtDt: StrtDt ?? GetGeneral?.dYSDate,
          EndDt: EndDt ?? GetGeneral?.dYEDate,

          ReportIdSave: ReportIdArray.includes(obj.id),
        })
      );
      dispatch(
        GetPaginationObj({
          id: obj.id,
          pagination,

          ReportIdSave: ReportIdArray.includes(obj.id),
        })
      );
    }
  }, [pagination.pageIndex, pagination.pageSize]);
  useEffect(() => {
    console.log(
      "condition",
      accList && tmList && accList[obj.id] && tmList[obj?.id] && !props?.TmData
    );
    console.log("accList", accList);
    console.log("tmList", tmList);
    console.log("accList[obj.id]", accList[obj.id]);
    console.log("tmList[obj.id] ", tmList[obj?.id]);
    if (accList && tmList && accList[obj.id] && tmList[obj?.id] && !props?.TmData) {
      setacTM({
        AccountListData: accList[obj.id],
        TMFormatData: tmList[obj?.id],
      });
    }
  }, [accList, tmList]);
  let lfstcol = -1;
  let lcolno = -1;
  let nstr = "#";
  let dstr = "0";

  // const selectionSettings = ;

  const Summary = (obj, i, tag) => {
    let stype = "sum";
    var value = 0;
    if (obj.cSType !== "N") {
      if (lfstcol < 0) {
        lfstcol = i;
      }
      switch (obj.cSType) {
        case "C":
          stype = "count";
          value = props.data
            ? props.data.reduce((acc, curr) => acc + curr[obj.DispExpr], 0)
            : acTM.AccountListData.reduce(
              (acc, curr) => acc + curr[obj.DispExpr],
              0
            );
          break;
        case "A":
          stype = "avg";
          break;
        case "X":
          stype = "max";
          break;
        case "M":
          stype = "min";
          break;
        case "U":
          stype = "custom";
          break;
        default:
          value = props.data
            ? props.data.reduce((acc, curr) => acc + curr[obj.DispExpr], 0)
            : acTM.AccountListData.reduce(
              (acc, curr) => acc + curr[obj.DispExpr],
              0
            );
          break;
      }
    } else if (obj.ColWidth > 4 && lfstcol < 0) {
      lcolno = i;
    }
    return typeof value == Number ? value.toFixed(4) : null;
  };
  const rendercolcell = (data, v) => {
    // console.log("EPLUS",v);
    // console.log('data===>>',data)
    let dtxt = data;
    var datatype =
      v.DataType === "N"
        ? "number"
        : v.ColClick !== "" || v.DispExpr === "M01F68"
          ? "custom"
          : "string";
    if (datatype === "custom") {
      if (dtxt === "A") {
        dtxt = "✓";
      } else {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("A")) {
      dtxt = Math.abs(dtxt);
    }
    if (v.DataType.includes("D")) {
      // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8) ;
      //  dtxt = "2023/12/12"
      if (data != null && data != "" && data != undefined) {
        dtxt =
          dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      } else {
        dtxt = "";
      }
      // return dtxt
    }
    if (v.DispFormat.includes("Z")) {
      if (dtxt === ".00") {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("X")) {
      if (dtxt !== "") {
        if (parseFloat(dtxt) > 0) {
          dtxt += " CR";
        } else {
          dtxt += " DB";
        }
      }
    }

    return (
      <p
        style={{
          fontSize: v.FontSize ? v.FontSize : 10,
          fontFamily: v.FontName ? v.FontName : "Verdana",
          color: v.ForeColor,
          backgroundColor: v.BackColor,
        }}
      >
        {dtxt}
      </p>
    );
  };

  const rendercolcellGrp = (data, v) => {
    // console.log("EPLUS",v);
    // console.log('data===>>',data)
    let dtxt = data;
    var datatype =
      v.DataType === "N"
        ? "number"
        : v.ColClick !== "" || v.DispExpr === "M01F68"
          ? "custom"
          : "string";
    if (datatype === "custom") {
      if (dtxt === "A") {
        dtxt = "✓";
      } else {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("A")) {
      dtxt = Math.abs(dtxt);
    }
    if (v.DataType.includes("D")) {
      dtxt =
        dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      //  dtxt = "2023/12/12"
      if (data != null && data != "" && data != undefined) {
        dtxt =
          dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      } else {
        dtxt = "";
      }
      return dtxt;
    }
    if (v.DispFormat.includes("Z")) {
      if (dtxt === ".00") {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("X")) {
      if (dtxt !== "") {
        if (parseFloat(dtxt) > 0) {
          dtxt += " CR";
        } else {
          dtxt += " DB";
        }
      }
    }

    return (
      <p
        style={{
          fontSize: v.FontSize ? v.FontSize : 10,
          fontFamily: v.FontName ? v.FontName : "Verdana",
          color: v.ForeColor,
          backgroundColor: v.BackColor,
        }}
      >
        {dtxt}
      </p>
    );
  };
  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  useEffect(() => {
    // console.log("acTM.TMFormatData", acTM?.TMFormatData);
    if (tag && tag == "PM") {
    } else {
      if (
        acTM.TMFormatData &&
        acTM.TMFormatData.oDCFrmt &&
        acTM.TMFormatData.oDCFrmt.aDCol &&
        (acTM.AccountListData || props.data)
      ) {
        var cols = [];
        var grp = [];
        console.log("colset", acTM?.TMFormatData?.oDCFrmt);
        acTM.TMFormatData.oDCFrmt.aDCol.map((v, i) => {
          cols.push({
            field: v.DispExpr,
            headerText: v.ColCap,
            width: v.ColWidth,
            textAlign:
              v.DAlign == 1
                ? v.DispCond.charAt(0).toUpperCase() + v.DispCond.slice(1)
                : "Left",
          });
        });
        // if (acTM.TMFormatData.oDCFrmt?.aDGrp.length > 0) {
        //   acTM.TMFormatData.oDCFrmt?.aDGrp.map((g, i) => {
        //     cols.push(
        //         {field:g.DispExpr, headerText:g.ColCap, width:g.ColWidth, textAlign:g.DAlign == 1 ? g.DispCond.charAt(0).toUpperCase()
        //             + g.DispCond.slice(1) : 'Left'}
        //     );
        //     grp.push(g.DispExpr);
        //   });
        //   setGrouping(grp);
        // }
        setColumns(cols);
      }
    }

    let ary = acTM?.TMFormatData?.oDCFrmt?.aDCol
      ?.filter((item) => item.lSearchCol === true)
      .map((item) => item.DispExpr);
    setlSearchCol(ary);
    setaSrcDic(acTM?.TMFormatData?.oDCFrmt?.aSrcDic);
    // setisDataGridCalled(true);
  }, [acTM.TMFormatData]);

  const toolbarOptions = ["Add", "Edit", "Delete", "Print", "Cancel"];

  // cos
  // const cellSaveFun = (e) =>{
  //      console.log("e of interaction ",e);
  // }
  const onEdit = (e) => {
    console.log("e of edit", e);
    if (
      e?.requestType == "save" &&
      !(JSON.stringify(e?.data) === JSON.stringify(e?.previousData))
    ) {
      console.log("inside edit save");
    }
  };

  const actionBegin = (e) => {
    if (e?.requestType === "save") {
      console.log("e of actionBegin", e);
    }
  };

  const getSelectedRowIndex = (e) => {
    console.log("e of selectedIndex", e);
  };


  const simulateEnterKeyPress = () => {
    console.log("inside key enter event")
    // Get the row element using the rowIndex
    // const rowElement = document.querySelector(`table`);
    // if (rowElement) {
    //   // Create a new KeyboardEvent with the "Enter" key
    //   const event = new KeyboardEvent("keydown", {
    //     key: "Enter",
    //     bubbles: true,
    //     cancelable: true,
    //   });

    //   // Dispatch the event on the row element
    //   rowElement.dispatchEvent(event);
    // }
  }

  return (
    <>
      {console.log("isLoading", isLoading)}
      {console.log("acTM.AccountListData", acTM.AccountListData)}
      {console.log("acTM.TMFormatData", acTM.TMFormatData)}
      {isLoading
        ? GlobalClass.RenderLoader("dots")
        : !isLoading &&
        acTM.AccountListData &&
        acTM.TMFormatData && (
          <>
            <GridComponent
              // selectedRowIndex={1}
              allowSelection={true}
              selectionSettings={{ mode: "Row", type: "Single" }}
              columns={columns}
              rowSelected={(e) => {
                console.log("e of row selected", e);
                setRowSelection({ [e.rowIndex]: e });
                setSelectedIndex(e?.rowIndex)
              }}
              dataSource={acTM.AccountListData ?? []}
              allowPaging={true}
              enableAdaptiveUI={true}
              enableHeaderFocus={true}
              allowSorting={true}
              pageSettings={{
                pageSizes: true,
                pageSize: pagination?.pageSize,
                // currentPage: pagination?.pageIndex,
                // totalRecordsCount: rowCount,
                // ...pagination,
              }}
              // pagerTemplate={(e)=>{console.log("pager Template",e)}}
              dataStateChange={dataStateChange.bind(this)}
              enableStickyHeader={true}
              allowFiltering={true}
              recordDoubleClick={simulateEnterKeyPress()}
            // onClick={(e) => {
            //   console.log("e of selected", e);
            // }}
            >
              <ColumnsDirective>
                {
                  columns.map((v, i) => {
                    <ColumnDirective
                      field={v.DispExpr}
                      headerText={v.ColCap}
                      width={v.ColWidth}
                    />
                  })
                }
              </ColumnsDirective>
              <Inject services={[Page, Sort]} />
            </GridComponent>

            {!form ? (
              <ButtonPanel
                data={acTM.TMFormatData?.oS60?.DTHKey}
                Skey={rowSelection}
                selectedIndex={selectedIndex}
                from={obj?.id}
                btnPnlObj={obj}
              />
            ) : (
              ""
            )}
          </>
        )}
    </>
  );
}

export default NewDataGrid;
