import { Button, Center, Grid, Group, Paper, Text } from "@mantine/core";
import React from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import useWindowDimensions from "../../utils/UseWindowDimensions";

export default function VoucherFmtForm() {
    const { height } = useWindowDimensions();

  let columns = [
    {
      accessor: "Description", // Convert index to string
      header: "Description",
      Cell: ({ row }) => {
        // console.log(row.original, "row.orginal");
        // return (
        //   <Button onClick={() => generateBill(row?.original)}>
        //     Generate Bill
        //   </Button>
        // );
      },

      maxSize: 100,
    },
  ];

  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: [],
    columns: columns,
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    enableTableHead: false, //Hide Column
    enableBottomToolbar: false, //pagination remove
    mantineTableProps:{withColumnBorders:true},
    initialState: {
      density: "xs",
      expanded: true,
    },

    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {},
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      height: 100,
    }),
  });
  return (
    <>
      <Paper shadow="md" radius="md" p="xs" withBorder>
        <Grid gutter={4}>
          <Grid.Col span={12}>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>Report Type</Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
            }}
          >
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Print Mode</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>

            <Grid.Col
              span={6}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Type</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
            }}
          >
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>ReportFor</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>

            <Grid.Col
              span={6}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Label Type</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={12}>
            <MantineReactTable table={table}/>
            <Center mt={5}>
                <Group>
                  <Button size="xs" compact>New</Button>
                  <Button size="xs" compact>Edit</Button>
                  <Button size="xs" compact>Copy</Button>
                  <Button size="xs" compact>import</Button>
                  <Button size="xs" compact>Cancel</Button>
                </Group>
              </Center>
          </Grid.Col>
          
        </Grid>
      </Paper>
    </>
  );
}
