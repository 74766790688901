import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../utils/store";
import { GetStockRate } from "../../utils/slices/StockRateSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { useMantineReactTable } from "mantine-react-table";
import { MantineReactTable } from "mantine-react-table";
import { ActionIcon, Flex, Skeleton, Tooltip } from "@mantine/core";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import gensetting from "../../utils/gensetting";
import { notifications } from "@mantine/notifications";
import GlobalClass from "../../utils/GlobalClass";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import { IconEdit } from "@tabler/icons-react";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

const StockRatePage = (props) => {
  // console.log("StockRatePage=>", props);
  const dispatch = useDispatch();
  const stockRateData = useSelector(
    (state) => state.StockRate?.StockRateData?.data?.STKLIST
  );
  // console.log("stockRateData", stockRateData);
  const isLoading = useSelector((state) => state.StockRate?.isLoading);
  const hasError = useSelector((state) => state.StockRate?.hasError);
  const ErrorMsg = useSelector((state) => state.StockRate?.ErrorMsg);
  // console.log("isLoading", isLoading);
  const [data, setData] = useState({});
  const [updatedData, setUpdatedData] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [stockObj,setStockObj] = useState({});
  const columnHelper = createColumnHelper();
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { height } = useWindowDimensions();
  useEffect(() => {
    dispatch(GetStockRate());
  }, []);
  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        Promise.resolve()
        .then(()=>{
          rowCancelHandler();
        })
          .then(() => {
              table.setEditingRow(null);

          });
      }
    });
  }, [tableEditingRow]);

  const rowSaveHandler = () => {
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      "Editing",
      "Please wait while we process your data"
    );
    var data1 = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "",
      cCode: "",
      cSData: JSON.stringify(stockObj),
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "",
      cCode: "",
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data1),
    };

    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostStockRate + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("api data", data);
        // console.log("api data status", data?.status);
        if (data.status === "SUCCESS") {
          GlobalClass.Notify("success", "Edited", "Edited successfully");
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
          .then(()=>{
            Store.dispatch(GetStockRate());
          })
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading(false));
        }
      })
      .catch((e) => {
        GlobalClass.Notify(
          "error",
          "Error",
          `${e?.message}`
        );
        dispatch(DataSaveLoading(false));
      });

    table?.setEditingRow(null);
    setTableEditingRow(null);
  };

  const rowCancelHandler = () => {
    setStockObj(null);
    table.setEditingRow(null);
    table.setCreatingRow(null);
    setTableEditingRow(null);
  };

  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      Promise.resolve(
        setTableEditingRow(row))
        .then(()=>{
          setStockObj(row?.original);
        })
        .then(()=>{
          table.setEditingRow(row)
        })
    }
  };


 

  const renderValue = (cellValue, row, column) => {
    var editor;
    editor = (
      <PLNumberBox
      id={"SRate"}
        value={stockObj?.FIELD78}
        // autoFocus={true}
        setEdit={(e) => {
            setStockObj({...stockObj,FIELD78:e});
        }}
        onKeyDown={(e)=>{
          if(e.key=="Enter"){
             rowSaveHandler();
          }
        }}
      />
    );

    return editor;
  };

  useEffect(()=>{
  let column=[];
  column.push(
    columnHelper.accessor("FIELD02", {
      header: "Product Name",
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
      size: "130",
    })
  );
  column.push(
    columnHelper.accessor("FIELD98", {
      header: "Stock Qty",
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
      size: "130",
    })
  );
  column.push(
    columnHelper.accessor("FIELD78", {
      header: "Stock Rate",
      enableColumnFilter: false,
      enableSorting: false,
      size: "130",
      Edit: ({ cell, column, table, row }) =>
        renderValue(cell.getValue(), row, column),
      Cell: ({ cell, renderedCellValue, row }) => cell.getValue(),
    })
  );
  column.push(
    columnHelper.accessor("M21STKAMT", {
      header: "Amount",
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
      size: "130",
    })
  );
  setUpdatedColumn(column);
  },[data,stockObj])

  var final;
  const [updatedColumn, setUpdatedColumn] = useState();
  useEffect(() => {
    setData(stockRateData);
    setUpdatedData(stockRateData);
  }, [stockRateData]);

  const table = useMantineReactTable({
    columns: updatedColumn ? updatedColumn : [],
    data: data ? data : [],
    enableFilters: false,
    enableColumnActions: false,
    // enableFullScreenToggle: false,
    // enableDensityToggle: false,
    enableTopToolbar: false,
    editDisplayMode: "row",
    onEditingRowCancel:rowCancelHandler,
    onEditingRowSave: rowSaveHandler,
    enableStickyHeader: true,
    mantineTableProps:{withColumnBorders:true},
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      tabIndex: 0,
      onKeyUp: (e) => {
        handleRowKeyDown(e, row);
      },
      onClick: () => {
        setRowSelection((prev) => {
          // [row.id]: !prev[row.id],
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
    }),
    renderRowActions: ({ row, table }) =>
    (
        <Flex gap="md">
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => {
                Promise.resolve(
                setTableEditingRow(row))
               .then(()=>{

                setStockObj(row?.original)
               })
                .then(()=>{
                  table.setEditingRow(row)
                })
              }}
            >
              <IconEdit size={"20px"} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      ),
    state: {
      density: "1.5px",
      rowSelection,
    },
    manualPagination: false,
    enablePagination: true,
    initialState: {
      density: "0px",
    },
    enableEditing: true,
  });
  return (
    <>
      {isLoading || SaveLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <MantineReactTable table={table} striped={true} />
      )}
    </>
  );
};

export default StockRatePage;
