import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";

export const GetDataFreeze = createAsyncThunk(
    "DataFreeze/GetDataFreeze",
    async (obj) => {
        // console.log("obj in GetDataFreeze", obj);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.cAction ?? "A",
                    "cCode": obj?.cCode ?? "",
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetF02DataFreez + "?pa=" + param);
            console.log("GetDataFreeze response=>>", response);
            return response?.data?.data;

        } catch (error) {
            return { error }
        }
    }

)


const DataFreeze = createSlice({
    name: "DataFreeze",
    initialState: {
        DataFreezeData: [],
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetDataFreeze.pending, (state, action) => {
                state.hasError = false;
                state.isLoading = true;
            })
            .addCase(GetDataFreeze.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.DataFreezeData = action.payload;
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = false;
                }
                else {
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetDataFreeze.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
    }
})

export default DataFreeze.reducer;


// export const GetAddressBookDeleteAPI = createAsyncThunk(
//     "AddressBook/GetAddressBookDeleteAPI",
//     async (obj) => {
//         console.log("obj in GetAddressBookDeleteAPI", obj);
//         try {

//             let param =
//                 JSON.stringify({
//                     "CorpID": gensetting.getcorpid(),
//                     "cUser": gensetting.getcuser(),
//                     "cPass": gensetting.getcpass(),
//                     "CmpNo": gensetting.getcmpno(),
//                     "cAction": obj.cAction ?? "A",
//                     "cCode": obj?.cCode ?? "",
//                     "cOPara": "",
//                 });

//             const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetAddressBook + "?pa=" + param);
//             console.log("GetAccountList response=>>", response);
//             return response.data;

//         } catch (error) {
//             return { error }
//         }
//     }

// )