import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconCurrencyRupee, IconEdit } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import { modals } from "@mantine/modals";
import gensetting from "../../../utils/gensetting";
import GlobalClass from "../../../utils/GlobalClass";
import Store from "../../../utils/store";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import SITableUserField from "../../SaleEntry/SITableUserField";
import {
  GetUserFieldObj,
  TransactionApi,
} from "../../../utils/TransactionSlices/Transaction";
import { ModalDelete, ModalFunction } from "../../../utils/slices/ModalSlice";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import {
  GetAccountList,
  GetTMFormat,
} from "../../../utils/slices/AccountListSlice";
import PLNarration from "../../../PlusComponent/PLNarration";
import T02Table from "../../T02/T02Table";
import PendingIssue from "./PendingIssue";
import {
  GetJOrderData,
  GetJWListData,
  GetNullPendingOrderData,
  RefreshIOrderPopUp,
  RefreshIssuePopUp,
} from "../../../utils/TransactionSlices/PendingIssueSlice";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import PLUserFieldBtn from "../../../PlusComponent/PLUserFieldBtn";
import FormatListTable from "../../SMSEmailWpBtn/FormatListTable";
import PLPrint from "../../../FunctionsCall/Print/PLPrint";

export default function JobWorkOutORBForm(props) {
  // console.log(props, "JobWorkOutForm--");

  const [group, setGroup] = useState({
    FIELD01: "JOPV73BZX483",
    FIELD02: "20240331",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "JO",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    UU73: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UBILTO: "",
    UDISP: "",
    UEMAIL: "",
    UGSTR: "",
    ULEN: "",
    ULRD: "",
    UNAME: "",
    UOD: "",
    UODD: "",
    UODP: "",
    UOP: "",
    UPAYT: "",
    UPR: "",
    URA: "",
    UREMA: "",
    UREST6: "",
    USAL: 0,
    USALM: "",
    UTR: "",
    UTRA: "",
    UUAASS01: "",
    UUASWER: "",
    UUFR4: "",
    UUJA: "",
    UUJBWID: "",
    UUJWTRID: "",
    UUPRDID: "",
    UUPRODC: "",
    UUPRTR: "",
    UUSALM: "",
    UUT: "",
    UUT78: "",
    UUTPR: "",
    UUUASD09: "",
    UUUASWER: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUUOS: "",
    UU05: "",
    UU87: "",
    UUISSUID: "",
    UUE: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    EA00000017: "",
    ED00000017: 0,
    EP00000017: 0,
    EA00000018: "",
    ED00000018: 0,
    EP00000018: 0,
    EA00000019: "",
    ED00000019: 0,
    EP00000019: 0,
    EA00000020: "",
    ED00000020: 0,
    EP00000020: 0,
    EA00000021: "",
    ED00000021: 0,
    EP00000021: 0,
    EA00000022: "",
    ED00000022: 0,
    EP00000022: 0,
    UUABCD: "",
    UUGFEJH: "",
    UUTEST56: "",
    EA00000023: "",
    ED00000023: 0,
    EP00000023: 0,
    UUSALESB: "",
    EA00000024: "",
    ED00000024: 0,
    EP00000024: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F00: "",
    FIELD04NM: "",
    FIELD05NM: "",
    FIELD39NM: "",
    FIELD34NM: "",
    CFRMTITLE: "Add Jobwork Outward (Issue)",
    BTOBEFF: "C",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [t02Data, setT02Data] = useState([]);
  const [t02DataENT, setT02DataENT] = useState([]);
  const [t02Obj, setT02Obj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    FIELD90: 0.0,
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    IA00000024: "",
    IA00000025: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    ID00000024: 0.0,
    ID00000025: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IP00000024: 0.0,
    IP00000025: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    IT00000024: "",
    IT00000025: "",
    UAP: 0.0,
    UBA: "",
    UDDATE: "",
    UDIS: 0.0,
    UDISC: 0.0,
    UGRO: 0.0,
    UISSUERA: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    URATE: 0.0,
    URE: "",
    UREMARK: "",
    IA00000026: "",
    ID00000026: 0.0,
    IP00000026: 0.0,
    IT00000026: "",
    UUWQ: "",
    USERFLD: "",
    F03NM: "",
    F20NM: "",
    CATNM: "",
    GRPNM: "",
    CATCD: "",
    GRPCD: "",
    USERFLDB: "",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    JODUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    T02F08EXP: 0.0,
    _EXPTOTALAMT: 0.0,
  });
  const [editT41, setEditT41] = useState({});
  const [editT02, setEditT02] = useState({});
  const [hasFocused, setHasFocused] = useState(true);
  const [hasFocused2, setHasFocused2] = useState(false);
  const [M01PRow, setM01PRow] = useState(null);
  const [isVisible, setIsVisible] = useState();
  const [selectedProductObj, setSelectedProductObj] = useState({});
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [creatingModeOn, setCreatingModeOn] = useState(false);
  const [flagCopy, setFlagCopy] = useState(false);
  const [t02Flag, setT02Flag] = useState(false)
  const [flag, setFlag] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  // const [HeaderExpenseFlag, setHeaderExpenseFlag] = useState(false);
  const [pagecCode, setPagecCode] = useState("");
  const [isHandleCreateNeeded, setIsHandleCreateNeeded] = useState("")



  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseButton: null,
    // onClickCloseButton: () => { },
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  const [dayVal, setDayVal] = useState("");
  let editData = props?.obj;
  let editId = editData?.id;
  // const columnHelper = createColumnHelper();
  let p0 = props?.obj.p0;
  let page = props?.obj.page;
  console.log("page", page);
  let { index, obj } = props;
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const formVouData = useSelector(
    (state) => state?.Transaction?.TransactionData
  );
  const { isUserFieldLoading, UserField } = useSelector(
    (store) => store?.Transaction
  );
  const [footerButtonArray, setFooterButtonArray] = useState([]);
  useEffect(() => {
    if (page !== "20400399") {
      setFooterButtonArray(["Copy", "Serial No."])
    }
    else {
      setFooterButtonArray(["Copy"])
    }
  }, [])

  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const oYear = useSelector(
    (state) => state?.Language?.General?.oYear?.OSC?._JYSUBJOB
  );
  console.log("accList", accList);
  const {
    isLoading: isLoadingEnt,
    hasError: hasErrorEnt,
    ErrorMsg,
  } = useSelector((state) => state?.Transaction);
  const GetYearAndDate = useSelector(
    (state) => state?.UserList?.YearAndDateSend
  );
  const copyRow = useSelector((state) => state?.GetPendingIssue?.copyRow);
  const inputRef = useRef(null);
  const input2Ref = useRef(null);
  const input3Id = useRef(null);
  const formRef = useRef(null);
  const vouNoref = useRef(null);
  const TableRef = useRef();
  const TableContainer = useRef(null);

  // const useHRef =useRef(null)
  const docId = document.getElementById("#docId");
  const map = new Map();
  let dataEnt;

  const dispatch = useDispatch();

  const [editingRow, setEditingRow] = useState(null);

  if (page === "20400399") {
    // Order JWO
    dataEnt = formVouData;
  } else if (page === "04050399") {
    // Receipt JWO
    dataEnt = formVouData;
  } else if (page === "04020399") {
    // Issue JWO
    dataEnt = formVouData;
  } else if (page === "20422041") {
    // Issue SBJ
    dataEnt = formVouData;
  } else if (page === "20432041") {
    // Receipt SBJ
    dataEnt = formVouData;
  }

  const T02 = formVouData?.T02;
  const M01PDT = formVouData?.M01PDT;
  const M01P = formVouData?.M01P;
  const M01PRT = formVouData?.M01PRT;
  const P_M31JDT = formVouData?.P_M31JDT;
  const P_M31J = formVouData?.P_M31J;
  const UFT41H = formVouData?.UFT41H;
  const T92 = formVouData?.T92;
  const T92DT = formVouData?.T92DT;
  const T41 = formVouData?.T41;
  const lEditVou = formVouData?.lEditVou;
  // console.log(T92DT, "P_M31JDT--", P_M31JDT, M01PDT);
  console.log("T92", T92);
  const OS11 = formVouData?.OS11;
  const OSV = formVouData?.OSV;
  // const cType = "JD"; //Temporay defined.
  // console.log(M01PDT, "M01PDT--");
  let UFT41Hval = UFT41H?.filter((v) => v?.S13F87 == "HEADER");
  let UFT41Fval = UFT41H?.filter((v) => v?.S13F87 == "FOOTER");
  let stringOfNames;
  const seperate1 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };
  useEffect(() => {
    console.log("formVouData", formVouData)
    console.log("dataent", dataEnt)
  }, [formVouData, dataEnt])
  useEffect(() => {
    if (SelectedNarrationData.length >= 0) {
      const stringifyArray = SelectedNarrationData.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setGroup({ ...group, FIELD15: stringOfNames });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  const rowSum = t02Data.reduce((a, b) => {
    if (b?.FIELD05 == "C") a = a + (b?.FIELD08 || 0);
    else if (b?.FIELD05 == "D") a = a - (b?.FIELD08 || 0);
    console.log(a, "inreduce", b?.FIELD08);
    return a;
  }, 0);
  useEffect(() => {
    let date = group?.FIELD02;
    // console.log(date, "group?.FIELD02");
    if (date?.length > 0) {
      const year = parseInt(date.substr(0, 4));
      const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substr(6, 2));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setDayVal(dayOfWeek?.slice(0, 3));
    }
  }, [group?.FIELD02]);
  useEffect(() => {
    // if (page == "20400399") {
    // if (editData?.p0 == "E") {
    //   Promise.resolve(Store.dispatch(TransactionApi({ p0: p0, id: editId })))
    //   .then(() =>
    //     dispatch(
    //       GetAccountList({
    //         id: "T_M32C",
    //         name: "",
    //         type: "A",
    //         p0: "T_M32C",
    //         p1: "T_M32C",
    //         p2: "",
    //         p3: "",
    //       })
    //     )
    //   )
    //   .then(() =>
    //     dispatch(
    //       GetTMFormat({
    //         id: "T_M32C",
    //         name: "",
    //         type: "A",
    //         p0: "T_M32C",
    //         p1: "T_M32C",
    //         p2: "",
    //         p3: "",
    //       })
    //     )
    //   )
    //   // })
    //   .then(() => setHasFocused(true)).then((data) => {
    //     // console.log(data?.payload, "setEditData");
    //     // T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null
    //     setEditT41(data?.payload?.T41);
    //     setEditT02(data?.payload?.T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));

    //     if (data?.payload?.T02?.length > 0) {
    //       // setBillAmt(
    //       //   data?.payload?.T02?.reduce((a, b) => (a = a + b?.FIELD08), 0)
    //       // );
    //       setIsVisible(true);
    //     } else {
    //       setIsVisible(false);
    //     }
    //   });
    // } else {
    // console.log("EnterDispa");
    if (obj) {
      Promise.resolve(
        Store.dispatch(
          TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 })
        )
      )
        .then(() => {
          setHasFocused(true);
          //   dispatch(
          //     GetAccountList({
          //       id: "T_M32C",
          //       name: "",
          //       type: "A",
          //       p0: "T_M32C",
          //       p1: "T_M32C",
          //       p2: "",
          //       p3: "",
          //     })
          //   );
        })
        // .then(() =>
        //   dispatch(
        //     GetTMFormat({
        //       id: "T_M32C",
        //       name: "",
        //       type: "A",
        //       p0: "T_M32C",
        //       p1: "T_M32C",
        //       p2: "",
        //       p3: "",
        //     })
        //   )
        // )
        .then(() =>
          dispatch(
            GetAccountList({
              id: "P_M21",
              name: "",
              type: "A",
              p0: "P_M21",
              p1: "P_M21",
              p2: "",
              p3: "",
            })
          )
        )


      // })

      // }
    }
    if (obj.page === "20400399") {
      setPagecCode("OJOJ")
    }
    else if (obj.page === "04020399") {
      setPagecCode("JIJI")
    }
    else if (obj.page === "04050399") {
      setPagecCode("JOJO")
    }
    else if (obj.page === "20422041") {
      setPagecCode("JYJY")
    }
    else if (obj.page === "20432041") {
      setPagecCode("JXJX")
    }
  }, [obj]);

  useEffect(() => {
    if (UFT41H && p0 == "E") {
      UFT41H?.forEach((item) => {
        if (item?.S13F14 == "PLPOPUP") {
          const key = seperate(item.S13F09)[1]; // Key
          const value = T41?.[item.S13F02]; // Value
          console.log("key here", key);
          console.log("group", T41);
          console.log("[item.S13F02]", [item.S13F02])
          console.log("value here", T41?.[item.S13F02]);
          // }
          if (map.has(key)) {
            // If it exists, add the value to the set/array of values for that key
            map.get(key).add(value); // Use a Set to ensure uniqueness of values

          }
          else {
            map.set(key, new Set([value]));
          }
        }

      })
      if (map?.size > 0) {
        console.log("map?.size", map?.size);
        map?.forEach((value, key) => {
          console.log("Array.from(value)", value, "key", key);
          const dyValue = Array.from(value).length ? ("'" + Array.from(value).join("', '") + "'") : '';
          console.log("dyValue", dyValue);
          setTimeout(() => {
            Promise.resolve(() => {
              dispatch(
                GetAccountList({
                  id: key,
                  name: "",
                  type: "A",
                  p0: key,
                  p1: key,
                  p2: "",
                  p3: "",
                  cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                })
              );
            }).then(() => {
              dispatch(GetTMFormat({
                "id": key,
                "name": "",
                "type": "A",
                "p0": key,
                "p1": key,
                "p2": "TFORM0000001",
                "p3": ""
              }))
            })
          }, [500])
        })
      }



    }
  }, [UFT41H])
  // useEffect(() => {
  //   // console.log("setBillAmt isVisible", t02Data);
  //   if (isVisible)
  //     setBillAmt(t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0));
  // }, [isVisible, t02Data]);

  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({
        ...BillSeriesHideShow,
        DataGridInput: true,
        TextInput: false,
      });
    }
  }, [BillSeriesHideShow.BillNoVal]);
  useEffect(() => {
    if (hasFocused) {
      inputRef?.current?.handlePopoverToggle();
      setHasFocused(false);
      // setHasFocused2(true);
    }
  }, [hasFocused]);

  useEffect(() => {
    if (T02) {
      setT02Flag(true)
      setT02Data(T02);
      // setT02Data(T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));    
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0])
      }
    }
  }, [T02])

  useEffect(() => {
    if (creatingModeOn == true) {
      setT02Obj({
        ...t02Obj,
        FIELD05: "R,C".includes(OS11?.S11F22) ? "C" : "D",
      });
    }
  }, [creatingModeOn, OS11]);
  useEffect(() => {
    console.log(OS11?.S11F01, "OS11?.S11F01=");
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01));
    }
  }, [OS11?.S11F01, obj]);

  useEffect(() => {
    // console.log(hasFocused2, "hasFocused2");
    if (hasFocused2) {
      console.log(hasFocused2, "hasFocused2");

      input2Ref?.current?.handlePopoverToggle();
    }
  }, [hasFocused2]);

  useEffect(() => {
    console.log(formVouData?.T41, "formVouData?.T41");
    setGroup(formVouData?.T41);
    setT02DataENT(formVouData?.T02ENT);
  }, [formVouData]);
  useEffect(() => {
    if (obj.p0 == "E") {
      setM01PRow(M01PRT);
    }
  }, [M01PRT]);
  function NarrationBox() {
    setFlag(false);
    // if(flag )
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Naration</Text>,
      body: (
        <PLNarration
          NarrationArray={NarrationData}
          Flag={SelectedNarrationData?.length > 0 ? false : true}
          selectedNarrationArray={SelectedNarrationData}
          // NarrationVal={T41Obj}
          setNarrationArray={(e) => {
            // setT41Obj(e)
            console.log("setNarrationArray", e);
            setNarrationData(e.NarrationData);
            setSelectedNarrationData(e.selectedNarration);
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
        />
      ),
      open: true,
      size: "md",
      position: "right",
    });
  }
  const seperate = (e) => {
    var paraArr = e.split("~C~");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };


  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  function UFT41HFieldRenderer(e) {
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            //console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        // ref={ufref}
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        //  ref={ufref}
                        width={"500px"}
                        value={{
                          value: group?.[h?.S13F02],
                          label: accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[h?.S13F02])?.[0]?.[h?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[h?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld)],
                        }}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e);
                          console.log("e of uf", [h?.S13F02]);
                          console.log("group inside uf", group);
                          setGroup({ ...group, [h?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={group?.[h?.S13F02]}
                        copno={seperate(h?.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        //  ref={ufref}
                        defaultval={
                          group?.[h?.S13F02]?.trim() != ""
                            ? group?.[h?.S13F02]
                            : GetYearAndDate?.strtDate
                        }
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          if (startDate?.length == 8) {
                            setGroup({ ...group, [h?.S13F02]: e });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={new Date(group?.[h?.S13F02]).getTime()}
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLNumberBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDataGrid
                          width={"500px"}
                          value={{
                            value: group?.[f?.S13F02],
                            label: accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[f?.S13F02])?.[0]?.[f?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[f?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(f?.S13F09)[1]]?.oS60?.cAssignFld)],
                          }}
                          p0={seperate(f.S13F09)[0]}
                          p1={seperate(f.S13F09)[1]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e?.FIELD01 });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLComboBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col> */}
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLComboBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          defaultval={
                            group?.[f?.S13F02]?.trim() != ""
                              ? group?.[f?.S13F02]
                              : GetYearAndDate?.strtDate
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            let { startDate } = DateToString(e);
                            if (startDate?.length == 8) {
                              setGroup({ ...group, [f?.S13F02]: e });
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          // value={new Date(group?.[f?.S13F02]).getTime()}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                 
                      <PLTextBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
              </>
            );
          }
        })
      );
    }
  }

  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }
  let compareTwoArrayOfObjects = (editT02, t02Data) => {
    return (
      editT02?.length === t02Data?.length &&
      editT02?.every((v) =>
        t02Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };
  function dispatchFunction() {
    Promise.resolve(Store.dispatch(DataSaveLoading(false)))
      .then(() => {
        // Store.dispatch(ModalDelete(index));
        if (p0 == "A") {
          Promise.resolve(Store.dispatch(TransactionApi(obj))).then(() => {
            Store.dispatch(GetNullPendingOrderData([]))
          });
        } else {
          Store.dispatch(ModalDelete(index));
        }
      })
      .then(() => {
        setBillSeriesHideShow({
          ...BillSeriesHideShow,
          BillNoVal: "",
        });
      })
      .then(() => {
        Store.dispatch(RefreshIssuePopUp({}));
      })
      .then(() => {
        Store.dispatch(RefreshIOrderPopUp({}));
      });
    let PaginationObj = Store?.getState()?.DataSaveLoadingSlice?.PaginationObj;
    // console.log(page, "ModalDelete");
    // Store.dispatch(ModalDelete(index));
    if (page == "04020399") {
      // console.log("IssuePage");
      Promise.resolve(
        Store.dispatch(
          GetAccountList({
            id: "04020399",
            name: "Issue",
            p0: "MT",
            p1: "TXNVOULISTJI",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["04020399"],
          })
        )
      );
    } else if (page == "20400399") {
      // console.log("orderPage");
      Promise.resolve(
        Store.dispatch(
          GetAccountList({
            id: "20400399",
            name: "Order",
            p0: "MT",
            p1: "TXNVOULISTOJ",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["20400399"],
          })
        )
      );
    } else if (page == "04050399") {
      // console.log("ReceiptPage");

      Promise.resolve(
        Store.dispatch(
          GetAccountList({
            id: "04050399",
            name: "Receipt",
            p0: "MT",
            p1: "TXNVOULISTJO",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["04050399"],
          })
        )
      );
    } else if (page == "20422041") {
      // console.log("ReceiptPage");

      Promise.resolve(
        Store.dispatch(
          GetAccountList({
            id: "20422041",
            name: "Issue",
            p0: "MT",
            p1: "TXNVOULISTJD",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["20422041"],
          })
        )
      );
    } else if (page == "20432041") {
      // console.log("ReceiptPage");

      Promise.resolve(
        Store.dispatch(
          GetAccountList({
            id: "20432041",
            name: "Receipt",
            p0: "MT",
            p1: "TXNVOULISTJC",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["20432041"],
          })
        )
      );
    }
  }
  function OnPrint() {
    Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Print Voucher</Text>,
        onClickCloseButton: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        onClose: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        body: (
          <>
            <PLPrint data={obj} />
          </>
        ),
        open: true,
        size: "sm",
        position: "right",
      });
    });
  }
  function OnWhtsUp() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>WhatsApp </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "W", text: "WhatsApp" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnSMS() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>SMS </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "S", text: "SMS" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function OnEmail() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>EMail </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {
            if (OSV._ONLINEWAP == "Y") {
              OnWhtsUp();
            } else if (OSV._ONLINESMS == "Y") {
              OnSMS();
            } else if (OSV._ONLINEVOU == "Y") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "E", text: "EMail" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }

  function handleOkButton(e) {
    console.log("OK BTN", p0, "editId",editId, "page", page);
    // if (t02Data?.length < 2 ) {

    if (group?.FIELD04 == "") {
      GlobalClass.Notify("warning", "Validation error", "Please fill out all the required fields");
    }
    else {

      let newT02 = T02?.filter((v, i) => v?.FIELD03 !== "");

      if (obj.p0 == "E" && compareTwoArrayOfObjects(newT02, t02Data) && JSON.stringify(T41) === JSON.stringify(group)) {
        Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
          dispatch(ModalDelete(props.index)));
        return;
      } 
      else {
        Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(() =>
          GlobalClass.Notify("info", p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data"));

        let newT41 = {
          ...group,
          FIELD06: group?.FIELD07 == 0 ? rowSum : group?.FIELD07,
        };

        let FilterT02Data = t02Data?.filter((t) => t?.FIELD03 != "")

        let dataToSend = { T41: e ? e : { ...newT41 }, T02: FilterT02Data };

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: props ? p0 : "A",
          cCode:
            p0 == "E"
              ? editId
              : page == "20400399"
                ? "OJOJ"
                : page == "04020399"
                  ? "JIJI"
                  : page == "04050399"
                    ? "JOJO"
                    : page == "20422041"
                      ? "JYJY"
                      : page == "20432041"
                        ? "JXJX"
                        : "",
          cOPara:
            page == "20422041"
              ? "CTYPE~C~JD"
              : page == "20432041"
                ? "CTYPE~C~JC"
                : "",
          cSData: JSON.stringify(dataToSend),
        };
        console.log(dataToSend, "data Num", e);
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: p0 == "E" ? p0 : "A",
          cCode:
            p0 == "E"
              ? editId
              : page == "20400399"
                ? "OJOJ"
                : page == "04020399"
                  ? "JIJI"
                  : page == "04050399"
                    ? "JOJO"
                    : page == "20422041"
                      ? "JYJY"
                      : page == "20432041"
                        ? "JXJX"
                        : "",
          cOPara:
            page == "20422041"
              ? "CTYPE~C~JD"
              : page == "20432041"
                ? "CTYPE~C~JC"
                : "",
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        console.log(dataToSend, "param Stock Journal");
        // try {
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
          requestOptions
        )
          .then((response) => {
            // console.log(!response.ok, "response.ok");
            if (!response?.ok) {
              console.log("response?.ok", response?.ok);

              throw new Error(`HTTP error! Status: ${response?.status}`);
            } //This block give more detail info about error other then 2xx status codes.
            return response?.json();
          })
          .then((data) => {
            // console.log("api data=>", data);
            // console.log("api data status=>", data?.status);
            console.log(data?.status, "data?.status-");
            if (data?.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                p0 == "A" ? "Added" : "Edited",
                p0 == "A" ? "Added successfully" : "Edited successfully"
              );
              Promise.resolve(
                OSV._ONLINEMAL == "Y" || OSV._ONLINEMAL == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                    () => {
                      OnEmail();
                    }
                  )
                  : OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L"
                    ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        OnWhtsUp();
                      }
                    )
                    : OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L"
                      ? Promise.resolve(dispatch(DataSaveLoading(false))).then(
                        () => {
                          OnSMS();
                        }
                      )
                      : Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                          OnPrint();
                        } else {
                          dispatchFunction();
                        }
                      })
              );
            } else if (data?.status == "FAIL") {
              Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
                () => {
                  setT02Data(t02Data);
                  GlobalClass.Notify("error", data?.status, data?.message);
                }
              );
            }
          })
          .catch((e) => {
            console.log("inside catch", e);
            GlobalClass.Notify("error", "Error", `${e.message}`);
            console.log(t02Data, "DataSaveLoading-");
            Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(() => {
              setT02Data(t02Data);
            });
          });
      }
    }
  }
  const spoilerControlRef = useRef();
  console.log(DrawerObj?.open, "M01PRow--");
  return (
    <>
      {hasErrorEnt ? <>
        <Text>{ErrorMsg?.message}</Text>
        <Button onClick={() => {
          if (obj) {
            Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => {
              dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": ""
              }))
            }).then(() => {
              if (obj?.p0 == "U") {
                dispatch(GetUserFieldObj(OS11?.S11F01))
              }
            })
          }
        }}>Retry</Button>
      </> : SaveLoading == true ||
        isLoadingEnt ||
        isUserFieldLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : obj?.p0 == "U" ? (
        <>
          <PLUserFieldBtn
            UserFieldArray={UserField?.UFTbl}
            T41={group}
            onChange={(e) => {
              // Promise.resolve(setT41Obj(e)).then(()=>
              handleOkButton(e);
              // )
            }}
          />
        </>
      ) : (
        <div ref={formRef}>
          <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={true}
            fullScreen={false} //
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
              onClick: () => {
                // setTextBox(false)
                if (typeof DrawerObj?.onClickCloseButton == "function") {
                  DrawerObj?.onClickCloseButton();
                } else {
                  setDrawerObj(dra);
                }
              },
            }}
            title={DrawerObj?.title}
            size={DrawerObj?.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? "bottom"}
            onClose={
              typeof DrawerObj?.onclose == "function"
                ? DrawerObj?.onclose
                : () => {
                  setDrawerObj(dra);
                }
            }
          // scrollAreaComponent={ScrollArea.Autosize}
          >
            {DrawerObj.body}
          </Modal>
          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "10",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Party A/c.</Text>
                  </Grid.Col>
                  <Grid.Col span={9} style={{ overflow: "hidden" }}>
                    <PLDataGrid
                      width={"500px"}
                      // autoFocus={true}
                      value={{ value: group?.FIELD04, label: group?.FIELD04NM }}
                      TmData={M01P}
                      dispexpr="FIELD02"
                      valexpr="FIELD01"
                      setEdit={(e) => {
                        // console.log("handleClose", e);
                        Promise.resolve(
                          setGroup({
                            ...group,
                            FIELD04: e?.FIELD01,
                            FIELD04NM: e?.FIELD02,
                          })
                        ).then(() => {
                          // console.log(e.FIELD01, "group?.FIELD04?.length");
                          if (e?.FIELD01?.length > 1) {
                            // console.log(e.FIELD01, "group?.FIELD04?.length");
                            setHasFocused2(true);
                            // GlobalClass.formNextInput(e, formRef);
                          }
                        });
                        setM01PRow(e);
                      }}
                      onKeyDown={(e) => {
                        console.log(e, "onKeyDown", formRef);
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                        // M48Dhandlechange(e);             
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{props?.date}</Text>
                  </Grid.Col>
                  <Grid.Col span={7}>
                    <PLDateBox
                      ref={input3Id}
                      // name="#DTBox"
                      style={{ margin: "0", padding: "0" }}
                      // onKeyDown={(e) => {
                      //   // console.log(e, "onKeyDown", formRef)
                      //   GlobalClass.formNextInput(e, formRef);
                      // }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log("Stocke PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");
                        if (startDate?.length == 8) {
                          Promise.resolve(
                            setGroup({ ...group, FIELD02: startDate })
                          ).then(() => {
                            // setHasFocused2(false);
                            console.log(docId, "vouNoref-");
                            docId?.current?.focus();
                          });
                        }
                      }}
                      defaultval={group?.FIELD02}
                    />
                  </Grid.Col>
                  <Grid.Col span={1} style={{ color: "red" }}>
                    <Text size={12}> {dayVal} </Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc No.</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      id="#docId"
                      disabled={OSV?._DOCNODATE == "N"}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={group?.FIELD10}
                      setEdit={(e) => {
                        setGroup({ ...group, FIELD10: e?.target?.value });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "0",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}></Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <Text size={12}> Balance : {M01PRow != null ? M01PRow?.FIELD99 : null} DB</Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{props?.No}</Text>
                  </Grid.Col>
                  <Grid.Col span={9} ref={vouNoref}>
                    {p0 == "E" ? (
                      <PLTextBox
                        value={group?.FIELD12}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          setGroup({
                            ...group,
                            T92F00: "",
                            FIELD12: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <>
                        {T92 ? (
                          <>
                            {BillSeriesHideShow?.DataGridInput == true &&
                              (!group?.T92F00 ? (
                                <PLDataGrid
                                  // ref={vouNoref}
                                  onKeyDown={(e) => {
                                    GlobalClass.formNextInput(e, formRef);
                                  }}
                                  width={"500px"}
                                  position={"unset"}
                                  value={group?.FIELD12}
                                  valexpr={"FIELD00"}
                                  TmData={T92}
                                  // data={T92DT ?? []}
                                  setEdit={(e) => {
                                    // console.log(e?.FIELD00, "FIELD00");
                                    Promise.resolve(
                                      setGroup({
                                        ...group,
                                        T92F00: e.FIELD00,
                                        FIELD12: "",
                                      })
                                    ).then(() => {
                                      // docId?.focus()
                                      if (e?.FIELD02) {
                                        let letr = e.FIELD02;
                                        // console.log("num=>", num);
                                        let num = parseInt(e.FIELD03) + 1;
                                        setBillSeriesHideShow({
                                          ...BillSeriesHideShow,
                                          DataGridInput: false,
                                          TextInput: true,
                                          BillNoVal: letr + num,
                                        });
                                      }
                                    });
                                  }}
                                />
                              ) : (
                                <PLTextBox
                                  value={group?.FIELD12}
                                  onKeyDown={(e) => {
                                    GlobalClass.formNextInput(e, formRef);
                                  }}
                                  // value={T41Obj?.T92F00}
                                  setEdit={(e) => {
                                    // console.log("setEdit=>", e);
                                    // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                    setGroup({
                                      ...group,
                                      T92F00: "",
                                      FIELD12: e.target.value,
                                    });
                                  }}
                                />
                              ))}
                            {BillSeriesHideShow?.TextInput == true && (
                              <PLTextBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                value={BillSeriesHideShow?.BillNoVal}
                                setEdit={(e) => {
                                  // //console.log(e)

                                  setBillSeriesHideShow({
                                    ...BillSeriesHideShow,
                                    BillNoVal: e.target.value,
                                  });
                                  setGroup({
                                    ...group,
                                    T92F00: "",
                                    FIELD12: e.target.value,
                                  });
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <PLTextBox
                            value={BillSeriesHideShow?.BillNoVal}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            // value={T41Obj?.T92F00}
                            setEdit={(e) => {
                              console.log("setEdit=>", e);
                              setBillSeriesHideShow({
                                ...BillSeriesHideShow,
                                BillNoVal: e.target.value,
                              });
                              setGroup({
                                ...group,
                                T92F00: "",
                                FIELD12: e.target.value,
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc. Date</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      disabled={OSV?._DOCNODATE == "N"}
                      style={{ margin: "0", padding: "0" }}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log("e PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");

                        if (startDate?.length == 8) {
                          Promise.resolve(
                            setGroup({ ...group, FIELD11: startDate })
                          );
                          // .then(()=>{
                          //   console.log(useHRef, "useHRef-")
                          //   useHRef?.current?.focus()
                          // });
                        }
                      }}
                      defaultval={group?.FIELD11?.trim()}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              {page == "20400399" ? (
                ""
              ) : (
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Jobwork Process</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDataGrid
                      // ref={page !== "20400399"?input2Ref : null}
                      ref={input2Ref}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      // setFocus= {()=>{setHasFocused2(false);}}
                      width={"500px"}
                      // value={group?.FIELD34}
                      pid={"P_M31J"}
                      value={{ value: group?.FIELD34, label: group?.FIELD34NM }}
                      TmData={P_M31J}
                      // data={P_M31JDT?.jData}
                      setEdit={(e) => {
                        // console.log("handleClose", e);
                        Promise.resolve(
                          setGroup({
                            ...group,
                            FIELD34: e?.FIELD01,
                            FIELD34NM: e?.FIELD02,
                          })
                        )
                          .then(() => {
                            // useHRef?.current?.focus()

                            setHasFocused2(false);
                          })
                          .then(() => {
                            console.log(input3Id, "input3Id=");

                            input3Id?.current.focus();
                          });
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}
              {page == "20432041" ? (
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>SubJobWork?</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLComboBox disabled />
                  </Grid.Col>
                </Grid.Col>
              ) : (
                ""
              )}
            </Grid>

            <>
              {
                UFT41H?.length > 0 && UFT41Hval?.length > 0 ?
                  <Divider
                    my="xs"
                    label={<Button
                      variant="subtle"
                      color="dark"
                      size="xs"
                      compact
                      onFocus={() => {
                        if (UFT41H?.filter((af) => af?.S13HMF == "H")?.length > 3) {
                          spoilerControlRef.current.click()
                        }
                      }}
                    >User Fields</Button>}
                    labelPosition="center"
                    labelProps={{ component: "strong" }}
                  /> : null
              }
              <Spoiler
                maxHeight={80}
                // showLabel="Show more"
                // hideLabel="Hide"
                controlRef={spoilerControlRef}
              >
                <Grid
                  gutter={4}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                >
                  {/* <Grid.Col span={12} style={{ display: "flex" }}> */}
                  {UFT41HFieldRenderer("Header")}
                  {/* </Grid.Col> */}
                </Grid>
              </Spoiler>
            </>

            <Divider
              // my="xs"
              mb={"xs"}
              label={<Button
                variant="subtle"
                color="dark"
                size="xs"
                compact
                onFocus={() => {
                  if (UFT41H?.filter((af) => af?.S13HMF == "H")?.length > 3) {
                    spoilerControlRef.current.click()
                  }

                  // document?.activeElement?.blur()
                  console.log("TableRef =>", TableRef);
                  TableRef?.current?.focus()

                  setTimeout(() => {

                    //Pending Challan jema avtu hoy ava pages ni id che if condition ma , pending challlan ama ny avtu
                    // if (obj?.id === "02700249" || obj?.id === "02340213") {
                    //   if (PendingChallanData?.dtchln?.length > 0 && PendingChallanStatus) {
                    //     Promise.resolve(PendingChallanFun()).then(() => setPendingChallanStatus(false))
                    //   } else {
                    //     // Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 }))

                    //     TableContainer?.current?.focus()

                    //     // TableRef?.current?.focus()

                    //     setRowSelection({ 0: true })
                    //   }
                    // } else {
                    // Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 })).then(() => {
                    //   // CategoryRef?.current?.focus()
                    //   CategoryRef?.current?.handlePopoverToggle()
                    // })

                    TableContainer?.current?.focus()
                    setRowSelection({ 0: true })

                    // TableRef?.current?.focus()
                    // }
                  }, 500)
                }}
              >Products</Button>}
              labelPosition="center"
              labelProps={{ component: "strong" }}
            />


            <Grid gutter={4} p="xs"
            // onKeyUp={(e) => {
            //   GlobalClass.formNextInput(e, formRef);
            // }}
            >
              <Grid.Col span={12}>
                {/* <MantineReactTable table={table} /> */}
                {t02Flag ?
                  <T02Table
                    accList={accList}
                    tmList={tmList}
                    dataEnt={formVouData}
                    obj={obj}
                    setT02Data={(e) => setT02Data(e)}
                    t02Data={t02Data}
                    setIsVisible={setIsVisible}
                    setCreatingModeOn={setCreatingModeOn}
                    t41={group}
                    t02Obj={t02Obj}
                    setT02Obj={(e) => setT02Obj(e)}
                    group={group}
                    setGroup={(e) => setGroup(e)}

                    setEditingRow={setEditingRow}
                    editingRow={editingRow}
                    setSelectedProductObj={setSelectedProductObj}
                    selectedProductObj={selectedProductObj}
                    flagCopy={flagCopy}
                    setFlagCopy={(e) => {
                      setFlagCopy(e)
                    }}
                    footerButtonArray={footerButtonArray}
                    TableRef={TableRef}
                    TableContainer={TableContainer}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    // HeaderExpenseFlag={HeaderExpenseFlag}
                    // setHeaderExpenseFlag={setHeaderExpenseFlag}
                    pagecCode={pagecCode}
                    isHandleCreateNeeded={isHandleCreateNeeded}
                    setIsHandleCreateNeeded={setIsHandleCreateNeeded}
                  // JWListData={JWListData}
                  // JWOrderData={JWOrderData}
                  /> : ""
                }
              </Grid.Col>
            </Grid>
            {/* <Center h={10}> */}
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Group style={{ display: "flex" }}>
                  <Button
                    size="sm"
                    variant="light"
                    style={{ height: "30px", width: "80px" }}
                    compact
                    onClick={() => {
                      console.log(copyRow?.original, "tableData-", copyRow);
                      if (
                        !creatingModeOn
                      ) {
                        setFlagCopy(true);
                      }
                    }}
                  >
                    Copy
                  </Button>
                  {page !== "20400399" ? (
                    <Button
                      size="sm"
                      variant="light"
                      style={{ height: "30px", width: "80px" }}
                      compact
                    >
                      Serial No.
                    </Button>
                  ) : (
                    ""
                  )}
                </Group> */}
              </Grid.Col>
            </Grid>
            {/* </Center> */}
            {/* <Divider size="xs" my={1} /> */}
            {UFT41Fval?.length > 0 ? (
              <Grid
                gutter={4}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
              >
                {/* <Grid.Col span={6}> */}
                {UFT41HFieldRenderer("Footer")}
                {/* </Grid.Col> */}
              </Grid>
            ) : (
              ""
            )}

            <Grid
              span={12}
              // my={3}
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <Grid.Col span={5}>
                <Text size={12}>Narration</Text>

                <PLTextarea
                  minRows={2}
                  value={group?.FIELD15}
                  onChange={(e) => {
                    // //console.log(e)
                    setGroup({ ...group, FIELD15: e.target.value });
                  }}
                  onFocus={(e) => {
                    // console.log(e, "OnFocusNarr")
                    if (OSV?._ANARRHELP == "Y" && flag) {
                      NarrationBox();
                    } else {
                      return;
                    }
                  }}
                  onKeyDown={(e) => {
                    // console.log("Narration=>", e.shiftKey);
                    if (e.ctrlKey && e.key === "F1") {
                      //console.log("Narration call");
                      NarrationBox();
                    }
                  }}
                />
              </Grid.Col>

              <Grid.Col
                span={4}
              // offset={2}
              >
                {/* <Box style={{ overflow: "hidden" }}> */}
                <Group mb={3}>
                  <Grid.Col>
                    {
                      <Grid style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                        <Text style={{ fontSize: 12 }}> Total Amount: </Text>
                        {/* <IconCurrencyRupee /> */}
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          {/* {billAmt} */}
                          {rowSum}
                          {OS11 ? (OS11["S11F21"] == "D" ? "CR" : "DB") : ""}
                        </Text>
                      </Grid>
                    }
                  </Grid.Col>
                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={() => handleOkButton()}
                    disabled={
                      creatingModeOn
                      // || t02Data?.length < 1
                    }
                  >
                    Ok
                  </Button>
                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={OnPrint}>
                    Print
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Paper>
          {lEditVou == false && <> {GlobalClass.Alert("info", props.index)}</>}
        </div>
      )}
    </>
  );
}
