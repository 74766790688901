import { Button, Grid, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { useDispatch, useSelector } from 'react-redux';
import { GetAddressBook, setFlag, setFormat } from '../../utils/TransactionSlices/AddressBook';
import GlobalClass from '../../utils/GlobalClass';
import gensetting from '../../utils/gensetting';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import PLDataGrid from '../../PlusComponent/PLDataGrid';

function ViewMode(props) {

  const { FIELD01, index, format } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector(store => store?.AddressBook?.isLoading);
  const AddressBookData = useSelector(store => store?.AddressBook?.AddressBookData);

  const [oM02, setoM02] = useState();
  // const [data,setData] = useState();

  useEffect(() => {
    dispatch(GetAddressBook({ cCode: FIELD01 }))
  }, [FIELD01])

  useEffect(() => {

    setoM02(AddressBookData?.oM02)
    // setData(AddressBookData?.oM02)
  }, [AddressBookData])

  const saveBtn = () => {

    // if (oM02?.FIELD61?.trim() == "") {
    //   alert("Blank Name is not allowed")
    // }
    // else {
    // dispatch(DataSaveLoading(true));
    GlobalClass.Notify("info", "Editing", "Please wait while we process your data");

    var data1 = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "",
      cOPara: "",
      cSData: JSON.stringify(oM02),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "",
      cOPara: "",
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data1),
    };

    // if (OBJ.p0 == "E" && JSON.stringify(data) === JSON.stringify(dataObj)) {
    //     GlobalClass.Notify("success", "Edited", "Edited successfully");
    //     Promise.resolve(dispatch(DataSaveLoading(false)))
    //         .then(() => dispatch(ModalDelete(props.index)));
    // }
    // else {

    fetch(GlobalClass.ApiUrl + GlobalClass.PostAddressBook + "?pa=" + param, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          GlobalClass.Notify("success", "Edited", "Edited successfully");
          dispatch(ModalDelete(index))
          dispatch(setFormat(format))
          // Promise.resolve(dispatch(DataSaveLoading(false)));
          // toggle && dispatch(GetAddressBook(obj))

          // setData(dataObj)
          // if (OBJ.p0 == "E") {
          //     dispatch(ModalDelete(props.index))
          // }

          // // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
          // // dispatch(GetAccountList({
          // //   id: "14950123",
          // //   name: "E-Commerce Operator",
          // //   p0: "MT",
          // //   p1: "T_G02",
          // //   p2: "",
          // //   p3: "",
          // //   type: "A",
          // //   pagination: PaginationObj["14950123"],
          // // })
          // // );
        }
        else if (data.status === "FAIL") {
          // Promise.resolve(dispatch(DataSaveLoading(false)));
          GlobalClass.Notify("error", data?.status, data?.message);
        }
      })
      .catch((e) => {
        // Promise.resolve(dispatch(DataSaveLoading(false)));
        GlobalClass.Notify("error", "Error", `${e?.message}`);
      });

    // }

  }

  const cancelBtn = () => {
    dispatch(ModalDelete(index))
    dispatch(setFormat(format))
    // setoM02(data);
  }

  console.log("viewmode ", oM02);

  return (
    <div>
      {
        isLoading ? GlobalClass.RenderLoader('dots') :
          <Grid gutter={2}>
            <Grid.Col span={12} style={{ display: 'flex' }}>
              <Grid.Col span={6} >

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text style={{fontSize:12}}>Name</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD61} disabled
                    // setEdit={(e) => {
                    //   setoM02({ ...oM02, FIELD61: e.target.value })
                    // }}
                    />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Contact Person</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD50}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD50: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD51}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD51: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Address</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD02}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD02: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD03}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD03: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD04}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD04: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD15}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD15: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>City</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLDataGrid width={300} value={oM02?.FIELD05}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD05: e.FIELD01 })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Pincode</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD07}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD07: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Area</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLDataGrid width={300} value={oM02?.FIELD06}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD06: e.FIELD01 })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Category</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLDataGrid width={300} value={oM02?.FIELD11}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD11: e.FIELD01 })
                      }} />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>

              <Grid.Col span={6}  >

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Phone(O)</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD21}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD21: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD22}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD22: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Phone(R)</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD23}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD23: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD24}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD24: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Mobile</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD35}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD35: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Factory</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD41}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD41: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Fax</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD31}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD31: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox value={oM02?.FIELD32}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD32: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>E-Mail-ID</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD43}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD43: e.target.value })
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                  <Grid.Col span={4}>
                    <Text  style={{fontSize:12}}>Website</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox value={oM02?.FIELD42}
                      setEdit={(e) => {
                        setoM02({ ...oM02, FIELD42: e.target.value })
                      }} />
                  </Grid.Col>
                </Grid.Col>

              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button size="xs" variant='subtle' onClick={saveBtn}>Save</Button>
              <Button size="xs" variant='subtle' onClick={cancelBtn}>Cancel</Button>
            </Grid.Col>
          </Grid>
      }
    </div>
  )
}

export default ViewMode
