import { Box, Button, Flex, Grid, Group, Skeleton } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

export default function PendingBillTable(props) {
  // const data = props.data;
  // console.log(props, "propsInPending");
  const [data, setData] = useState({});
  const [ReRender, setReRender] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [successFlag, setSuccess] = useState(false);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  let columns;

  useEffect(() => {
    setData(props.data);
  }, [props]);
  columns = [
    {
      accessor: "Party Name", // Use 'accessor' instead of 'accessorKey'
      header: "Party Name",
      Cell: ({ row }) => {
        // console.log(row.original.PrName, "getVal");
        return row?.original?.PrName;
      },
    },
    // {
    //   accessor: "Bill", // Convert index to string
    //   header: "Generate Bill",
    //   Cell: ({ row }) => {
    //     // console.log(row.original, "row.orginal");
    //     return (
    //       <Button onClick={() => generateBill(row?.original)} size="xs">
    //         Generate Bill
    //       </Button>
    //     );
    //   },

    //   maxSize: 100,
    // },
  ];
  useEffect(() => {
    columns = [
      {
        accessor: "Party Name", // Use 'accessor' instead of 'accessorKey'
        header: "Party Name",
        Cell: ({ row }) => {
          return row?.original?.PrName;
        },
      },
      // {
      //   accessor: "Bill", // Convert index to string
      //   header: "Generate Bill",
      //   Cell: ({ row }) => {
      //     return (
      //       <Button onClick={() => generateBill(row?.original)} size="xs">
      //         Generate Bill
      //       </Button>
      //     );
      //   },

      //   maxSize: 100,
      // },
    ];
  }, [ReRender]);

  async function generateBill() {
    Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(
      GlobalClass.Notify(
        "info",
        "Submitting..",
        "Please Wait While Generating Bill."
      )
    );
    const rowData1 = table?.getSelectedRowModel().flatRows;
    console.log(rowData1, "rowData==");
    // let success = true;
    const processItem = async (v) => {
      console.log(v, "rowData--");
      let success = false;
      let rowData = v;
      let jsonData = {
        dtFTbl: [{ ...rowData, LSELECT: "Y" }],
        oInfo: data?.oINFO,
      };
      console.log("submit==>", jsonData);

      var sendData = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        // cAction: "A",
        cCode: "",
        cSData: JSON.stringify(jsonData),
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        // cAction: "A",
        cCode: "",
        // cSData: JSON.stringify(group),
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sendData),
      };
      try {
        const response = await fetch(
          GlobalClass.ApiUrl + GlobalClass.MakeAutoBill + "?pa=" + param,
          requestOptions
        );
        const data = await response.json();

        if (data?.status == "SUCCESS") {
          Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
            GlobalClass.Notify(
              "success",
              "Successful",
              `Your Bill Generated Successfully For ${rowData?.PrName}`
            )
          );
          setReRender((prev) => !prev);
          setRowSelection([])
          success = true;
          return;
          // modals.closeAll();
        } else if (data?.status == "FAIL") {
          Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
            GlobalClass.Notify(
              "error",
              "Error occured!",
              `Failed to generate bill for ${rowData?.PrName} - Responded with status ${data?.status} `
            )
          );
        }
        // .then((response) => response.json())
        // .then((data) => {
        //   // console.log("api data=>", data);
        //   // console.log("api data status=>", data?.status);

        // });
      } catch (error) {
        Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
          GlobalClass.Notify(
            "error",
            "Error occured!",
            `Responded with status  ${error} `
          )
        );

        // console.error(error);
        return { error };
      }
    };

    for (const obj of rowData1) {
      // console.log("obj", obj);
      await processItem(obj?.original);
    }
    // console.log(jsonData, "requestOptions");
  }
  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: data?.dtFTbl ?? [],
    columns: columns,
    mantineTableProps:{withColumnBorders:true},
    enableRowSelection: (row) => {
      console.log(row);
      if (row.id == "mrt-row-create") {
        return false;
      } else {
        return true;
      }
    },
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    initialState: {
      density: "xs",
      expanded: true,
    },
    state: {
      rowSelection,
    },
    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: true,
    enableTableFooter: false,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {},
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      height: 50,
    }),
  });
  // console.log(SaveLoading, "SaveLoading");
  return (
    <>
      <Box>
        <Grid>
          {!SaveLoading ? (
            <>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button onClick={() => generateBill()} size="xs">
                  Generate Bill
                </Button>
              </Grid.Col>
            </>
          ) : (
            <Grid.Col span={12}>{GlobalClass.RenderLoader("dots")} </Grid.Col>
          )}
        </Grid>
      </Box>
    </>
  );
}
