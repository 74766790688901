import { useDispatch } from "react-redux";
import { GetAccountList, GetTMFormat } from "../../utils/slices/AccountListSlice";
import Store from "../../utils/store";

export function GSTR2(data) {
    console.log("data", data);
    const OBJ = JSON?.parse(data);
    const obj = OBJ?.BtnPnlObj

    console.log("OBJ", OBJ);


    if (OBJ?.p0 == "B2B") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM0000B2B" }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM0000B2B" }));

    }
    else if (OBJ?.p0 == "B2BUR") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00B2BUR" }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00B2BUR" }));


    }
    else if (OBJ?.p0 == "IMPS") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM000IMPS" }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM000IMPS" }));


    } else if (OBJ?.p0 == "CDNR") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM000CDNR" }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM000CDNR" }));


    } else if (OBJ?.p0 == "CDNUR") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00CDNUR" }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00CDNUR" }));


    } else if (OBJ?.p0 == "AT") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00000AT " }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00000AT " }));


    } else if (OBJ?.p0 == "ATADJ") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00ATADJ " }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00ATADJ " }));


    } else if (OBJ?.p0 == "EXEMP") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00EXEMP" }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM00EXEMP" }));


    } else if (OBJ?.p0 == "ITCR") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM000ITCR " }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM000ITCR " }));


    } else if (OBJ?.p0 == "HSNSUM") {
        Store.dispatch(GetTMFormat({ ...obj, p1: "GSTR2RETURN", p2: "TFORM0HSNSUM " }));
        Store.dispatch(GetAccountList({ ...obj, p1: "GSTR2RETURN", p2: "TFORM0HSNSUM " }));


    }

    //   StrtDt: currentDate1?.[obj?.id]?.From ? currentDate1?.[obj?.id]?.From : strtDate, // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
    //   EndDt: currentDate1?.[obj?.id]?.To ? currentDate1?.[obj?.id]?.To : edate,


}