import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';

export const GetEInvoice = createAsyncThunk(
    "EInvoice/GetEInvoice",
    async (obj) => {
        console.log("TSalesSlices/GetPLData", obj);
        try {
            let param = JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": "A",
                "cCode": "",
                "cOPara": "",
                "Custid": "",
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetE01Ent + "?pa=" + param);
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)

const EInvoiceSlice = createSlice({
    name: "EInvoice",
    initialState: {
        EInvoiceData: [],
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(GetEInvoice.pending, (state, action) => {
            state.isLoading = true;
            state.hasError = false;
          })
          .addCase(GetEInvoice.fulfilled, (state, action) => {
            if (!action.payload?.error) {
                state.EInvoiceData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            } else {
                state.isLoading = false;
                state.hasError = true;
                state.ErrorMsg = action.payload?.error;
            }
          })
          .addCase(GetEInvoice.rejected, (state, action) => {
            state.hasError = true;
            state.isLoading = false;
          })
    }
});

export const { } = EInvoiceSlice.actions

export default EInvoiceSlice.reducer