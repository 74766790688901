import { modals } from '@mantine/modals';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUserList, SetYearAndDateData } from '../../utils/slices/UserListSlice';
import { ClearTabs } from '../../utils/slices/TabList';
import { GetLanguageData } from '../../utils/slices/LanguageSlice';
import { getCurrentDate, getSortingField } from '../../utils/slices/AccountListSlice';
export const YearAndDateTable = (props) => {

  console.log('YearAndDateTable props=>', props);

  const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);
  const apiSuccess = useSelector((state)=>state?.Language.apiSuccess);
  // const GetGeneral = useSelector((state) => state.Language.General);

  const [columns, setColumns] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);

  const dispatch = useDispatch();

  // const columnHelper = createColumnHelper();

  console.log('GetYearDateData=>', GetYearDateData);


  useEffect(() => {
    var cols = [];
    if (GetYearDateData && GetYearDateData.length > 0) {
      Object.keys(GetYearDateData[0]).map((key) => {
        cols.push({
          accessorKey: key,
          header: key,
          Cell: ({ row, cell }) => {
            let val = cell.getValue();
            let newVal;
            if (key == "strtDate") {
              newVal = val?.slice(6, 8) + "/" + val?.slice(4, 6) + "/" + val?.slice(0, 4)
              return newVal
            } else if (key == "endDate") {
              newVal = val?.slice(6, 8) + "/" + val?.slice(4, 6) + "/" + val?.slice(0, 4)
              return newVal
            }
            return val

          }
        })
      })
    }
    setColumns(cols);
    console.log('GetYearDateData columns=>', columns);
  }, [GetYearDateData])

  useEffect(() => {
    if (selectedRowData != null) {
       Promise.resolve(dispatch(SetYearAndDateData(selectedRowData))).then(()=>{
      localStorage.setItem("YrNo",selectedRowData?.YearNo?.slice(0,2))
    })
      .then(()=>{
        window.location.reload();
      })
      
    };
    },[selectedRowData])


    // useEffect(()=>{
    //   if(apiSuccess==true){
    //     window.location.reload();
    //   }
    // },[apiSuccess])

  // console.log('GetGeneral=>',GetGeneral);

  const SendSelectedRow = (e) => {
    GetYearDateData.map((d, i) => {
      if (i == e) {
        setSelectedRowData(d)
      }
    })
  }

  const tabled = useMantineReactTable({
    data: GetYearDateData ?? [],
    columns,
    // enableRowSelection: false, //enable some features
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    mantineTableProps:{withColumnBorders:true},
    initialState: {
      columnVisibility: { YearNo: false }
    },
    state: {
      density: "10px",
      rowSelection
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        console.log("row", row);
        if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
          // modals.closeAll();
          // setCmpLoading(false);
          // cmpAction.close();
        } else {
          sessionStorage.setItem("year", row.getValue("YearNo"));
          // setSelected(row.getValue("YearNo"));
          // dispatch(SetCmpNumber(row.getValue("YearNo")))
          // setCmpLoading(true);
          // modals.closeAll();
          dispatch(ClearTabs(''))
        }

        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
        Promise.resolve(SendSelectedRow(row.id)).then(() => {
          modals.closeAll()
        })
      },
      selected: rowSelection[row.id],
      sx: { cursor: 'pointer' },
      // height: 30
    })
  })

  console.log('rowSelection==>', rowSelection);
  console.log('selectedRowData==>', selectedRowData)

  return (<>
    {
      GetYearDateData && GetYearDateData?.length > 0 &&
      <MantineReactTable table={tabled} />
    }
  </>)
}

