import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import axios from "axios";
// import { notifications } from "@mantine/notifications";
// import Store from "../store";
// import { GetAccountList } from "./AccountListSlice";

export const GetGSTListGroup = createAsyncThunk(
  "GSTList/GetGSTList",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: "T_M51G",
        cFmtID: "TFORM0000001",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetGenData + "?pa=" + param
      );
      // console.log("Response of get", response);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);


const GSTListSlice = createSlice({
  name: "GSTListGroup",
  initialState: {
    GSTListData: [],
    isLoading: false,
    hasError: false,
    errorMessage: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetGSTListGroup.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.GSTListData = [];
      })
      .addCase(GetGSTListGroup.fulfilled, (state, action) => {
        state.GSTListData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetGSTListGroup.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default GSTListSlice.reducer;
