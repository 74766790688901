import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import GlobalClass from "../GlobalClass";
import axios from "axios";

export const GetTaxClassGenData = createAsyncThunk(
  "TaxClassGenData/GetTaxClassGenData",
  async (obj) => {
    // console.log("=>Obj GetTaxClassGenData", obj);
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: "T_M14",
        cFmtID: ":",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GenData + "?pa=" + param
      );
      // console.log("GetTaxClassGenData response=>>", response.data.data);
      if (response?.data?.status == "SUCCESS") {
        return response?.data?.data;
      } else {
        return { error: response?.data?.message };
      }
      // return response.data.data;
    } catch (error) {
      // console.error(error);
      return { error };
    }
  }
);
export const GetTaxClassTmFormat = createAsyncThunk(
  "TaxClassTmFormat/GetTaxClassTmFormat",
  async (obj) => {
    // console.log(obj, "=>Obj GetVouchNum");

    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        // cAction: obj?.cAction ? obj.cAction : "A",
        cRepId: "T_M14",
        cFmtID: ":",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param
      );
      console.log("GetTaxClassTmFormat response=>>", response?.data);
      if (response?.data?.status == "SUCCESS") {
        return response?.data?.data;
      } else {
        return { error: response?.data?.message };
      }
      // return response.data.data;
    } catch (error) {
      // console.error(error);
      return { error };
    }
  }
);
export const GetM14Ent = createAsyncThunk("M14Ent/GetM14Ent", async (obj) => {
  console.log(obj, "=>Obj GetVouchNum");

  try {
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 ? obj.p0 : "A",
      cCode: obj?.p0 =="A" ? "" :obj?.id,
      cWhere: "",
      "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      // cRepId: "T_T92",
      // cFmtID: "TFORM0000001",
    });
    const response = await axios.get(
      GlobalClass.ApiUrl + GlobalClass.GetM14Ent + "?pa=" + param
    );
    // console.log("GetM14Ent response=>>", response.data);
    if (response?.data?.status == "SUCCESS") {
      return response?.data?.data;
    } else {
      console.log("Cors error", response?.data)
      return { error: response?.data?.message };
    }
  } catch (error) {
    console.error(error, "Cors");
    return { error };
  }
});

const TaxClassSlice = createSlice({
  name: "TaxClass",
  initialState: {
    TaxClassFormData: [],
    TaxClassTmFormat: [],
    TaxClassGenData: [],
    // VoucherNumField: [],
    isLoading: false,
    isLoadingForm: false,
    hasErrorForm: false,
    hasError: false,
    ErrorMsg: "",
    successMsg: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetTaxClassGenData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetTaxClassGenData.fulfilled, (state, action) => {
        // console.log("TaxClassGenData action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.TaxClassGenData = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.TaxClassGenData = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetTaxClassGenData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetTaxClassTmFormat.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetTaxClassTmFormat.fulfilled, (state, action) => {
        // console.log("TaxClassTmFormat action.payload=>", action?.payload);
        if (!action?.payload?.error) {
          state.TaxClassTmFormat = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.TaxClassTmFormat = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetTaxClassTmFormat.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetM14Ent.pending, (state, action) => {
        state.isLoadingForm = true;
        state.hasErrorForm = false;
      })
      .addCase(GetM14Ent.fulfilled, (state, action) => {
        console.log("GetM14Ent action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.TaxClassFormData = action?.payload;
          state.isLoadingForm = false;
          state.hasErrorForm = false;
        } else {
          state.TaxClassFormData = [];
          state.isLoadingForm = false;
          state.hasErrorForm = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetM14Ent.rejected, (state, action) => {
        state.hasErrorForm = true;
        state.isLoadingForm = false;
      });
  },
});

export const {} = TaxClassSlice.actions;

export default TaxClassSlice.reducer;
