import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import GlobalClass from "../GlobalClass";
import axios from "axios";
import Store from "../store";
// import { notifications } from "@mantine/notifications";
export const GetVouchNumData = createAsyncThunk(
  "VouchNumber/GetVouchNumData",
  async (obj) => {
    console.log("=>Obj GetVouchNumData", obj);
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: obj?.RepId ? obj?.RepId : "T_T92",
        cFmtID: obj?.FmtID ? "" : "TFORM0000001",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GenData + "?pa=" + param
      );
      // console.log("GetVouchNumData response=>>", response.data.data);
      if (response?.data?.status == "SUCCESS") {
        return response?.data?.data;
      } else {
        return { error: response?.data?.message };
      }
      // return response.data.data;
    } catch (error) {
      // console.error(error);
      return { error };
    }
  }
);
export const GetVouchNum = createAsyncThunk(
  "VouchNumber/GetVouchNum",
  async (obj) => {
    // console.log(obj, "=>Obj GetVouchNum");

    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cRepId: obj?.RepId ? obj?.RepId : "T_T92",
        cFmtID: obj?.FmtID ? "" : "TFORM0000001",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param
      );
      // console.log("GetVouchNum response=>>", response.data);
      if (response?.data?.status == "SUCCESS") {
        return response?.data?.data;
      } else {
        return { error: response?.data?.message };
      }
      // return response.data.data;
    } catch (error) {
      // console.error(error);
      return { error };
    }
  }
);
export const GetVouchNumField = createAsyncThunk(
  "VouchNumber/GetVouchNumField",
  async (obj) => {
    // console.log(obj.cCode, "=>Obj GetVouchNumField");
    if (obj.cAction === "D") {
      GlobalClass.Notify(
        "info",
        "Deleting",
        "Please wait while we process your data"
      );
    }
    try {
      // console.log("Entered-----------");
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetT92Field + "?pa=" + param
      );
      // console.log("GetVouchNumField response=>>", response.data);
      if (response.data.status == "SUCCESS") {
        if (obj.cAction === "D" && response?.data?.status === "SUCCESS") {
          Promise.resolve(Store.dispatch(GetVouchNumData())).then(() =>
            GlobalClass.Notify("success", "Deleted", "Deleted successfully")
          );
        }
        return response?.data?.data;
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
        return { error: response?.data?.message };
      }
      // return response.data.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);

const VoucherNumSlice = createSlice({
  name: "VouchNumber",
  initialState: {
    VoucherNumTemp: [],
    VoucherNumdata: [],
    VoucherNumField: [],
    isLoading: false,
    isLoadingForm: false,
    hasError: false,
    ErrorMsg: "",
    successMsg: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetVouchNum.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetVouchNum.fulfilled, (state, action) => {
        // console.log("VoucherNumTemp action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.VoucherNumTemp = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.VoucherNumTemp = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetVouchNum.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetVouchNumData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetVouchNumData.fulfilled, (state, action) => {
        // console.log("VoucherNumdata action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.VoucherNumdata = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.VoucherNumdata = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetVouchNumData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetVouchNumField.pending, (state, action) => {
        state.isLoadingForm = true;
        state.hasError = false;
      })
      .addCase(GetVouchNumField.fulfilled, (state, action) => {
        // console.log("VoucherNumField action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.VoucherNumField = action.payload;
          state.isLoadingForm = false;
          state.hasError = false;
        } else {
          state.VoucherNumField = [];
          state.isLoadingForm = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetVouchNumField.rejected, (state, action) => {
        state.hasError = true;
        state.isLoadingForm = false;
      });
  },
});

export const {} = VoucherNumSlice.actions;

export default VoucherNumSlice.reducer;
