import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Text,
  // Skeleton,
  Center,
  Button,
  Textarea,
  Flex,
  Tooltip,
  ActionIcon,
  Group,
  Drawer,
  Divider,
  Spoiler,
  Modal,
  Paper,
  Box,
  // Group,
} from "@mantine/core";
// import { notifications } from "@mantine/notifications";
import PLDateBox from "../../PlusComponent/PLDateBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { useDispatch, useSelector } from "react-redux";
// import { GetOpeningStockEnt } from "../../utils/TransactionSlices/openingStockEntSlice";
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import Store from "../../utils/store";
import { IconEdit } from "@tabler/icons-react";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
// import { ModalFunction } from "../../utils/slices/ModalSlice";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { modals } from "@mantine/modals";
import OSTableUserField from "./OSTableUserField";
// import { GetOpeningStockGen } from "../../utils/TransactionSlices/openingStockSliceGen";
// import { GetOpeningStockTM } from "../../utils/TransactionSlices/openingStockSliceTM";
// import { setToggleButton } from "../../utils/TransactionSlices/openingStockEntSlice";
import PLNarration from "../../PlusComponent/PLNarration";
import {
  BatchLogAddRemoveFun,
  GetUserFieldObj,
  TransactionApi,
} from "../../utils/TransactionSlices/Transaction";
import { ModalDelete } from "../../utils/slices/ModalSlice";

import {
  GetAccountList,
  GetTMFormat,
} from "../../utils/slices/AccountListSlice";
import { getProductName } from "../../utils/TransactionSlices/TSalesSlices";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import { GetCMPSetup } from "../../utils/slices/CompanySetupSlice";
import { IconTrash } from "@tabler/icons-react";
import FormatListTable from "../SMSEmailWpBtn/FormatListTable";
import PLPrint from "../../FunctionsCall/Print/PLPrint";
import PLUserFieldBtn from "../../PlusComponent/PLUserFieldBtn";
import { Qty_CheckStk } from "../SaleEntry/QtyCheckStock";
import { ReverseCalCulation } from "../SaleEntry/EquationsFunctions";

const OpeningStockAddForm = (props) => {
  //   const OSV = useSelector(
  //     (state) => state?.OpeningStockEnt?.OpeningStockDataEnt?.data?.OSV

  //   );
  // console.log("data Props",props?.data)
  const { obj } = props;
  const dispatch = useDispatch();
  const inputRef = useRef();
  const purRef = useRef();
  const dummyRef = useRef();
  const formRef = useRef();
  const TableRef = useRef();
  const TableContainer= useRef();
  const spoilerControlRef = useRef();
  const prct = useRef();
  const pr = useRef();
  const [columns, setColumns] = useState([]);
  const [T02Data, setT02Data] = useState([]);
  const [showTotalAmount, setShowTotalAmount] = useState(false);
  const [NarrationData, setNarrationData] = useState([]);
  const [FIELD01Value, setFIELD01value] = useState("");
  const [BatchIssueObj, setBatchIssueObj] = useState(null);
  const [LocationObj, setLocationObj] = useState(null);
  // const [date, setDate] = useState(DateToString(new Date())?.weekday);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [categoryGroup, setCategoryGroup] = useState(false);
  const [purchasePartyObj, setPurchasePartyObj] = useState(null);
  const [addNewDisabled, setAddNewDisabled] = useState(false);
  const [priceList, setPriceList] = useState(null);
  const [prCtDisabled, setPrCtDisabled] = useState(false);
  // const [modalClosed,setModalClosed]=useState(false);
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: () => {},
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [T41Obj, setT41Obj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "0",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    UBILTO: "",
    UGSTR: "",
    UOD: "",
    USALM: "",
    UTR: "",
    UDISP: "",
    ULEN: "",
    UODD: "",
    ULRD: "",
    UPAYT: "",
    USAL: 0,
    UPR: "",
    UNAME: "",
    UOP: "",
    UTRA: "",
    UODP: "",
    UREMA: "",
    UEMAIL: "",
    URA: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    F20NM: "",
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UUASDF05: "",
    UUASD09: "",
    UUBAER: "",
    UUUBAER: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    UUASWER: "",
    UUUASWER: "",
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: true,
    lMTaxEnt: true,
    T92F02: "",
    CFRMTITLE: "",
    BTOBEFF: "",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
    T92F00: null,
  });
  const [T02Obj, setT02Obj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    F20NM: "",
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    UAP: 0.0,
    UDIS: 0.0,
    UGRO: 0.0,
    UISSUERA: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    URATE: 0.0,
    URE: "",
    UT76: "",
    UUDIS: "",
    UUDISAAA: "",
    UUGR01: "",
    UUGRO: "",
    UUGROAAA: "",
    UUOS: "",
    IA: "",
    ID: 0.0,
    IP: 0.0,
    IT: "",
    IA00000024: "",
    ID00000024: 0.0,
    IP00000024: 0.0,
    IT00000024: "",
    IA00000025: "",
    ID00000025: 0.0,
    IP00000025: 0.0,
    IT00000025: "",
    IA00000026: "",
    ID00000026: 0.0,
    IP00000026: 0.0,
    IT00000026: "",
    IA00000007: "",
    ID00000007: 0.0,
    IP00000007: 0.0,
    IT00000007: "",
    IA00000027: "",
    ID00000027: 0.0,
    IP00000027: 0.0,
    IT00000027: "",
    FIELD90: 0.0,
    USERFLD: "1",
    F03NM: "",
    F20NM: "",
    CATNM: "",
    GRPNM: "",
    USERFLDB: "2",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    JODUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    T02F08EXP: 0.0,
    _EXPTOTALAMT: 0.0,
  });

  const [toggleButton, setToggleButton] = useState(false);
  const [creatingRowEnabled, setCreatingRowEnabled] = useState(false);
  const [editingrowEnabled, setEditingRowEnabled] = useState();
  const [userFieldHideShow, setUserFieldHideShow] = useState("");
  const [productData, setProductData] = useState();
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [OSVData, setOSVData] = useState();
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    Group: "",
    Catogary: "",
    Product: "",
  });
  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });

  const [dayVal, setDayVal] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  // const OpeningStockDataEnt = useSelector(
  //   (state) => state.OpeningStockEnt?.OpeningStockDataEnt?.data
  // );
  const CMPSetup = useSelector((state) => state?.CompanySetup);
  const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
  const T92 = useSelector((state) => state.Transaction?.TransactionData?.T92);
  const T92DT = useSelector(
    (state) => state.Transaction?.TransactionData?.T92DT
  );
  const T02ENT = useSelector(
    (state) => state.Transaction?.TransactionData?.T02ENT
  );
  const T02 = useSelector((state) => state.Transaction?.TransactionData?.T02);
  const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV);
  const T02UF = useSelector(
    (state) => state.Transaction?.TransactionData?.T02UF
  );

  const UFT41H = useSelector(
    (state) => state.Transaction?.TransactionData?.UFT41H
  );
  const OS11 = useSelector(
    (state) => state?.Transaction?.TransactionData?.OS11
  );
  const lEditVou = useSelector(
    (state) => state.Transaction?.TransactionData?.lEditVou
  );
  const dtUFT11 = useSelector(
    (state) => state.Transaction?.TransactionData?.dtUFT11
  );
  const { oYear } = useSelector((state) => state?.Language?.General);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const PLDATA = useSelector(
    (state) => state?.Transaction?.TransactionData?.PLDATA
  );
  const isLoading = useSelector((state) => state?.Transaction?.isLoading);
  const hasError = useSelector((state) => state?.Transaction?.hasError);
  const ErrorMsg = useSelector((state) => state?.Transaction?.ErrorMsg);
  const { isUserFieldLoading, UserField } = useSelector(
    (state) => state?.Transaction
  );
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const map = new Map();
  // const toggleButton = useSelector(
  //   (state) => state?.OpeningStockEnt?.toggleButton
  // );

  const { height } = useWindowDimensions();
  const columnHelper = createColumnHelper();
  let stringOfNames;
  let ExpenseObj = {
    OS11,
    OSV,
    oYear,
    ItemCode: productData?.FIELD03,
    selectedProductObj : productData,
    T02Obj: { ...T02Obj, VorC: T41Obj?.FIELD45 },
    T41Obj,
    T02Data
  }
  function DateToString(e) {
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });
    let weekday = e.toLocaleString("en-IN", { weekday: "long" });
    // let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { startDate, weekday };
  }

  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({
        ...BillSeriesHideShow,
        DataGridInput: true,
        TextInput: false,
      });
    }
  }, [BillSeriesHideShow.BillNoVal]);

  useEffect(() => {
    if (oYear) {
      setUserFieldHideShow(oYear?.OSC?._USERFIELD);
    }
  }, [oYear]);

  useEffect(() => {
    Promise.resolve(
      dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))
    ).then(() => {{
      if (obj.p0 == "E") {
        dispatch(
          GetAccountList({
            id: "P_M21",
            name: "",
            type: "A",
            p0: "P_M21",
            p1: "P_M21",
            p2: "",
            p3: "",
          })
        );
      }
    }
      // Promise.resolve(
      //   dispatch(
      //     GetAccountList({
      //       id: "T_M32G",
      //       name: "",
      //       type: "A",
      //       p0: "T_M32G",
      //       p1: "T_M32G",
      //       p2: "",
      //       p3: "",
      //     })
      //   )
      // )
      //   .then(() =>
      //     dispatch(
      //       GetTMFormat({
      //         id: "T_M32G",
      //         name: "",
      //         type: "A",
      //         p0: "T_M32G",
      //         p1: "T_M32G",
      //         p2: "",
      //         p3: "",
      //       })
      //     )
      //   )
      //   .then(() =>
      //   dispatch(
      //     GetAccountList({
      //       id: "T_M32C",
      //       name: "",
      //       type: "A",
      //       p0: "T_M32C",
      //       p1: "T_M32C",
      //       p2: "",
      //       p3: "",
      //     })
      //   )
      //   )
      //   .then(() =>
      //     dispatch(
      //       GetTMFormat({
      //         id: "T_M32C",
      //         name: "",
      //         type: "A",
      //         p0: "T_M32C",
      //         p1: "T_M32C",
      //         p2: "",
      //         p3: "",
      //       })
      //     )
      //   );
      // Promise.resolve(
      //   dispatch(
      //     GetAccountList({
      //       id: "T_M32C",
      //       name: "",
      //       type: "A",
      //       p0: "T_M32C",
      //       p1: "T_M32C",
      //       p2: "",
      //       p3: "",
      //     })
      //   )
      // )
      //   .then(() =>
      //     dispatch(
      //       GetTMFormat({
      //         id: "T_M32C",
      //         name: "",
      //         type: "A",
      //         p0: "T_M32C",
      //         p1: "T_M32C",
      //         p2: "",
      //         p3: "",
      //       })
      //     )
      //   )
    });
  }, [obj]);

  useEffect(() => {
    if (UFT41H && obj?.p0 == "E") {
      UFT41H?.forEach((item) => {
        if (item?.S13F14 == "PLPOPUP") {
          const key = seperate(item.S13F09)[1]; // Key
          const value = T41?.[item.S13F02]; // Value
          if (map.has(key)) {
            // If it exists, add the value to the set/array of values for that key
            map.get(key).add(value); // Use a Set to ensure uniqueness of values
          } else {
            map.set(key, new Set([value]));
          }
        }
      });

      if (map?.size > 0) {
        map.forEach((value, key) => {
          const dyValue = Array.from(value).length
            ? "'" + Array.from(value).join("', '") + "'"
            : "";
          setTimeout(() => {
            Promise.resolve(()=>{
              dispatch(
              GetAccountList({
                id: key,
                name: "",
                type: "A",
                p0: key,
                p1: key,
                p2: "",
                p3: "",
                cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
              })
            );}).then(()=>{
              dispatch(GetTMFormat({
                "id": key,
                "name": "",
                "type": "A",
                "p0": key,
                "p1": key,
                "p2": "TFORM0000001",
                "p3": ""
              }))
            })
            
          }, [500]);
        });
      }
    }
  }, [UFT41H]);

  useEffect(() => {
    GetCMPSetup(obj);
  }, []);
  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (SelectedNarrationData.length > 0) {
      const stringifyArray = SelectedNarrationData.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
    }
    setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
    // creatingRowEnabled
    // ? Promise.resolve(
    //     handleCreateUser({ table })
    //   ).then(() => {
    //     setTimeout(() => {
    //       rowCancelHandler();
    //       table.setCreatingRow(true);
    //       setToggleButton(true);
    //       setCreatingRowEnabled(true);
    //       setAddNewDisabled(false);
    //       // inputRef?.current?.handlePopoverToggle();
    //       prct?.current?.handlePopoverToggle();
    //     }, 100);
    //   })
    // : Promise.resolve(
    //     handleSaveUser({ table })
    //   ).then(() => {
    //     setTimeout(() => {
    //       rowCancelHandler();
    //       setAddNewDisabled(false);
    //       table.setCreatingRow(true);
    //       setToggleButton(true);
    //       setCreatingRowEnabled(true);
    //       // inputRef?.current?.handlePopoverToggle();
    //       prct?.current?.handlePopoverToggle();
    //     }, 100);
    //   });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  useEffect(() => {
    let date = T41Obj?.FIELD02;
    // console.log(date, "group?.FIELD02");
    if (date?.length > 0) {
      const year = parseInt(date.substring(0, 4));
      const month = parseInt(date.substring(4, 6)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substring(6, 8));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setDayVal(dayOfWeek?.slice(0, 3));
    }
  }, [T41Obj?.FIELD02]);

  // useEffect(() => {
  //   if(table?.getSelectedRowModel()?.flatRows.length > 0)
  //   {T02Data?.map((t, index) => {
  //     if (index in rowSelection) {
  //       //  onkeydown=((e)=>{
  //       document.addEventListener("keydown", (e) => {

  //         if (e.key == "Enter") {
  //           Promise.resolve().then(()=>{
  //             table.setCreatingRow(null);
  //           }).then(() => {
  //             table.setEditingRow(table?.getSelectedRowModel()?.flatRows[0]);
  //             setT02Obj(t);
  //             setToggleButton(false);
  //             setAddNewDisabled(true);
  //           });
  //         }
  //         else if (e.key == "Escape") {
  //           Promise.resolve(rowCancelHandler()).then(() => {
  //             table.setEditingRow(null);
  //             table.setCreatingRow(null);
  //           });
  //         }
  //       });
  //       // })
  //     }
  //   });}
  // }, [rowSelection]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        Promise.resolve()
          .then(() => rowCancelHandler())
          .then(() => {
            if (tableEditingRow == null) {
              table.setCreatingRow(null);
            } else {
              table.setEditingRow(null);
            }
          });
      }
    });
  }, [tableEditingRow]);


  useEffect(() => {
    if (T02) {
      setT02Data(T02);
      if (obj.p0 == "E") {
        // setT02Obj({...T02?.[T02?.length - 1],ExpAmount:0});
        setT02Obj({...T02?.[T02?.length - 1],FIELD05: OS11?.S11F21}) ;
      } else {
        // setT02Obj({...T02?.[0],ExpAmount:0})
        setT02Obj({...T02?.[0],FIELD05: OS11?.S11F21 });
      }
    }
  }, [T02]);

  useEffect(() => {
    setOSVData(OSV);
  }, [OSV]);

  useEffect(() => {
    if (T41) {
      setT41Obj(T41);
    }
  }, [T41]);

  useEffect(() => {
    if (BatchIssueObj !== null) {
      setT02Obj({
        ...T02Obj,
        FIELD11: BatchIssueObj?.FIELD01,
        FIELD41: BatchIssueObj?.FIELD02,
      });
    } else {
      setT02Obj({
        ...T02Obj,
        FIELD11: "",
        FIELD41: "",
      });
    }
  }, [BatchIssueObj, T02Obj?.FIELD11]);
  useEffect(() => {
    if (LocationObj !== null) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...T02Obj,
          FIELD20: LocationObj?.FIELD01 ?? "",
          F20NM: LocationObj?.FIELD02 ?? "",
        });
      });
    }
  }, [LocationObj, T02Obj?.FIELD20]);

  useEffect(() => {
    if (priceList !== null) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...T02Obj,
          FIELD27: priceList ?? "",
        });
      });
    }
  }, [priceList, T02Obj?.FIELD27]);

  useEffect(() => {
    setT02Obj({
      ...T02Obj,
      FIELD07:
        T02Obj?.FIELD05 == "C" || T02Obj?.FIELD05 == "D"
          ? productData?.FIELD21
          : productData?.FIELD22,
    });
  }, [productData?.FIELD21, productData?.FIELD22]);

  useEffect(() => {
    setT02Obj({
      ...T02Obj,
      FIELD22: productData?.FIELD28 == 0 ? 1 : productData?.FIELD28,
    });
  }, [productData?.FIELD28]);

  useEffect(() => {
    setT02Obj({ ...T02Obj, FIELD06: T02Obj?.FIELD21 * T02Obj?.FIELD22 });
  }, [T02Obj?.FIELD21, T02Obj?.FIELD22]);

  useEffect(() => {
    if (categoryGroup == true) {
      setT02Obj({ ...T02Obj, FIELD03: "", F03NM: "" });
    }
    setCategoryGroup(false);
  }, [categoryGroup]);

  useEffect(() => {
    if (purchasePartyObj != null) {
      Promise.resolve()
        .then(() => {
          setT02Obj({ ...T02Obj, FIELD12: purchasePartyObj?.FIELD01 ?? "" });
        })

        .then(() => {
          // creatingRowEnabled
          //   ? Promise.resolve(handleCreateUser({ table })).then(() => {
          //       rowCancelHandler();
          //       table.setCreatingRow(true);
          //       setToggleButton(true);
          //       setCreatingRowEnabled(true);
          //       setAddNewDisabled(false);
          //       // inputRef?.current?.handlePopoverToggle();
          //       prct?.current?.handlePopoverToggle();
          //     })
          //   : Promise.resolve(handleSaveUser({ table })).then(() => {
          //       rowCancelHandler();
          //       table.setCreatingRow(true);
          //       setToggleButton(true);
          //       setCreatingRowEnabled(true);
          //       setAddNewDisabled(false);
          //       // inputRef?.current?.handlePopoverToggle();
          //       prct?.current?.handlePopoverToggle();
          //     });
          creatingRowEnabled
            ? Promise.resolve(handleCreateUser({ table }))
            // .then(() => {
            //     setTimeout(() => {
            //       if (
            //         !(
            //           ((productData?.FIELD51 == "Y" || T02Obj?.FIELD05=="C") &&
            //             T02Obj?.FIELD41?.trim() == "") ||
            //           T02Obj?.FIELD03 == ""
            //         )
            //       ) {
            //         if (obj.p0 == "E") {
            //           setT02Obj(T02?.[T02?.length - 1]);
            //         } else {
            //           setT02Obj(T02?.[0]);
            //         }
            //       }
            //       setAddNewDisabled(false);
            //       table.setCreatingRow(true);
            //       setToggleButton(true);
            //       setCreatingRowEnabled(true);
            //       prct?.current?.handlePopoverToggle();
            //     }, 500);
            //   })
            : Promise.resolve( ((productData?.FIELD51 == "Y" ||T02Obj?.FIELD05=="C") && T02Obj?.FIELD41?.trim() == "") ||
            T02Obj?.FIELD03 == "" || ((T02Obj?.FIELD03 != "" && productData?.FIELD51 != "N") && T02Obj?.FIELD11=="") ? (
             GlobalClass.Notify(
               "warning",
               "Validation error",
               "Please fill out all the required fields"
             )) :handleSaveUser())
                // .then(() => {
                //   setTimeout(() => {
                //     if (
                //       !(
                //         ((productData?.FIELD51 == "Y" || T02Obj?.FIELD05=="C") &&
                //           T02Obj?.FIELD41?.trim() == "") ||
                //         T02Obj?.FIELD03 == ""
                //       )
                //     ) {
                //       if (obj.p0 == "E") {
                //         setT02Obj(T02?.[T02?.length - 1]);
                //       } else {
                //         setT02Obj(T02?.[0]);
                //       }
                //     }
                //     setAddNewDisabled(false);
                //     table.setCreatingRow(true);
                //     setToggleButton(true);
                //     setCreatingRowEnabled(true);
                //     prct?.current?.handlePopoverToggle();
                //   }, 500);
                // });
        });
    }
  }, [purchasePartyObj]);

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate1 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const NagativeQtyPopUpFun = (e) => {
    GlobalClass.Notify("warning", "Warning", e)
  }

  const TableUserFieldFun = (header, index) => {
    switch (header) {
      case "E00USRFLD":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>User Field</Text>,
          body: (
            <>
              <OSTableUserField
                T02UF={T02UF}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve(setT02Obj(e)).then(() => {
                    NextIndexFun(index);
                  });
                }}
                DrawerOBJ={DrawerObj}
                setDrawerOBJ={(e) => {
                  setDrawerObj(e);
                }}
                PageObj={obj}
              />
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;

      case "E00UFBAT":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>Batch User Field</Text>,
          body: (
            <>
              <OSTableUserField
                T02UF={dtUFT11}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve(
                    setT02Obj({ ...e, FIELD11: BatchIssueObj?.FIELD01 })
                  ).then(() => {
                    NextIndexFun(index - 1);
                  });
                }}
                DrawerOBJ={DrawerObj}
                setDrawerOBJ={(e) => {
                  setDrawerObj(e);
                }}
                PageObj={obj}
              />
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });

        break;

      default:
        break;
    }
  };

  const handleCreateUser = () => {
    // if (
    //   ((productData?.FIELD51 == "Y" ||T02Obj?.FIELD05=="C") && T02Obj?.FIELD41?.trim() == "") ||
    //   T02Obj?.FIELD03 == ""
    // ) {
    //   GlobalClass.Notify(
    //     "warning",
    //     "Validation error",
    //     "Please fill out all the required fields"
    //   );
    //   setToggleButton(true);
    // } else {
      setShowTotalAmount(true);
      Store.dispatch(
        BatchLogAddRemoveFun({
          PageCode: "03450342",
          T02Data: T02Data,
          Row: T02Obj,
          OBJ: obj,
          T41Obj: T41Obj,
        })
      );
      setT02Data([...T02Data, T02Obj]);
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0]);
      }
      setToggleButton(false);
      setAddNewDisabled(false);
      setBatchIssueObj(null);
      setLocationObj(null);
      setPriceList(null);
      setCreatingRowEnabled(false);
      table.setCreatingRow(null);
      table.setEditingRow(null);
    // }
  };

  const HiddenButtonField = (uID, v, c, cellid, index) => {
    // console.log("inside hiddenButtonField",c.S53F02)
    switch (c.S53F02) {
      case "E00T02F11":
        if (
          T02Obj?.FIELD03 != "" && productData?.FIELD51 != "N"
        ) {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () =>
                  NextIndexFun(T02UF && T02UF.length > 0 ? index : index + 1)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () =>
                  NextIndexFun(T02UF && T02UF.length > 0 ? index : index + 1)
              );
            },
            body: (
              <>
                <PLDataGrid
                  // autoFocus={true}
                  // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                  id={`T02Ent_${index}`}
                  cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                  width={"500px"}
                  value={T02Obj?.[c?.S53F04]}
                  p1={seperate(c.S53F15)[1]}
                  setEdit={(e) => {
                    // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                    Promise.resolve(
                    setBatchIssueObj(e)).then(()=>{
                      Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                        () =>
                          NextIndexFun(T02UF && T02UF.length > 0 ? index : index + 1)
                      );
                    })
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 0px",
                  }}
                >
                  <Button
                    onClick={() => {
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        NextIndexFun(
                          T02UF && T02UF.length > 0 ? index : index + 1
                        );
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        Promise.resolve(
                          setDrawerObj({ ...DrawerObj, open: false })
                        ).then(() => {
                          NextIndexFun(
                            T02UF && T02UF.length > 0 ? index : index + 1
                          );
                        });
                      }
                    }}
                  >
                    OK
                  </Button>
                </div>
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00USERFLD":
        if (T02UF?.length > 0) {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () =>
                  NextIndexFun(productData?.FIELD59 == "N" ? index + 1 : index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () =>
                  NextIndexFun(productData?.FIELD59 == "N" ? index + 1 : index)
              );
            },
            body: (
              <>
                <OSTableUserField
                  T02UF={T02UF}
                  T02ObjSend={T02Obj}
                  setT02Objsend={(e) => {
                    Promise.resolve(setT02Obj(e)).then(() => {
                      if (productData?.FIELD59 == "N") {
                        NextIndexFun(index + 1);
                      } else {
                        NextIndexFun(index);
                      }
                    });
                  }}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    setDrawerObj(e);
                  }}
                  PageObj={obj}
                />
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00UFBAT":
        if (productData?.FIELD51 != "N") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>Batch User Field</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              <>
                <OSTableUserField
                  T02UF={dtUFT11}
                  T02ObjSend={T02Obj}
                  setT02Objsend={(e) => {
                    Promise.resolve(setT02Obj(e)).then(() =>
                      NextIndexFun(index)
                    );
                  }}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    setDrawerObj(e);
                  }}
                  PageObj={obj}
                />
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }

        break;
      case "E00T02F23":
        if (typeof PLDATA !== "string") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () =>
                  NextIndexFun(productData?.FIELD59 == "N" ? index + 3 : index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () =>
                  NextIndexFun(productData?.FIELD59 == "N" ? index + 3 : index)
              );
            },
            body: (
              <>
                <PLComboBox
                  initiallyOpened={true}
                  cmbid={`T02Ent_${index}`}
                  data={typeof PLDATA == "string" ? [{}] : PLDATA}
                  value={obj?.p0 == "A" ? T02Obj?.[c?.S53F04] : priceList}
                  dispexpr={"FIELD02"}
                  valexpr={"FIELD01"}
                  setEdit={(e) => {
                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e }))
                      .then(() => {
                        setPriceList(e);
                      })
                      .then(() => {
                        if (T41Obj.FIELD16 == "C") {
                          setPriceListParemeter({
                            ...priceListParemeter,
                            accountCode: T41Obj.FIELD31,
                            priceListCode: e,
                          });
                        } else {
                          setPriceListParemeter({
                            ...priceListParemeter,
                            accountCode: T41Obj.FIELD04,
                            priceListCode: e,
                          });
                        }
                      }).then(()=>{
                        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                          () =>
                            NextIndexFun(productData?.FIELD59 == "N" ? index + 3 : index)
                        );
                      })
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 0px",
                  }}
                >
                  <Button
                    onClick={() => {
                      // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        NextIndexFun(
                          productData?.FIELD59 == "N" ? index + 3 : index
                        );
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        Promise.resolve(
                          setDrawerObj({ ...DrawerObj, open: false })
                        ).then(() => {
                          NextIndexFun(index);
                        });
                      }
                    }}
                  >
                    OK
                  </Button>
                </div>
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00SRIDUM":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          body: (
            <>
              <PLTextBox
                value={T02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(()=>{
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                      () => NextIndexFun(index)
                    );
                  })
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 0px",
                }}
              >
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      NextIndexFun(index);
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        NextIndexFun(index);
                      });
                    }
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;
      case "E00SRENT":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          body: (
            <>
              <PLTextBox
                value={T02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  Promise.resolve(
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(()=>{
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                      () => NextIndexFun(index))
                  })
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 0px",
                }}
              >
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      NextIndexFun(index);
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        NextIndexFun(index);
                      });
                    }
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;
      case "RID0053":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          body: (
            <>
              <PLTextBox
                value={T02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  Promise.resolve(
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(()=>{
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                      () => NextIndexFun(index)
                    );
                  })
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 0px",
                }}
              >
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      NextIndexFun(index);
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        NextIndexFun(index);
                      });
                    }
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;
      case "E00SRIDUMF":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          body: (
            <>
              <PLTextBox
                value={T02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  Promise.resolve(
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(()=>{
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                      () => NextIndexFun(index)
                    );
                  })
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 0px",
                }}
              >
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      NextIndexFun(index);
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        NextIndexFun(index);
                      });
                    }
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;
      case "E00SRRDUMF":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () =>
                NextIndexFun(
                  productData?.FIELD51 == "N" || T02Obj?.FIELD05 !== "C"
                    ? index + 1
                    : index
                )
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () =>
                NextIndexFun(
                  productData?.FIELD51 == "N" || T02Obj?.FIELD05 !== "C"
                    ? index + 1
                    : index
                )
            );
          },
          body: (
            <>
              <PLTextBox
                value={T02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  Promise.resolve(
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(()=>{
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                      () =>
                        NextIndexFun(
                          productData?.FIELD51 == "N" || T02Obj?.FIELD05 !== "C"
                            ? index + 1
                            : index
                        )
                    );
                  })
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 0px",
                }}
              >
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      // console.log(
                      //   "productData?.FIELD51==>",
                      //   productData?.FIELD51,
                      //   "T02Obj?.FIELD05==>",
                      //   T02Obj?.FIELD05
                      // );
                      if (
                        productData?.FIELD51 == "N" ||
                        T02Obj?.FIELD05 !== "C"
                      ) {
                        NextIndexFun(index + 1);
                      } else {
                        NextIndexFun(index);
                      }
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        if (
                          productData?.FIELD51 == "N" ||
                          T02Obj?.FIELD05 !== "C"
                        ) {
                          NextIndexFun(index + 1);
                        } else {
                          NextIndexFun(index);
                        }
                      });
                    }
                  }}
                >
                  OK
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;

      // case "E00ORDDUM":
      // setDrawerObj({
      //   ...dra,
      //   title: <Text fw={700}>{c.S53F03}</Text>,
      //   body: (
      //     <>
      //       <Text>{c.S53F03}</Text>
      //       <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
      //       <Button
      //         onClick={() => {
      //           // setDrawerObj({ ...DrawerObj, open: false })
      //           Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
      //             .then(() => {
      //               if (T02Obj?.FIELD03 != "" && productData?.FIELD51 != "N" && T02Obj?.FIELD05 == "D") {
      //                 NextIndexFun(index)
      //               } else {
      //                 NextIndexFun(index + 1)
      //               }
      //             }
      //             )
      //         }}
      //         onKeyDown={(e) => {
      //           if (e.key == "Enter") {
      //             Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
      //               .then(() => {
      //                 if (T02Obj?.FIELD03 != "" && productData?.FIELD51 != "N" && T02Obj?.FIELD05 == "D") {
      //                   NextIndexFun(index)
      //                 } else {
      //                   NextIndexFun(index + 1)
      //                 }
      //               }
      //               )
      //           }
      //         }}
      //       >Ok</Button>
      //     </div>
      //     </>
      //   ),
      //   open: true,
      //   size: "sm",
      //   position: "right",
      // });
      // break;
      default:
        break;
    }
  };

  const UFT41HRenderField = (e) => {
    //S13F02 Save UserField
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            // console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={{
                          value: T41Obj?.[h?.S13F02],
                          label: accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 == T41Obj?.[h?.S13F02])?.[0]?.[h?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 === T41Obj?.[h?.S13F02])?.[0] || {}) ? "FIELD02":tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld)],
                          
                        }}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        setEdit={(e) => {
                          // console.log('header UserField=>>', e);
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={T41Obj?.[h?.S13F02]}
                        copno={seperate(h.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          if (DateToString(e)?.startDate?.length == 8) {
                            setT41Obj({
                              ...T41Obj,
                              [h?.S13F02]: DateToString(e)?.startDate,
                            });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          if (DateToString(e)?.startDate?.length == 8) {
                            setT41Obj({
                              ...T41Obj,
                              [h?.S13F02]: DateToString(e)?.startDate,
                            });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLNumberBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDataGrid
                        width={"500px"}
                        value={{
                          value: T41Obj?.[f?.S13F02],
                          label: accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 == T41Obj?.[f?.S13F02])?.[0]?.[f?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 === T41Obj?.[f?.S13F02])?.[0] || {}) ? "FIELD02":tmList[seperate(f?.S13F09)[1]]?.oS60?.cAssignFld)],                       
                        }}
                        // p0={seprate(f.S13F09)[0]}
                        p1={seperate(f.S13F09)[1]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLComboBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        defaultval={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          if (DateToString(e)?.startDate?.length == 0) {
                            setT41Obj({
                              ...T41Obj,
                              [f?.S13F02]: DateToString(e)?.startDate,
                            });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          if (DateToString(e).startDate?.length == 0) {
                            setT41Obj({
                              ...T41Obj,
                              [f?.S13F02]: DateToString(e).startDate,
                            });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    }
  };

  const handleSaveUser = () => {
    // if (
    //   ((productData?.FIELD51 == "Y" || T02Obj?.FIELD05=="C") && T02Obj?.FIELD41?.trim() == "") ||
    //   T02Obj?.FIELD03 == ""
    // ) {
    //   GlobalClass.Notify(
    //     "warning",
    //     "Validation error",
    //     "Please fill out all the required fields"
    //   );
    //   setToggleButton(true);
    // } else {
      setShowTotalAmount(true);
      Store.dispatch(
        BatchLogAddRemoveFun({
          PageCode: "03450342",
          T02Data: T02Data,
          Row: T02Obj,
          OBJ: obj,
          T41Obj: T41Obj,
        })
      );
      let newArray = [...T02Data];
      newArray[tableEditingRow?.index] = T02Obj;
      let nullFilter = newArray?.filter((d) => d?.FIELD03 != "");
      // setT02Data(nullFilter);
    setT02Data([...nullFilter, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
   
      // setT02Data(newArray);
    //   let nullFilter = newArray?.filter((d) => d?.FIELD03 != "");
    //   // setT02Data(nullFilter);
    // setT02Data([...nullFilter, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
   
    //   // setT02Data(newArray);
      if (obj.p0 == "E") {
        // setT02Obj({...T02?.[T02?.length - 1],ExpAmount:0});
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        // setT02Obj({...T02?.[0],ExpAmount:0})
        setT02Obj(T02?.[0]);
      }
      setToggleButton(false);
      setAddNewDisabled(false);
      table.setEditingRow(null);
      setTableEditingRow(null);
      setCreatingRowEnabled(false);
      setBatchIssueObj(null);
      setLocationObj(null);
      setPriceList(null);
      setRowSelection({ [T02Data?.length]: true });
    // }
  };

  const rowCancelHandler = () => {
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0]);
    }
    setToggleButton(false);
    setAddNewDisabled(false);
    setCreatingRowEnabled(false);
    setBatchIssueObj(null);
    setLocationObj(null);
    setTableEditingRow(null)
    setPriceList(null);
    table.setCreatingRow(null);
    table.setEditingRow(null);
  };

  const openDeleteConfirmModal = (row) => {
    // console.log("openDeleteConfirmModal", row);
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    let EditT02Data = [...T02Data];
    let newArray = EditT02Data.filter((d, i) => i != row.index);
    // setNarrationData(newArray)

    setT02Data(newArray);
    GlobalClass.Notify("success", "Deleted", "Deleted successfully");
  };

  const dispatchFunction = () => {
    let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
    Promise.resolve(dispatch(DataSaveLoading(false)))
      // .then(() => dispatch(ModalDelete(props.index)))
      .then(() => {
        if (obj.p0 == "A") {
          Promise.resolve(
            dispatch(
              TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 })
            )
          ).then(() => {
            // Promise.resolve(
            //   dispatch(
            //     GetAccountList({
            //       id: "T_M32G",
            //       name: "",
            //       type: "A",
            //       p0: "T_M32G",
            //       p1: "T_M32G",
            //       p2: "",
            //       p3: "",
            //     })
            //   )
            // )
            //   .then(() =>
            //     dispatch(
            //       GetTMFormat({
            //         id: "T_M32G",
            //         name: "",
            //         type: "A",
            //         p0: "T_M32G",
            //         p1: "T_M32G",
            //         p2: "",
            //         p3: "",
            //       })
            //     )
            //   )
            //   .then(() =>
            //   dispatch(
            //     GetAccountList({
            //       id: "T_M32C",
            //       name: "",
            //       type: "A",
            //       p0: "T_M32C",
            //       p1: "T_M32C",
            //       p2: "",
            //       p3: "",
            //     })
            //   )
            //   )
            //   .then(() =>
            //     dispatch(
            //       GetTMFormat({
            //         id: "T_M32C",
            //         name: "",
            //         type: "A",
            //         p0: "T_M32C",
            //         p1: "T_M32C",
            //         p2: "",
            //         p3: "",
            //       })
            //     )
            //   );
            // Promise.resolve(
            //   dispatch(
            //     GetAccountList({
            //       id: "T_M32C",
            //       name: "",
            //       type: "A",
            //       p0: "T_M32C",
            //       p1: "T_M32C",
            //       p2: "",
            //       p3: "",
            //     })
            //   )
            // ).then(() =>
            //   dispatch(
            //     GetTMFormat({
            //       id: "T_M32C",
            //       name: "",
            //       type: "A",
            //       p0: "T_M32C",
            //       p1: "T_M32C",
            //       p2: "",
            //       p3: "",
            //     })
            //   )
            // );
          });
        } else {
          dispatch(ModalDelete(props.index));
        }
      })
      .then(() => {
        // Store.dispatch(GetOpeningStockTM()).then(() =>
        //   Store.dispatch(GetOpeningStockGen())
        dispatch(
          GetAccountList({
            id: "03450342",
            name: "Opening Stock",
            p0: "MT",
            p1: "TXNVOULISTHO",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["03450342"],
          })
        );
      });
  };

  const handlePrintButton = () => {
    {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Print Voucher</Text>,
        onClickCloseButton: () => {
          // setDrawerObj(dra);
          Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
            () => {
              dispatchFunction();
            }
          );
        },
        onClose: () => {
          // setDrawerObj(dra);
          Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
            () => {
              dispatchFunction();
            }
          );
        },
        body: (
          <>
            <PLPrint data={obj} />
          </>
        ),
        open: true,
        size: "sm",
        position: "right",
      });
    }
  };

  const handleOkButton = (e) => {
    // console.log("OSVData._ONLINESMS",OSVData._ONLINESMS);

    if (T02Data == T02 && T41 == T41Obj) {
      dispatch(ModalDelete(props.index));
      return;
    }
    // let newData1;
    // for(i=0;i<currentRow?.length();i++){
    // newData1 = T02Data;
    // }
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      obj?.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    var newData = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 == "A" ? "A" : "E",
      cCode: obj?.P0 == "A" ? "HOHO" : T41Obj?.FIELD01,
      cSData: JSON.stringify({
        T41:
          obj.p0 == "U"
            ? e
            : {
                ...T41Obj,
                FIELD06: T41Obj?.FIELD07 == 0 ? rowSum : T41Obj?.FIELD07,
              },
        T02: T02Data,
      }),
    };
console.log("newData",newData);
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 == "A" ? "A" : "E",
      cCode: obj?.P0 == "A" ? "HOHO" : T41Obj?.FIELD01,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    };

    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          GlobalClass.Notify(
            "success",
            obj?.p0 == "A" ? "Added" : "Edited",
            obj?.p0 == "A" ? "Added successfully" : "Edited successfully"
          );
          Promise.resolve(
            OSVData._ONLINESMS == "Y" || OSVData._ONLINESMS == "L"
              ? Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
                  setDrawerObj({
                    ...dra,
                    title: <Text fw={700}>SMS Format</Text>,
                    onClickCloseButton: () => {
                      // setDrawerObj(dra);
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        if (
                          OSVData._ONLINEVOU == "Y" ||
                          OSVData._ONLINEVOU == "D"
                        ) {
                          Promise.resolve(
                            dispatch(DataSaveLoading(false))
                          ).then(() => {
                            setDrawerObj({
                              ...dra,
                              title: <Text fw={700}>Print Voucher</Text>,
                              onClickCloseButton: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(
                                  setDrawerObj({ ...DrawerObj, open: false })
                                ).then(() => {
                                  dispatchFunction();
                                });
                              },
                              onClose: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(
                                  setDrawerObj({ ...DrawerObj, open: false })
                                ).then(() => {
                                  dispatchFunction();
                                });
                              },
                              body: (
                                <>
                                  <PLPrint data={obj} />
                                </>
                              ),
                              open: true,
                              size: "sm",
                              position: "right",
                            });
                          });
                        } else {
                          dispatchFunction();
                        }
                      });
                    },
                    onClose: () => {
                      // setDrawerObj(dra);
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => {
                        if (OSVData._ONLINEVOU == "Y") {
                          Promise.resolve(
                            dispatch(DataSaveLoading(false))
                          ).then(() => {
                            setDrawerObj({
                              ...dra,
                              title: <Text fw={700}>Print Voucher</Text>,
                              onClickCloseButton: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(
                                  setDrawerObj({ ...DrawerObj, open: false })
                                ).then(() => dispatchFunction());
                              },
                              onClose: () => {
                                // setDrawerObj(dra);
                                Promise.resolve(
                                  setDrawerObj({ ...DrawerObj, open: false })
                                ).then(() => dispatchFunction());
                              },
                              body: (
                                <>
                                  <PLPrint data={obj} />
                                </>
                              ),
                              open: true,
                              size: "sm",
                              position: "right",
                            });
                          });
                        } else {
                          dispatchFunction();
                        }
                      });
                    },
                    body: (
                      <>
                        <FormatListTable
                          obj={{ ...obj, p0: "S", text: "SMS" }}
                          index={props.index}
                        />
                      </>
                    ),
                    open: true,
                    size: "sm",
                    position: "right",
                  });
                })
              : Promise.resolve(
                  setDrawerObj({ ...DrawerObj, open: false })
                ).then(() => {
                  if (OSVData._ONLINEVOU == "Y") {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(
                      () => {
                        setDrawerObj({
                          ...dra,
                          title: <Text fw={700}>Print Voucher</Text>,
                          onClickCloseButton: () => {
                            // setDrawerObj(dra);
                            Promise.resolve(
                              setDrawerObj({ ...DrawerObj, open: false })
                            ).then(() => {
                              dispatchFunction();
                            });
                          },
                          onClose: () => {
                            // setDrawerObj(dra);
                            Promise.resolve(
                              setDrawerObj({ ...DrawerObj, open: false })
                            ).then(() => {
                              dispatchFunction();
                            });
                          },
                          body: (
                            <>
                              <PLPrint data={obj} />
                            </>
                          ),
                          open: true,
                          size: "sm",
                          position: "right",
                        });
                      }
                    );
                  } else {
                    dispatchFunction();
                  }
                })
          );

          //
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading(false));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e?.message}`);
        dispatch(DataSaveLoading(false));
      });
  };

  const rowEditHandle = () => {
    let productObjFind = {};
    accList["P_M21"]?.forEach((p, i) => {
      if (p?.FIELD01 == T02Obj?.FIELD03) {
        productObjFind = { ...p };
      }
    });

    // console.log("productObjFind", productObjFind);
    // if (
    //   productObjFind?.FIELD11?.length > 0 ||
    //   productObjFind?.FIELD20?.length > 0
    // ) {
    //   return true;
    // }
    return false;
  };

  const RowDetailHandle = (e) => {
    let productObjFind = {};
    let cStr = "";
    accList["P_M21"]?.forEach((p, i) => {
      if (p?.FIELD01 == e?.FIELD03) {
        productObjFind = { ...p };
      }
    });

    let FUnit = (e?.FIELD05 == 'I' || e?.FIELD05 == 'D') ? (productObjFind?.FIELD06 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD06) : (productObjFind?.FIELD05 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD05);

    // let cStr = cStr + FUnit + "  " + productObjFind.FIELD98 + "   ";

    cStr = cStr + FUnit + "  " + productObjFind?.FIELD98 + "   ";

    if (oYear?.OSC?._DUALSTOCK == "Y" && productObjFind?.FIELD59 == 'Y') {
      let SUnit = productObjFind?.FIELD27 == "" ? oYear?.OSC?._UNIT2 : productObjFind?.FIELD27
      return cStr + SUnit + "  " + productObjFind?.FIELD93
    } else {
      return cStr
    }

  }


  const Summary = (id, i, tag) => {
    // let stype = "sum";
    var value = "";
    if (obj?.p0 == "A") {
      switch (id?.S53F02) {
        case "E000T02F03":
          value = "Total Entry : " + T02Data?.length;
          break;
        default:
          value = null;
          break;
      }
    } else if (obj?.p0 == "E") {
      switch (id?.S53F02) {
        case "E000T02F03":
          value = "Total Entry : " + T02Data?.length;
          break;
        case "E00T02F06":
          value = T02Data?.reduce((a, b) => (a = a + b?.FIELD33), 0);
          break;
        case "E00T02F07":
          value = T02Data?.reduce((a, b) => (a = a + b?.FIELD07), 0);
          break;
        case "E00T02F21":
          value = T02Data?.reduce((a, b) => (a = a + b?.FIELD21), 0);
          break;

        default:
          value = null;
          break;
      }
    }

    return value;
  };

  const renderDynamicVal = (col, val, row, field07) => {
    if (field07 == 0) {
      return "";
    }
    if (col == "E00T02RORI") {
      let ret = "";
      if (val == "C") {
        ret = "R";
      } else if (val == "D") {
        ret = "I";
      }
      return ret;
    } else if (col == "E000T02F03") {
      return T02Data[row?.id]?.F03NM;
    }else if(col == "E00T02F20"){
      return row?.original?.F20NM
    } else {
      return val;
    }
  };

  // console.log("productData",productData);
  // console.log("T02Obj?.FIELD05",T02Obj?.FIELD05)
  const renderValue = (c, row, index, column, cellid, uID, val) => {
    // console.log("C of renderValue",c);
    if (
      c?.S53F02 == "E000T02F03" &&
      obj?.p0 == "E" &&
      rowEditHandle() &&
      row.id !== "mrt-row-create"
    ) {
      return T02Obj?.F03NM;
    }
    if (c?.S53F07 == 0) {
      return (
        <Button
          id={`T02Ent_${index}`}
          variant="default"
          onFocus={() => {
            document.activeElement.blur();
            HiddenButtonField(uID, val, c, cellid, index);
          }}
          onClick={() => HiddenButtonField(uID, val, c, cellid, index)}
          style={{ padding: "0px", margin: "0px", height: "0px", width: "0px" }}
        />
      );
    } else {
      switch (c?.S53F14) {
        case "NUMBOX":
          // console.log("c?.S53F02",c?.S53F02,"index",c?.S53F03);
          // console.log("T02Ent_${index}",`T02Ent_${index}`);
          return (
            <PLNumberBox
            setNumber={(e) => {
              if(oYear?.OSC?._REVSECOND == "Y" && ["E00T02F21","E00T02F22","E00T02F06"].includes(c?.S53F02)){
                // console.log("ReverseCalCulation =>",[{
                //   "oYear?.OSC?._REVSECOND":oYear?.OSC?._REVSECOND,
                //   "e?.target?.value":e?.target?.value
                // }]);
                setTimeout(()=>{
                  new Promise((resolve)=>{
                    let rev = ReverseCalCulation(c, e?.target?.value, T02Obj)
                    resolve(rev)
                  }).then((res)=>{
                    console.log("ReverseCalCulation res",res);
                    setT02Obj(res)
                  })
                },500)
              }
              if (c.S53F02 == "E00T02F06" && (T02Obj?.FIELD05=="D" || T02Obj?.FIELD05=="I")) {
                new Promise((resolve) => {
                  let qt = Qty_CheckStk({ ...ExpenseObj, BatchIssue: BatchIssueObj, ProductAllData: accList["P_M21"] })
                  resolve(qt)
                }).then((res) => {
                  if (res != "OK" && T02Obj?.FIELD33 != 0) {
                    Promise.resolve(NagativeQtyPopUpFun(res)).then(() => NextIndexFun(index - 1))
                    // NagativeQtyPopUpFun(res)
                  }
                })
              }
            }}
              disabled={
                (c?.S53F02 == "E00T02F21" || c?.S53F02 == "E00T02F22") &&
                productData?.FIELD59 == "N"
              }
              id={`T02Ent_${index}`}
              value={T02Obj?.[c?.S53F04]}
              decimalSeparator={
                c?.S53F02 == "E00T02F07" ||
                c?.S53F02 == "E00T02F08" ||
                c?.S53F02 == "E00T02F21" ||
                c?.S53F02 == "E00T02F06" ||
                c?.S53F02 == "E00T02F81" ||
                c?.S53F02 == "E00T02F22"
                  ? true
                  : false
              }
              precision={
                c?.S53F02 == "E00T02F07" ||
                c?.S53F02 == "E00T02F06" ||
                c?.S53F02 == "E00T02F81" ||
                c?.S53F02 == "E00T02F22"
                  ? 3
                  : c?.S53F02 == "E00T02F08" || c?.S53F02 == "E00T02F21"
                  ? 2
                  : ""
              }
              setEdit={(e) => {
                Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e }));
              }}
              onKeyDown={(e) => {
                if((e?.key=="Tab"||e?.key=="Enter") && c.S53F02 == "E00T02F06"){
                  setTimeout(() => {
                    NextIndexFun(index-1);
                    if (oYear?.OSC?._LOCATIONW == "Y") {
                      dummyRef?.current?.handlePopoverToggle()
                    }
                  }, 500)
                }
                // if (e.key == "Tab") {
                //   if (
                //     c?.S53F02 == "E00T02F21" ||
                //     c?.S53F02 == "E00T02F22" ||
                //     c?.S53F02 == "E00T02F07" ||
                //     c?.S53F02 == "E00T02F06" ||
                //     (c?.S53F02 == "E00T02F08" && OSVData?._PRCHPARTY == "Y") ||
                //     (c?.S53F02 == "E00T02F81" && productData?.FIELD51 !== "N")
                //   ) {
                //     NextIndexFun(index - 1);
                //   } else if (
                //     productData?.FIELD51 == "N" ||
                //     (c?.S53F02 == "E00T02F81" && T02Obj?.FIELD05 != "C")
                //   ) {
                //     NextIndexFun(index + 1);
                //   }
                //   else {
                //     NextIndexFun(index);
                //   }
                // }
                //  else
                if (
                  e.key == "Enter" &&
                  c?.S53F02 == "E00T02F08" && //Amount
                  OSVData?._PRCHPARTY == "N"
                ) {
                  creatingRowEnabled
                    ? Promise.resolve(handleCreateUser({ table, row }))
                    // .then(
                    //     () => {
                    //       setTimeout(() => {
                    //         if (
                    //           !(
                    //             ((productData?.FIELD51 == "Y"|| T02Obj?.FIELD05=="C") &&
                    //               T02Obj?.FIELD41?.trim() == "") ||
                    //             T02Obj?.FIELD03 == ""
                    //           )
                    //         ) {
                    //           if (obj.p0 == "E") {
                    //             setT02Obj(T02?.[T02?.length - 1]);
                    //           } else {
                    //             setT02Obj(T02?.[0]);
                    //           }
                    //         }
                    //         setAddNewDisabled(false);
                    //         table.setCreatingRow(true);
                    //         setToggleButton(true);
                    //         // setCreatingRowEnabled(true);
                    //         // inputRef?.current?.handlePopoverToggle();
                    //         // prct?.current?.handlePopoverToggle();
                    //       }, 500);
                    //     }
                    //   )
                    : Promise.resolve( ((productData?.FIELD51 == "Y" ||T02Obj?.FIELD05=="C") && T02Obj?.FIELD41?.trim() == "") ||
                    T02Obj?.FIELD03 == "" || ((T02Obj?.FIELD03 != "" && productData?.FIELD51 != "N") && T02Obj?.FIELD11=="") ? (
                     GlobalClass.Notify(
                       "warning",
                       "Validation error",
                       "Please fill out all the required fields"
                     )) :handleSaveUser({ table, row }))
                        // .then(() => {
                        //   setTimeout(() => {
                        //     if (
                        //       !(
                        //         ((productData?.FIELD51 == "Y" ||T02Obj?.FIELD05=="C") &&
                        //           T02Obj?.FIELD41?.trim() == "") ||
                        //         T02Obj?.FIELD03 == ""
                        //       )
                        //     ) {
                        //       if (obj.p0 == "E") {
                        //         setT02Obj(T02?.[T02?.length - 1]);
                        //       } else {
                        //         setT02Obj(T02?.[0]);
                        //       }
                        //     }
                        //     setAddNewDisabled(false);
                        //     table.setCreatingRow(true);
                        //     setToggleButton(true);
                        //     // setCreatingRowEnabled(true);

                        //     // inputRef?.current?.handlePopoverToggle();
                        //     prct?.current?.handlePopoverToggle();
                        //   }, 500);
                        // });
                }
              }}
            />
          );

        case "TEXTBOX":
          if (c?.S53F02 == "E00USERFLD") {
            return (
              <Button
                // fullWidth

                id={`T02Ent_${index}`}
                style={{
                  height: 0,
                  marginTop: 0,
                  width: "0px",
                  padding: "0px",
                }}
                onFocus={() => {
                  document.activeElement.blur();
                  if (T02UF?.length > 0) {
                    TableUserFieldFun(c?.S53F02, index);
                  } else {
                    NextIndexFun(index);
                  }
                }}
                variant="default"
                onClick={() =>
                  T02UF?.length > 0 ? TableUserFieldFun(c?.S53F02, index) : null
                }
              ></Button>
            );
          } else if (c?.S53F02 == "E00UFBAT") {
            return (
              <Button
                id={`T02Ent_${index}`}
                fullWidth
                style={{ height: "30px" }}
                variant="default"
                disabled={productData?.FIELD51 == "N"}
                //  onFocus={() => { document.getElementById("id" + (cellid + 1)).focus(); TableUserFieldFun() }}
                onClick={() => TableUserFieldFun(c?.S53F02, index)}
              >
                {c?.S53F03}
              </Button>
            );
          } else if (c?.S53F02 == "E00T02F11") {
            return T02Obj?.FIELD03 != "" ? (
              <PLDataGrid
                // autoFocus={true}
                // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                disabled={productData?.FIELD51 == "N"}
                id={`T02Ent_${index}`}
                cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                width={"500px"}
                height="30px"
                value={T02Obj?.[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                // p1={'P_M21'}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 });

                  Promise.resolve(setBatchIssueObj(e)).then(() => {
                    NextIndexFun(index);
                  });
                }}
              />
            ) : (
              <Button
                id={`T02Ent_${index}`}
                fullWidth
                disabled={productData?.FIELD51 == "N"}
                style={{
                  padding: "0px 5px",
                  display: "flex",
                  alignItems: "flex-start",
                  fontWeight: "normal",
                  fontSize: "12px",
                  overflow: "hidden",
                  height: "30px",
                }}
                variant="default"
                onClick={() =>
                  GlobalClass.Notify("warning", "Warning", "Select Any Product")
                }
              >
                Select
              </Button>
            );
            // return (
            //   <PLDataGrid
            //     cWhere={`T11.FIELD08 LIKE '%${FIELD01Value}%'`}
            //     width={"500px"}
            //     value={T02Obj?.[c?.S53F04]}
            //     p1={seperate(c.S53F15)[1]}
            //     // p1={'P_M21'}
            //     setEdit={(e) => {
            //       setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01, F03NM: e?.FIELD02});
            //     }}
            //   />
            // );
          } else if (c?.S53F02 == "E00T02F23") {
            return (
              <PLComboBox
                data={typeof PLDATA === "string" ? [] : PLDATA}
                disabled={typeof PLDATA === "string" ? true : false}
                dispexpr={"FIELD02"}
                valexpr={"FIELD01"}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                value={T02Obj?.[c?.S53F04]}
              />
            );
          } else {
            {console.log("productData?.FIELD51",productData?.FIELD51,"==>",T02Obj?.FIELD05)}
            return (
              <PLTextBox
                id={`T02Ent_${index}`}
                disabled={
                  (c?.S53F02 == "E00T02F41" && productData?.FIELD51 == "N") ||
                  (c?.S53F02 == "E00T02F41" && T02Obj?.FIELD05 !== "C")
                }
                value={T02Obj?.[c?.S53F04]}
                onKeyDown={(e) => {
                  if (e.key == "Tab" || e.key == "Enter") {
                    setTimeout(() => {
                      NextIndexFun(index);
                      if (oYear?.OSC?._LOCATIONW == "Y") {
                        dummyRef?.current?.handlePopoverToggle()
                      }
                    }, 500)
                  }
                }}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                }}
              />
            );
          }
        case "PLPOPUP":
          if (c.S53F02 == "E000T02F03") {
            return (
              <PLDataGrid
                ref={pr}
                id={`T02Ent_${index}`}
                width={"500px"}
                height="30px"
                value={{ value: T02Obj?.[c?.S53F04], label: T02Obj?.F03NM }}
                cWhere={`M21.FIELD11 LIKE '%${
                  T02Obj?.GRPNM ?? ""
                }%' AND M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log("e of product", e);
                  setBatchIssueObj(null);
                  setProductData(e);
                  Promise.resolve(
                    setT02Obj({
                      ...T02Obj,
                      [c?.S53F04]: e?.FIELD01,
                      F03NM: e?.FIELD02,
                    })
                  )
                    .then(() => {
                      setFIELD01value(e.FIELD01);
                    })
                    .then(() => {
                      setTimeout(() => {
                        if (
                          T02Obj?.FIELD03 != "" &&
                          productData?.FIELD51 != "N" &&
                          T02Obj?.FIELD05 == "C"
                        ) {
                          NextIndexFun(index);
                        } else {
                          NextIndexFun(index + 1);
                        }
                      }, 500);
                    });
                }}
              />
            );
          } else {
            return (
              <PLDataGrid
                ref={c?.S53F02 == "E00T02F12" ? purRef : dummyRef}
                id={`T02Ent_${index}`}
                disabled={(c.S53F02 == "E00T02F20" && oYear?.OSC?._LOCATIONW == "Y") ? productData != null ? (productData?.FIELD57 == "N" ? true : false) : false : false}
                width={"450px"}
                height="30px"
                value={
                  c?.S53F02 == "E00T02F20"
                    ? { value: T02Obj?.FIELD20, label: T02Obj?.F20NM }
                    : T02Obj?.[c?.S53F04]
                }
                p1={seperate(c?.S53F15)[1]}
                setEdit={(e) => {
                  if (c?.S53F02 !== "E00T02F20" && c?.S53F02 !== "E00T02F12") {
                    Promise.resolve(
                      setT02Obj({ ...T02Obj, [c?.S53F04]: e.FIELD01 })
                    ).then(() => {
                      NextIndexFun(index);
                    });
                  } else if (c?.S53F02 == "E00T02F20") {
                    // console.log("inside if")
                    Promise.resolve(setLocationObj(e)).then(() => {
                      NextIndexFun(index);
                    });
                  } else if (c?.S53F02 == "E00T02F12") {
                    {
                      // console.log("T02OBJ", T02Obj);
                    }
                    setT02Obj({ ...T02Obj, [c?.S53F04]: e.FIELD01 });
                    setPurchasePartyObj(e);
                  }
                }}
              />
            );
          }
        case "COMBOBOX":
          return (
            <PLComboBox
              cmbid={`T02Ent_${index}`}
              copno={seperate(c?.S53F15)[1]}
              value={T02Obj?.[c?.S53F04]}
              dispexpr="DisplayMember"
              valexpr="ValueMember"
              setEdit={(e) => {
                if (c?.S53F02 == "E00T02RORI" && productData?.FIELD51 != "N") {
                  setBatchIssueObj(null);
                  Promise.resolve(
                    setT02Obj({
                      ...T02Obj,
                      [c?.S53F04]: e.value,
                      FIELD07:
                        e?.value == "C" || e?.value == "D"
                          ? productData?.FIELD21
                          : productData?.FIELD22,
                    })
                  ).then(() => {
                    NextIndexFun(index);
                  });
                } else {
                  Promise.resolve(
                    setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })
                  ).then(() => {
                    NextIndexFun(index);
                  });
                }
              }}
            />
          );
        case "DATEPICKER":
          let { startDate } = DateToString(new Date());
          return (
            <PLDateBox
              setEdit={(e) => {
                let { startDate } = DateToString(e);
                Promise.resolve(() => {
                  if (startDate?.length == 8) {
                    setT02Obj({ ...T02Obj, [c?.S53F04]: startDate });
                  }
                }).then(() => {
                  NextIndexFun(index);
                });
              }}
              defaultval={
                typeof T02Obj?.[c?.S53F04] == "string" &&
                T02Obj?.[c?.S53F04] != "Invalid Date"
                  ? T02Obj?.[c?.S53F04] != ""
                    ? T02Obj?.[c?.S53F04]?.trim()
                    : startDate
                  : startDate
              }
              showClearButton={false}
              showDropDownButton={false}
              useMaskBehavior={true}
              dispformat="DD/MM/YYYY"
            />
          );

          break;

        default:
          return null;
          break;
      }
    }
  };

  const rowSum = T02Data?.reduce((a, b) => {
    if (b?.FIELD05 == "C") a = a + (b?.FIELD08 || 0);
    else if (b?.FIELD05 == "D") a = a - (b?.FIELD08 || 0);
    return a;
  }, 0);

  const NextIndexFun = (index) => {
    let nextInd;
    nextInd = index + 1;
    const nextInput = document.getElementById(`T02Ent_${nextInd}`);

    if (nextInput) {
      nextInput.focus();
    }
  };

  useEffect(() => {
    if (T02Obj?.FIELD21 > 0 && T02Obj?.FIELD22 > 0) {
      let qty = T02Obj?.FIELD21 * T02Obj?.FIELD22;
      setT02Obj((prevObj) => ({ ...prevObj, FIELD33: qty }));
    }
  }, [T02Obj?.FIELD22, T02Obj?.FIELD21, T02Data]);
  useEffect(() => {
    if (T02Obj?.FIELD33 > 0 && T02Obj?.FIELD07 > 0) {
      setT02Obj({ ...T02Obj, FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07 });
    }
  }, [T02Obj?.FIELD33, T02Obj?.FIELD07]);

  useEffect(() => {
    if (T02Obj?.FIELD33) {
      setT02Obj({
        ...T02Obj,
        FIELD21: parseInt(T02Obj?.FIELD33) / parseInt(T02Obj?.FIELD22),
      });
    }
  }, [T02Obj?.FIELD33]);

  // useEffect(())
  useEffect(() => {
    if (creatingRowEnabled == true) {
      setT02Obj({
        ...T02Obj,
        FIELD05: "R,C".includes(OS11?.S11F22) ? "C" : "D",
      });
    }
  }, [OS11?.S11F22, creatingRowEnabled]);
  useEffect(() => {
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01));
    }
  }, [OS11?.S11F01, obj]);
  // console.log("GroupCatogaryProduct?.Group", GroupCatogaryProduct?.Group);
  useEffect(() => {
    if (isLoading == false) {
      let col = [];
      // col.push(
      //   columnHelper.accessor("GRPNM", {
      //     header: "Product Group",
      //     id: "Product_Group",
      //     size: 150,
      //     Edit: ({ cell, column, table, row }) => {
      //       let val = cell.getValue();
      //       // return <><Text>Test</Text></>
      //       // console.log("cell=>", cell,"column=>",column,"table==>",table,"row=>",row);

      //       return obj.p0 == "A" ? (
      //         <PLDataGrid
      //         ref={inputRef}
      //           width={"500px"}
      //           height="30px"
      //           autoFocus={true}
      //           TmData={tmList["T_M32G"]?.cSTID}
      //           data={accList["T_M32G"]}
      //           value={T02Obj?.GRPNM}
      //           setEdit={(e) => {
      //             setCategoryGroup(true);
      //             Promise.resolve(
      //               setT02Obj({ ...T02Obj, GRPNM: e?.FIELD01 })
      //             ).then(() => {
      //               const nextInput = document.getElementById("ID_123");
      //               // console.log("nextInput",nextInput)
      //               if (nextInput) {
      //                 nextInput.focus();
      //               }
      //             });
      //           }}
      //         />
      //       ) : (
      //         T02Obj?.GRPNM
      //       );
      //     },
      //   })
      // );
      col.push(
        columnHelper.accessor("CATCD", {
          header: "Product Category",
          id: "Product_Category",
          size: 150,
          Edit: ({ cell, column, table, row }) => {
            let val = cell.getValue();
            // return <><Text>Test</Text></>
            // console.log("cell?.id==>", cell?.id);
            if (
              obj?.p0 == "E" &&
              row.id !== "mrt-row-create" &&
              rowEditHandle()
            ) {
              return T02Obj?.CATNM;
            } else {
              return (
                <PLDataGrid
                  ref={prct}
                  id={"ID_123"}
                  width={"500px"}
                  height="30px"
                  TmData={"T_M32C"}
                  value={{ value: T02Obj?.CATCD, label: T02Obj?.CATNM }}
                  setEdit={(e) => {
                    setCategoryGroup(true);
                    Promise.resolve(
                      setT02Obj({
                        ...T02Obj,
                        CATCD: e?.FIELD01,
                        CATNM: e?.FIELD02,
                      })
                    ).then(() => {
                      pr?.current?.handlePopoverToggle();
                    });
                  }}
                />
              );
            }
          },
          Cell: ({ cell, row }) => {
            let val = cell.getValue();
            return T02Data[row.id]?.CATNM;
          },
        })
      );
      T02ENT?.filter((item) => item.S53F00 == "C").map((a, i) => {
        col.push(
          columnHelper?.accessor(a?.S53F04, {
            header: a.S53F07 == 0 ? "" : a.S53F03,
            size: a.S53F07 == 0 ? 0 : 150,
            id: a?.S53UID,
            enableColumnFilter: false,
            enableSorting: false,
            enableGrouping: false,
            enableColumnActions: false,
            Edit: ({ cell, column, table, row }) => {
              let val = cell.getValue();
              return renderValue(a, row, i, column, cell?.id, a?.S53UID, val);
            },
            Cell: ({ col, cell, row }) => {
              let val = cell.getValue();
              return renderDynamicVal(a.S53F02, val, row, a?.S53F07);
            },
            Footer: () => (
              <div>
                {a.S53F02 == "E000T02F03"
                  ? `Total Entry :${
                      table?.getSelectedRowModel()?.flatRows.length > 0
                        ? parseInt(
                            table?.getSelectedRowModel()?.flatRows[0]?.index
                          ) +
                          1 +
                          "/"
                        : ""
                    }${T02Data?.length}`
                  : Summary(a, i, "c")}
              </div>
            ),
          })
        );
      });
      setColumns(col);
    }
  }, [
    T02ENT,
    T02Obj,
    T02,
    T02Data,
    tmList,
    accList,
    GroupCatogaryProduct,
    productData,
  ]);

  const table = useMantineReactTable({
    columns: columns ? columns : [],
    data: T02Data ? T02Data : [],
    mantineTableProps:{withColumnBorders:true},
    enableFilters: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    // enableTopToolbar: true,
    enablePagination: true,
    enableEditing: true,
    enableHiding: false,
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    createDisplayMode: "row",
    editDisplayMode: "row",
    onCreatingRowSave: ()=>{ 
      if(((productData?.FIELD51 == "Y" ||T02Obj?.FIELD05=="C") && T02Obj?.FIELD41?.trim() == "") ||
      T02Obj?.FIELD03 == ""){
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      )}
      else{handleCreateUser()}},
    onEditingRowSave:()=>{  if(((productData?.FIELD51 == "Y" ||T02Obj?.FIELD05=="C") && T02Obj?.FIELD41?.trim() == "") ||
    T02Obj?.FIELD03 == "" || ((T02Obj?.FIELD03 != "" && productData?.FIELD51 != "N") && T02Obj?.FIELD11=="")){
   console.log("t02obJ",T02Obj);
   console.log("productData",productData);
    GlobalClass.Notify(
      "warning",
      "Validation error",
      "Please fill out all the required fields"
    )}
   else{handleSaveUser()}},
    onCreatingRowCancel: rowCancelHandler,
    onEditingRowCancel: rowCancelHandler,
    enableMultiRowSelection: false,
    enableSelectAll: false,
    positionToolbarAlertBanner: false,
    // selectDisplayMode:""
    // onRowSelectionChange: setRowSelection,
    mantineTableBodyProps: {
      tabIndex: 0,
      ref: TableRef,
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      ref: TableContainer,
      tabIndex: 0,
      onKeyUp: (e) => {
        handleRowKeyDown(e, row);
      },
      onClick: () => {
        setRowSelection((prev) => {
          // [row.id]: !prev[row.id],
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      // onKeyDown:(e)=>{
      //   if(e.key == "13"){
      //     table.setEditingRow(row);
      //           setT02Obj(row.original);
      //           setToggleButton(true);
      // }
      // // handleRowKeyDown(e, row);
      // },
      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
    }),
    state: {
      density: "1.5px",
      rowSelection,
    },
    enableTopToolbar:false,
    renderRowActions: ({ row, table }) =>
      !toggleButton && (
        <Flex gap="md">
          <Tooltip label="Edit">
            <ActionIcon
              onClick={() => {
                // if(T02Obj.LVALID && obj.p0=="E"){
                //   // GlobalClass.Notify("warning","Bill prepared for this entry, Cannot edit entry")
                // }else{
                table.setEditingRow(row);
                setT02Obj(row.original);
                setToggleButton(true);
                setTableEditingRow(row);
                // }
              }}
            >
              <IconEdit size={"20px"} />
            </ActionIcon>
          </Tooltip>
          { row?.original?.FIELD03 != "" && <Tooltip label="Delete">
            <ActionIcon
              color="red"
              onClick={() => {
                if (T02Obj.LVALID && obj.p0 == "E") {
                  GlobalClass.Notify(
                    "warning",
                    "Bill prepared for this entry, Cannot delete entry"
                  );
                } else {
                  openDeleteConfirmModal();
                }
              }}
            >
              <IconTrash size={"20px"} />
            </ActionIcon>
          </Tooltip>}
          
        </Flex>
      ),
//     renderTopToolbarCustomActions: ({ table,row }) => {
//       // <Button
//       //   disabled={toggleButton || addNewDisabled}
//       //   onFocus={() => {
//       //     if (spoilerControlRef?.current?.innerText == "Hide") {
//       //       spoilerControlRef.current.click();
//       //     }
//       //     table.setCreatingRow(true);
//       //     setToggleButton(true);
//       //     setCreatingRowEnabled(true);
//       //     // inputRef?.current?.handlePopoverToggle();
//       //     prct?.current?.handlePopoverToggle();
//       //     if (obj.p0 == "E") {
//       //       // setT02Obj({...T02?.[T02?.length - 1],ExpAmount:0});
//       //       setT02Obj(T02?.[T02?.length - 1]);
//       //     } else {
//       //       // setT02Obj({...T02?.[0],ExpAmount:0})
//       //       setT02Obj(T02?.[0]);
//       //     }
//       //   }}
//       //   onClick={() => {
//       //     table.setCreatingRow(true);
//       //     setToggleButton(true);
//       //     setCreatingRowEnabled(true);
//       //     // inputRef?.current?.handlePopoverToggle();
//       //     prct?.current?.handlePopoverToggle();
//       //     if (obj.p0 == "E") {
//       //       // setT02Obj({...T02?.[T02?.length - 1],ExpAmount:0});
//       //       setT02Obj(T02?.[T02?.length - 1]);
//       //     } else {
//       //       // setT02Obj({...T02?.[0],ExpAmount:0})
//       //       setT02Obj(T02?.[0]);
//       //     }
//       //   }}
//       //   size="xs"
//       // >
//       //   Add New
//       // </Button>
// return <Button
// disabled={tableEditingRow != null}
// onFocus={() => {
//   if (spoilerControlRef?.current?.innerText == "Hide") {
//     spoilerControlRef.current.click()
//   }
//   table.setCreatingRow(true);
//   setToggleButton(true);
//   (true);
//   setTimeout(() => {
//       Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 })).then(() => {
//         // CategoryRef?.current?.focus()
//         prct?.current?.handlePopoverToggle()
//       })
//   }, 500)
// }}
// onClick={() => {
//   table.setCreatingRow(true);
//           setToggleButton(true);
//           setCreatingRowEnabled(true);
//   setTimeout(() => {
//       Promise.resolve(table.setCreatingRow(true)).then(() => {
//         prct?.current?.handlePopoverToggle()
//       })
//     TableRef?.current?.focus();
//   }, 500)
// }}
// size="xs"
// >
// Add New
// </Button>
//     },
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
              {
                table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "" ? RowDetailHandle(table?.getSelectedRowModel()?.flatRows[0]?.original) : null
              }
            </Text>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group spacing={"xs"} position="center">

              <Button size="xs" variant="subtle" compact onClick={() => {
                if (table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "") {
                  let newT02Data = T02Data?.filter((d) => d?.FIELD03 != "")
                  setT02Data([...newT02Data, table?.getSelectedRowModel()?.flatRows[0]?.original, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
                }
              }}>
                Copy
              </Button>
              <Button size="xs" variant="subtle" compact>
                Serial No
              </Button>
              <Button size="xs" variant="subtle" compact>
                import
              </Button>
            </Group>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>

        </Grid>
      </Box>
    )
  });

  const handleRowKeyDown = (e, row) => {
    console.log("handleRowKeyDown parameter =>", [{
      "e": e,
      "row": row,
      "tableEditingRow": tableEditingRow,
      "TableRef.current": TableRef.current
    }])

    // if (e?.key == "Enter") {
    //   console.log("handleRowKeyDown parameter enter =>", [{
    //     "e": e,
    //     "row": row
    //   }])

    //   if (row?.id == 'mrt-row-create') {
    //     return
    //   } else {
    //     table.setEditingRow(row)
    //     setTableEditingRow(row)
    //     setT02Obj(row?.original)
    //   }
    // }else if(e?.key == "ArrowDown"){
    //     setRowSelection({[(row?.index) + 1]:true})
    // }else if(e?.key == "ArrowUp"){
    //     setRowSelection({[(row?.index) - 1]:true})
    // }



    const tabled = TableRef.current;
    const rows = tabled.querySelectorAll('tr');
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    switch (e?.key) {
      case 'ArrowDown':
        // e.preventDefault();
        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

        } else if (
          table?.getPageCount() && table?.getPageCount() > 1 &&
          pagination?.pageIndex < table?.getPageCount() - 1
        ) {
          // Navigate to the next page
          table.nextPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex + 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case 'ArrowUp':

        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
        } else if (pagination.pageIndex > 0) {
          // Navigate to the previous page
          table.previousPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex - 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case "Enter":
        if (row?.id == 'mrt-row-create') {
          return
        }else if (tableEditingRow) {
          return
        } else {
          table?.setEditingRow(row)
          setTableEditingRow(row)
          setT02Obj(row?.original)
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      { hasError ? <>
        <Text>{ErrorMsg?.message}</Text>
        <Button onClick={() => {
          if (obj) {
              Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => {
                  dispatch(GetAccountList({
                    "id": "P_M21",
                    "name": "",
                    "type": "A",
                    "p0": "P_M21",
                    "p1": "P_M21",
                    "p2": "",
                    "p3": ""
                  }))
              }).then(() => {
                if (obj?.p0 == "U") {
                  dispatch(GetUserFieldObj(OS11?.S11F01))
                }
              })
          }
        }}>Retry</Button>
      </> : SaveLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : isLoading || isUserFieldLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : obj?.p0 == "U" ? (
        <>
          <PLUserFieldBtn
            UserFieldArray={UserField?.UFTbl}
            T41={T41Obj}
            onChange={(e) => {
              handleOkButton();
              // )
            }}
          />
        </>
      ) : (
        <div ref={formRef}>
          <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={true}
            fullScreen={false} //
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
              onClick: (e) => {
                if (typeof DrawerObj?.onClickCloseButton == "function") {
                  DrawerObj?.onClickCloseButton();
                } else {
                  setDrawerObj(dra);
                }
              },
            }}
            title={DrawerObj.title}
            size={DrawerObj.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? "bottom"}
            onClose={
              typeof DrawerObj?.onclose == "function"
                ? DrawerObj?.onclose
                : () => {
                    setDrawerObj(dra);
                  }
            }

            // scrollAreaComponent={ScrollArea.Autosize}
          >
            {DrawerObj.body}
          </Modal>{" "}
          <Paper>
          <Grid gutter={4}>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0",
                marginTop: "10",
                marginBottom: "0",
              }}
            >
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Voucher Date</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <PLDateBox
                    // onKeyDown={(e) => {
                    //   GlobalClass.formNextInput(e, formRef);
                    // }}
                    style={{ margin: "0", padding: "0" }}
                    dispformat="DD/MM/YYYY"
                    defaultval={T41Obj?.FIELD02}
                    setEdit={(e) => {
                      if (DateToString(e)?.startDate?.length == 8) {
                        setT41Obj({
                          ...T41Obj,
                          FIELD02: DateToString(e)?.startDate,
                        });
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={1} style={{ color: "red" }}>
                  {dayVal}
                </Grid.Col>
              </Grid.Col>
              {
                <Grid.Col
                  span={6}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Doc. No.</Text>
                  </Grid.Col>

                  <Grid.Col span={3}>
                    <PLTextBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={T41Obj?.FIELD10}
                      disabled={OSVData?._DOCDATE !== "Y"}
                      style={{ fontSize: 12 }}
                      setEdit={(e) => {
                        setT41Obj({ ...T41Obj, FIELD10: e.target.value });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              }
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0",
                marginTop: "0",
                marginBottom: "0",
              }}
            >
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Voucher No</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  {obj.p0 == "E" ? (
                    <PLTextBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={T41Obj?.FIELD12}
                      // value={T41Obj?.T92F00}
                      setEdit={(e) => {
                        // console.log("setEdit=>", e);
                        // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                        setT41Obj({
                          ...T41Obj,
                          T92F00: "",
                          FIELD12: e.target.value,
                        });
                      }}
                    />
                  ) : T92 ? (
                    <>
                      {BillSeriesHideShow?.DataGridInput == true && (
                        <>
                          {!T41Obj?.T92F00 ? (
                            <PLDataGrid
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              width={"500px"}
                              position={"unset"}
                              valexpr={"FIELD00"}
                              // width={350}
                              value={BillSeriesHideShow?.BillNoVal}
                              TmData={T92}
                              data={T92DT}
                              setEdit={(e) => {
                                // console.log("Bill No.=>", e);
                                // Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD01 })).then(()=>{
                                //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                // })
                                //"FIELD00"
                                Promise.resolve(
                                  setT41Obj({
                                    ...T41Obj,
                                    T92F00: e.FIELD00,
                                    FIELD12: "",
                                  })
                                ).then(() => {
                                  if (e?.FIELD02) {
                                    let letr = e.FIELD02;
                                    let num = parseInt(e.FIELD03) + 1;
                                    setBillSeriesHideShow({
                                      ...BillSeriesHideShow,
                                      DataGridInput: false,
                                      TextInput: true,
                                      BillNoVal: letr + num,
                                    });
                                  }
                                });
                              }}
                            />
                          ) : (
                            <PLTextBox
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              value={T41Obj?.FIELD12}
                              // value={T41Obj?.T92F00}
                              setEdit={(e) => {
                                // console.log("setEdit=>", e);
                                // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                setT41Obj({
                                  ...T41Obj,
                                  T92F00: "",
                                  FIELD12: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      )}
                      {BillSeriesHideShow?.TextInput == true && (
                        <PLTextBox
                          // setText={() => {
                          //   setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                          // }}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          value={BillSeriesHideShow?.BillNoVal}
                          // value={T41Obj?.T92F00}
                          setEdit={(e) => {
                            // console.log("setEdit=>", e);
                            setBillSeriesHideShow({
                              ...BillSeriesHideShow,
                              BillNoVal: e.target.value,
                            });
                            setT41Obj({
                              ...T41Obj,
                              T92F00: "",
                              FIELD12: e.target.value,
                            });
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <PLTextBox
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      disabled={true}
                      placeholder={"Select"}
                    />
                  )}
                </Grid.Col>
              </Grid.Col>
              {
                <Grid.Col
                  span={6}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Doc. Date</Text>
                  </Grid.Col>

                  <Grid.Col span={4}>
                    {T41Obj?.FIELD11.trim() == "" ? (
                      <PLDateBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        disabled={OSVData?._DOCDATE !== "Y"}
                        style={{ margin: "0", padding: "0" }}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          if (DateToString(e)?.startDate?.length == 8) {
                            setT41Obj({
                              ...T41Obj,
                              FIELD11: DateToString(e)?.startDate,
                            });
                          }
                        }}
                        isVal={false}
                        placeholder={""}
                      />
                    ) : (
                      <PLDateBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        disabled={OSVData?._DOCDATE !== "Y"}
                        style={{ margin: "0", padding: "0" }}
                        dispformat="DD/MM/YYYY"
                        defaultval={T41Obj?.FIELD11}
                        setEdit={(e) => {
                          if (DateToString(e)?.startDate?.length == 8) {
                            {
                              setT41Obj({
                                ...T41Obj,
                                FIELD11: DateToString(e)?.startDate,
                              });
                            }
                          }
                        }}
                      />
                    )}
                  </Grid.Col>
                </Grid.Col>
              }
            </Grid.Col>
            <Grid.Col span={12}>
              {userFieldHideShow == "Y" ? (
                <>
                  {
                                  UFT41H && UFT41H?.length > 0 ?
                                    <Divider
                                      my="xs"
                                      label={<Button
                                        variant="subtle"
                                        color="dark"
                                        size="xs"
                                        compact
                                        onFocus={() => {
                                          if (UFT41H?.filter((af) => af.S13HMF == "H")?.length > 0) {
                                            spoilerControlRef.current.click()
                                          }
                                        }}
                                      >User Fields</Button>}
                                      labelPosition="center"
                                      labelProps={{ component: "strong" }}
                                    /> : null
                                }
                  <Spoiler
                    maxHeight={80}
                    showLabel="Show more"
                    hideLabel="Hide"
                    controlRef={spoilerControlRef}
                  >
                    <Grid
                      gutter={4}
                      mb={5}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                    >
                      {UFT41HRenderField("Header")}
                    </Grid>
                  </Spoiler>
                </>
              ) : (
                ""
              )}
              <Divider
                          my="xs"
                          label={<Button
                            variant="subtle"
                            color="dark"
                            size="xs"
                            compact
                            onFocus={() => {
                              TableRef.current?.focus();
                              if (UFT41H?.filter((af) => af.S13HMF == "H")?.length > 0) {
                                spoilerControlRef.current.click()
                              }

                              // document?.activeElement?.blur()
                              TableContainer.current.focus();

                        
                                  setRowSelection({ 0: true })
                            }}
                          >Products</Button>}
                          labelPosition="center"
                          labelProps={{ component: "strong" }}
                        />
            </Grid.Col>
          </Grid>
          <Grid
            gutter={4}
          >
            <MantineReactTable table={table} />
          </Grid>
          <Grid>
            <Grid.Col
              span={12}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, formRef);
              }}
            >
              {userFieldHideShow == "Y" && UFT41HRenderField("Footer")}
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col
              span={12}
              style={{ display: "flex", flexDirection: "row" }}
            ></Grid.Col>
            <Grid.Col span={3}>
              <Text style={{ marginLeft: "5px" }}>Narration</Text>
            </Grid.Col>
            {showTotalAmount == true || obj?.p0 == "E" ? (
              <Grid.Col
                span={9}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid.Col span={7}></Grid.Col>
                <Grid.Col span={3}>
                  <Text>Total Amount ₹</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  {rowSum} {OS11 ? (OS11["S11F21"] == "D" ? "CR" : "DB") : ""}
                </Grid.Col>
              </Grid.Col>
            ) : (
              ""
            )}
          </Grid>
          <Grid gutter={4}>
            <Grid.Col
              span={10}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Textarea
                  style={{ width: "350px", marginleft: "0" }}
                  value={T41Obj?.FIELD15}
                  onChange={(e) => {
                    setT41Obj({ ...T41Obj, FIELD15: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.ctrlKey && e.key === "F1") {
                      setDrawerObj({
                        ...dra,
                        title: <Text fw={700}>Naration</Text>,
                        body: (
                          <>
                            <PLNarration
                              // NarrationVal={T41Obj}
                              // NarrationValObj={(e) => {
                              //     setT41Obj(e)
                              // }}
                              NarrationArray={NarrationData}
                              Flag={
                                SelectedNarrationData?.length > 0 ? false : true
                              }
                              selectedNarrationArray={SelectedNarrationData}
                              // NarrationVal={T41Obj}
                              setNarrationArray={(e) => {
                                // setT41Obj(e)
                                setNarrationData(e.NarrationData);
                                setSelectedNarrationData(e.selectedNarration);
                              }}
                              DrawerOBJ={DrawerObj}
                              setDrawerOBJ={(e) => {
                                setDrawerObj(e);
                              }}
                            />
                          </>
                        ),
                        open: true,
                        size: "50%",
                        position: "right",
                      });
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={5} style={{ marginLeft: "380px" }}>
              <Group spacing={"xs"} position="center">
                <Button
                  style={{ height: "30px", width: "100px" }} size="sm"
                  onClick={handleOkButton}
                  disabled={toggleButton == true || T02Data?.length < 1}
                >
                  Ok
                </Button>
                <Button
                 style={{ height: "30px", width: "100px" }} size="sm"
                  onClick={handlePrintButton}
                >
                  Print
                </Button>
              </Group>
              </Grid.Col>
            </Grid.Col>
          </Grid>
          </Paper>
          {lEditVou == false && <> {GlobalClass.Alert("info", props.index)}</>}
        </div>
      )}
    </>
  );
};

export default OpeningStockAddForm;
