import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";
// import { notifications } from "@mantine/notifications";
// import { modals } from "@mantine/modals";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const ECommerceOperatorApi = createAsyncThunk(
    'ECommerceOperator/ECommerceOperatorApi',
    async (OBJ) => {
        // console.log("ECommerceOperatorApi", OBJ);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0,
                    "cCode": OBJ.p0 === "A" ? "" : OBJ.G02F01,
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetG02Ent + "?pa=" + param);
            // console.log(response.data.data, "ECommerceOperatorApi response");
            return response.data.data;
        }
        catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }

)

export const ECommerceOperatorDeleteApi = createAsyncThunk(
    'ECommerceOperator/ECommerceOperatorDeleteApi',
    async (OBJ) => {
        GlobalClass.Notify(
            "info",
            "Deleting",
            "Please wait while we process your data"
          );
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0,
                    "cCode": OBJ.G02F01,
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetG02Ent + "?pa=" + param);
            // console.log("ECommerceOperatorApi response", response);

            if (response.data.status === 'SUCCESS') {
               
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                Store.dispatch(GetAccountList({
                    id: "14950123",
                    name: "E-Commerce Operator",
                    p0: "MT",
                    p1: "T_G02",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["14950123"]

                }))

            }
            else if (response.data.status === 'FAIL') {
                GlobalClass.Notify(
                    "error",
                    response?.data?.status,
                    response?.data?.message
                  );
            }
        }
        catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
          }
    }

)

const ECommerceOperator = createSlice({
    name: 'ECommerceOperator',
    initialState: {
        ECommerceOperatorData: [],
        isLoading: false,
        hasError: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(ECommerceOperatorApi.pending, (state, action) => {
                // console.log("pending");
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(ECommerceOperatorApi.fulfilled, (state, action) => {
                // console.log("state", state);
                // console.log("action", action);
                state.ECommerceOperatorData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(ECommerceOperatorApi.rejected, (state, action) => {
                // console.log("rejected");
                state.hasError = true
                state.isLoading = false;
            })
    }
})

export default ECommerceOperator.reducer;