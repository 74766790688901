import { Button, Grid, Group, Text } from '@mantine/core'
import React, { useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';

const MasterFilterForm = () => {

    const [MFields,setMFields]=useState({
        "ProductGroup":"",
        "ProductCategory":"",
        "Product":"",
        "AccountGroup":"",
        "AccountArea":"",
        "AccountCity":"",
        "Account":""
    })


    const handleInput = (e) => {
        let inputField;
        switch (e) {
            case "product":
                inputField = (<>
                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Group Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLComboBox
                                value={MFields?.ProductGroup}
                                copno={"AS"}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log("MFields?.ProductGroup",e);
                                    setMFields({...MFields,ProductGroup:e?.value})
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Category Name</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLComboBox
                                value={MFields?.ProductCategory}
                                copno={"AS"}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log("MFields?.ProductCategory",e);
                                    setMFields({...MFields,ProductCategory:e?.value})
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={6} style={{ display: "flex", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Product</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <PLComboBox
                                value={MFields?.Product}
                                copno={"AS"}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log("MFields?.Product",e);
                                    setMFields({...MFields,Product:e?.value})
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                </>)
                break;

            default:
                break;
        }

        return inputField

    }

    return (<>
        <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    handleInput("product")
                }
            </Grid.Col>
            <Grid.Col span={12}>
                <Group spacing={"xs"} position="center">
                    <Button style={{ height: "30px", width: "100px" }} size="sm">Ok</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </>)
}

export default MasterFilterForm