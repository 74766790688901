import React, { useEffect, useRef, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import DataGrid from '../../components/DataGrid'
import { useDispatch, useSelector } from 'react-redux'
import { ProcessMasterData } from '../../utils/slices/ProcessMasterSlice'
import { ActionIcon, Button, Drawer, Flex, Grid, Group, Modal, Text, Tooltip } from '@mantine/core'
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { modals } from '@mantine/modals'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { notifications } from '@mantine/notifications'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import GlobalClass from '../../utils/GlobalClass'
import gensetting from '../../utils/gensetting'
// import { ProcessMasterTable } from './ProcessMasterTable'

export const ProcessMasterTable = (props) => {

    const { obj } = props;

    // console.log("M73 props", props);

    const [columns, setColumns] = useState([]);
    const columnHelper = createColumnHelper();
    // const [rowSelection, setRowSelection] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [M73, setM73] = useState([])
    const [M73Grid, setM73Grid] = useState([])
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const [rowSelection, setRowSelection] = useState({});

    const ProductRef = useRef(null);
    const CategoryRef = useRef(null);
    const ProcessTypeRef = useRef(null)

    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
        onClickCloseBtn: ""
    }

    const [DrawerObj, setDrawerObj] = useState(dra);

    const [newObj, setNewObj] = useState({
        "FIELD01": null,
        "FIELD02": null,
        "FIELD03": null,
        "FIELD04": null,
        "FIELD05": null,
        "FIELD06": null,
        "FIELD07": null,
        "FIELD08": null,
        "FIELD09": null,
        "FIELD26": null,
        "FIELD27": null,
        "FIELD00": null,
        "FLDUNQ": null,
        "FLDAED": null,
        "FLDBRC": null,
        "EGKEYID": 1
    })

    const [processName, setProcessName] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [ProcessSaveFlag, setProcessSaveFlag] = useState(false)

    const ProcessMData = useSelector((state) => state.ProcessMaster.ProcessMData)
    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)


    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(ProcessMasterData({ action: obj?.p0, code: obj?.p0 == 'E' ? obj?.id : "" }))
    // }, [])

    useEffect(() => {
        if (ProcessMData) {
            setM73(ProcessMData?.M73?.filter((t) => t?.FIELD04 != "" && t?.FIELD04 != null))
            // setNewObj(ProcessMData?.M73[0])
        }
    }, [ProcessMData])


    const RenderValue = (col, val) => {
        let cVal = '';
        switch (col) {
            case "Process Type":
                cVal = (val == 'R' ? 'Raw Material' :
                    val == 'P' ? 'Product' :
                        val == 'S' ? 'Semi Finished' :
                            val == 'W' ? 'Wastage' :
                                val == 'B' ? 'Bi Product' :
                                    val == 'C' ? 'Packing' :
                                        val)
                break;
            case "I/O":
                cVal = (val == 'I' ? 'Input' :
                    val == 'O' ? 'Output' :
                        val)
                break;
            case "Product Name":
                break;
            default:
                cVal = val
                break;
        }
        return cVal;

    }

    // const rendercolcell = (val) => {
    //     return (
    //         <p
    //             style={{
    //                 fontSize: 10,
    //                 // fontFamily:"",
    //                 color: "black",
    //                 backgroundColor: 'white',
    //             }}
    //         >
    //             {val}
    //         </p>
    //     )
    // }


    const seprate = (e) => {
        var paraArr = e.split('~C~');
        return paraArr
    }

    // useEffect(() => {
    //     if (props.M73) {
    //         setM73(props.M73)
    //     }
    //     // console.log("M73Data===>", M73);
    // }, [props.M73])


    useEffect(() => {
        if (newObj?.FIELD26 != "" && newObj?.FIELD27 != "") {
            setNewObj({ ...newObj, FIELD05: newObj?.FIELD26 * newObj?.FIELD27 })
        }
    }, [newObj?.FIELD06, newObj?.FIELD26, newObj?.FIELD27])

    useEffect(() => {
        if (newObj?.FIELD05 != "" && newObj?.FIELD09 != "") {
            setNewObj({ ...newObj, FIELD06: newObj?.FIELD05 * newObj?.FIELD09 })
        }
    }, [newObj?.FIELD06, newObj?.FIELD05, newObj?.FIELD09])

    const NextIndexFun = (index) => {
        const nextInd = index + 1;
        // console.log("nextInd=>", nextInd)
        const nextInput = document.getElementById(`T02Ent_${nextInd}`)
        console.log("nextInd & nextInput =>", [{
            nextInd: nextInd,
            nextInput: nextInput
        }])

        if (nextInput) {
            nextInput.focus()
        }

    }

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        // console.log("paraArr=>", paraArr);
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const SelectProcessFun = () => {
        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Select Process</Text>,
            onClose: () => {
                setDrawerObj({ ...DrawerObj, open: false })
                // setDrawerObj(dra);
                //   Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
                <Grid>
                    <Grid.Col span={12}>
                        <PLDataGrid
                            autoFocus={true}
                            // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                            // id={`M73Ent_${index}`}
                            width={"500px"}
                            value={newObj?.FIELD02}
                            p1={"T_M71"}
                            setEdit={(e) => {
                                console.log("Batch Issue Object=>", e);
                                // setNewObj({ ...newObj, FIELD02: e?.FIELD01 })
                                setProcessName(e)
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="filled"
                            size="sm"
                            onClick={() => {
                                setDrawerObj({ ...DrawerObj, open: false })
                                //   Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                            }}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    setDrawerObj({ ...DrawerObj, open: false })
                                    // Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                }
                            }}
                        >Ok</Button>
                    </Grid.Col>
                </Grid>
            </>,
            open: true,
            size: "md",
            position: "right"
        })
    }

    const RenderInputBox = (c, index) => {
        let InputBox;
        switch (c?.S53F14) {
            case "COMBOBOX":
                if (c?.S53F02 == "RID0036") { // Process Type
                    InputBox = <PLComboBox
                        autoFocus
                        // open
                        // ref={ProcessTypeRef}
                        cmbid={`M73Ent_${index}`}
                        // value={newObj.FIELD08 ?? val}
                        value={newObj?.[c?.S53F04]}
                        // defaultValue={val}
                        data={ProcessMData?.COP17}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        setEdit={(e) => {
                            console.log("newObj.FIELD08 Process Type =>", e);
                            Promise.resolve(setNewObj({ ...newObj, FIELD08: e, FIELD07: ((e == "R" || e == "S") ? "I" : "O") })).then(() => {
                                setTimeout(() => {
                                    CategoryRef?.current?.handlePopoverToggle()
                                },500)

                                // NextIndexFun(index)
                            })
                        }}
                    />

                } else {
                    InputBox = <PLComboBox
                        id={`M73Ent_${index}`}
                        disabled={c?.S53F02 == "RID0038" ? true : false}
                        value={newObj?.[c?.S53F04]}
                        copno={seperate(c.S53F15)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                            Promise.resolve(setNewObj({ ...newObj, [c?.S53F04]: e.value })).then(() => NextIndexFun(index))
                            // setT01Obj({ ...T01Obj, [c?.S53F04]: e.value });
                        }}
                    />
                }
                break;
            case "PLPOPUP":
                if (c?.S53F02 == "RID0037") { // Product Name
                    InputBox = <PLDataGrid
                        ref={ProductRef}
                        id={`M73Ent_${index}`}
                        width={"500px"}
                        cWhere={`M21.FIELD12 LIKE '%${newObj?.CATCD ?? ""}%'`}
                        // value={newObj.FIELD04 ?? val}
                        value={{ value: newObj?.FIELD04, label: newObj?.F04NM }}
                        // value={newObj?.[c?.S53F04]}
                        p1={seperate(c.S53F15)[1]}
                        setEdit={(e) => {
                            // setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01 });

                            // setSelectedProduct

                            // setNewObj({ ...newObj, FIELD04: e.FIELD01, F04NM: e?.FIELD02, FIELD09: (newObj?.FIELD07 == "I" ? e?.FIELD21 : e?.FIELD22) })

                            Promise.resolve(setNewObj({ ...newObj, FIELD04: e.FIELD01, F04NM: e?.FIELD02, FIELD09: (newObj?.FIELD07 == "I" ? e?.FIELD21 : e?.FIELD22) })).then(() => setSelectedProduct(e)).then(() => {
                                setTimeout(() => {
                                    if (newObj?.FIELD08 == "S") {
                                        SelectProcessFun()
                                    } else {
                                        NextIndexFun(index + 1)
                                    }
                                }, 500)
                            })
                        }}
                    />

                } else {
                    InputBox = <PLDataGrid
                        // ref={}
                        id={`M73Ent_${index}`}
                        width={"500px"}
                        value={newObj?.[c?.S53F04]}
                        p1={seperate(c.S53F15)[1]}
                        setEdit={(e) => {
                            // setT01Obj({ ...T01Obj, [c?.S53F04]: e?.FIELD01 });

                            Promise.resolve(setNewObj({ ...newObj, [c?.S53F04]: e?.FIELD01 })).then(() => NextIndexFun(index))

                        }}
                    />

                }
                break;
            case "NUMBOX":
                InputBox = <PLNumberBox
                    decimalSeparator={true}
                    precision={c?.S53F02 == "RID0039" || c?.S53F02 == "RID0040" ? 3 : 2}
                    // precision={3}
                    onKeyDown={(e) => {
                        if (e.key == "Tab") {
                            // console.log("onKeyDown=>", e);
                            NextIndexFun(index - 1)
                        }

                        if (e?.key == "Enter" && c?.S53F02 == "RID0041") {
                            if (tableEditingRow == null) {
                                // handleCreateUser()
                                Promise.resolve(handleCreateUser()).then(() => setTimeout(() => {
                                    table.setCreatingRow(true)
                                }, 500))
                            } else {
                                // handleSaveUser()
                                Promise.resolve(handleSaveUser()).then(() => setTimeout(() => {
                                    table.setCreatingRow(true)
                                }, 500))
                            }
                        }
                    }}
                    id={`M73Ent_${index}`}
                    value={newObj?.[c?.S53F04]}
                    setEdit={(e) => {
                        setNewObj({ ...newObj, [c?.S53F04]: e });
                    }}
                />
                break;
            case "TEXTBOX":
                InputBox = <PLTextBox
                    onKeyDown={(e) => {
                        if (e.key == "Tab") {
                            console.log("onKeyDown=>", e);
                            NextIndexFun(index - 1)
                        }
                    }}
                    id={`M73Ent_${index}`}
                    value={newObj?.[c?.S53F04]}
                    setEdit={(e) => {
                        setNewObj({ ...newObj, [c?.S53F04]: e.target.value });
                    }}
                />
                break;
            default:
                break;
        }
        return InputBox
    }

    useEffect(() => {
        // console.log("useEffect is calling");
        // console.log('ProcessMData==>', ProcessMData);
        if (ProcessMData?.M73Grd) {
            var cols = [];
            var CategoryCol = [];

            CategoryCol.push(
                columnHelper.accessor("CATCD", {
                    header: "Product Category",
                    id: "Product_Category",
                    size: 150,
                    Edit: ({ cell, row }) => {
                        let val = cell.getValue();
                        // console.log("Cell =>>", cell);
                        // return <><Text>Test</Text></>
                        // console.log("cell?.id==>", cell?.id);

                        return <PLDataGrid
                            ref={CategoryRef}
                            // autoFocus={true}
                            width={"500px"}
                            TmData={"T_M32C"}
                            value={{ value: newObj?.CATCD, label: newObj?.CATNM }}
                            // data={accList["T_M32C"]}
                            // value={T02Obj?.CATNM}
                            setEdit={(e) => {
                                console.log("Product Category Obj=>", e);
                                // setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                                Promise.resolve(setNewObj({ ...newObj, CATCD: e?.FIELD01, CATNM: e?.FIELD02 })).then(() => {
                                    // setTimeout(() => {
                                    //     NextIndexFun(0)
                                    // }, 1000)
                                    // console.log("ProcessTypeRef?.current =>", ProcessTypeRef);
                                    ProductRef?.current?.handlePopoverToggle()
                                })



                                // Promise.resolve(setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })).then(()=>setT02Obj({ ...T02Obj, CATNM: e?.FIELD01 })).then(()=>document.getElementById(`T02Ent_${1}`))
                            }}
                        />

                        // return <Button
                        //   id={`T02Ent_${100}`}
                        //   variant="default"
                        //   onFocus={() => {
                        //     ProductCategoryDrawer()
                        //   }}
                        //   onClick={() => ProductCategoryDrawer()}
                        //   style={{ padding: "0px", margin: "0px" }}
                        // >Product Category</Button>
                    },
                    Cell: ({ cell, row }) => {
                        let val = cell.getValue();
                        return M73[row.id]?.CATNM

                    },
                })
            )
            ProcessMData?.M73Grd.map((v, i) => {
                cols.push(
                    columnHelper.accessor(v.S53F04, {
                        header: v.S53F03,
                        size: 150,
                        Edit: ({ cell, column, table, row }) => {
                            var val = cell.getValue();
                            // console.log("value", val);
                            return RenderInputBox(v, i)
                        },
                        Cell: ({ cell, column, table, row }) => {
                            var val = cell.getValue();
                            // console.log("rendervalue",RenderValue(v.S53F03, val))
                            if (v.S53F03 == "Product Name") { // Location
                                return M73[row.id]?.F04NM
                            } else {
                                return RenderValue(v.S53F03, val)
                            }
                        }
                    })
                )

            })
            const updateArr = [...cols]
            updateArr.splice(2, 0, CategoryCol[0])
            console.log("cols =>", [{
                cols: cols,
                CategoryCol: CategoryCol,
                updateArr: updateArr
            }]);
            setColumns(updateArr)
        }
    }, [ProcessMData?.M73Grd, newObj, selectedProduct])


    useEffect(() => {
        if (newObj?.FIELD08 && selectedProduct != null) {
            setNewObj({
                ...newObj,
                FIELD04: selectedProduct?.FIELD01,
                F04NM: selectedProduct?.FIELD02,
                FIELD09: (newObj?.FIELD07 == "I" ? selectedProduct?.FIELD21 : selectedProduct?.FIELD22)
            })
        }
    }, [newObj?.FIELD08])

    useEffect(() => {
        if (ProcessSaveFlag) {
            console.log("ProcessSaveFlag =>", ProcessSaveFlag)
            Promise.resolve(props.getM73(M73)).then(() => setProcessSaveFlag(false))
        }
    }, [ProcessSaveFlag])


    // useEffect(() => {
    //     console.log("set newrow useeffect 1", rowSelection);
    //     // Object.values(selectrow).some(value => value === true)
    //     if (Object.values(rowSelection).some(value => value === true)) {
    //         console.log("set newrow useeffect 2", rowSelection);
    //         M73?.map((t, i) => {
    //             if (i in rowSelection) {
    //                 document.addEventListener("keydown", (e) => {
    //                     console.log("set newrow useeffect 3", rowSelection);
    //                     if (e?.key == "Enter") {
    //                         console.log("set newrow useeffect 4", rowSelection);
    //                         Promise.resolve().then(() => {
    //                             table.setEditingRow(table?.getSelectedRowModel()?.flatRows[0])
    //                             setNewObj(t)
    //                             setTableEditingRow(table?.getSelectedRowModel()?.flatRows[0])
    //                             // setRowSelection({0:false})
    //                         })
    //                     }
    //                 })
    //             }
    //         })
    //     }
    // }, [M73, rowSelection])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e?.key == "Escape") {
                if (tableEditingRow == null) {
                    console.log("document.addEventListener =>", e);
                    table.setCreatingRow(null)
                } else {
                    console.log("document.addEventListener =>", e);
                    table.setEditingRow(null);
                    setTableEditingRow(null)
                }
            }
        })
    }, [tableEditingRow])

    //CREATE action
    const handleCreateUser = async () => {
        // console.log('newObj==>', newObj);

        setM73([...M73, newObj])
        // setValidationErrors({});
        // exitCreatingMode();
        table.setCreatingRow(null)
        setNewObj({
            "FIELD01": null,
            "FIELD02": null,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": null,
            "FIELD06": null,
            "FIELD07": null,
            "FIELD08": null,
            "FIELD09": null,
            "FIELD26": null,
            "FIELD27": null,
            "FIELD00": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1
        })
        setSelectedProduct(null)
        setProcessSaveFlag(true)
        setRowSelection({})
    };

    //UPDATE action
    const handleSaveUser = async () => {
        // console.log('handleSaveUser edit=>', row);
        new Promise((resolve, reject) => {
            var tempM73 = [...M73];
            tempM73[tableEditingRow?.index] = newObj;
            resolve(setM73(tempM73))
        }).then(() => {
            table.setEditingRow(null); //exit editing mode
            setTableEditingRow(null)
            setNewObj({
                "FIELD01": null,
                "FIELD02": null,
                "FIELD03": null,
                "FIELD04": null,
                "FIELD05": null,
                "FIELD06": null,
                "FIELD07": null,
                "FIELD08": null,
                "FIELD09": null,
                "FIELD26": null,
                "FIELD27": null,
                "FIELD00": null,
                "FLDUNQ": null,
                "FLDAED": null,
                "FLDBRC": null,
                "EGKEYID": 1
            })
            setSelectedProduct(null)
            setProcessSaveFlag(true)
            setRowSelection({})
        })
        // setM73(tempM73);

    };

    //DELETE action
    const openDeleteConfirmModal = (row) => {
        // modals.openConfirmModal({
        //     title: 'Are you sure you want to delete this user?',
        //     zIndex: 401,
        //     children: (
        //         <Text>
        //             Are you sure you want to delete? This action cannot be undone.
        //         </Text>
        //     ),
        //     labels: { confirm: 'Delete', cancel: 'Cancel' },
        //     confirmProps: { color: 'red' },
        //     onConfirm: () => {
        //         // console.log("openDeleteConfirmModal==>", row);
        //         const newArr = M73.filter((object, i) => {
        //             // console.log('M73.filter i', i);
        //             // console.log('M73.filter row.index', row.index);

        //             return i !== row.index;
        //         });
        //         // console.log('newArr', newArr);
        //         setM73(newArr)
        //     },
        // });


        if (window.confirm('Confirm to Delete ?')) {
            let newArray = M73?.filter((d, i) => i != row?.index)
            setM73(newArray)
            setRowSelection({})
        }
    }

    const handleNullT41Obj = () => {
        setNewObj({
            "FIELD01": null,
            "FIELD02": null,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": null,
            "FIELD06": null,
            "FIELD07": null,
            "FIELD08": null,
            "FIELD09": null,
            "FIELD26": null,
            "FIELD27": null,
            "FIELD00": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1
        })
    };

    // console.log("newObj=>",newObj);

    const table = useMantineReactTable({
        data: M73 ? M73 : [],
        columns,
        // enableRowSelection: false,
        positionToolbarAlertBanner: "none",
        enableColumnOrdering: false,
        enableGlobalFilter: false,
        enableGrouping: true,
        enableDensityToggle: false,
        // enableColumnResizing:true,
        enableColumnDragging: false,
        // defaultColumn: { minSize: 40, maxSize: 150, size: 10 },
        enableEditing: true,
        editDisplayMode: 'row',
        createDisplayMode: 'row',
        getCoreRowModel: getCoreRowModel(),
        enableFullScreenToggle: false,
        enableColumnActions: false,
        enableFilters: false,
        enableHiding: false,
        mantineTableContainerProps: { sx: { height: '300px' } },
        enableSelectAll: false,
        enableMultiRowSelection: false,
        enableTableFooter: false,
        enableTopToolbar: true,
        mantineTableProps:{withColumnBorders:true},
        initialState: {
            density: 'xs',
            expanded: true,
            columnVisibility: { EGKEYID: false, FIELD02: false }
        },

        state: {
            // grouping,
            rowSelection,
            density: "0px",
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            tabIndex: 0,
            onKeyUp: (e) => handleRowKeyDown(e, row),
            onClick: () => {
                // console.log("Selected Row==>", row);
                //   setSelectedRow(row.original);
                // setRowSelection((prev) => ({
                //     [row.id]: !prev[row.id],
                // }));

                setRowSelection((prev) => {
                    // [row.id]: !prev[row.id],
                    if (!prev[row.id]) {
                        return { [row.id]: true }
                    }
                    return prev
                })
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
        onCreatingRowCancel: handleNullT41Obj,
        onEditingRowCancel: handleNullT41Obj,
        onCreatingRowSave: ({ exitCreatingMode }) => {
            if ((newObj?.FIELD08 == null || newObj?.FIELD08 == "") && (newObj?.FIELD04 == null || newObj?.FIELD04 == "")) {
                GlobalClass.Notify("warning", "Warning:Empty Field", "Some Field Require to Enter")
            } else {
                handleCreateUser()
            }
        },
        onEditingRowSave: ({ values, table, row }) => {
            if ((newObj?.FIELD08 == null || newObj?.FIELD08 == "") && (newObj?.FIELD04 == null || newObj?.FIELD04 == "")) {
                GlobalClass.Notify("warning", "Warning:Empty Field", "Some Field Require to Enter")
            } else {
                handleSaveUser()
            }
        },
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon onClick={() => {
                        table.setEditingRow(row)
                        // console.log('table.setEditingRow=>', row);
                        setNewObj(row.original);
                        setTableEditingRow(row)
                    }}>
                        <IconEdit size={'20px'} />
                    </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                    <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
                        <IconTrash />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                onClick={() => {
                    table.setCreatingRow(true);
                }}
                size='xs'
                onFocus={() => {
                    table.setCreatingRow(true)
                }}
            >
                Add New
            </Button>
        ),
        // mantineTopToolbarProps:{
        //     height:300
        // }
    })

    const handleRowKeyDown = (e, row) => {
        if (e?.key == "Enter") {
            console.log("handleRowKeyDown parameter =>", [{
                "e": e,
                "row": row
            }])

            table.setEditingRow(row)
            setTableEditingRow(row)
            setNewObj(row?.original)
        }

    }

    return (<>
        <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={true}
            fullScreen={false}//
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
                onClick: (e) => {
                    setDrawerObj(dra);
                },
            }}
            title={DrawerObj?.title}
            size={DrawerObj?.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? 'bottom'}
            onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
        // scrollAreaComponent={ScrollArea.Autosize}
        >
            {DrawerObj.body}
        </Modal>
        <MantineReactTable table={table} />
    </>)
}
