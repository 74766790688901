import { Button, Card, Grid, Group, Paper, Skeleton, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import { modals } from "@mantine/modals";
import { useDispatch, useSelector } from "react-redux";
import { GetM24PriceList } from "../../utils/slices/PriceListSlice";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import Store from "../../utils/store";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

const PriceListForm = (props) => {
  const { obj } = props;

  // console.log("PriceListForm props=>", obj);

  const M24Datacomplete = useSelector(
    (state) => state.M24PriceList.M24PriceListData
  );
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const M24Data = useSelector(
    (state) => state.M24PriceList.M24PriceListData?.M24
  );
  const COPAD = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.COPAD
  );
  const COPLVL = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.COPLVL
  );
  const COPRT = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.COPRT
  );
  const COPASK = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.COPASK
  );
  const P_S11DT = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.P_S11DT
  );
  const DTPR = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.DTPR
  );
  const DTPD = useSelector(
    (state) => state.M24PriceList?.M24PriceListData?.DTPD
  );
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.M24PriceList
  );

  // const { COPLVL, COPRT, COPASK, P_S11DT, DTPR, DTPD } = useSelector((state) => state.M24PriceList.M24PriceListData)
  const dispatch = useDispatch();
  const [priceData, setPriceData] = useState({});

  const [partyLevelField, setPartyLevelField] = useState({});
  const [productLevelField, setProductLevelField] = useState({});
  // const [DateBoxValue, setDateBoxValue] = useState({
  //   DateOne: null,
  //   DateTwo: null,
  // });

  // console.log('priceData', priceData)
  // console.log('M24Data', M24Data)
  // console.log('P_S11', P_S11)
  // console.log('P_S11DT', P_S11DT)
  // console.log('DTPR', DTPR)
  // console.log('DTPD', DTPD)

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (obj.p0 === "E") {
      dispatch(GetM24PriceList({ action: obj.p0, code: obj.id }));
    } else {
      dispatch(GetM24PriceList());
    }
  }, [obj]);

  useEffect(() => {
    if (M24Data) {
      setPriceData(M24Data);
    }
  }, [M24Data]);

  console.log("PriceData==>", priceData);

  const saperate = (e, b, m) => {
    let arr;
    let newAarr;
    let tempArr;
    if (priceData?.FIELD28 === "M") {
      if (b == "Two") {
        arr = e.split("#");
        console.log("arr Two===============>", arr);
        newAarr = arr[1].split(",");
        // tempArr = arr[0]?.split(",")
        // console.log('tempArr',tempArr)
        console.log('newAarr Two===>', newAarr)
        return newAarr;
      } else {
        arr = e.split("#");
        console.log("arr===============>", arr);
        newAarr = arr[0].split(",");
        console.log('newAarr===>', newAarr)
        return newAarr;
      }
    } else {
      // console.log("================>", e);
      if (b == "Two") {
        arr = e?.split("#");
        // console.log('single==>>', arr);
        return arr?.[1];
      } else {
        arr = e?.split("#");
        // console.log('single==>>', arr);
        return arr?.[0];
      }
    }
  };

  useEffect(() => {
    if (priceData?.FIELD28 === "M") {
      var MCDFLD = [];
      var CTBLNM = [];
      var PARTY = [];
      var PRODUCT = [];

      if (partyLevelField.length > 0 && productLevelField.length > 0) {
        MCDFLD = partyLevelField.map((p) => {
          return p.MCDFLD;
        });
        CTBLNM = productLevelField.map((r) => {
          return r.MCDFLD;
        });
        PARTY = partyLevelField.map((p) => {
          return p.CTBLNM;
        });
        PRODUCT = productLevelField.map((r) => {
          return r.CTBLNM;
        });
      }

      let partyVal = MCDFLD.toString() + "#" + CTBLNM.toString();
      let productVal = PARTY.toString() + "#" + PRODUCT.toString();
      console.log("partyValue", partyVal);
      console.log("productValue", productVal);

      return setPriceData({
        ...priceData,
        FIELD21: partyVal,
        FIELD22: productVal,
      });
    } else {
      let party = partyLevelField?.MCDFLD + "#" + productLevelField?.MCDFLD;
      console.log('party=>>', party)
      let product = partyLevelField?.CTBLNM + "#" + productLevelField?.CTBLNM;
      console.log('product==>>', product)
      return setPriceData({ ...priceData, FIELD21: party, FIELD22: product });
    }
  }, [partyLevelField, productLevelField]);

  const HandleSubmit = (e) => {
    console.log("Form Submit props=>", obj);
    e.preventDefault();
    if ((JSON.stringify(priceData) == JSON.stringify(M24Data)) && obj?.p0 == "E") {
      Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
      return;
    } else {
      if (priceData?.FIELD21 == "#" && priceData?.FIELD22 == "TBLNONE#TBLNONE") {
        GlobalClass.Notify('warning', "Party Level and Product Level Both Can Not be NONE")
      } else {
        GlobalClass.Notify(
          "info",
          obj.p0 == "A" ? "Adding" : "Editing",
          "Please wait while we process your data"
        );
        dispatch(DataSaveLoading(true));
        // console.log('priceData', priceData)
        // console.log('price===>', priceData?.FIELD21, "product==>", priceData?.FIELD22);
        let cleanPriceData = {
          ...priceData,
          FIELD11: priceData.FIELD11.replace(/^'|'$/g, ''), // Remove Extra single quotes  
          FIELD12: priceData.FIELD12.replace(/^'|'$/g, '')
        };

        let jData;
        if (obj.p0 == "A") {
          jData = { ...cleanPriceData, FIELD01: "" };
        } else {
          jData = { ...cleanPriceData, FIELD01: obj.id };
        }

        console.log("HandleSubmit jData=>", jData);
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: obj.p0,
          cCode: obj.p0 == "E" ? obj.id : "",
          cSData: JSON.stringify(jData),
        };
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: obj.p0,
          cCode: obj.p0 == "E" ? obj.id : "",
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostM24Ent + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("api data", data);
            // console.log("api data status", data?.status);
            if (data.status === "SUCCESS") {
              let PaginationObj =
                Store.getState().DataSaveLoadingSlice.PaginationObj;
              Promise.resolve(dispatch(DataSaveLoading(false)))
                .then(() => {
                  if (obj.p0 == "A") {
                    dispatch(GetM24PriceList())
                  } else {
                    dispatch(ModalDelete(props.index))
                  }
                })
                .then(() =>
                  dispatch(
                    GetAccountList({
                      id: "00960001",
                      name: "Price List",
                      p0: "MT",
                      p1: "T_M24",
                      p2: "",
                      p3: "",
                      type: "A",
                      pagination: PaginationObj["00960001"],
                    })
                  )
                )
                .then(() =>
                  GlobalClass.Notify(
                    "success",
                    obj.p0 == "A" ? "Added" : "Edited",
                    obj.p0 == "A" ? "Added successfully" : "Edited successfully"
                  )
                );
            } else {
              Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                GlobalClass.Notify(
                  "error",
                  data?.status,
                  data?.message
                )
              );
            }
          })
          .catch((e) => {
            Promise.resolve(
              GlobalClass.Notify(
                "error",
                "Error",
                `${e?.message}`
              )).then(() => dispatch(DataSaveLoading(false)));
          });
      }
    }
  };

  console.log('partyLevelField=>', partyLevelField);
  console.log('productLevelField=>', productLevelField);

  return (
    <>
      {
        hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (obj && obj.id != "") {
              if (obj.p0 === "E") {
                dispatch(GetM24PriceList({ action: obj.p0, code: obj.id }));
              } else {
                dispatch(GetM24PriceList());
              }
            }
          }}>Retry</Button>
        </> :
          SaveLoading == true ? (
            GlobalClass.RenderLoader("dots")
          ) : (
            <>
              {isLoading
                ? GlobalClass.RenderLoader("dots")
                : M24Datacomplete && (
                  <Paper shadow="md" radius="md" p="xs" withBorder>
                    <Grid gutter={4}>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Name</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLTextBox
                            autoFocus={true}
                            setEdit={(e) => {
                              setPriceData({
                                ...priceData,
                                FIELD02: e.target.value,
                              });
                            }}
                            value={priceData.FIELD02}
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>From</Text>
                          </Grid.Col>
                          <Grid.Col span={8}>
                            <PLDateBox
                              dispformat="DD/MM/YYYY"
                              defaultval={priceData?.FIELD11?.replace(/^'|'$/g, '')}
                              setEdit={(e) => {
                                let year = e.toLocaleString("en-IN", {
                                  year: "numeric",
                                });
                                let month = e.toLocaleString("en-IN", {
                                  month: "2-digit",
                                });
                                let day = e.toLocaleString("en-IN", {
                                  day: "2-digit",
                                });

                                let startDate = year + month + day;
                                console.log("startDate", startDate);
                                if (startDate?.length == 8) {
                                  setPriceData({
                                    ...priceData,
                                    FIELD11: startDate,
                                  });
                                }
                                // setDateBoxValue({ ...DateBoxValue, DateOne: e });
                              }}
                            // defaultval={new Date(priceData?.FIELD11).getTime()}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text
                              style={{
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              To
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={8}>
                            <PLDateBox
                              dispformat="DD/MM/YYYY"
                              // value={DateBoxValue.DateTwo}
                              setEdit={(e) => {
                                let year = e.toLocaleString("en-IN", {
                                  year: "numeric",
                                });
                                let month = e.toLocaleString("en-IN", {
                                  month: "2-digit",
                                });
                                let day = e.toLocaleString("en-IN", {
                                  day: "2-digit",
                                });

                                let endDate = year + month + day;

                                if (endDate?.length == 8) {
                                  setPriceData({ ...priceData, FIELD12: endDate });
                                }
                                // setDateBoxValue({ ...DateBoxValue, DateTwo: e });
                              }}
                              defaultval={priceData?.FIELD12?.replace(/^'|'$/g, '')}
                            />
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Sale/Purchase</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLComboBox
                            disabled={obj.p0 == "E" ? true : false}
                            data={P_S11DT}
                            dispexpr="S11F02"
                            valexpr="S11F01"
                            setEdit={(e) => {
                              console.log("Sale/Purchase", e);
                              setPriceData({
                                ...priceData,
                                FIELD27: e === "SS" ? "S" : e === "PP" ? "P" : "",
                              });
                            }}
                            value={
                              priceData.FIELD27 === "P"
                                ? "PP"
                                : priceData.FIELD27 === "S"
                                  ? "SS"
                                  : priceData.FIELD27
                            }
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Active/Deactive</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLComboBox
                            data={COPAD}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              setPriceData({ ...priceData, FIELD06: e });
                            }}
                            value={priceData.FIELD06}
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Level</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLComboBox
                            disabled={obj.p0 == "E" ? true : false}
                            data={COPLVL}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              console.log("PLComboBox", e);
                              setPriceData({ ...priceData, FIELD28: e });
                            }}
                            value={priceData.FIELD28}
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Party Level</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLComboBox
                            dropdownPosition={"bottom"}
                            data={DTPR}
                            disabled={obj.p0 == "E" ? true : false}
                            dispexpr="DISPNM"
                            valexpr="MCDFLD"
                            multiple={priceData.FIELD28 === "M" ? true : false}
                            setEdit={(e) => {
                              // console.log("Party Level", e);
                              if (priceData.FIELD28 === "M") {
                                var val = [];
                                e.map((l) => {
                                  if (typeof partyLevelField === Array) {
                                    if (partyLevelField.length > 0) {
                                      val = partyLevelField.filter((p) => {
                                        return p.MCDFLD != l;
                                      });
                                    }
                                  }
                                  DTPR?.map((d) => {
                                    if (d.MCDFLD === l) {
                                      val.push(d);
                                    }
                                  });
                                });
                                setPartyLevelField(val);
                              } else {
                                DTPR?.map((d) => {
                                  if (d.MCDFLD === e) {
                                    setPartyLevelField(d);
                                  }
                                });
                              }
                            }}
                            value={
                              obj.p0 == "A"
                                ? partyLevelField?.MCDFLD
                                : saperate(M24Data?.FIELD21)
                            }
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Product Level</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLComboBox
                            disabled={obj.p0 == "E" ? true : false}
                            data={DTPD}
                            dispexpr="DISPNM"
                            valexpr="CTBLNM"
                            multiple={priceData.FIELD28 === "M" ? true : false}
                            setEdit={(e) => {
                              // console.log("Product Level", e);
                              if (priceData.FIELD28 === "M") {
                                var val = [];
                                e.map((l) => {
                                  if (typeof productLevelField === Array) {
                                    if (productLevelField.length > 0) {
                                      val = productLevelField.filter((p) => {
                                        return p.CTBLNM != l;
                                      });
                                    }
                                  }
                                  DTPD?.map((d) => {
                                    if (d.CTBLNM === l) {
                                      val.push(d);
                                    }
                                  });
                                });
                                setProductLevelField(val);
                              } else {
                                DTPD?.map((d) => {
                                  if (d.CTBLNM === e) {
                                    setProductLevelField(d);
                                  }
                                });
                              }
                            }}
                            value={
                              obj.p0 == "A"
                                ? productLevelField?.CTBLNM
                                : saperate(M24Data?.FIELD22, "Two")
                            }
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Rate Type</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLComboBox
                            disabled={obj.p0 == "E" ? true : false}
                            data={COPRT}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              setPriceData({ ...priceData, FIELD25: e });
                            }}
                            value={priceData.FIELD25}
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>Ask On</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLComboBox
                            data={COPASK}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            setEdit={(e) => {
                              setPriceData({ ...priceData, FIELD26: e });
                            }}
                            value={priceData.FIELD26}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid>
                    <Group
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Button onClick={(e) => HandleSubmit(e)}>
                        OK
                      </Button>
                      <Button onClick={() => dispatch(ModalDelete(props.index))}>Cancel</Button>
                    </Group>
                  </Paper>
                )}
            </>
          )}
    </>
  );
};

export default PriceListForm;
