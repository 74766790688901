import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import axios from "axios";

export const GetUserSecurity = createAsyncThunk(
    "UserSecurity/GetuserSecurity",
    async(obj) => {
        try {
            let param = JSON.stringify({
              CorpID: gensetting.getcorpid(),
              cUser: gensetting.getcuser(),
              cPass: gensetting.getcpass(),
              CmpNo: gensetting.getcmpno(),
              cAction:"",
              cOPara: "",
              "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(
              GlobalClass.ApiUrl + GlobalClass.getUserSecurity + "?pa=" + param
            );
            return response.data?.data;
          } catch (error) {
            console.error(error);
            return { error };
          }
    }
)

const UserSecuritySlice = createSlice({
    name:"UserSecurity",
    initialState: {
        UserSecurityData:[],
        isLoading:false,
        hasError:false,
        ErrorMsg:{}
    },
    reducers:{},
    extraReducers: (builder) =>{
      builder
      .addCase(GetUserSecurity.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.UserSecurityData = [];
      })
      .addCase(GetUserSecurity.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.UserSecurityData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetUserSecurity.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
    }
})
export default UserSecuritySlice.reducer;