import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import axios from "axios";
import gensetting from "../gensetting";
import { IconBooks, IconDeviceDesktopAnalytics, IconFileInvoice, IconLogout, IconReport, IconSettings, IconTool } from "@tabler/icons-react";
import Localize from "../rc";
import Store from "../store";
// import Store from "../store";
// import { GetUserMasterData } from "./UserMasterSlice";

// user List api
export const GetUserList = createAsyncThunk(
    "UserList/GetUserList",
    async (obj) => {
        console.log('UserList/GetUserList Slice Call', obj);
        try {
            // const user = sessionStorage.getItem("authUser");
            const user = localStorage.getItem("authUser")

            // console.log("UserList/GetUserList user =>",user); 

            if (!obj?.Action) {
                if (user) {
                    return JSON.parse(user);
                }
            }

            let param = "p1=" + gensetting.getcorpid() + "&p2=" + gensetting.getcuser() + "&p3=" + gensetting.getcpass() + "&YrNo" + localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetCorpDet + "?" + param);
            // console.log("GetUserList response=>>", response.data.data);
            if (response.data.status === "SUCCESS") {
                // sessionStorage.setItem("authUser",JSON.stringify(response.data.data));
                localStorage.setItem("authUser", JSON.stringify(response.data.data))
                return response.data.data;
            } else {
                return { error: response.data.message }
            }

        } catch (error) {
            console.error(error);
            return { error }
        }
    }
);
let rc = new Localize().rc
function localizeCap(data) {

    return data.map((itm) => {
        if (!itm.name.includes("Separator")) {
            if (itm.capid) {
                itm.name = rc(itm.capid)
            }
            // itm.name = rc(itm.capid)

        }
        if (itm.items) {
            itm.items = localizeCap(itm.items)
        }
        return itm
    });
}
export const GetMenuList = createAsyncThunk(
    "UserList/GetMenuList",
    async () => {
        // Promise.resolve(Store.dispatch(GetUserMasterData())).then(()=>{
        //     let usermastername=Store.getState().UserMasterSlice.UserMasterData

        //     console.log('usermastername obj=>', usermastername);
        // })
        // console.log('UserList/GetUserList obj=>', obj);
        try {

            let responseData = [];
            var menu = localStorage.getItem("Menu");
            if (false) {
                var newmenu = JSON.parse(menu);
                // console.log("newmenu===>",newmenu);
                newmenu.map((itm) => {

                    if (itm.name === 'Master' || itm.name === "Transaction" || itm.name === "Report" || itm.name === "Utility" || itm.name === "Setup" || itm.name === "Exit") {
                        switch (itm.name) {
                            case 'Master':
                                // setActive(itm.name);
                                // setSubMenuarray(itm.items)
                                itm.icon = IconBooks;
                                break;
                            case 'Transaction':
                                itm.icon = IconFileInvoice;
                                break;
                            case 'Report':
                                itm.icon = IconReport;
                                break;
                            case 'Utility':
                                itm.icon = IconTool;
                                break;
                            case 'Setup':
                                itm.icon = IconSettings;
                                break;
                            case 'Exit':
                                itm.icon = IconLogout;
                                break;
                            default:
                                itm.icon = IconDeviceDesktopAnalytics;
                                break;
                        }

                    }
                    return itm;
                })
                responseData = newmenu;

            } else {
                const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetMenu + "?pa=" + GlobalClass.getdataparam());

                responseData = response.data.data;
                // console.log("GetUserList response=>>", response.data.data);

                localStorage.setItem("Menu", JSON.stringify(responseData));
                responseData = responseData.map((itm) => {
                    if (itm.name === 'Master' || itm.name === "Transaction" || itm.name === "Report" || itm.name === "Utility" || itm.name === "Setup" || itm.name === "Exit") {
                        switch (itm.name) {
                            case 'Master':
                                // setActive(itm.name);
                                // setSubMenuarray(itm.items)
                                itm.icon = IconBooks;
                                break;
                            case 'Transaction':
                                itm.icon = IconFileInvoice;
                                break;
                            case 'Report':
                                itm.icon = IconReport;
                                break;
                            case 'Utility':
                                itm.icon = IconTool;
                                break;
                            case 'Setup':
                                itm.icon = IconSettings;
                                break;
                            case 'Exit':
                                itm.icon = IconLogout;
                                break;
                            default:
                                itm.icon = IconDeviceDesktopAnalytics;
                                break;
                        }

                    }
                    return itm;
                })

            }
            return await localizeCap(responseData);
        } catch (error) {
            console.error(error);
            return { error: "Network Error!!" }  // Vara Pooja
        }
    }
);

export const GetAddCmpEnt = createAsyncThunk(
    "UserList/GetAddCmpEnt",
    async (OBJ) => {
        console.log("UserList/GetAddCmpEnt=>", OBJ);
        try {
            let param = JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                // "CmpNo": gensetting.getcmpno(),
                "CmpNo": OBJ?.cmpNo ? OBJ?.cmpNo : "",
                "cAction": OBJ?.action ? OBJ?.action : "A"
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM00Ent + "?pa=" + param)
            // console.log('response', response)
            if (OBJ?.action == "D") {
                if (response.data.status === "SUCCESS") {
                    Promise.resolve(Store.dispatch(GetUserList({ Action: OBJ?.action }))).then(() =>
                        GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
                } else {
                    GlobalClass.Notify(
                        "error",
                        response?.data?.status,
                        response?.data?.message
                    );
                }
            }
            if (response.data.status === "SUCCESS") {
                return response.data.data;
            } else {
                return {error: response.data.message};
            }
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)




const UserListSlice = createSlice({
    name: 'UserList',
    initialState: {
        UserListData: [],
        MenuListData: [],
        AddCmpEnt: [],
        CmpNumber: '',
        YearAndDateSend: null,
        isLoading: false,
        isMenuLoading: false,
        hasError: false,
        ErrorMsg: ""
    },
    reducers: {
        SetCmpNumber: (state, action) => {
            console.log("GetCmpNumber=>", action.payload);
            state.CmpNumber = action.payload
        },
        SetYearAndDateData: (state, action) => {
            console.log("SetYearAndDateFocus=>", action.payload);
            state.YearAndDateSend = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserList.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetUserList.fulfilled, (state, action) => {
                // console.log('GetUserList action',action.payload);
                if (!action.payload?.error) {
                    state.UserListData = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                }
                else {
                    state.UserListData = action.payload.error;
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload.error
                }

            })
            .addCase(GetUserList.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetMenuList.pending, (state, action) => {
                state.isMenuLoading = true;
                state.hasError = false;
            })
            .addCase(GetMenuList.fulfilled, (state, action) => {
                // console.log("GetMenuList fulfilled", action.payload);

                if (action?.payload && !action.payload?.error) {
                    state.MenuListData = action.payload;
                    state.isMenuLoading = false;
                    state.hasError = false;
                } else {
                    state.isMenuLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }    // Vara Pooja


            })
            .addCase(GetMenuList.rejected, (state, action) => {
                state.hasError = true
                state.isMenuLoading = false;
            })
            .addCase(GetAddCmpEnt.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetAddCmpEnt.fulfilled, (state, action) => {
                console.log("GetAddCmpEnt action.payload =>", action);
                if (action?.payload && !action.payload?.error) {
                    state.AddCmpEnt = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.AddCmpEnt = [];
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetAddCmpEnt.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })

    }
})

export const { SetCmpNumber, SetYearAndDateData } = UserListSlice.actions

export default UserListSlice.reducer

