import { Grid, Group, Skeleton } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import ButtonPanel from '../../PlusComponent/ButtonPanel';
import GlobalClass from '../../utils/GlobalClass';
import { GetTMFormat } from '../../utils/slices/AccountListSlice';


const UserMasterListTable = (props) => {

    const { obj } = props;

    console.log('UserMasterList props=>', props);

    const [columns, setColumns] = useState([])
    const [newData, setNewData] = useState([])


    const [rowSelection, setRowSelection] = useState({});

    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state?.UserMasterSlice);

    const P_M41UML = useSelector((state) => state?.UserMasterSlice?.UserMasterData?.P_M41UML);
    // const P_M41UMLData = useSelector((state) => state?.UserMasterSlice?.UserMasterData?.P_M41UMLData?.jData);

    const UserMasterTableData = useSelector((state) => state?.UserMasterSlice?.UserMasterTableData)

    const UserMasterOBJ = useSelector((state) => state?.UserMasterSlice?.UserMasterOBJ)

  const {TMFormatData: tmList ,isLoading : tmListIsLoading } = useSelector((state) => state.AccountList);



    const columnHelper = createColumnHelper();
    console.log(UserMasterOBJ , UserMasterTableData , "UserMasterTableData")
    const renderDynamicVal = (col, val) => {
        var ret = '';
        switch (col) {

            case "Type":
                ret = (val == 'C' ? 'Character' : (val == 'N' ? 'Numeric' : (val == 'M' ? 'Memo' : (val == 'D' ? 'Date' : (val == 'I' ? 'Image' : (val == 'T' ? 'Time' : (val == 'E' ? 'Date Time' : (val == 'A' ? 'Attechment' : (val == 'H' ? 'Hyperlink' : val)))))))))
                break;
            //   case "Help":
            //     ret = val == "N" ? "No" :
            //       val == "Y" ? "Yes" : val
            //     break;
            case "Status":
                ret = val == "E" ? "Enable" :
                    val == "D" ? "Disable" : val
                break;
            default:
                ret = val
                break;
        }
        return ret;
    }

    useEffect(() => {
        if (P_M41UML) {
          console.log("tmList", tmList);
          dispatch(GetTMFormat({
            "id": P_M41UML.toString().split("~C~")[0],
            "name": "",
            "type": "A",
            "p0": P_M41UML.toString().split("~C~")[0],
            "p1": P_M41UML.toString().split("~C~")[0],
            "p2": P_M41UML.toString().split("~C~")[1],
            "p3": ""
          }))
        }
      }, [P_M41UML])

    useEffect(() => {
        if (tmList) {
            let col = [];
            tmList[P_M41UML?.toString().split("~C~")[0]]?.oDCFrmt?.aDCol.map((d) => {
                col.push(
                    columnHelper.accessor(d.DispExpr, {
                        header: d.ColCap,
                        enableHiding: true,
                        size: d.ColWidth,
                        minSize: 0,
                        maxSize: d.ColWidth,
                        Cell: ({ cell }) => {
                            var val = cell.getValue();
                            return renderDynamicVal(d.ColCap, val)

                        }
                    })
                )
            })
            setColumns(col)
        }
    }, [tmList])

    const simulateEnterKeyPress = () => {
        console.log('Double Click');
        // Get the row element using the rowIndex
        const rowElement = document.querySelector(`table`);
        if (rowElement) {
            // Create a new KeyboardEvent with the "Enter" key
            const event = new KeyboardEvent("keydown", {
                key: "Enter",
                bubbles: true,
                cancelable: true,
            });

            // Dispatch the event on the row element
            rowElement.dispatchEvent(event);
        }
    }

    useEffect(() => {
        if (UserMasterOBJ) {
            console.log(UserMasterOBJ , "UserMasterOBJ", UserMasterTableData , obj )
            let newArray = UserMasterTableData?.M41UF.filter((d) => d?.FIELD01 == UserMasterOBJ?.FIELD01 && d.FIELD13 != "FIELD01")
            setNewData(newArray)
        }
    }, [UserMasterTableData?.M41UF, UserMasterOBJ])


    const table = useMantineReactTable({
        data: newData,
        columns,
        mantineTableContainerProps: { sx: { maxHeight: "350px" } },
        enableTopToolbar: true,
        enableColumnActions:false,
        enableSorting:false,
        mantineTableProps:{withColumnBorders:true},
        initialState: {
            density: "xs"
        },
        state: {
            rowSelection,
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            pl: 5,
            onClick: () => {
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
            },

            onDoubleClick: () => {
                Promise.resolve(
                    setRowSelection((prev) => ({
                        [row.id]: true,
                    }))
                ).then(() => {
                    simulateEnterKeyPress();
                })
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
    })

    return (<>
        {
            UserMasterTableData && UserMasterTableData?.M41UF && UserMasterTableData?.M41UF.length > 0 ? <Grid gutter={4}>
                <Grid.Col span={12}>
                    <MantineReactTable table={table} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Group spacing={'xs'} position="center" display={'flex'}>
                        <ButtonPanel
                            data={tmList[P_M41UML?.toString().split("~C~")[0]]?.oS60?.DTHKey}
                            Skey={table.getSelectedRowModel().flatRows[0]}
                            from={obj?.id}
                            FormCode={"UMLF"}
                        />
                    </Group>
                </Grid.Col>
            </Grid> : GlobalClass.RenderLoader('dots')
        }
    </>)
}

export default UserMasterListTable