import React from 'react'
import Store from '../../utils/store';
import { ModalFunction } from '../../utils/slices/ModalSlice';
import { Text } from '@mantine/core';
import MasterFilterForm from './MasterFilterForm';

export const ButtonMaster = () => {
    return (
        <div>ButtonMaster</div>
    )
}


export const TM_MSEL = async (data) => {
    const OBJ = JSON.parse(data);
    console.log("OBJJ TM_MSEL = Master btn =>", OBJ);

    Store.dispatch(
        ModalFunction({
          onclose: (e) => { },
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "lg",
          position:"right",
          MBody: (i) => <MasterFilterForm data={OBJ} index={i} />,
          MClose: true,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
        })
      );

}