import React, { useEffect, useState, createContext } from 'react';
import { createStyles, Navbar, UnstyledButton, Tooltip, Title, rem, Divider, NavLink, ScrollArea, Text, Image, Drawer, Menu, Button, Modal } from '@mantine/core';
import {
  IconDeviceDesktopAnalytics,
  IconSettings,
  IconBooks,
  IconFileInvoice,
  IconReport,
  IconLogout,
  IconTool,
  IconFingerprint
} from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';

import { ClearTabs, TabAdd, UpdateSelectedMenu } from '../utils/slices/TabList';
import BindFunWithPara from '../MyComponent/BindFunWithPara';
import { modals } from '@mantine/modals';
import DataGrid from '../components/DataGrid';
import MyComponent from '../MyComponent/MyComponent';
import YearAndDate from '../MyComponent/YearAndDate';
import Localize from '../utils/rc';
import { ModalFunction } from '../utils/slices/ModalSlice';
import { GetCmpSetUpObj, GetReportIdArray, GetTransactionReportIdArray } from '../utils/slices/DataSaveLoadingSlice';
import Store from '../utils/store';
import useWindowDimensions from '../utils/UseWindowDimensions'
import App from "../App.json"

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },

  aside: {
    flex: `0 0 ${rem(60)}`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]
      }`,
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },
  menuList: {
    // height: rem(500),
    // border: '1px solid red',
    overflow: 'hidden',
    overflowY: 'scroll'
  },

  mainLink: {
    // width: rem(40),
    height: rem(40),
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  mainLinkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },

  title: {
    boxSizing: 'border-box',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xl,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: rem(18),
    height: rem(60),
    borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
  },

  logo: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: rem(60),
    paddingTop: theme.spacing.md,
    borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
    marginBottom: theme.spacing.xl,
  },

  link: {
    boxSizing: 'border-box',
    display: 'block',
    textDecoration: 'none',
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    padding: `0 ${theme.spacing.md}`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: rem(26),
    lineHeight: rem(26),

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  linkActive: {
    '&, &:hover': {
      borderLeftColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
        .background,
      backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
        .background,
      color: theme.white,
    },
  },
}));

export const NavbarName = createContext('');

export default function MainMenu(props: any) {
  interface menuArray {
    name?: string,
    icon?: React.ElementType,
    id?: string,
    type?: string,
    capid?: string,
    items?: any
  }
  const { classes, cx } = useStyles();
  const selected = useSelector((state: any) => state.TabList.selected);
  const Menuarray = useSelector((state: any) => state.UserList.MenuListData);
  const { height } = useWindowDimensions();
  const [active, setActive] = useState(selected);
  const [opened, setOpened] = useState({});
  const [activeLink, setActiveLink] = useState('Settings');
  const [MainMenu, setMainMenu] = useState<menuArray[]>([]);

  let dra = {
    title: null,
    size: "80%",
    body: null,
    open: false,
    onClickCloseBtn: () => { },
    position: null,
    onclose: () => { }
  }

  const [DrawerObj, setDrawerObj] = useState(dra);
  const dispatch = useDispatch();
  const handleMainOpen = (e="") =>{
    if(e){
      setOpened({...opened,[e]:!opened[e]});
    }
    else{
      setOpened(MainMenu?.map((link) => {return {[link.id]:(opened[link.id]?!opened[link.id]:true)}}));
    }
  }
  const MainLinks = ({links, sub}) => {
    return links?.map((link) => {
      if (link.name.includes("Separator")) {
        return <Divider key={link.id} my="sm" variant="dashed" />
      }
      if (!sub) {
          var CustomIcon: React.ElementType = link?.icon;
          return (
            <Menu key={link.label} withArrow arrowPosition='center' opened={opened[link.id]??false} onChange={()=>handleMainOpen(link.id)} trigger="click" transitionProps={{ exitDuration: 0 }} withinPortal>
          <Menu.Target>
            <Button m={'xs'} className='boxshadow-btn' variant="default" color='dark' size="xs"
            style={link.name == active ?{borderColor:'ActiveBorder'}:{}}
            leftIcon={<CustomIcon/>}
            onClick={(event) => {
            setActive(link.name);
            // setSubMenuarray(link.items)
            if (link.id == "00030000") {
              SidebarObjSave(link.id, link.items)
            } else if (link.id == "00020000") {
              SidebarObjSave(link.id, link.items)
            }
            handleMainOpen(link.id)
        }}>{link.name}</Button>
          </Menu.Target>
          <Menu.Dropdown className='boxshadow'>
           <ScrollArea  h={500} w={200}>  {MainLinks({links:link.items,sub:true})}</ScrollArea>
          {/* <MainLinks links={link.items} sub={true} /> */}
          </Menu.Dropdown>
        </Menu>
       
          );
        
      }
      else{
        return <NavLink key={link.id}
        label={link.name}
        active={activeLink === link.id}
        childrenOffset={20} 
        onClick={(event)=>{
          if (link.items) {
            return
          }
          event.preventDefault();
          setActiveLink(link.id);
          if (link.capid == "_MNU0114" || link.capid == "_MNU0115") { // Company Setup
            Promise.resolve(dispatch(ClearTabs(''))).then(() => {
              dispatch(GetCmpSetUpObj(null))
              var obj = {
                id: link.capid,
                name: link.name,
                type: link.type,
                p0:link
              }
              Store.dispatch(TabAdd(obj)); 
              // setDrawerObj({
              //   ...DrawerObj,
              //   title: <Text fw={700}>{link.name}</Text>,
              //   body: <MyComponent obj={link} />,
              //   onClickCloseBtn: () => {
              //     setDrawerObj(dra)
              //     Store.dispatch(GetCmpSetUpObj(null))
                  
              //   },
              //   onclose: () => {
              //     setDrawerObj(dra)
              //     Store.dispatch(GetCmpSetUpObj(null))
                  
              //   },
              //   open: true,
              //   position: "right"
              // })
            })
          } 
          // else if (link.capid == "_MNU0115") { //Voucher Setup
          //   console.log("call Voucher setup");
          //   Promise.resolve(dispatch(ClearTabs(''))).then(() => {

          //     setDrawerObj({
          //       ...DrawerObj,
          //       title: <Text fw={700}>{link.name}</Text>,
          //       body: <MyComponent obj={link} />,
          //       onClickCloseBtn: () => {
          //         setDrawerObj(dra)
          //         Store.dispatch(GetCmpSetUpObj(null))
                  
          //       },
          //       onclose: () => {
          //         setDrawerObj(dra)
          //         Store.dispatch(GetCmpSetUpObj(null))
                  
          //       },
          //       open: true,
          //       position: "right"
          //     })
          //   })
          // } 
          else {
            BindFunWithPara(link);
          }
          // props.setOpened();
          handleMainOpen()
        }}>
          {link.items && MainLinks({links:link.items,sub:true})}
        </NavLink>
      }
    });
  };
  let ReportArray = [];
  let ReportAarrayList = [];
  let TransactionArray = [];
  let TransactionArrayList = [];
  const SidebarObjSave = (id, items) => {
    switch (id) {
      case "00030000":
        if (items) {
          items.map((l1) => {
            ReportArray.push(l1.id)
            if (l1.items) {
              return SidebarObjSave(id, l1.items)
            }
          })
          // console.log("ReportArray=>", ReportArray);
          ReportAarrayList = [...ReportArray]
          // console.log("AarrayList=>", ReportAarrayList);
        }
        Store.dispatch(GetReportIdArray(ReportAarrayList))
        Store.dispatch(GetTransactionReportIdArray(ReportAarrayList))
        break;
      case "00020000":
        if (items) {
          items.map((l1) => {
            TransactionArray.push(l1.id)
            if (l1.items) {
              return SidebarObjSave(id, l1.items)
            }
          })
          // console.log("TransactionArray=>", TransactionArray)
          TransactionArrayList = [...TransactionArray]
          // console.log("TransactionArrayList=>", TransactionArrayList)
        }
        Store.dispatch(GetTransactionReportIdArray(TransactionArrayList))
        break;

      default:
        break;
    }

  }

  useEffect(() => {
    if (Menuarray) {
      setMainMenu(Menuarray);
    }
  }, [Menuarray])
  useEffect(() => {
    if (MainMenu && MainMenu?.length > 0) {
      setActive(MainMenu[0].name);
    }
  }, [MainMenu])
  useEffect(() => {
    if (selected !== activeLink) {
      setActiveLink(selected)
    }
  }, [selected, activeLink])
  return (<>

    {DrawerObj?.open && <Modal
      opened={DrawerObj?.open}
      // onClose={close}
      withCloseButton={true}
      // overlayProps={{ opacity: 0.5, blur: 4 }}
      closeButtonProps={{
        onClick: (e) => {
            setDrawerObj(dra);
            Store.dispatch(GetCmpSetUpObj(null))
        },
      }}
      title={DrawerObj?.title}
      size={DrawerObj?.size}
      // centered={.Centered}
      closeOnClickOutside={true}
      // overlayProps={m.Overlay}
      // position={DrawerObj?.position}
      onClose={() => { setDrawerObj(dra);Store.dispatch(GetCmpSetUpObj(null)); }}
    // scrollAreaComponent={ScrollArea.Autosize}
    >
      {DrawerObj?.body}
    </Modal>}
          {Menuarray?.length > 0 && MainLinks({links:MainMenu,sub:false})} 
  </>);
}