import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../utils/UseWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { Button, Grid, Group } from '@mantine/core';
import { GetTMFormat } from '../../utils/slices/AccountListSlice';
import GlobalClass from '../../utils/GlobalClass';

const PendingOrderTable = (props) => {
    const { OrderData, OrderFormat, selectedOrder, DrawerOBJ, setDrawerOBJ, selectedPendingOrder } = props;

    const [columns, setColumns] = useState([])
    const [ChallanTableData, setChallanTableData] = useState([])
    const [rowSelection, setRowSelection] = useState({})

    const dispatch = useDispatch();
    const columnHelper = createColumnHelper();

    const { height, width } = useWindowDimensions();

    const { TMFormatData: tmList, isLoading } = useSelector((state) => state?.AccountList)

    useEffect(() => {
        if (OrderFormat) {
            dispatch(GetTMFormat({
                "id": OrderFormat?.toString().split("~C~")[0],
                "name": "",
                "type": "A",
                "p0": OrderFormat?.toString().split("~C~")[0],
                "p1": OrderFormat?.toString().split("~C~")[0],
                "p2": OrderFormat?.toString().split("~C~")[1],
                "p3": ""
            }))
        }
    }, [OrderFormat])

    useEffect(() => {
        if (selectedOrder) {
            let newObj = {}
            console.log("selectedOrder=>", selectedOrder);
            OrderData?.map((n1, i) => {
                selectedOrder?.map((n2, j) => {
                    if (n1 == n2) {
                        newObj = { ...newObj, [i]: true }
                        // setRowSelection({ ...rowSelection, [i]: true })
                    }
                })
            })
            return setRowSelection(newObj)
        }
    }, [selectedOrder, OrderData])

    useEffect(() => {
        if (OrderData) {
            setChallanTableData(OrderData)
        }
    }, [OrderData])

    useEffect(() => {
        if (tmList) {
            console.log("tmList[OrderFormat?.toString().split()[0]]==>", tmList[OrderFormat?.toString().split("~C~")[0]]);
            let col = [];
            tmList[OrderFormat?.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?.map((d, i) => {
                col.push(
                    columnHelper.accessor(d?.DispExpr, {
                        header: d?.ColCap,
                        // enableHiding: true,
                        size: d?.ColWidth,
                        minSize: 0,
                        maxSize: d?.ColWidth,
                        Cell: ({ cell, renderedCellValue }) => {
                            //  console.log('cell====>',cell),
                            return rendercolcell(i == 0 ? renderedCellValue : cell.getValue(), d)
                        },
                    })
                )
            })
            setColumns(col)
        }
    }, [tmList])

    const rendercolcell = (data, v) => {
        // console.log("EPLUS",v);
        // console.log('data===>>',data)
        let dtxt = data;
        var datatype =
            v.DataType === "N"
                ? "number"
                : v.ColClick !== "" || v.DispExpr === "M01F68"
                    ? "custom"
                    : "string";
        if (datatype === "custom") {
            if (dtxt === "A") {
                dtxt = "✓";
            } else {
                dtxt = "";
            }
        }

        if (v.DispFormat.includes("A")) {
            dtxt = Math.abs(dtxt);
        }
        if (v?.ColCap == "Date") {
            // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8) ;
            //  dtxt = "2023/12/12"
            if (data != null && data != "" && data != undefined) {
                dtxt = dtxt.toString()  //Vara Pooja
                dtxt =
                    dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
            } else {
                dtxt = "";
            }
            // return dtxt
        }
        if (v.DispFormat.includes("Z")) {
            if (dtxt === ".00") {
                dtxt = "";
            }
        }

        if (v.DispFormat.includes("X")) {
            if (dtxt !== "") {
                if (parseFloat(dtxt) > 0) {
                    dtxt += " CR";
                } else {
                    dtxt += " DB";
                }
            }
        }

        return (
            <p
                style={{
                    fontSize: v.FontSize ? v.FontSize : 12,
                    fontFamily: v.FontName ? v.FontName : "Verdana",
                    color: v.ForeColor,
                    backgroundColor: v.BackColor,
                }}
            >
                {dtxt}
            </p>
        );
    };

    const table = useMantineReactTable({
        data: ChallanTableData,
        columns,
        mantineTableProps:{withColumnBorders:true},
        positionToolbarAlertBanner: "bottom",
        onRowSelectionChange: setRowSelection,
        state: {
            rowSelection
        },
        initialState: {
            density: "xs"
        },
        // enableRowVirtualization: true,
        enableSelectAll: false,
        enableMultiRowSelection: false,
        enableColumnOrdering: false,
        enableGlobalFilter: true,
        enableGrouping: false,
        enableDensityToggle: false,
        enableColumnActions: false,
        enableFilters:true,
        enableHiding:false,
        enableFullScreenToggle:false,
        mantineTableContainerProps: {
            sx: { height: height * 0.7 },
        },
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
            },
            sx: { cursor: "pointer" }
        })
    })

    const SubmitPendingOrder = () => {
        console.log("SubmitPendingQuotation fun call");

        let newArray = []

        OrderData?.map((n, i) => {
            if (i in rowSelection) {
                newArray.push(n)
            }
        })

        Promise.resolve(selectedPendingOrder({ orderData: OrderData, selectedOrder: newArray })).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
    }

    return (<>
        {
            !tmList[OrderFormat?.toString().split("~C~")[0]] ? GlobalClass.RenderLoader('dots') :
                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <MantineReactTable table={table} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Group spacing={"xs"} position="center">
                            <Button onClick={() => SubmitPendingOrder()}>Ok</Button>
                        </Group>
                    </Grid.Col>
                </Grid>
        }
    </>)
}

export default PendingOrderTable