import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTDSMasterGroup } from "../../utils/slices/TDSMasterSlice";
import { useEffect } from "react";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { Button, Center, Grid, Modal, Paper, Text } from "@mantine/core";
import DataGrid from "../../components/DataGrid";
import SimpleTable from "./TDSMasterTable";
import Store from "../../utils/store";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import GlobalClass from "../../utils/GlobalClass";
import { createColumnHelper } from "@tanstack/react-table";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import DeducteeTypeTable from "./DeducteeTypeTable";

export const TDSMaster = (props) => {
  // console.log("props.obj=>>", props.obj);
  const inputRef = useRef();
  const [fmtId, setFmtId] = useState("");
  const [section, setSection] = useState("");
  const [paymentCode, setPaymentCode] = useState("");
  // console.log("fmtid", fmtId);
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: () => {}
  };
  const natureOfPaymentCalled = () => {
    inputRef?.current?.handlePopoverToggle();
  };
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const [updatedColumn, setUpdatedColumn] = useState([]);
  const [PLValue,setPLValue] = useState();
  const [updatedColumn1, setUpdatedColumn1] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [rowSelection1, setRowSelection1] = useState({});
  const [data, setData] = useState();
  const [data1,setData1] = useState();
  const [flag,setFlag] = useState(false);
  const [showDetails,setShowDetails] = useState();
  const [DrawerObj, setDrawerObj] = useState(dra);
  const TDSMasterData = useSelector(
    (state) => state.TDSMasterGroup.TDSMasterGroupData
  );
  const { height, width } = useWindowDimensions();
  //   const TDSMaster = useSelector((state)=>state?.AccountList?.AccountListData?.P_M53NO);
  // console.log("TDSMaster",TDSMaster);
  // console.log("TDSMasterData",TDSMasterData?.data?.data?.jData)
  const isLoading = useSelector((state) => state?.TDSMasterGroup?.isLoading);
  const isLoading1 = useSelector((state) => state?.AccountList?.isLoading);
  const TDSMaster = useSelector(
    (state) => state?.AccountList?.AccountListData?.P_M53NO
  );

  // console.log("TDSMasterData", TDSMasterData);
  // console.log("TDSMaster", TDSMaster);

  // useEffect(()=>{
  //   console.log("rowselection",rowSelection1);
  // },[rowSelection1])

  useEffect(() => {
    if (fmtId) {
      Promise.resolve(dispatch(GetTDSMasterGroup({ fmtId }))).then(() => {
        Store.dispatch(
          GetAccountList({
            id: "P_M53NO",
            name: "",
            p0: "TFORM0000001",
            p1: "P_M53NO",
            p2: "",
            p3: "",
            type: "A",
          })
        );
      });
    }
  }, [fmtId]);

  // console.log("frmtId",frmtId)

  // console.log("data",data)

  useEffect(() => {
    let column = [];
    column.push(
      columnHelper.accessor("FIELD02", {
        header: "Deductee Name",
        enableColumnFilter: false,
        enableGrouping: true,
        enableSorting: false,
        size: "300",
      })
    );
    column.push(
      columnHelper.accessor("FIELD11", {
        header: "Applied Date",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) =>
          cell.getValue().slice(6, 8) +
          "/" +
          cell.getValue().slice(4, 6) +
          "/" +
          cell.getValue().slice(0, 4),
        size: "300",
      })
    );
    column.push(
      columnHelper.accessor("FIELD15", {
        header: "TDS Limit",
        enableColumnFilter: false,
        enableSorting: false,
        size: "200",
      })
    );
    column.push(
      columnHelper.accessor("FIELD16", {
        header: "TDS%",
        enableColumnFilter: false,
        enableSorting: false,
        size: "200",
      })
    );

    setUpdatedColumn(column);
  }, [data]);

  useEffect(() => {
    let column1 = [];
    column1.push(
      columnHelper.accessor("FIELD02", {
        header: "Select Deductee Type",
        enableColumnFilter: false,
        enableGrouping: true,
        enableSorting: false,
        size: "200",
      })
    );

    setUpdatedColumn1(column1);
  }, [showDetails]);
  useEffect(() => {
    if(flag==true && TDSMasterData?.data?.data?.jData && TDSMaster){
    try {
      var final = [];
      var mainData = TDSMaster?.filter(
        (e) => e.FIELD00 == "R" && fmtId == e?.FIELD13
      );

      mainData.map((e) => {
        TDSMasterData?.data?.data?.jData.map((d) => {
          if (d.FIELD01 == e.FIELD12) {
            final.push({ ...d });
          }
        });
      });
      Promise?.resolve(setData(final)).then(()=>{
        setTimeout(()=>{setFlag(false)},1000)
      })
    } catch (error) {
      console.error(error);
    }}
  }, [flag,TDSMasterData?.data?.data?.jData,TDSMaster]);

//   const deducteeTable = useMantineReactTable({
//     data : showDetails ? showDetails : [],
//     columns : updatedColumn1 ? updatedColumn1 : [],
//     enableFilters: false,
//     enableColumnActions: false,
//     enableFullScreenToggle: false,
//     enableDensityToggle: false,
//     enableTopToolbar: false,
//     enablePagination: false,
//     onRowSelectionChange: setRowSelection,
//     mantineTableContainerProps: {
//       sx: { height: height * 0.40 },
//     },
//     mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
//       onClick: () => {
//         console.log("hello from onclick",row)
//         setRowSelection((prev) => ({
//           [row.id]: !prev[row.id],
//         }))
//         // ).then(()=>{
//           // console.log("rowSelection",rowSelection);
//         // })
//       },
//       selected: rowSelection[row.id],
//       sx: { cursor: "pointer" },
//     }),
//     initialState:{
//       density:"0px"
//     },
//     state:{
//       rowSelection 
//     }
// })

  const detailsClicked = () => {
    // let filteredData1 = TDSMaster?.filter((a) => 
    //   console.log("f111",a)
    // );
    // console.log("filteredData1",filteredData1);
    if(Object.keys(rowSelection1).length > 0 && Object.keys(PLValue).length > 0){
    let filteredData = TDSMasterData?.data?.data?.jData?.filter((a) => 
       a?.FIELD21 == "1" || a?.FIELD00 == "D"
    );
    Promise.resolve(setShowDetails(filteredData)).then(()=>{
      setDrawerObj({
        ...dra,
        // title: <Text fw={700}>Select deductee Type</Text>,
        body: (
          <>
          
          {/* <MantineReactTable table={deducteeTable}/>
          <Grid>
          <Grid.Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          mt={5}
        >
          <Button size="sm" compact>
            Show Details
          </Button>
        </Grid.Col>
        </Grid> */}
        <DeducteeTypeTable showDetails={filteredData} updatedColumn1={updatedColumn1}/>
          </>
        ),
        open: true,
        size: "75%",
        position: "right",
      });
    })
    ;}
  
  };

  const table = useMantineReactTable({
    columns: updatedColumn ? updatedColumn : [],
    data: data ? data : [],
    enableFilters: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enablePagination: true,
    enableGrouping: true,
    initialState: {
      expanded: false,
      grouping: ["FIELD02"],
      density: "0px",
    },
    mantineTableProps:{withColumnBorders:true},
    mantineTableContainerProps: {
      sx: { height: height * 0.6 },
    },
    state: {
      rowSelection : rowSelection1,
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      
      onClick: () => {
        setRowSelection1((prev) => ({
          [row.id]: [row.id],
        }));
      },
      selected: rowSelection1[row.id],
      sx: { cursor: "pointer" },
    }),
    // enableRowSelection:true,
    // enableMultiRowSelection:false,
    // selectDisplayMode:"radio"
  });

  

  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {

            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            }
            else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        closeOnEscape={false}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
        // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>
      <Grid gutter={4}>
        <Grid.Col span={2}>
          <Text size="sm">Nature of payment</Text>
        </Grid.Col>
        <Grid.Col span={1}></Grid.Col>
        <Grid.Col span={7}>
          <PLDataGrid
            // isButton={true}
            ref={inputRef}
            // autoFocus={true}
            width={"450px"}
            p1={"P_M53NO"}
            cWhere={"FIELD00 = 'N'"}
            p0={"TFORM0000001"}
            setEdit={(e) => {
              
              Promise.resolve(setFmtId(e.FIELD01)).then(()=>{
                setRowSelection1({});
                setSection(e.FIELD04);
                setPaymentCode(e.FIELD05);
                setPLValue(e?.FIELD01);}).then(()=>{
                  setFlag(true)
                })
            }}
            value={PLValue}
          />
        </Grid.Col>
        <Grid.Col span={2}></Grid.Col>

        <Grid.Col span={2}>
          <Text size="sm">Section: {section}</Text>
        </Grid.Col>
        <Grid.Col span={1}></Grid.Col>
        <Grid.Col span={2}>
          <Text size="sm">Payment Code: {paymentCode}</Text>
        </Grid.Col>
        <Grid.Col span={7}></Grid.Col>

        {/* <Grid.Col span={12}> */}
        {/* <Paper style={{height:"300px"}}> */}
        {(isLoading || isLoading1)
          ? GlobalClass.RenderLoader("skeleton")
          : updatedColumn &&
            updatedColumn.length > 0 &&
            data &&
            data.length > 0 && (
             <Grid.Col span={12}><MantineReactTable table={table} striped={true} /></Grid.Col> 
            )}
        <Grid.Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          mt={5}
        >
          <Button size="xs" mr={3} onClick={natureOfPaymentCalled}>
            Nature Of Payment
          </Button>
          <Button size="xs" onClick={detailsClicked}>
            Show Details
          </Button>
        </Grid.Col>
        {/* </Paper> */}
        {/* </Grid.Col> */}
      </Grid>
    </>
  );
};
