import {
  ActionIcon,
  Button,
  Grid,
  Loader,
  Modal,
  ScrollArea,
  Table,
  Tabs,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCMPSetup,
  SetTableObjArray,
} from "../utils/slices/CompanySetupSlice";
import "react-data-grid/lib/styles.css";
import dayjs from "dayjs";

import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Anchor,
  Group,
  rem,
} from "@mantine/core";
import {
  Icon123,
  IconApps,
  IconArrowBackUp,
  IconArrowLeft,
  IconBackspace,
  IconBackspaceFilled,
  IconBarcode,
  IconBooks,
  IconBrandDatabricks,
  IconBuildingBank,
  IconDeviceDesktopAnalytics,
  IconDeviceFloppy,
  IconFileDescription,
  IconFilePercent,
  IconFileUpload,
  IconInfoCircle,
  IconLivePhoto,
  IconMail,
  IconReceiptTax,
  IconRefresh,
  IconReportAnalytics,
  IconReportMoney,
  IconServerCog,
  IconSettings2,
  IconSettingsPlus,
  IconShare2,
  IconShoppingCartDiscount,
  IconTableOptions,
} from "@tabler/icons-react";
import PLComboBox from "../PlusComponent/PLComboBox";
import PLNumberBox from "../PlusComponent/PLNumberBox";
import PLTextBox from "../PlusComponent/PLTextBox";
import PLDateBox from "../PlusComponent/PLDateBox";
import { TDSMaster } from "../FunctionsCall/TDSMaster/TDSMaster";
import PLModalBox from "../PlusComponent/PLModalBox";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import Localize from "../utils/rc";
import Store from "../utils/store";
import { ModalDelete, ModalFunction } from "../utils/slices/ModalSlice";
import UserMasterTable from "../FunctionsCall/UserMaster/UserMasterTable";
import EntryType from "../SetupComponent/EntryType/EntryType";
import UserFieldModal from "../FunctionsCall/UserField/UserFieldModal";
import { GetCasePartyRequired } from "../utils/TransactionSlices/TSalesSlices";
import GlobalClass from "../utils/GlobalClass";
import gensetting from "../utils/gensetting";
import {
  DataSaveLoading,
  GetCmpSetUpObj,
} from "../utils/slices/DataSaveLoadingSlice";
import TickerDetail from "../SetupComponent/CompanySetup/TickerDetail";
import AccountPopUpDetail from "../SetupComponent/CompanySetup/AccountPopUpDetail";
import ProductPopUpDetail from "../SetupComponent/CompanySetup/ProductPopUpDetail";
import ProductPopUpType from "../SetupComponent/CompanySetup/ProductPopUpType";
import BranchDetail from "../SetupComponent/CompanySetup/BranchDetail";
import CmpSetupComboBox from "../SetupComponent/CompanySetup/CmpSetupComboBox";
// import AlertSMSVou from "../SetupComponent/CompanySetup/AlertSMSVou";
import AuditUser from "../SetupComponent/CompanySetup/AuditUser";
import BaseCurrency from "../SetupComponent/CompanySetup/BaseCurrency";
import CS_ECommerceOperator from "../SetupComponent/CompanySetup/CS_ECommerceOperator";
import NatureOfGoods from "../SetupComponent/CompanySetup/NatureOfGoods";
import PlusM525 from "../SetupComponent/CompanySetup/PlusM525";
import SecLang from "../SetupComponent/CompanySetup/SecLang";
import DefaultInvType from "../SetupComponent/CompanySetup/DefaultInvType";
import BillSeries from "../SetupComponent/CompanySetup/BillSeries";
import AccountPopUpType from "../SetupComponent/CompanySetup/AccountPopUpType";
// import EInvBal from "../SetupComponent/CompanySetup/EInvBal";
// import BarcodeType from "../SetupComponent/CompanySetup/BarcodeType";
import BillType from "../SetupComponent/CompanySetup/BillType";
import UserFieldAutoNumModal from "../SetupComponent/CompanySetup/UserFieldAutoNumber/UserFieldAutoNumModal";
import BrokerRequired from "../SetupComponent/CompanySetup/BrokerRequired";
import CommonPrefixSuffix from "../SetupComponent/CompanySetup/CommonPrefixSuffix";
import POSMachines from "../SetupComponent/CompanySetup/POSMachines";
import PlusM554 from "../SetupComponent/CompanySetup/PlusM554";
import TDS_TCSDetails from "../SetupComponent/CompanySetup/TDS_TCSDetails";
import CashPartyDetail from "../SetupComponent/VoucherSetup/CashPartyDetail";
import GSTRulesCheck from "../SetupComponent/VoucherSetup/GSTRulesCheck";
import PLUSM471 from "../SetupComponent/VoucherSetup/PLUSM471";
import ApplicableTaxRate from "../SetupComponent/VoucherSetup/ApplicableTaxRate";
import GroupFilterInParty from "../SetupComponent/VoucherSetup/GroupFilterInParty";
import WaterMarkList from "../SetupComponent/CompanySetup/WaterMarkList/WaterMarkList";
import WaterMarkListModal from "../SetupComponent/CompanySetup/WaterMarkList/WaterMarkListModal";
import LanZoom from "../SetupComponent/CompanySetup/LanZoom";
import useWindowDimensions from "../utils/UseWindowDimensions";
import VouchValidExpForm from "../SetupComponent/VoucherSetup/VoucherSetUpForm/VouchValidExpForm";
import PLUSM440 from "../SetupComponent/VoucherSetup/VoucherSetUpForm/PLUSM440";
import ItemWiseForm from "../SetupComponent/VoucherSetup/VoucherSetUpForm/ItemWiseForm";
import FormatSelectionForm from "../SetupComponent/VoucherSetup/VoucherSetUpForm/FormatSelectionForm";
import AuditorPassword from "../SetupComponent/CompanySetup/AuditorPassword";
import AutoVatSetUpForm from "../SetupComponent/CompanySetup/AutoVatSetUpForm";
// import MultiBarCode from "../SetupComponent/CompanySetup/MultiBarCode";
// import UserMaster from "./UserMaster/UserMaster";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: rem(90),
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));

export default function CMPSetup(props) {
  console.log("CMPSetup props ==>", props);
  let rc = new Localize().rc;

  const { obj } = props;
  // console.log("CMPSetup props", obj);
  const [scrolled, setScrolled] = useState(false);
  const { height } = useWindowDimensions();
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: null
  }

  const [DrawerObj, setDrawerObj] = useState(dra);
  const dispatch = useDispatch();
  const CompanySetupData = useSelector(
    (state) => state?.CompanySetup?.CompanySetupData?.S72DT
  );
  const CompanySetupDatacol = useSelector(
    (state) => state.CompanySetup?.CompanySetupData?.S72Grd
  );

  const { isLoadingCmp, TableObjArray, hasError, ErrorMsg } = useSelector(
    (state) => state?.CompanySetup
  );

  const CompanySetup = useSelector(
    (state) => state.CompanySetup?.CompanySetupData?.F02DT
  );
  const CompanySetupcol = useSelector(
    (state) => state.CompanySetup?.CompanySetupData?.F02Grd
  );

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  // const { hasError, ErrorMsg, isLoadingCMP } = useSelector((state) => state?.CompanySetup);

  const CasePartyRequired = useSelector(
    (state) => state?.TSalesSlices?.CasePartyRequired
  );

  const CmpVouSetUpObj = useSelector(
    (state) => state.DataSaveLoadingSlice?.CmpSetUpObjSave
  );
  // console.log('CmpSetUpObj', CmpSetUpObj)

  // console.log('CompanySetupData===>', CompanySetupData);
  // console.log('CompanySetupDatacol===>', CompanySetupDatacol);
  // console.log('CompanySetup===>', CompanySetup);
  // console.log('CompanySetupcol===>', CompanySetupcol);

  // const [selected, setSelected] = useState(null);
  // const [selectedItem, setSelectedItem] = useState(null);
  // console.log('selectedItem', selectedItem)
  // {
  //   LACTIVE: 1,
  //   S72F00: "Company Setup",
  //   S72F01: "CCCC",
  //   S72F02: "GERG",
  //   S72F03: "GERG",
  //   S72F04: "",
  //   S72F05: "005",
  //   S72F06: "General Setup",
  //   S72F07: "",
  //   S72UID: 33,
  // }

  const [comboBox, setComboBox] = useState("");
  const [numberBox, setNumberBox] = useState("");
  const [dateBox, setDateBox] = useState("");
  const [textBox, setTextBox] = useState("");
  const [editObj, setEdit] = useState({});
  const [blurObj, setBlurObj] = useState({});
  // console.log("editObj==>", editObj);
  const [modalBox, setModalBox] = useState("");
  const [columns, setColumns] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [blur, setBlur] = useState(false);

  const [TableRowObj, setTableRowObj] = useState([]);

  // console.log("test", CompanySetup?.filter((v, i) => v.F02F02 === "GERG"));
  // let defData = CompanySetupData[0];

  // console.log(CompanySetupData, "CompanySetupData");
  // useEffect(() => {
  //   dispatch(GetCMPSetup(obj));
  // }, []);
  // console.log(CompanySetupData);
  // console.log(CompanySetupData[0]);

  useEffect(() => {
    if (hasError && !isLoadingCmp) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    console.log("CmpVouSetUpObj BlurObj.......12", CmpVouSetUpObj);
    if (props.cID && CmpVouSetUpObj == null) {
      Promise.resolve(dispatch(GetCMPSetup({ cID: props?.cID }))).then(() => {
        if (props.cID == "CCCC") {
          Store.dispatch(
            GetCmpSetUpObj({
              LACTIVE: 1,
              S72F00: "Company Setup",
              S72F01: "CCCC",
              S72F02: "GERG",
              S72F03: "GERG",
              S72F04: "",
              S72F05: "005",
              S72F06: "General Setup",
              S72F07: "",
              S72UID: 33,
            })
          );
        } else {
          Store.dispatch(
            GetCmpSetUpObj({
              LACTIVE: 1,
              S72F00: "Voucher Setup",
              S72F01: "TTTT",
              S72F02: "SPSS",
              S72F03: "_VTYLNSS",
              S72F04: "",
              S72F05: "010",
              S72F06: "Sales Invoice",
              S72F07: "!SH",
              S72UID: 10,
            })
          );
        }
      });
    }
  }, [props, CmpVouSetUpObj, obj]);

  const { classes, theme, cx } = useStyles();

  const items =
    CompanySetupData &&
    CompanySetupData.map((item) => {
      var Icon = IconBooks;
      switch (item.S72F06) {
        case "General Setup":
          // setActive(item.name);
          // setSubMenuarray(item.items)
          Icon = IconServerCog;
          break;
        case "Rajkot Live":
          Icon = IconLivePhoto;
          break;
        case "Company Info Setup":
          Icon = IconInfoCircle;
          break;
        case "Master Setup":
          Icon = IconSettings2;
          break;
        case "E-Milan Setup":
          Icon = IconMail;
          break;
        case "Advance Setup":
          Icon = IconSettingsPlus;
          break;
        case "Customization":
          Icon = IconTableOptions;
          break;
        case "GST":
          Icon = IconFilePercent;
          break;
        case "VAT Setup":
          Icon = IconReceiptTax;
          break;
        case "Data Synchronize":
          Icon = IconBrandDatabricks;
          break;
        case "TDS/TCS Setup":
          Icon = IconArrowBackUp;
          break;
        case "Report Setup":
          Icon = IconFileDescription;
          break;
        case "Serial No. Setup":
          Icon = Icon123;
          break;
        case "BarCode Setup":
          Icon = IconBarcode;
          break;
        case "Share Setup":
          Icon = IconShare2;
          break;
        case "Dealer Excise":
          Icon = IconShoppingCartDiscount;
          break;
        case "AIOCD Data Upload":
          Icon = IconFileUpload;
          break;
        case "Point of Sale":
          Icon = IconReportMoney;
          break;
        case "SalesPlus Integration":
          Icon = IconReportAnalytics;
          break;
        case "Online Net Banking":
          Icon = IconBuildingBank;
          break;
        case "Online App":
          Icon = IconApps;
          break;
        default:
          Icon = IconDeviceDesktopAnalytics;
          break;
      }
      return (
        <Tabs.Tab
          onClick={() => {
            // setSelected(CompanySetup.filter((v,i)=>v.F02F02==item.S72F02));
            // setSelectedItem(item);
            Store.dispatch(GetCmpSetUpObj(item));
          }}
          value={"table"}
          style={{
            borderColor:
              // CmpSetUpObj?.S72F06 == item?.S72F06 ? "Highlight" : "",
              CmpVouSetUpObj?.S72F06 == item?.S72F06 ? "Highlight" : "",
          }}
          icon={<Icon size="0.8rem" color={theme.colors[item.color]} />}
        >
          {item?.S72F06}
        </Tabs.Tab>
      );
    });
  const [Data, setData] = useState([]);
  const inputRef = useRef(null);
  const columnHelper = createColumnHelper();

  useEffect(() => {
    if (CompanySetup) {
      console.log("BlurObj.......cmp");
      dispatch(SetTableObjArray(CompanySetup));
    }
  }, [CompanySetup]);
  const tabled = useMantineReactTable({
    data: Data ?? [],
    columns,
    mantineTableProps:{withColumnBorders:true},
    renderBottomToolbar: () => {
      return <Group style={{ justifyContent: 'space-between' }}><Button
        mr={10}
        ml={4}

        // onClick={() => {
        //   setSelected(null);
        //   setSelectedItem(null);
        // }}
        onClick={() => setEdit({})}
        variant="outline"
        radius="md"
        color="gray"
      >
        <IconRefresh size="1.5rem" /> Reset
      </Button>
        <Button
          mr={4}
          ml={4}

          // onClick={() => {
          //   setSelected(null);
          //   setSelectedItem(null);
          // }}
          onClick={() => saveCmpSetup()}
          variant="filled"
          radius="md"
          color="teal"
        >
          <IconDeviceFloppy size="1.5rem" /> Save
        </Button> </Group>
    },
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableGrouping: true,
    enableDensityToggle: false,
    initialState: {
      grouping,
      density: "5px",
    },
    state: {
      grouping,
      density: "5px",
    },
    mantineTableContainerProps: {
      sx: { height: height * 0.75 },
    },
    layoutMode: 'semantic',
    enablePagination: false,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableColumnDragging: false,
    enableColumnActions: false,
    onGroupingChange: setGrouping,
    enableGlobalFilterModes: false,
    enableRowVirtualization: true,
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableEditing: true,
    editDisplayMode: "cell",

    // mantineEditTextInputProps: ({ cell }) => ({
    //   onBlur: (event) => {
    //     console.info(event, cell.id, "onblur");
    //   },
    // }),
    mantineTableProps: { // Nairin Salot
      highlightOnHover: false // Set highlightOnHover to false
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {
        // setEdit(row.original)
        console.log("row", row);
        console.log("cell", cell);
      },
      onClick: () => {
        console.log("on click row", row);
        console.log("cell", cell);
        // handleClick(cell, row)
        if (blur) {
          // If blur is true, disable editing
          tabled.setEditingCell(null);
        } else {
          return;
        }

        setBlur(false);
      },
    }),
  });

  const UserMasterFunction = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "70%",
      body: <UserMasterTable
        //  open={true}
        // data={d}
        data={v}
        data2={CompanySetupcol.filter((v, i) => v.S53F00 == "C").sort(
          (a, b) => a.S53F09 - b.S53F09
        )}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
  };
  const EntryTypeFunction = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "70%",
      body: <EntryType
        //  open={true}
        // data={d}
        data={v}
        data2={CompanySetupcol.filter((v, i) => v.S53F00 == "C").sort(
          (a, b) => a.S53F09 - b.S53F09
        )}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
    // console.log("EntryTypeFunction call");
    // Promise.resolve(
    //   Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{v.F02F03E}</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: () => (
    //         <EntryType
    //           open={true}
    //           // data={d}
    //           data={v}
    //           data2={CompanySetupcol.filter((v, i) => v.S53F00 == "C").sort(
    //             (a, b) => a.S53F09 - b.S53F09
    //           )}
    //           setModal={(e) => {
    //             table.setEditingCell();
    //           }}
    //           obj={obj}
    //         />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       },
    //     })
    //   )
    // ).then(() => table.setEditingCell());
  };
  const UserFieldFunction = (v, d, table) => {
    // console.log("UserFieldFunctioncall");
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "70%",
      body: <UserFieldModal
        //  open={true}
        // data={d}
        data={v}
        data2={CompanySetupcol.filter((v, i) => v.S53F00 == "C").sort(
          (a, b) => a.S53F09 - b.S53F09
        )}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    // Promise.resolve(
    //   Store.dispatch(
    //     ModalFunction()
    //   )
    // )
    // .then(() => table.setEditingCell());
    table.setEditingCell()
  };
  const AlertMsgTypeFunction = (v, d, table) => {
    // Promise.resolve().then(() => table.setEditingCell())
    console.log('v =>>', v)
    console.log('editObj[v.F02F01].value', editObj[v.F02F01]?.value)
    console.log('editObj[v.F02F01])', typeof editObj[v.F02F01])
    console.log('d =>', d)
    return (
      <CmpSetupComboBox
        DefineCol={v?.F02F01}
        // editObjSend={editObj}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          })
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          } else {
            table.setEditingCell()
          }
        }}
      />
    );
  };
  const TickerDetailFunction = (v, d, table) => {
    return <TickerDetail
      obj={v}
      value={
        (typeof editObj[v.F02F01] == "object"
          ? editObj[v.F02F01].value
          : editObj[v.F02F01]) ?? d
      }
      setEditSend={(e) => {
        // console.log("==============>", e);
        setEdit({
          ...editObj,
          [v.F02F01]: e
        })
        table.setEditingCell();
      }}
    />
  };
  const AccPopUpDetailFun = (v, d, table) => {
    return (
      <AccountPopUpDetail
        obj={v}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
      />
    );
  };
  const ProductPopUpDeailFun = (v, d, table) => {
    return (
      <ProductPopUpDetail
        obj={v}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
      />
    );
  };
  const ProductPopUpTypeFun = (v, d, table) => {
    return (
      <ProductPopUpType
        obj={v}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
      />
    );
  };
  const BranchDetailFun = (v, d, table) => {
    return (
      <BranchDetail
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const ReqQtyDetailsFun = (v, d, table) => {
    return (
      <CmpSetupComboBox
        DefineCol={v?.F02F01}
        // editObjSend={editObj}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const AlertSMSVouFun = (v, d, table) => {
    // console.log('funcal=>>')
    return (
      <CmpSetupComboBox
        DefineCol={v?.F02F01}
        // editObjSend={editObj}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const AuditUserFun = (v, d, table) => {
    return (
      <AuditUser
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const LANZoomFun = (v, d, table) => {
    return (
      <LanZoom
        setEditSend={(e) => {
          // console.log('LANZoomFun e',e)
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const BaseCurrencyFun = (v, d, table) => {
    return (
      <BaseCurrency
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const ECommerceOperatorFun = (v, d, table) => {
    return (
      <CS_ECommerceOperator
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const NatureOfGoodsFun = (v, d, table) => {
    return (
      <NatureOfGoods
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const PLUSM525Fun = (v, d, table) => {
    return (
      <PlusM525
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const SecLangFun = (v, d, table) => {
    return (
      <SecLang
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const DefaultInvTypeFun = (v, d, table) => {
    return (
      <DefaultInvType
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const BillSeriesFun = (v, d, table) => {
    return (
      <BillSeries
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const AccountPopTypeFun = (v, d, table) => {
    return (
      <AccountPopUpType
        setEditSend={(e) => {
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        obj={v}
      />
    );
  };
  const EInvTypFun = (v, d, table) => {
    return (
      <CmpSetupComboBox
        DefineCol={v?.F02F01}
        // editObjSend={editObj}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const BarcodeTypeFun = (v, d, table) => {
    return (
      <CmpSetupComboBox
        DefineCol={v?.F02F01}
        // editObjSend={editObj}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const BillTypeFun = (v, d, table) => {
    return <BillType obj={v} />;
  };
  const UserFieldAutoNumFun = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "70%",
      body: <UserFieldAutoNumModal
        //  open={true}
        // data={d}
        data={v}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
  };
  const MultiBarCodeFun = (v, d, table) => {
    return (
      <CmpSetupComboBox
        DefineCol={v?.F02F01}
        // editObjSend={editObj}
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const BrokerRequiredFun = (v, d, table) => {
    return (
      <BrokerRequired
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
      />
    );
  };
  const PrefixSuffixFun = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "md",
      body: <CommonPrefixSuffix
        //  open={true}
        // data={d}
        data={v}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
        DrawerObjSend={DrawerObj}
        setDrawerObjSend={(e) => {
          setDrawerObj(e)
        }}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
  };
  const POSMachinesFun = (v, d, table) => {
    return (
      <POSMachines
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        obj={v}
      />
    );
  };
  const PLUSM554Fun = (v, d, table) => {
    return (
      <PlusM554
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
        obj={v}
      />
    );
  };
  const WaterMarkListFun = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "70%",
      body: <WaterMarkListModal
        //  open={true}
        // data={d}
        data={v}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
  };
  const TDS_TCSDetailFun = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "70%",
      body: <TDS_TCSDetails
        //  open={true}
        // data={d}
        data={v}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
  };
  const AuditorPasswordFun = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "md",
      body: <AuditorPassword
        //  open={true}
        // data={d}
        data={v}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
  }
  const AutoVatSetupFun = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "lg",
      body: <AutoVatSetUpForm
        //  open={true}
        // data={d}
        data={v}
        setModal={(e) => {
          table.setEditingCell();
        }}
        obj={obj}
      />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
  }

  // Voucher Setup
  const CashPartyDetailFun = (v, d, table) => {
    return (
      <CashPartyDetail
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("CashPartyDetail value", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const GSTRulesCheckFun = (v, d, table) => {
    return (
      <GSTRulesCheck
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const PlusM471Fun = (v, d, table) => {
    return (
      <PLUSM471
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
      />
    );
  };
  const ApplicableTaxRateFun = (v, d, table) => {
    return (
      <ApplicableTaxRate
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
        }}
        closeCombo={(e) => {
          if (e == true) {
            table.setEditingCell();
          }
        }}
      />
    );
  };
  const GroupFilterPartyFun = (v, d, table) => {
    return (
      <GroupFilterInParty
        value={
          (typeof editObj[v.F02F01] == "object"
            ? editObj[v.F02F01].value
            : editObj[v.F02F01]) ?? d
        }
        setEditSend={(e) => {
          // console.log("==============>", e);
          setEdit({
            ...editObj,
            [v.F02F01]: e,
          });
          table.setEditingCell();
        }}
      />
    );
  };
  const FormatSelectionfn = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "50%",
      body: <FormatSelectionForm />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
    // Promise.resolve(
    //   Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{v.F02F03E}</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: () => <FormatSelectionForm />,
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       },
    //     })
    //   )
    // ).then(() => table.setEditingCell());
  };
  const ItemWiseFormfn = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "50%",
      body: <ItemWiseForm />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
    // Promise.resolve(
    //   Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{v.F02F03E}</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: () => <ItemWiseForm />,
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       },
    //     })
    //   )
    // ).then(() => table.setEditingCell());
  };
  const PLUSM440fn = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "50%",
      body: <PLUSM440 />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
    // Promise.resolve(
    //   Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{v.F02F03E}</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: () => <PLUSM440 />,
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       },
    //     })
    //   )
    // ).then(() => table.setEditingCell());
  };
  const VouchValidExpFn = (v, d, table) => {
    setDrawerObj({
      onClickCloseBtn: () => { setDrawerObj(dra); },
      title: <Text fw={700}>{v.F02F03E}</Text>,
      action: true,
      open: true,
      size: "50%",
      body: <VouchValidExpForm />,
      close: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
    table.setEditingCell()
    // Promise.resolve(
    //   Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{v.F02F03E}</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: () => <VouchValidExpForm />,
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       },
    //     })
    //   )
    // ).then(() => table.setEditingCell());
  };
  const RenderPageFunction = (v, d, table) => {
    let EditPage;
    console.log(v?.F02F01, "F02F01-");
    switch (v?.F02F01) {
      case "GEU00002":
        EditPage = <>{UserFieldFunction(v, d, table)}</>;
        break;
      case "GEU00001":
        EditPage = <>{UserMasterFunction(v, d, table)}</>;
        break;
      case "SPSS0001":
      case "SPSS0002":
      case "SPPPX001":
      case "SPPP0002":
      case "SPSS0001":
      case "SPSS0001":
      case "SPSS0001":
      case "SPSS0001":
      case "SPSS0001":
      case "SPHCX001":
      case "SPHC0002":
      case "SPHDX001":
      case "SPHD0002":
      case "SPBP0012":
      case "SPBP0011":
      case "SPBR0012":
      case "SPBR0011":
      case "SPCP0012":
      case "SPCP0011":
      case "SPCR0012":
      case "SPCR0011":
      case "SPSS0001":
      case "SPSH0006":
      case "SPSS0001":
      case "SPJJ0032":
      case "SPJJ0033":
      case "SPJZ0032":
      case "SPJZ0033":
        EditPage = <>{EntryTypeFunction(v, d, table)}</>;
        break;
      case "GET00246":
        EditPage = <>{AlertMsgTypeFunction(v, d, table)}</>;
        break;
      case "SWT0A013":
        EditPage = <>{TickerDetailFunction(v, d, table)}</>;
        break;
      case "GE000239":
        EditPage = <>{AccPopUpDetailFun(v, d, table)}</>;
        break;
      case "GE000240":
        EditPage = <>{ProductPopUpDeailFun(v, d, table)}</>;
        break;
      case "RG000011":
        EditPage = <>{ProductPopUpTypeFun(v, d, table)}</>;
        break;
      case "GE000342":
        EditPage = <>{BranchDetailFun(v, d, table)}</>;
        break;
      case "RGQD0001":
        EditPage = <>{ReqQtyDetailsFun(v, d, table)}</>;
        break;
      case "GE000245":
        EditPage = <>{AlertSMSVouFun(v, d, table)}</>;
        break;
      case "MKCK0010":
        EditPage = <>{AuditUserFun(v, d, table)}</>;
        break;
      case "LANZOOM3":
        EditPage = <>{LANZoomFun(v, d, table)}</>;
        break;
      case "GEPCSC12":
        EditPage = <>{BaseCurrencyFun(v, d, table)}</>;
        break;
      case "GST00004":
        EditPage = <>{ECommerceOperatorFun(v, d, table)}</>;
        break;
      case "GETFC004":
        EditPage = <>{NatureOfGoodsFun(v, d, table)}</>;
        break;
      case "RTDS0162":
        EditPage = <>{PLUSM525Fun(v, d, table)}</>;
        break;
      case "RGLAN001":
        EditPage = <>{SecLangFun(v, d, table)}</>;
        break;
      case "POS00004":
        EditPage = <>{DefaultInvTypeFun(v, d, table)}</>;
        break;
      case "POS00005":
        EditPage = <>{BillSeriesFun(v, d, table)}</>;
        break;
      case "RG000012":
        EditPage = <>{AccountPopTypeFun(v, d, table)}</>;
        break;
      case "GST00039":
        EditPage = <>{EInvTypFun(v, d, table)}</>;
        break;
      case "GEBC0015":
        EditPage = <>{BarcodeTypeFun(v, d, table)}</>;
        break;
      case "POS00006":
        EditPage = <>{BillTypeFun(v, d, table)}</>;
        break;
      case "GEU00004":
        EditPage = <>{UserFieldAutoNumFun(v, d, table)}</>;
        break;
      case "GEBC0016":
        EditPage = <>{MultiBarCodeFun(v, d, table)}</>;
        break;
      case "GEJB0004":
        EditPage = <>{BrokerRequiredFun(v, d, table)}</>;
        break;
      case "GE000234":
        EditPage = <>{PrefixSuffixFun(v, d, table)}</>;
        break;
      case "POS00012":
        EditPage = <>{POSMachinesFun(v, d, table)}</>;
        break;
      case "POS00030":
        EditPage = <>{PLUSM554Fun(v, d, table)}</>;
        break;
      case "WMARK001":
        EditPage = <>{WaterMarkListFun(v, d, table)}</>;
        break;
      case "MM000005":
        EditPage = <>{TDS_TCSDetailFun(v, d, table)}</>;
        break;
      case "SPSS0083":
        EditPage = <>{CashPartyDetailFun(v, d, table)}</>;
        break;
      case "SPRLSS02":
        EditPage = <>{GSTRulesCheckFun(v, d, table)}</>;
        break;
      case "SPORD317":
        EditPage = <>{PlusM471Fun(v, d, table)}</>;
        break;
      case "SPSF0051":
        EditPage = <>{ApplicableTaxRateFun(v, d, table)}</>;
        break;
      case "SPSS0099":
        EditPage = <>{GroupFilterPartyFun(v, d, table)}</>;
        break;
      case "SPBPF050": //Format Selection Expression
      case "SPBRF050":
      case "SPCG0003":
      case "SPCPF050":
      case "SPCRF050":
      case "SPDG0003":
      case "SPEG0003":
      case "SPHCF050":
      case "SPHDF050":
      case "SPHOF050":
      case "SPHPF050":
      case "SPHRF050":
      case "SPHSF050":
      case "SPIG0003":
      case "F2XX2036":
      case "SPINF050":
      case "SPJAF050":
      case "SPJCF050":
      case "SPJDF050":
      case "SPJIF050":
      case "SPJJF050":
      case "SPJOF050":
      case "SPJRF050":
      case "SPJWF050":
      case "SPJXF050":
      case "SPJYF050":
      case "SPJZF050":
      case "SPNCF050":
      case "SPNDF050":
      case "SPOJF050":
      case "SPOPF050":
      case "SPOSF050":
      case "F2XX2041":
      case "SPPPF050":
      case "SPPRF050":
      case "F2XX2045":
      case "SPQTF050":
      case "SPSJF050":
      case "SPSRF050":
      case "SPSF0050":
      case "SPV60018":
      case "SPV70018":
      case "SPV80018":
        EditPage = <>{FormatSelectionfn(v, d, table)}</>;
        break;

      case "SPS00061": //Item Entry Validation Expression
      case "SPS00051":
      case "SPS00065":
      case "SPHC0065":
      case "SPHD0065":
      case "HOS00065":
      case "SPS00059":
      case "SPHR0061":
      case "HXS00065":
      case "HYS00065":
      case "SPS00069":
      case "SPS00067":
      case "SPJA0012":
      case "SPJC0012":
      case "SPJD0012":
      case "SPJI0012":
      case "SPJJ0023":
      case "SPJO0012":
      case "SPJW0012":
      case "SPJX0012":
      case "SPJY0012":
      case "SPJZ0023":
      case "SPNI0019":
      case "SPNR0019":
      case "SPOJ0017":
      case "SPS00063":
      case "SPPH0002":
      case "SPS00053":
      case "SPS00055":
      case "SPSQ0065":
      case "SPRB0019":
      case "SPSJ0023":
      case "SPSK0010":
      case "SPS00057":
      case "SPS00081":
      case "SPVM0019":
      case "SPVN0023":
        EditPage = <>{ItemWiseFormfn(v, d, table)}</>;
        break;
      case "SPS00064": //Voucher Validation Expression
      case "SPS00056":
      case "SPS00052":
      case "SPS00060":
      case "SPS00050":
      case "SPHR0060":
      case "SPS00062":
      case "SPS00058":
      case "SPS00054":
      case "SPHC0064":
      case "SPHD0064":
      case "HOS00064":
      case "SPS00068":
      case "SPS00066":
      case "SPS00080":
      case "HXS00064":
      case "HYS00064":
      case "SPJA0011":
      case "SPJC0011":
      case "SPJD0011":
      case "SPJI0011":
      case "SPJJ0022":
      case "SPJO0011":
      case "SPJW0011":
      case "SPJX0011":
      case "SPJY0011":
      case "SPJZ0022":
      case "SPNI0007":
      case "SPNR0007":
      case "SPOJ0016":
      case "SPPH0001":
      case "SPSQ0064":
      case "SPRB0007":
      case "SPSJ0022":
      case "SPSK0009":
      case "SPV60005":
      case "SPV70005":
      case "SPV80005":
      case "SPVM0007":
      case "SPVN0022":
      case "SPV00011":
        EditPage = <>{VouchValidExpFn(v, d, table)}</>;
        break;
      case "SPS0EQ68": //PLUSM440
      case "SPS0EQ64":
      case "SPS0EQ60":
      case "SPEQEQ52":
      case "SPEQEQ56":
      case "SPHREQ60":
      case "SPS0EQ62":
      case "SPS0EQ58":
      case "SPEQEQ54":
      case "SPEQ0051":
      case "SPS0EQ66":
      case "SPJJEQ22":
      case "SPJZEQ22":
      case "SPNIEQ07":
      case "SPNREQ07":
      case "SPOJ0027":
      case "SPPHEQ01":
      case "SPPHEQ01":
      case "SPSJEQ22":
      case "SPSKEQ09":
      case "SPS0EQ80":
      case "SPV6EQ05":
      case "SPV7EQ05":
      case "SPV8EQ05":
      case "SPVMEQ07":
      case "SPVNEQ22":
        EditPage = <>{PLUSM440fn(v, d, table)}</>;
        break;

      case "RG000129": // Auditor Password
      case "SPFZ0001": // DataFreeze Password
        EditPage = <>{AuditorPasswordFun(v, d, table)}</>
        break;
      case "MM000x04":
        EditPage = <>{AutoVatSetupFun(v, d, table)}</>
        break;
      default:
        // EditPage = <>{v.F02F03E}</>;
        EditPage = <>{""}</>;
        break;
    }
    return EditPage;
  };

  const renderEditor = (v, d, table) => {
    console.log("v========>", v);
    var editor;
    switch (v.F02F05) {
      case "1":
        editor =
          <>
            {" "}
            <PLComboBox
              autoFocus={true}
              cmbid={v.F02F01}
              dropdownPosition={"top"}
              setComboBox={(e) => {
                setBlur(true);
                setComboBox(e);
              }}
              value={
                (typeof editObj[v.F02F01] == "object"
                  ? editObj[v.F02F01].value
                  : editObj[v.F02F01]) ?? d
              }
              placeholder={d}
              defaultval={d}
              dispexpr="DisplayMember"
              valexpr="ValueMember"
              copno="1"
              // label={d}
              setEdit={(e) => {
                console.log("show obj=>", e);
                setEdit({
                  ...editObj,
                  [v.F02F01]: e.value,
                });
                table.setEditingCell();
              }}
            />
          </>
          ;
        break;
      case "2":
        console.log("combo box case 2", d);
        if (v?.F02F01 == "SP000082") {
          editor =
            <PLComboBox
              autoFocus={true}
              dropdownPosition="top"
              cmbid={v.F02F01}
              setComboBox={(e) => {
                setBlur(true);
                setComboBox(e);
              }}
              defaultval={d}
              // placeholder={d}
              value={
                (typeof editObj[v.F02F01] == "object"
                  ? editObj[v.F02F01].value
                  : editObj[v.F02F01]) ?? d
              }
              dispexpr="DisplayMember"
              valexpr="ValueMember"
              copno={"F39"}
              // label={d}
              setEdit={(e) => {
                console.log("CasePartyRequired=>", e);
                setEdit({
                  ...editObj,
                  [v.F02F01]: e.value,
                });
                dispatch(
                  GetCasePartyRequired({
                    ...CasePartyRequired,
                    DropDownValue: e.value,
                  })
                );
                table.setEditingCell();
              }}
            />
            ;
        } else if (v?.F02F01 == "GEJB0001") {
          editor =
            <PLComboBox
              autoFocus={true}
              dropdownPosition="top"
              searchable={true}
              cmbid={v.F02F01}
              setComboBox={(e) => {
                setBlur(true);
                setComboBox(e);
              }}
              defaultval={d}
              // placeholder={d}
              value={
                (typeof editObj[v.F02F01] == "object"
                  ? editObj[v.F02F01].value
                  : editObj[v.F02F01]) ?? d
              }
              dispexpr="DisplayMember"
              valexpr="ValueMember"
              copno={"F15"}
              // label={d}
              setEdit={(e) => {
                console.log("CasePartyRequired=>", e);
                setEdit({
                  ...editObj,
                  [v.F02F01]: e.value,
                });
                // dispatch(
                //   GetCasePartyRequired({
                //     ...CasePartyRequired,
                //     DropDownValue: e.value,
                //   })
                // );
                table.setEditingCell();
              }}
            />
            ;
        } else {
          editor =
            <PLComboBox
              autoFocus={true}
              dropdownPosition="top"
              cmbid={v.F02F01}
              setComboBox={(e) => {
                setBlur(true);
                setComboBox(e);
              }}
              defaultval={d}
              placeholder={d}
              value={
                (typeof editObj[v.F02F01] == "object"
                  ? editObj[v.F02F01].value
                  : editObj[v.F02F01]) ?? d
              }
              dispexpr="DisplayMember"
              valexpr="ValueMember"
              copno={v.F02F08}
              setEdit={(e) => {
                setEdit({
                  ...editObj,
                  [v.F02F01]: e.value,
                });
                table.setEditingCell();
              }}
            />
            ;
        }
        break;
      case "3":
        editor = <>{RenderPageFunction(v, d, table)}</>;
        break;
      case "4":
        console.log("number d--", d);
        editor =
          <PLNumberBox
            autoFocus={true}
            name={v.F02F01}
            value={d ?? numberBox}
            defaultval={d}
            ntypeofentry={v.F02F31}
            setNumber={(e) => {
              setNumberBox(e);
              setBlur(true);
            }}
            setEdit={(e) => {
              console.log("edit", e);
              setEdit({ ...editObj, [v.F02F01]: e });
            }}
          />
          ;
        break;
      case "5":
        console.log(d, "date d--");
        editor =
          <PLDateBox
            autoFocus={true}
            setEdit={(e) => {
              console.log("EditDate---", e);
              let year = e.toLocaleString("en-IN", { year: "numeric" });
              let month = e.toLocaleString("en-IN", { month: "2-digit" });
              let day = e.toLocaleString("en-IN", { day: "2-digit" });

              let startDate = day + "/" + month + "/" + year;
              console.log("startDate--", startDate);

              setEdit({ ...editObj, [v.F02F01]: startDate });
              // table.setEditingCell(null);
            }}
            value={dateBox}
            name={v.F02F01}
            defaultval={d.slice(3, 6) + d.slice(0, 3) + d.slice(6, 10)}
            showClearButton={false}
            showDropDownButton={false}
            useMaskBehavior={true}
            // disptype="date"
            label={dateBox}
            dispformat="DD/MM/YYYY"
            setDate={(e) => {
              setBlur(true);
              // console.log("e",e);
              setDateBox(e);
            }}
          />
          ;

        break;
      case "6":
        console.log("d of textBox", d);
        editor =
          <PLTextBox
            autoFocus={true}
            name={v.F02F01}
            value={d ?? textBox}
            defaultval={d}
            nmaxlength={v.F02F31}
            setText={(e) => {
              setBlur(true);
              setTextBox(e);
            }}
            setEdit={(e) => {
              console.log("edit12", e);
              setEdit({ ...editObj, [v.F02F01]: e.target.value });
            }}
          />
          ;
        break;
      case "7":
        editor =
          <PLTextBox
            setText={(e) => {
              setBlur(true);
              setTextBox(e);
            }}
            autoFocus={true}
            name={v.F02F01}
            entrytype="PASSWORD"
            value={textBox}
            setEdit={(e) => {
              console.log("edit12", e);
              setEdit({ ...editObj, [v.F02F01]: e.target.value });
            }}
          // setText={(e) => setTextBox(e.target.value)}
          />
          ;
        break;
      case "X":
        editor = null;
        break;
      default:
        break;
    }
    // table.setEditingRow(null);
    return editor;
  };

  const returnVal = (cell, row) => {
    // console.log("cell=>", cell)
    // console.log("row=>>", row)
    let value = editObj[row.original.F02F01]
    switch (row.original.F02F01) {
      // case "GET00246":
      //   console.log("editObj[row.original.F02F01] =>", editObj[row.original.F02F01]);
      //   let arr = editObj[row.original.F02F01]?.split(',')
      //   let arrFullname = [];
      //   console.log("ComboArr===>", arr);
      //   arr?.map((v) => {
      //     if (v == "S") {
      //       arrFullname.push("SMS")
      //     } else if (v == "W") {
      //       arrFullname.push("Whatsapp")
      //     }
      //   })
      //   console.log("arrFullname =>", arrFullname.join(','));
      //   return arrFullname.join(',')
      //   // switch (editObj[row.original.F02F01]) {
      //   //   case "W":
      //   //     return <div style={{ color: "red" }}>{"Whatsapp"}</div>;
      //   //     break;
      //   //   case "S":
      //   //     return <div style={{ color: "red" }}>{"SMS"}</div>
      //   //     break;
      //   //   case "W,S":
      //   //     return <div style={{ color: "red" }}>{"Whatsapp,SMS"}</div>;
      //   //     break;
      //   //   case "S,W":
      //   //     return <div style={{ color: "red" }}>{"SMS,Whatsapp"}</div>;
      //   //     break;
      //   //   default:
      //   //     return <div>{editObj[row.original.F02F01]}</div>
      //   //     break;
      //   // }
      //   break;

      case "GEBCF016":
        switch (value) {
          case "2":
            return <div style={{ color: "red" }}>{"2nd Qty"}</div>;
            break;
          case "1":
            return <div style={{ color: "red" }}>{"1st Qty"}</div>;
            break;
          default:
            return <div style={{ color: "red" }}>{value}</div>
            break;
        };
        break;
      case "GET00246": // msg type
      case "RGQD0001": // required quantity details
      case "GE000245": // alert sms for voucher
      case "GST00039": // Auto upload e-invoice bill type
      case "GEBC0015": // barcode type
      case "GEBC0016": // multi barcode setup
      case "LANZOOM3": // lan zooming user
      case "SWT0A013": // ticker detail
      case "MKCK0010": // Auditor User
      case "RG000012": // Account popup type
      case "GE000239": // Account popup detail
      case "RG000011": // product popup type
      case "GE000240": // product popup detail 
      case "GE000342": // default branch
      case "GEJB0004": //Broker required
      case "GST00004": // E-Commerce Operator
      case "RTDS0162": // Exclude Expense Amount In TDS On Purchase ?
      case "RGLAN001": // Secondary Language
      case "POS00004": // Default Invoice Type
      case "POS00005": // Bill Series
      case "POS00030": // POS hardware > PLUSM554
      case "SPSS0083": // Voucher Setup > Sales invoice > Entry Option > Cash Party Detail
      case "SPSS0099": // Group Filter In Party
      case "SPRLSS02": // GST Rules To Check For
      case "SPSF0051": // Applicable % Of Tax Rate (65%)?
        return <div style={{ color: "red" }}>{value !== "" ? "*" : ""}</div>
        break;
      case "RG000010": // Report setup > general setup > report type
        if (value == "V") {
          return "Vertical"
        } else {
          return "Horizontal"
        }
        break;
      case "RG000100": // Report setup > general setup > printer setup
        if (value == "D") {
          return "Default"
        } else if (value == "U") {
          return "Userwise"
        } else {
          return "System User"
        }
        break;
      case "GE000041": // bill to bill method
        if (value == "A") {
          return "After Amount"
        } else {
          return "Before Amount"
        }
        break;
      case "RG000007": // closing stock calculation method
        if (value == "F") {
          return "Fifo"
        } else if (value == "N") {
          return "None"
        } else if (value == "X") {
          return "Fixed"
        } else if (value == "A") {
          return "Average"
        } else {
          return "Stock Rate"
        }
        break;
      case "MM000004": // Account Search on Alias
        if (value == "N") {
          return "None"
        } else if (value == "M") {
          return "Name"
        } else if (value == "A") {
          return "Alias"
        }
        break;
      case "RRRTD001": // Decimal Point For Rate
        if (value == "D") {
          return "Datewise"
        }
        break;
      case "MM000003": // product search on alias
        if (value == "N") {
          return "None"
        } else if (value == "M") {
          return "Name"
        } else {
          return "Alias"
        }
        break;
      case "GEMD0238": // Date type
        if (value == "M") {
          return "Month-Year"
        } else {
          return "Year"
        }
        break;
      case "GEAPD008": // Auto production on second unit
        if (value == "S") {
          return "Second Unit"
        } else {
          return "First Unit"
        }
        break;
      case "GESCH005": // Master for Redeem Point Entry
        if (value == "A") {
          return "Account"
        } else {
          return "Customer"
        }
        break;
      case "GEPCSC12": // base currency
        if (value == "CUR00000") {
          return "INR-R"
        } else if (value == "USD00000") {
          return "USD-$"
        }
        break;
      case "GEJB0001": // Jobwork Type
        if (value == "I") {
          return "Issue"
        } else if (value == "R") {
          return "Receipt"
        } else if (value == "B") {
          return "Both"
        } else {
          return "None"
        }
        break;
      case "GEJB0002": // Shortage Calculation On
        if (value == "F") {
          return "First Unit"
        } else if (value == "S") {
          return "Second Unit"
        }
        break;
      case "GEJB0010": // Jobwork
        if (value == "1") {
          return "Qty-1"
        } else if (value == "2") {
          return "Qty-2"
        } else {
          return "Both"
        }
        break;
      case "GST00034": // GST > TC4SETU
        if (value == "1") {
          return "Qty-1"
        } else if (value == "2") {
          return "Qty-2"
        }
        break;
      case "GST00038": // In GST Reports, Purchase Date On
        if (value == "V") {
          return "Voucher Date"
        } else if (value == "B") {
          return "Bill Date"
        }
        break;
      case "GST00028": //GST Return Filling Type (Monthly/Quarterly) ?
        if (value == "M") {
          return "Monthly"
        } else {
          return "Quarterly"
        }
        break;
      case "VS000008": // Default Tax/Retail
        if (value == "T") {
          return "Tax Invoice"
        } else if (value == "B") {
          return "Bill Of Supply"
        } else if (value == "O") {
          return "Other Option"
        } else {
          return "Default"
        }
        break;
      case "GETFC004": // Default Nature Of Goods In Produts
        if (value == "NG000009") {
          return "Timber Obtained Under Forest Lease"
        } else if (value == "NG000008") {
          return "Timber Obtained by Any Mode Other Than Forest Lease"
        } else if (value == "NG000007") {
          return "Tendu Leaves"
        } else if (value == "NG000006") {
          return "Scrap"
        } else if (value == "NG000012") {
          return "Other Goods (1/0.1%)"
        } else if (value == "NG000011") {
          return "Other Goods (1/0.075%)"
        } else if (value == "NG000010") {
          return "Minerals, being coal or lignite or iron ore"
        } else if (value == "NG000005") {
          return "Contractors/Licensee/Lease Relating to Toll Plaza"
        } else if (value == "NG000004") {
          return "Contractors/Licensee/Lease Relating to Parking Lots"
        } else if (value == "NG000003") {
          return "Contractors/Licensee/Lease Relating to Mine/Quarry"
        } else if (value == "NG000002") {
          return "Any Other Forest Produce (Not Being Tendu Leaves)"
        } else if (value == "NG000001") {
          return "Alcoholic Liquor for Human Consumption"
        }
        break;
      case "OSET0001": // Purchase Order Adjustment Clear On
        if (value == "F") {
          return "Qty - 1"
        } else if (value == "S") {
          return "Qty - 2"
        } else if (value == "B") {
          return "Both"
        } else if (value == "A") {
          return "Any Qty - 1 Or Qty - 2"
        }
        break;
      case "OSET0002": // Sales Order Adjustment Clear On
        if (value == "F") {
          return "Qty - 1"
        } else if (value == "S") {
          return "Qty - 2"
        } else if (value == "B") {
          return "Both"
        } else if (value == "A") {
          return "Any Qty - 1 Or Qty - 2"
        }
        break;
      case "OSET0003": // Jobwork Order Adjustment Clear On
        if (value == "F") {
          return "Qty - 1"
        } else if (value == "S") {
          return "Qty - 2"
        } else if (value == "B") {
          return "Both"
        } else if (value == "A") {
          return "Any Qty - 1 Or Qty - 2"
        }
        break;
      case "GESHR004": // Gain Calculation Method
        if (value == "F") {
          return "Fifo"
        } else if (value == "L") {
          return "LIFO"
        }
        break;
      case "SPUP0007": // GST Income Entry
        if (value == "M") {
          return "Manual Issue"
        } else if (value == "E") {
          return "Electric Issue"
        }
        break;
      case "POS00021": // Cash Drawer Attached To
        if (value == "C") {
          return "Com Port"
        } else if (value == "P") {
          return "Printer"
        }
        break;
      case "ONLAPP07":
        if (value == "B") {
          return "B2B"
        } else if (value == "C") {
          return "B2C"
        } else if (value == "O") {
          return "Both"
        } else {
          return "Other"
        }
        break;
      case "SPS00071":
      case "SPSSEXP1":
        if (value == "Y") {
          return "Yes"
        } else if (value == "N") {
          return "No"
        } else if (value == "A") {
          return "Entry Not Allowed"
        }
        break;
      case "SPRLSS01": // voucher Setup > Check For GST Rules ?
      case "SPRLPR01":
      case "SPRLHC01":
      case "SPRLHD01":
      case "SPRLSR01":
      case "SPRLPP01":
        if (value == "N") {
          return "None"
        } else if (value == "S") {
          return "Strict"
        } else {
          return "Warning"
        }
        break;
      case "SP000071":
      case "SP000077":
      case "SP000075":
      case "SPA00071":
      case "SPHR0075":
      case "SP000070":
      case "SP000078":
      case "SP000076":
      case "SPA00070":
      case "SP000065":
      case "SP000066":
      case "SP000069":
      case "SP000068":
      case "SP000067":
      case "SP000072":
      case "SP000073":
      case "SPHC0077":
      case "SP000074":
      case "SPHD0077":
      case "HO000077":
      case "SP00IN76":
      case "SP000146":
      case "SPQ00077":
      case "SPVN0005":
      case "SPVM0005":
      case "SPV00003":
      case "SPV60003":
      case "SPV70003":
      case "SPNI0005":
      case "SPNR0005":
      case "SPV80003":
      case "SPJA0003":
      case "SP000153":
      case "SPRB0005":
      case "SPPS0003":
      case "SPSK0005":
      case "SPOJ0008":
      case "SPJI0003":
      case "SPJO0003":
      case "SPJJ0005":
      case "SPJX0003":
      case "SPJY0003":
      case "SPJZ0005":
      case "SPJW0003":
      case "SPSJ0005":
      case "SPJC0003":
      case "SPJD0003":
        if (value == "C") {
          return "Yes"
        } else if (value == "N") {
          return "No"
        }
        break;
      case "POSSS001": // Customer Master Require In Bill ?
      case "POSHS001": // sales Challan 
        if (value == "C") {
          return "Cash"
        } else if (value == "Y") {
          return "Debit"
        } else if (value == "N") {
          return "None"
        } else if (value == "B") {
          return "Both"
        }
        break;
      case "SP000017": // Negative Stock Warning
      case "SP000042": // Sales Challan 
      case "HPSSEXP1": // Batch Expiry Date Warning ? 
      case "SPHR0042":
      case "SPBPL003":
      case "SP000142":
      case "SPTS0042":
      case "SPVN0024":
      case "SPV60013":
      case "SPV70013":
      case "SPOS0071":
      case "SPOS0104":
      case "SPA00001":
        if (value == "A") {
          return "Entry Not Allowed"
        } else if (value == "N") {
          return "No"
        } else if (value == "Y") {
          return "Yes"
        }
        break;
      case "SPSS0017": // Serial No Mismatch Quantity
      case "SPHS0017": // Sales Challan  
      case "SPSR0017":
      case "SPHR0017":
      case "SPPP0017":
      case "SPHP0017":
      case "SPPR0017":
      case "SPHO0017":
      case "SPIJ0017":
      case "SPIN0017":
      case "SPTS0017":
      case "SPV60014":
      case "SPV70014":
      case "SPPH0006":
      case "SPSK0019":
        if (value == "Y") {
          return "Allowed"
        } else if (value == "W") {
          return "Warning"
        } else if (value == "N") {
          return "Entry Not Allowed"
        }
        break;
      case "SP000015": // Online Bill Printing
      case "SPOS0032": // Online Order Printing
      case "SP000029": // Online Challan Printing
      case "SPA00015":
      case "SPHR0029":
      case "SP000018":
      case "SP000031":
      case "SP000030":
      case "SPA00018":
      case "SP000020":
      case "SP000019":
      case "SP000021":
      case "SP000022":
      case "SP000033":
      case "SP000034":
      case "SPHC0032":
      case "SP000035":
      case "SPHD0057":
      case "SPHO0032":
      case "F2XX2034":
      case "SP000061":
      case "SP000060":
      case "SPQ00032":
      case "SPVN0001":
      case "SPVM0024":
      case "SPV00001":
      case "SPV60016":
      case "SPV70016":
      case "SPNI0024":
      case "SPNR0024":
      case "SPV80016":
      case "SPJA0001":
      case "SP000151":
      case "SPRB0024":
      case "F2XX2042":
      case "F2XX2038":
      case "SPSK0001":
      case "SPOJ0006":
      case "SPJI0001":
      case "SPJO0001":
      case "SPJJ0001":
      case "SPJX0001":
      case "SPJY0001":
      case "SPJZ0001":
      case "SPJW0001":
      case "SPSJ0001":
      case "SPJC0001":
      case "SPJD0001":
      case "SPEG0001":
      case "SPIG0001":
      case "SPCG0001":
      case "SPDG0001":
        if (value == "D") {
          return "Dialog"
        } else if (value == "Y") {
          return "Yes"
        } else if (value == "N") {
          return "No"
        }
        break;
      case "SPSSTE01": // LISTEDAT
      case "SPOSTE01":
      case "SPHSTE01":
      case "SPSRTE01":
      case "SPHRTE01":
      case "SPPPTE01":
      case "SPOPTE01":
      case "SPHPTE01":
      case "SPPRTE01":
      case "SPCRTE01":
      case "SPCPTE01":
      case "SPBPTE01":
      case "SPBRTE01":
      case "SPBCTE01":
      case "SPJRTE01":
      case "SPNCTE01":
      case "SPHCTE01":
      case "SPNDTE01":
      case "SPHDTE01":
      case "SPHOTE01":
      case "SPIJTE01":
      case "SPINTE01":
      case "SPTSTE01":
      case "SPPQTE01":
      case "SPVNTE01":
      case "SPVMTE01":
      case "SPVOTE01":
      case "SPV6TE01":
      case "SPV7TE01":
      case "SPNITE01":
      case "SPNRTE01":
      case "SPV8TE01":
      case "SPJATE01":
      case "SPSHTE01":
      case "SPRBTE01":
      case "SPPSTE01":
      case "SPOHTE01":
      case "SPSKTE01":
      case "SPOJTE01":
      case "SPJITE01":
      case "SPJOTE01":
      case "SPJJTE01":
      case "SPJXTE01":
      case "SPJYTE01":
      case "SPJZTE01":
      case "SPJWTE01":
      case "SPSJTE01":
      case "SPJCTE01":
      case "SPJDTE01":
      case "SPEGTE01":
      case "SPIGTE01":
      case "SPCGTE01":
      case "SPDGTE01":
        if (value == "F") {
          return "Full year date"
        } else {
          return "Current Date"
        }
        break;
      case "SPORD317": // Pending Voucher List Preferences
        if (value == "HSP") {
          return "Challan"
        }
        break;
      case "SPS00001":
      case "SPMSS001":
      case "SPMTL001":
      case "SPMWP001": // Online WhatsApp
      case "SPS00029":
      case "SPOSX001":
      case "SPMTLOS1":
      case "SPMWPOS1":
      case "SPHS0001":
      case "SPMTLHS1":
      case "SPMWPHS1":
      case "SPS00007":
      case "SPSR0001":
      case "SPMWPSR1":
      case "SPMTLSR1":
      case "SPHR0011":
      case "SPHR0001":
      case "SPHRPHS1":
      case "SPHRLHS1":
      case "SPS00003":
      case "SPPP0001":
      case "SPMTLPP1":
      case "SPMWPPP1":
      case "SPS00013":
      case "SPOP0001":
      case "SPMWPOP1":
      case "SPMTLOP1":
      case "SP000009":
      case "SPHP0001":
      case "SPMWPHP1":
      case "SPMTLHP1":
      case "SPS00005":
      case "SPPR0001":
      case "SPMTLPR1":
      case "SPMWPPR1":
      case "SPS00011":
      case "SPS00021":
      case "SPCR0001":
      case "SPMWPCR1":
      case "SPMTLCR1":
      case "SPMWPCP1":
      case "SPMTLCP1":
      case "SPCP0001":
      case "SPS00019":
      case "SPMWPBP1":
      case "SPMTLBP1":
      case "SPBP0001":
      case "SPS00015":
      case "SPS00017":
      case "SPBR0001":
      case "SPMTLBR1":
      case "SPMWPBR1":
      case "SPS00023":
      case "SPJ10001":
      case "SPMWPJR1":
      case "SPMTLJR1":
      case "SPS00025":
      case "SPNC0001":
      case "SPMWPNC1":
      case "SPMTLNC1":
      case "SPHC0029":
      case "SPC0001":
      case "SPMWPHC1":
      case "SPMTLHC1":
      case "SPS00027":
      case "SPNDE001":
      case "SPMWPND1":
      case "SPMTLND1":
      case "SPHD0029":
      case "SPD0001":
      case "SPMWPHD1":
      case "SPMTLHD1":
      case "HOS00029":
      case "SPINX001":
      case "SPTS0001":
      case "SPQS0002":
      case "F2XX2076":
      case "SPMTLQT1":
      case "SPMWPQT1":
      case "SPVN0020":
      case "SPVN0026":
      case "SPMTLVN1":
      case "SPMWPVN1":
      case "SPMTLVM1":
      case "SPMWPVM1":
      case "SPVM0029":
      case "SPVM0027":
      case "SPV00009":
      case "SPV60020":
      case "SPV60022":
      case "SPMTLV61":
      case "SPMWPV61":
      case "SPV70020":
      case "SPV70022":
      case "SPMTLV71":
      case "SPMWPV71":
      case "SPNI0027":
      case "SPNI0029":
      case "SPMTLNI1":
      case "SPMWPNI1":
      case "SPMWPNR1":
      case "SPMTLNR1":
      case "SPNR0029":
      case "SPNR0027":
      case "SPMWPV81":
      case "SPMTLV81":
      case "SPV80022":
      case "SPV80020":
      case "SPMTLJA1":
      case "SPMWPJA1":
      case "F2XX2046":
      case "SPJA0009":
      case "SPSH0009":
      case "SPSH0008":
      case "SPSH0001":
      case "SPSH0007":
      case "SPRB0027":
      case "SPRB0029":
      case "SPMTLRB1":
      case "SPMWPRB1":
      case "SPSK0012":
      case "SPOJ0014":
      case "SPOJ0001":
      case "SPOJ0025":
      case "SPOJ0028":
      case "SPJI0009":
      case "SPJI0026":
      case "SPMTLJI1":
      case "SPMWPJI1":
      case "SPMTLJO1":
      case "SPMWPJO1":
      case "SPJO0026":
      case "SPJO0009":
      case "SPJJ0020":
      case "SPJJ0026":
      case "SPMTLJJ1":
      case "SPMWPJJ1":
      case "SPJX0009":
      case "F2XX2066":
      case "SPMTLJX1":
      case "SPMWPJX1":
      case "SPMWPJY1":
      case "SPMTLJY1":
      case "F2XX2071":
      case "SPJY0009":
      case "SPMWPJZ1":
      case "SPMTLJZ1":
      case "SPJZ0026":
      case "SPJZ0020":
      case "SPJW0009":
      case "F2XX2061":
      case "SPMWPJW1":
      case "SPMTLJW1":
      case "SPSJ0020":
      case "SPSJ0026":
      case "SPMWPSJ1":
      case "SPMTLSJ1":
      case "SPJC0009":
      case "F2XX2051":
      case "SPMWPJC1":
      case "SPMTLJC1":
      case "SPJD0009":
      case "F2XX2056":
      case "SPMWPJD1":
      case "SPMTLJD1":
      case "SPEG0014":
      case "SPEG0012":
      case "SPEG0007":
      case "SPEG0005":
      case "SPIG0014":
      case "SPIG0012":
      case "SPIG0007":
      case "SPIG0005":
      case "SPCG0005":
      case "SPCG0007":
      case "SPCG0014":
      case "SPCG0012":
      case "SPDG0014":
      case "SPDG0012":
      case "SPDG0007":
      case "SPDG0005":
        if (value == "L") {
          return "List"
        } else if (value == "N") {
          return "No"
        } else if (value == "Y") {
          return "Yes"
        }
        break;
      case "SPA00082":
      case "SPHR0084":
      case "SPOP0082":
      case "SP000085":
      case "SPA00083":
      case "SPQT0083":
      case "SPVM0002":
      case "SPNI0002":
      case "SPNR0002":
      case "SP000154":
      case "SPRB0082":
      case "SPRB0002":
      case "SPOJ0082":
      case "SPJJ0006":
      case "SPJZ0006":
      case "SPSJ0006":
      case "SPEG0082":
      case "SPIG0082":
      case "SPCG0082":
      case "SPDG0082":
      case "SP000082":
      case "SPOS0082":
      case "SP000084":
      case "SP000083":
      case "SPVN0006":
        if (value == "D") {
          return "Detail"
        } else if (value == "Y") {
          return "Yes"
        } else if (value == "N") {
          return "No"
        }
        break;
      case "SP000181":
      case "SPVM0021":
      case "SPNI0021":
      case "SPNR0021":
      case "SP000164":
      case "SPRB0021":
      case "SPJJ0181":
      case "SPSJ0181":
        if (value == "A") {
          return "All Date"
        } else if (value == "E") {
          return "Entry Date"
        } else if (value == "N") {
          return "None"
        }
        break;
      case "SPH00108":
        if (value == "S") {
          return "Sales Rate"
        } else if (value == "P") {
          return "Purchase Rate"
        }
        break;
      default:
        return <div style={{ color: "red" }}>{value}</div>
        break;
    }
  }

  const renderValue = (cell, row) => {
    // console.log("editObj[row.original.F02F01]",cell)
    // console.log("renderValue =>", [{
    //   "cell": cell,
    //   "row": row
    // }]);
    // console.log(row , "---row.original.F02F01")
    // console.log(
    //   editObj[row?.original?.F02F01],
    //   "blur editObj[row.original.F02F01]............"
    // );
    // console.log(blurObj[row.original.F02F01], "numberBox blur");
    // console.log("blur flag", blur);
    // if (blur) {
    //   console.log("Inside BlurIf", typeof numberBox);
    //   switch (typeof numberBox) {
    //     case "object":
    //       return;
    //       <div style={{ color: "red" }}>
    //         {numberBox}
    //       </div>;

    //       break;
    //     case "string":
    //       console.log("string blur", numberBox);
    //       switch (numberBox) {
    //         case "N":
    //           return <div style={{ color: "red" }}>{"No"}</div>;
    //           break;
    //         case "Y":
    //           return <div style={{ color: "red" }}>{"Yes"}</div>;
    //           break;
    //         default:
    //           console.log("default blur");
    //           return (
    //             <div style={{ color: "red" }}>
    //               {numberBox}
    //             </div>
    //           );

    //           break;
    //       }
    //       break;
    //     case "number":
    //       console.log(numberBox, "renderVal number blur");
    //       switch (numberBox) {
    //         default:
    //           return (
    //             <div style={{ color: "red" }}>
    //               {numberBox}
    //             </div>
    //           );

    //           break;
    //       }
    //     default:
    //       return cell;
    //       break;
    //   }
    //   setBlur(false);
    // } else {
    // console.log("editObj[row.original.F02F01]", editObj[row.original.F02F01]);
    if (row?.original?.F02F05 == "1") {
      console.log("if condition")
      let val = editObj[row.original.F02F01]
      if (val == "N") {
        return <div style={{ color: "red" }}>{"No"}</div>;
      } else if (val == "Y") {
        return <div style={{ color: "red" }}>{"Yes"}</div>;
      } else {
        return <div>{cell}</div>;
      }
    } else {

      switch (typeof editObj[row.original.F02F01]) {
        case "object":
          return (
            <div style={{ color: "red" }}>
              {editObj[row.original.F02F01].label}
            </div>
          );

          break;
        case "string":
          // switch (editObj[row.original.F02F01]) {

          // default:
          // return <div style={{ color: "red" }>{e}ditObj[row.original.F02F01]}</div>
          return <div style={{ color: "red" }}>{returnVal(cell, row)}</div>
          // break;
          // }
          break;
        case "number":
          // console.log(editObj[row.original.F02F01], "renderVal number");
          switch (editObj[row.original.F02F01]) {
            default:
              return (
                <div style={{ color: "red" }}>{editObj[row.original.F02F01]}</div>
              );
              break;
          }
        default:
          return cell;
          break;
        // }
      }
    }
  };

  useEffect(() => {
    // console.log("BlurObj.......", blurObj);
    if (CompanySetupcol && CompanySetupcol.length > 0) {
      var cols = [];
      var grp = [];
      CompanySetupcol.filter((v, i) => v.S53F00 == "C" || v.S53F00 == "G")
        .sort((a, b) => a.S53F09 - b.S53F09)
        .map((v, i) => {
          // console.log("v=======>", v);
          // console.log("v.S53F00", v.S53F00, "v.S53F03", v.S53F03);

          if (v.S53F00 == "C" && v.S53F03 !== "Type") {
            // console.log("Useeffect On");
            cols.push(
              columnHelper.accessor(v.S53F04, {
                header: v.S53F03,
                Edit: ({ cell, column, table, row }) => {
                  let value = cell.getValue();
                  let getInput;
                  // let NewArray = TableObjArray.filter((a, i) => a?.F02UNQ == row?.original?.F02UNQ || row.original)

                  if (i === 3) {
                    getInput = renderEditor(
                      row.original,
                      cell.getValue(),
                      table
                    );
                    // return getInput
                  } else {
                    getInput = value;
                  }
                  return getInput;
                },

                // i === 3
                //   ? renderEditor(row.original, cell.getValue(), table)
                //   : cell.getValue(),
                Cell: ({ cell, renderedCellValue, row }) => {
                  let value = cell.getValue();
                  // console.log('value',value)
                  if (i == 3) {
                    return renderValue(value, row);
                  }
                  return value;
                },
              })
            );
          }
        });
      cols.push(
        columnHelper.accessor("F02F15", {
          header: "",
          enableHiding: true,

          Cell: ({ cell }) => rc(cell.getValue()),
          AggregatedCell: ({ cell }) => rc(cell.getValue()),
        })
      );
      setColumns(cols);
      setGrouping(["F02F15"]);
    }
  }, [CompanySetupcol, editObj, blur, blurObj, TableObjArray]);
  useEffect(() => {
    if (CompanySetup && CmpVouSetUpObj) {
      // console.log("selectedItem.S72F02==>", selectedItem);
      // console.log(
      //   "Data",
      //   CompanySetup.filter(
      //     (v, i) =>
      //       // console.log('v.F02F02==>',v.F02F02)
      //       v.F02F02 === selectedItem.S72F02
      //   )
      // );
      setData(
        CompanySetup.filter(
          (v, i) =>
            // console.log("v.F02F02==>",v.F02F02,"selectedItem.S72F02==>",selectedItem.S72F02),
            v.F02F02 === CmpVouSetUpObj.S72F02
        )
      );
    }
  }, [CmpVouSetUpObj, CompanySetup]);
  const tableRender = (data) => {
    return (
      <>
        <Modal
        zIndex={199}
          opened={DrawerObj?.open}
          // onClose={close}
          withCloseButton={true}

          fullScreen={false}//
          // overlayProps={{ opacity: 0.5, blur: 4 }}
          closeButtonProps={{
            onClick: (e) => {

              if (typeof DrawerObj?.onClickCloseButton == "function") {
                DrawerObj?.onClickCloseButton();
              }
              else {
                setDrawerObj(dra);
              }
            },
          }}
          title={DrawerObj?.title}
          size={DrawerObj?.size}
          // centered={.Centered}
          closeOnClickOutside={true}
          // overlayProps={m.Overlay}
          position={DrawerObj?.position ?? 'bottom'}
          onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
        // scrollAreaComponent={ScrollArea.Autosize}
        >
          {DrawerObj.body}
        </Modal>
        {CompanySetupcol &&
          CompanySetupcol.length > 0 &&
          CompanySetup &&
          Data && <MantineReactTable table={tabled} />}
      </>

      // <Table striped highlightOnHover withBorder withColumnBorders miw={700}>
      //   <thead>
      //     <tr>
      //       {CompanySetupcol && CompanySetupcol.length > 0 &&
      //         CompanySetupcol.filter((v, i) => v.S53F00 == 'C').sort((a, b) => a.S53F09 - b.S53F09).map((v, i) => {

      //           if (selectedItem && 'PEIE'.includes(selectedItem.S72F02)) {
      //             return (<th>{v.S53F03}</th>)
      //           }
      //           else {
      //             if (v.S53F03 != "Type") {
      //               return (<th>{v.S53F03}</th>)
      //             }
      //           }
      //           if (selectedItem && 'PEIE'.includes(selectedItem.S72F02)) {
      //             return (<th>{v.S53F03}</th>)
      //           }
      //           else {
      //             if (v.S53F03 != "Type") {
      //               return (<th>{v.S53F03}</th>)
      //             }
      //           }

      //         })}
      //     </tr>
      //   </thead>
      //   <tbody>
      //         })}
      //     </tr>
      //   </thead>
      //   <tbody>

      //     {/* {data && data.length > 0 && data.map((v, i) => {
      //       return (<tr>
      //         {CompanySetupcol && CompanySetupcol.length > 0 &&
      //           CompanySetupcol.filter((vf, i) => vf.S53F00 == 'G').sort((a, b) => a.S53F09 - b.S53F09).map((c, ci) => {
      //             return <td style={{fontWeight:'bold'}} >{v[c.S53F04]}</td>
      //             // F02F15E
      //           })}
      //       </tr>
      //       )
      //     })} */}

      //     {data && data.length > 0 && data.map((v, i) => {
      //       return (<tr>
      //         {CompanySetupcol && CompanySetupcol.length > 0 &&
      //           CompanySetupcol.filter((vf, i) => vf.S53F00 == 'C').sort((a, b) => a.S53F09 - b.S53F09).map((c, ci) => {
      //             if (selectedItem && 'PEIE'.includes(selectedItem.S72F02)) {
      //               return <td>D- {v[c.S53F04]}</td>
      //             }
      //             else {
      //               if (c.S53F03 !== "Type") {
      //                 if (ci === 1) {
      //                   switch (v.F02F05) {
      //                     case '1':
      //                       return <td style={editObj[v.F02F01] ? { color: "red" } : {}} onDoubleClick={() => setComboBox(v.F02F01)} > {
      //                         !comboBox.includes(v.F02F01) ? (editObj[v.F02F01] ? editObj[v.F02F01].label : v[c.S53F04]) : <PLComboBox
      //                           cmbid={v.F02F01}
      //                           setComboBox={(e) => setComboBox(e)}
      //                           value={v[c.S53F04]}
      //                           dispexpr="DisplayMember"
      //                           valexpr="ValueMember"
      //                           copno='1'
      //                           setEdit={(e) => { console.log("edit", e); setEdit({ ...editObj, [v.F02F01]: e.value }); }}
      //                         />
      //                       }
      //                       </td>
      //                     case '2':
      //                       return <td style={editObj[v.F02F01] ? { color: "red" } : {}} onDoubleClick={() => setComboBox(v.F02F01)} > {
      //                         !comboBox.includes(v.F02F01) ? (editObj[v.F02F01] ? editObj[v.F02F01].label : v[c.S53F04]) : <PLComboBox
      //                           cmbid={v.F02F01}
      //                           setComboBox={(e) => setComboBox(e)}
      //                           value={v[c.S53F04]}
      //                           dispexpr="DisplayMember"
      //                           valexpr="ValueMember"
      //                           copno={v.F02F08}
      //                           setEdit={(e) => { console.log("edit", e); setEdit({ ...editObj, [v.F02F01]: e }); }}
      //                         />
      //                       }
      //                       </td>
      //                     case '3':
      //                       return <td onDoubleClick={() => setModalBox(v.F02F01)}>
      //                         {
      //                           !modalBox.includes(v.F02F01) ? (editObj[v.F02F01] ? editObj[v.F02F01].label : v[c.S53F04]) : <PLModalBox
      //                             open={true}
      //                             // data={v[c.S53F04]}
      //                             data={v}
      //                             data2={c}
      //                             setModal={(e) => setModalBox(e)}
      //                             obj={obj}
      //                           />
      //                         }
      //                       </td>
      //                     case '4':
      //                       return <td style={editObj[v.F02F01] ? { color: "red" } : {}} onDoubleClick={() => setNumberBox(v.F02F01)}>
      //                         {
      //                           !numberBox.includes(v.F02F01) ? (editObj[v.F02F01] ? editObj[v.F02F01] : v[c.S53F04]) : <PLNumberBox
      //                             name={v.F02F01}
      //                             value={v[c.S53F04]}
      //                             ntypeofentry={v.F02F31}
      //                             setNumber={(e) => setNumberBox(e)}
      //                             setEdit={(e) => { console.log("edit", e); setEdit({ ...editObj, [v.F02F01]: e }); }}
      //                           />
      //                         }
      //                       </td>
      //                     case '5':
      //                       return <td style={editObj[v.F02F01] ? { color: "red" } : {}} onDoubleClick={() => setDateBox(v.F02F01)}>
      //                         {
      //                           !dateBox.includes(v.F02F01) ? (editObj[v.F02F01] ? editObj[v.F02F01] : v[c.S53F04]) : <PLDateBox
      //                             setEdit={(e) => { console.log("edit", e); setEdit({ ...editObj, [v.F02F01]: e }); }}
      //                             name={v.F02F01}
      //                             defaultval={v[c.S53F04]}
      //                             showClearButton={false}
      //                             showDropDownButton={false}
      //                             useMaskBehavior={true}
      //                             disptype="date"
      //                             dispformat="DD MM YYYY"
      //                             setDate={(e) => setDateBox(e)}
      //                           />
      //                         }
      //                       </td>
      //                     case '6':
      //                       return <td style={editObj[v.F02F01] ? { color: "red" } : {}} onDoubleClick={() => setTextBox(v.F02F01)}>
      //                         {
      //                           !textBox.includes(v.F02F01) ? (editObj[v.F02F01] ? editObj[v.F02F01] : v[c.S53F04]) : <PLTextBox
      //                             name={v.F02F01}
      //                             value={v[c.S53F04]}
      //                             nmaxlength={v.F02F31}
      //                             setText={(e) => setTextBox(e)}
      //                             setEdit={(e) => { console.log("edit12", e); setEdit({ ...editObj, [v.F02F01]: e }); }}
      //                           />
      //                         }
      //                       </td>
      //                     case '7':
      //                       return <td style={editObj[v.F02F01] ? { color: "red" } : {}} onDoubleClick={() => setTextBox(v.F02F01)}>
      //                         {
      //                           !textBox.includes(v.F02F01) ? (editObj[v.F02F01] ? editObj[v.F02F01].label : v[c.S53F04]) : <PLTextBox
      //                             name={v.F02F01}
      //                             entrytype='PASSWORD'
      //                             value={v[c.S53F04]}
      //                             setText={(e) => setTextBox(e)}
      //                           />
      //                         }
      //                       </td>
      //                     case 'X':
      //                       return null
      //                     default:
      //                       return null;
      //                   }
      //                 }
      //                 return <td onClick={() => console.log('CLICK', v.F02F05)} style={{ width: '50%' }} >
      //                   {v[c.S53F04]}
      //                 </td>
      //               }

      //             }
      //           })}
      //       </tr>
      //       )
      //     })}
      //   </tbody>
      // </Table>
    );
  };

  function saveCmpSetup() {
    dispatch(DataSaveLoading(true));
    // console.log("submit data--", Data, "Edit==", editObj, "columns", columns);
    // setSelected(null);
    // setSelectedItem(null);
    Store.dispatch(GetCmpSetUpObj(null));
    // console.log("Table edit Object=>", Data);
    // console.log("TableRowObj==>", TableRowObj);
    console.log("TableObjArray==>", TableObjArray);
    let newArray = [];
    TableObjArray.map((t) => {
      if (t?.F02F01 in editObj) {
        console.log("Match objects==>", editObj[t?.F02F01]);
        newArray.push({ ...t, F02F07: editObj[t?.F02F01] });
      }
    });
    console.log("newArray==>", newArray);

    if (window.confirm(`Sure to Change ${props.obj.name}`)) {
      GlobalClass.Notify(
        "info",
        "Editing",
        "Please wait while we process your data"
      );
      let PostObj = { F02DT: [...newArray] };

      console.log("ObjSend==>", PostObj);

      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: props.cID,
        cSData: JSON.stringify(PostObj),
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: props.cID,
        cCode: "",
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostCMPSetup + "?pa=" + param,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "SUCCESS") {
            // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
            Promise.resolve(dispatch(DataSaveLoading(false)))
              .then(() => dispatch(ModalDelete(props.index)))
              .then(() =>
                GlobalClass.Notify("success", "Edited", "Edited successfully")
              ).then(() => window.location.reload())
          } else if (data.status == "FAIL") {
            Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
              GlobalClass.Notify("error", data?.status, data?.message)
            );
          }
        })
        .catch((e) =>
          Promise.resolve(
            GlobalClass.Notify("error", "Error", `${e.message}`)
          ).then(() => dispatch(DataSaveLoading(false)))
        );
    } else {
      dispatch(DataSaveLoading(false));
      if (props.cID == "CCCC") {
        dispatch(
          GetCmpSetUpObj({
            LACTIVE: 1,
            S72F00: "Company Setup",
            S72F01: "CCCC",
            S72F02: "GERG",
            S72F03: "GERG",
            S72F04: "",
            S72F05: "005",
            S72F06: "General Setup",
            S72F07: "",
            S72UID: 33,
          })
        );
      } else {
        dispatch(
          GetCmpSetUpObj({
            LACTIVE: 1,
            S72F00: "Voucher Setup",
            S72F01: "TTTT",
            S72F02: "SPSS",
            S72F03: "_VTYLNSS",
            S72F04: "",
            S72F05: "010",
            S72F06: "Sales Invoice",
            S72F07: "!SH",
            S72UID: 10,
          })
        );
      }
    }
  }

  // const FindObjBtn = () => {
  //   console.log("TableObjArray==>", TableObjArray);
  //   let GetObj = TableObjArray.filter((t) => t.F02F01 == "SPSS0099")
  //   console.log("GetObj==========>", GetObj);

  // }

  // if (hasError && !isLoadingCmp) {
  //   GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
  // }

  return (<>{
    hasError ? <>
      <Text>{ErrorMsg.message}</Text>
      <Button onClick={() => {
        console.log("Refetch API Btn Click=>", [{
          "props.cID": props.cID,
          "CmpVouSetUpObj": CmpVouSetUpObj
        }]);
        if (props.cID) {
          Promise.resolve(dispatch(GetCMPSetup({ cID: props?.cID }))).then(() => {
            if (props.cID == "CCCC") {
              Store.dispatch(
                GetCmpSetUpObj({
                  LACTIVE: 1,
                  S72F00: "Company Setup",
                  S72F01: "CCCC",
                  S72F02: "GERG",
                  S72F03: "GERG",
                  S72F04: "",
                  S72F05: "005",
                  S72F06: "General Setup",
                  S72F07: "",
                  S72UID: 33,
                })
              );
            } else {
              Store.dispatch(
                GetCmpSetUpObj({
                  LACTIVE: 1,
                  S72F00: "Voucher Setup",
                  S72F01: "TTTT",
                  S72F02: "SPSS",
                  S72F03: "_VTYLNSS",
                  S72F04: "",
                  S72F05: "010",
                  S72F06: "Sales Invoice",
                  S72F07: "!SH",
                  S72UID: 10,
                })
              );
            }
          });
        }
      }}>Retry</Button>
    </> :
      <>
        {SaveLoading == true || isLoadingCmp == true ? (
          GlobalClass.RenderLoader("skeleton", 15, true)
        ) : (
          <>
            {CompanySetupData && CompanySetupData.length > 0 && (
              <>
                {/* <Card radius="md" className={classes.card} key={items.id}> */}
                {/* <ScrollArea
                type="never"
                offsetScrollbars
                scrollbarSize={2}
                scrollHideDelay={0}
                h={600}
              > */}
                <Tabs
                  variant="outline"
                  orientation="vertical"
                  defaultValue={"table"}
                  key={items.id}
                >
                  <div style={{ height: height * 0.80, overflow: "hidden", overflowY: "scroll", msOverflowStyle: "none", scrollbarWidth: "none" }}>
                    <Tabs.List>{items}</Tabs.List>
                  </div>
                  <Tabs.Panel value={"table"} pl="xs">
                    {tableRender()}
                  </Tabs.Panel>
                </Tabs>
                {/* </ScrollArea> */}

                {/* </Card> */}
              </>
            )}
          </>
        )}
      </>
  }
  </>
  );
}
