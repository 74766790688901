import {
  Alert,
  Button,
  Card,
  Group,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getHotkeyHandler, useHotkeys } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { Kbd } from "@mantine/core";
// 02270213
// 02210213
let fun;
let fun2;
// let productionQuickEntryBtn = {
//   "BID": "VACTLIST",
//   "KEYCODE": "45",
//   "KEYEXPR": "VouEntAct('Q','','C')",
//   "CAPID": "B_ADD",
//   "CAPTION": "Add",
//   "TOOLTIP": "Insert",
//   "GRPSEQ": 1,
//   "BTNSEQ": 1,
//   "BLEVEL": "L",
//   "DCOND": "",
//   "BNAME": "VOU_A",
//   "BIMAGE": "add16.png",
//   "FCOLOR": "",
//   "BCOLOR": "",
//   "FNAME": "",
//   "FSIZE": 0,
//   "FSTYLE": "N",
//   "BUNIID": 249,
//   "KEYEXPR_D": "VouEnt.VouEntAct(this,A)",
//   "lActive": "Y"
// }
function keycodeToKey(e) {
  switch (e) {
    case "13":
      return "Enter";
      break;

    default:
      return "";
      break;
  }
}
const ButtonPanel = forwardRef((props, ref) => {
  console.log("ButtonPanel props==>", props);
  // let HotKeys = props?.data?.map((btn, i) => [
  //         btn.TOOLTIP ?? keycodeToKey(btn.KEYCODE),
  //         () => BindFunWithPara(btn),
  //       ]);
  // // document.getElementById("Datagrid" + props.from).addEventListener("keydown",getHotkeyHandler(HotKeys))
  // useHotkeys(HotKeys);
  // useEffect(()=>{
  //   if(document.getElementById("Datagrid" + props?.from) && props?.data){
  //     let HotKeys = props?.data?.map((btn, i) => [
  //       btn.TOOLTIP ?? btn.TOOLTIP, //: keycodeToKey(btn.KEYCODE),
  //       () => BindFunWithPara(btn),
  //     ]);
  //     console.log("documentDataGrid",document.getElementById("Datagrid" + props?.from));
  //     document.getElementById("Datagrid" + props?.from).addEventListener("keydown",getHotkeyHandler(HotKeys))
  //   }
  // },[props?.from, props?.data])

  window.TM_ZINOUT = ""; //Nairin
  // console.log(props.from , "04020399")
  let prt = require("../FunctionsCall/Print/PLPrint");
  window.TM_PRINT = prt.TM_PRINT;
  let filter = require("../FunctionsCall/Filter/PLFilter");
  window.TM_EXPORT = filter.TM_EXPORT;
  window.PrintingFunc = require('../components/PrintVoucherReport').PrintingFunc;
  // let fun2 = require("../FunctionsCall/AccountLedger/PLFormat");
  window.TM_FORMAT = (e) => {
    if (props?.setFormatBtn && typeof props?.setFormatBtn == "function") {
      props.setFormatBtn(e);
    }
  };
  let msg = require("../Transaction/SMSEmailWpBtn/MessageFun");
  window.swe = msg.swe;

  let sms = require("../Transaction/SMSEmailWpBtn/SMS/AddBtn");
  window.SMSAED = sms.SMSAED;
  let email = require("../Transaction/SMSEmailWpBtn/SMS/AddBtn");
  window.EMLAED = email.EMLAED;
  let whtsup = require("../Transaction/SMSEmailWpBtn/SMS/AddBtn");
  window.WAPAED = whtsup.WAPAED;

  window.SLED = require("../FunctionsCall/AccountLedger/SLedger").SLED; // Account Ledger
  window.TM_ZINOUT = require("../FunctionsCall/AccountLedger/SLedger").TM_ZINOUT; // Product Ledger

  // window.PLED_MAIN = require("../FunctionsCall/AccountLedger/SLedger").PLED_MAIN;
  window.TM_PRINTVF = require("../components/PrintVoucherReport").TM_PRINTVF;
  window.TMSHOWORDER = require("../PlusComponent/PLOrder").TMSHOWORDER;  
  window.TM_MSEL = require("../FunctionsCall/BtnMaster/ButtonMaster").TM_MSEL;

  //Report > Order/Challan > Purchase Order = Cleared btn
  window.CHLNOP = require("../FunctionsCall/BtnMaster/ReportClearedFilter").CHLNOP; 
  //Report > Order/Challan > Sales Order = Cleared btn
  window.CHLNOS = require("../FunctionsCall/BtnMaster/ReportClearedFilter").CHLNOS; 

  // Report > Outstanding >  BillWise Receivable = Adjustment wise btn
  window.BTOBOSTDR = require("../FunctionsCall/BtnMaster/ReportClearedFilter").BTOBOSTDR; 


  // break;
  if (props?.from?.charAt(0) == "U") {
    if (props.btnPnlObj.p1 == "T_TESTCERT") {
      fun = require("../FunctionsCall/UserMaster/UserMasterEntry/TestCertificate/TestCertificate");
      window.MSAED = fun.MSAED;
    } else {
      fun = require("../FunctionsCall/UserMaster/UserMasterEntry/UserMasterEntry");
      window.MSAED = fun.MSAED;
    }
  } else if (props?.from?.charAt(0) == "A") { //Account Ledger
    // console.log(props?.from?.charAt(0), "props?.from?.charAt(0)");
    window.TM_ZINOUT = require("../ReportComponent/Account Books/LedGer/AccLedger").TM_ZINOUT;
  }else if (props?.from?.charAt(0) == "P") { // Product Ledger
    // console.log(props?.from?.charAt(0), "props?.from?.charAt(0)");
    // window.TM_ZINOUT = require("../FunctionsCall/AccountLedger/SLedger").TM_ZINOUT;

    window.TM_ZINOUT = require("../ReportComponent/Account Books/LedGer/AccLedger").TM_ZINOUT;
    
  } else {
    switch (props.from) {
      case "Narration":
        window.M35AED = require("../FunctionsCall/Narration/Narration").M35AED
        break;
      case "00510001":
      case "P_M01":
        window.M01 = require("../FunctionsCall/M01Form/m01");
        // window.M01 = fun.M01;
        // window.M01AED = fun.M01AED;
        break;
      // case "T1890588":
      case "T0410837":
        fun = require("../FunctionsCall/AccountLedger/PLFormat");
        window.M01 = require("../FunctionsCall/M01Form/m01");

        // window.TM_ZINOUT = require("../FunctionsCall/AccountLedger/SLedger").SLED
        // window.TM_FORMAT = fun.TM_FORMAT;
        break;
      case "13740005":
        fun = require("../FunctionsCall/UserField/UserField");
        window.M41UFAction = fun.M41UFAction;
        window.M41UMAction = fun.M41UMAction;
        window.UserField = fun.UserField;
        fun = require("../SetupComponent/CompanySetup/UserFieldAutoNumber/UserFieldAutoNum");
        window.T95AED = fun.T95AED;
        fun = require("../SetupComponent/CompanySetup/WaterMarkList/WaterMarkListFun");
        window.WMKAED = fun.WMKAED;
        // M41UMAction
        break;
      case "00540001":
      case "P_M11E":
        fun = require("../FunctionsCall/AccountGroup/AccountGroup");
        window.MSAED = fun.MSAED;
        break;
      case "01260123":
      case "P_M32G":
      case "P_M32C":
        fun = require("../FunctionsCall/OtherInfo/ProductGroup");
        window.MSAED = fun.MSAED;
        break;

      case "01290123":
      case "T_M32C":
        fun = require("../FunctionsCall/OtherInfo/ProductGroup");
        window.MSAED = fun.MSAED;
        break;
      case "00750072":
        fun = require("../FunctionsCall/GSTMaster/GSTMaster");
        window.M51AED = fun.M51AED;
        break;
      case "00600057":
        fun = require("../FunctionsCall/GSTMaster/GSTMaster");
        window.M51AED = fun.M51AED;
        break;
      case "01310123":
        fun = require("../FunctionsCall/LocationList/LocationList");
        window.MSAED = fun.MSAED;
        break;
      case "P_M32L":
        fun = require("../FunctionsCall/LocationList/LocationList");
        window.MSAED = fun.MSAED;
        break;
      case "00960001":
        fun = require("../FunctionsCall/PriceList/PriceList");
        window.M24AED = fun.M24AED;
        break;
      case "00930001":
      case "P_M21":
        fun = require("../FunctionsCall/ProductList/ProductList");
        window.M21 = fun;
        window.M21AED = fun.M21AED;
        break;
      case "P_HSN":
        fun = require("../FunctionsCall/ProductList/HSNCode")
        window.M35AED = fun.M35AED
        break;
      case "P_UNTS":
        fun = require("../FunctionsCall/ProductList/SaleUnit")
        window.M35AED = fun.M35AED
        break;
      case "P_UNTP":
        fun = require("../FunctionsCall/ProductList/PurchaseUnit")
        window.M35AED = fun.M35AED
        break;
      case "14950123":
        fun = require("../FunctionsCall/OtherInfo/ECommerceOperator");
        window.MSAED = fun.MSAED;
        break;
      case "04740462":
        fun = require("../FunctionsCall/Transaction/Transaction");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "04800462":
        fun = require("../FunctionsCall/Transaction/Transaction");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "04770462":
        fun = require("../FunctionsCall/Transaction/Transaction");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "04830462":
        fun = require("../FunctionsCall/Transaction/Transaction");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "04860462":
        fun = require("../FunctionsCall/Transaction/Transaction");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "01980001":
        // fun = require("../FunctionsCall/ProcessMaster/ProcessMaster");
        // window.VouEntAct = fun.VouEntAct;
        fun = require("../FunctionsCall/ProcessMaster/M71");
        window.M71AED = fun.M71AED;
        break;
      case "01340123":
      case "P_M31T":
        fun = require("../FunctionsCall/OtherInfo/CityList");
        window.MSAED = fun.MSAED;
        break;
      case "01350123":
      case "P_M31A":
        fun = require("../FunctionsCall/OtherInfo/AreaList");
        window.MSAED = fun.MSAED;
        break;
      case "01360123":
      case "P_M31R":
        fun = require("../FunctionsCall/AccountCategory/AccountCategory");
        window.MSAED = fun.MSAED;
        break;
      case "02210213": //sales_invoice_1
      case "02230213": // sales_invoice_2
      case "02270213": //sales_Return_1
      case "02250213": //sales_Return_2
      case "02280213": //sales_Challan
      case "02430213": // Sales Order
      case "02460213": // Sales Quotation
      case "02550249": // Purchase_Invoice_1 add
      case "02530249": //Purchase_Invoice_2
      case "02590249": //Purchase_return_1
      case "02570249": //Purchase_return_2 add
      case "02670249": // Purchase Challan
      case "02760249": //Purchase Order
        fun = require("../Transaction/SaleEntry/TransactionSales");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "04920489":
      case "04950489":
      case "04980489":
      case "05010489":
      case "05040489":
      case "05070489":
      case "05100489":
      case "05130489":
        fun = require("../Transaction/JournalEntry/JournalEntry");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "13770005":
        fun = require("../FunctionsCall/VoucherSetup/CallInvType");
        // window.M45VoucherSetup = fun.M45VoucherSetup;
        window.CallInvType = fun.CallInvType;
        break;
      case "03450342":
        fun = require("../Transaction/StockEntry/OpeningStock");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "03600342":
        fun = require("../Transaction/StockEntry/StockJournalFun");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "03630342":
        fun = require("../Transaction/StockEntry/StockTransfer/StockTransferFun");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "03960002":
        fun = require("../Transaction/JobWorkOpening/JobWorkOpening");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "20422041":
      case "20432041":
      case "04020399":
      case "04050399":
      case "20400399":
        fun = require("../Transaction/JobWorkOut/RIOJobOutFunction/JobWorkOutFun");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "04110399":
      // fun = require("../Transaction/JobWorkOut/JobWorkOutBill/JobWorkBillFun");
      // window.VouEntAct = fun.VouEntAct;
      // break;
      case "20442041":
        fun = require("../Transaction/JobWorkOut/JobWorkOutBill/JobWorkBillFun");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "04200417":
      // fun = require("../Transaction/JobWorkIn/RIPFunctionCalls/JwiRIPFun");
      // window.VouEntAct = fun.VouEntAct;
      // break;
      case "04230417":
      // fun = require("../Transaction/JobWorkIn/RIPFunctionCalls/JwiRIPFun");
      // window.VouEntAct = fun.VouEntAct;
      // break;
      case "04260417":
        fun = require("../Transaction/JobWorkIn/RIPFunctionCalls/JwiRIPFun");
        window.VouEntAct = fun.VouEntAct;
        break;

      case "04320417":
        fun = require("../Transaction/JobWorkIn/BothBillFunCall/JwiJobWorkBillFun");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "03900002":
        fun = require("../Transaction/Production/Production");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "03510342":
        fun = require("../Transaction/StockEntry/CreditDebitNote/CreditDebitNote");
        window.VouEntAct = fun.VouEntAct;
        break;

      case "03540342":
        fun = require("../Transaction/StockEntry/CreditDebitNote/CreditDebitNote");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "05520546":
      case "05490546":
      case "05550546":
      case "20460546":
        fun = require("../Transaction/GST Entry/Journal Entry/JournalEntryFn");
        window.VouEntAct = fun.VouEntAct;
        break;
      case "15000001":
      case "P_M14":
        fun = require("../FunctionsCall/TaxClass/TaxClass");
        window.M14AED = fun.M14AED;
        break;
      case "01320123":
        fun = require("../FunctionsCall/JobProcessList/JobProcessList");
        window.MSAED = fun.MSAED;
        break;
      case "13591353":
        fun = require("../Utility/Personal Diary/MailingLetterFunctions");
        window.LTRAED = fun.LTRAED;
        break;

      case "14131410":
        fun = require("../SetupComponent/User/User Detail/UserDetail");
        window.M81AED = fun.M81AED;
        fun = require("../SetupComponent/User/User Detail/UserLevel");
        window.M81GAED = fun.M81GAED;
        break;

      case "06870681":
        fun = require("../ReportComponent/GSTReturns/GSTR2")
        window.GSTR2 = fun.GSTR2
        break;
      case "06840681":
        fun = require("../ReportComponent/GSTReturns/GSTR1")
        window.G1RET = fun.G1RET
        break;
        case "07890783":
          window.M01 = require("../FunctionsCall/M01Form/m01");
          break;
      case "20570001":
        fun = require("../FunctionsCall/LeadEntry/LeadEntry")
        window.M02LAED = fun.M02LAED
        break;
      default:
        break;
    }
  }
  // console.log("cSTID", props?.FullData?.TMFormatData?.cSTID);
  const BindFunWithPara = (itemobj, s) => {
    // console.log("itemobj", itemobj);  

    // console.log('props.table=>',props.table.current);
    var paraStart = itemobj?.KEYEXPR.indexOf("(");
    var paraEnd = itemobj?.KEYEXPR.lastIndexOf(")");
    var fun = itemobj?.KEYEXPR.substring(0, paraStart); // "M01." +
    var parameter = "(";
    var para = itemobj?.KEYEXPR.substring(paraStart + 1, paraEnd - 1);

    para = para.replace(/\'/g, "");
    var paraArr = para.split(",");

    // console.log("paraArr",paraArr);

    var para0,
      para1,
      para2,
      para3,
      para4,
      para5,
      para6,
      para7,
      para8,
      para9,
      para10;
    if (paraArr?.length > 0) {
      para0 = paraArr[0];
    }
    if (paraArr?.length > 1) {
      para1 = paraArr[1];
    }
    if (paraArr?.length > 2) {
      para2 = paraArr[2];
    }
    if (paraArr?.length > 3) {
      para3 = paraArr[3];
    }
    if (paraArr?.length > 4) {
      para4 = paraArr[4];
    }
    if (paraArr?.length > 5) {
      para5 = paraArr[5];
    }
    if (paraArr?.length > 6) {
      para6 = paraArr[6];
    }
    if (paraArr?.length > 7) {
      para7 = paraArr[7];
    }
    if (paraArr?.length > 8) {
      para8 = paraArr[8];
    }
    if (paraArr?.length > 9) {
      para9 = paraArr[9];
    }
    if (paraArr?.length > 10) {
      para10 = paraArr[10];
    }
    if (fun.indexOf("window.") !== -1) {
      fun = fun.substring(7);
    }

    // console.log("para2",para2);

    // var orig = {...props.Skey?.original}
    // Object.keys(orig).map((e)=>{
    //   console.log(`keyss ${e} => ${orig[e]}`);
    //   if(orig[e] && typeof orig[e] == "string"){
    //     orig = {...orig,[e]:orig[e]?.replaceAll("'", "")?.replaceAll('"', '')?.replaceAll(',', '')}
    //   }

    // })
    const tabobject = JSON.stringify({
      id: props.Skey?.original?.FIELD01,
      text: itemobj.CAPTION,
      p0: para0,
      p1: para1,
      p2: para2,
      M41UID: props.Skey?.original?.M41UID,
      M45UID: props.Skey?.original?.M45UID,
      WMF01: props.Skey?.original?.WMF01,
      page: props.from, // props?.FullData?.TMFormatData?.cSTID
      G02F01: props.Skey?.original?.G02F01,
      DataGridRow: { ...props.Skey?.original, FIELD02: typeof props?.Skey?.original?.FIELD02 == 'string' ?  (props.Skey?.original?.FIELD02?.replaceAll("'", "")?.replaceAll('"', '')?.replaceAll(',', '')) : (props?.Skey?.original?.FIELD02) },
      // DataGridRow:{...orig},
      FormCode: props?.FormCode,
      VoucherFormType: props?.VoucherFormType,
      ModalIndex: props?.ModalIndex,
      BtnPnlObj: props?.btnPnlObj,
      S11F01: props?.S11F01
    });

    // console.log("fun---", fun, tabobject); //T41VLISTPPPP
    try {
      eval(
        fun +
        "(`" +
        tabobject +
        "`,`" +
        (props.Skey?.original?.FIELD01 ?? "") +
        "`,`" +
        para1 +
        "`,`" +
        para2 +
        "`,`" +
        para3 +
        "`,`" +
        para4 +
        "`,`" +
        para5 +
        "`,`" +
        para6 +
        "`,`" +
        para7 +
        "`,`" +
        para8 +
        "`,`" +
        para9 +
        "`,`" +
        para10 +
        "`)"
      );
    }
    catch (e) {
      console.log("Eval Error", e);
    }
  };
  useImperativeHandle(ref, () => ({
    handleButtonClick(index) {
      // console.log('Button clicked:', props.data);
      BindFunWithPara(index);
    },
  }))
  return (
    <>
      <Group
        spacing={"xs"}
        position="center"
        display={"flex"}

      >
        {/* {
          props?.from == "03900002" ?
            <Button
              size="sm"
              variant="subtle"
              compact
              ref={props.ref}
              onClick={() => {
                BindFunWithPara(productionQuickEntryBtn);
              }}
            >
              Quick Entry
            </Button> : ""
        } */}
        {props?.data?.map((btn, i) => {
          if (btn.CAPTION) {
            return (
              <div key={i}>
                <Tooltip label={btn.TOOLTIP}>
                  <Button
                    size="sm"
                    variant="subtle"
                    compact
                    onClick={() => {
                      // console.log('btn panel data',btn);
                      BindFunWithPara(btn);
                    }}
                  >
                    {btn.CAPTION}
                  </Button>
                </Tooltip>
              </div>
            );
          }
        })}
      </Group>



    </>
  );
})

export default React.memo(ButtonPanel);
