import {
    IconBuildingBank,
    IconCashBanknote,
    IconCoin,
    IconCreditCard,
    IconReceipt,
    IconReceiptRefund,
    IconReceiptTax,
    IconRepeat,
    IconReport,
  } from '@tabler/icons-react';
  import {
    Anchor,
    Card,
    Group,
    SimpleGrid,
    Text,
    UnstyledButton,
    useMantineTheme,
  } from '@mantine/core';
  import classes from './shortcuts.css';
import { useLocalStorage } from '@mantine/hooks';
  
  const mockdata = [
    { title: 'Credit cards', icon: IconCreditCard, color: 'violet' },
    { title: 'Banks nearby', icon: IconBuildingBank, color: 'indigo' },
    { title: 'Transfers', icon: IconRepeat, color: 'blue' },
    { title: 'Refunds', icon: IconReceiptRefund, color: 'green' },
    { title: 'Receipts', icon: IconReceipt, color: 'teal' },
    { title: 'Taxes', icon: IconReceiptTax, color: 'cyan' },
    { title: 'Reports', icon: IconReport, color: 'pink' },
    { title: 'Payments', icon: IconCoin, color: 'red' },
    { title: 'Cashback', icon: IconCashBanknote, color: 'orange' },
  ];
 
  export function Shortcuts() {
    const theme = useMantineTheme();
    const [value, setValue] = useLocalStorage({key:"Shortcuts",defaultValue:mockdata})
    const items = value.map((item) => (
        <UnstyledButton key={item.title} className={classes.item} style={{flex:1}}>
          <item.icon color={theme.colors[item.color][6]} size={32} />
          <Text size="xs" mt={7}>
            {item.title}
          </Text>
        </UnstyledButton>
      ));
    return (
      <Card withBorder radius="md" className={classes.card} >
        <Group justify="space-between" w={"100%"}>
          <Text className={classes.title} style={{flex:1}}>Shortcuts</Text>
          <Anchor size="xs" c="dimmed" style={{ lineHeight: 1,flex:1,textAlign:'right' }}>
            Manage Shortcuts
          </Anchor> 
        </Group>
        <SimpleGrid cols={3} mt="md" style={{display:'flex', justifyContent:'center'}}>
          {items}
        </SimpleGrid>
      </Card>
    );
  }