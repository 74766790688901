import { Container, Group, ActionIcon, rem, Image, useMantineColorScheme, createStyles, Text, useMantineTheme, Dialog, Divider, Grid } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconVersions, IconBrandWhatsapp, IconSun, IconMoonStars, IconAB, IconTypography, IconPalette, IconBellRinging2, IconBellRinging } from '@tabler/icons-react';
import App from "../App.json"
import WhatsAppPopover from '../MyComponent/WhatsappPopup'
import { useState } from 'react';
import SessionSetting from './SessionSetting';
var iconSize = "2rem";
export function FooterBar(styles) {
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: ""
  }
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme()
  const dark = colorScheme === 'dark';
  const [DrawerObj, setDrawerObj] = useState(dra);
  return (
   <>
   <Dialog opened={DrawerObj.open} withCloseButton onClose={()=>setDrawerObj(dra)} size="lg" radius="md" p={30} shadow='md'>
   {DrawerObj.body}
      </Dialog>
       <Container fluid className={'footerf'} > 
     {/* <div className={'footerf'} style={{paddingBottom:5}} >   */}
        <Grid columns={11}>
          <Grid.Col span={2}>
            <Text variant='text' color={'gray'}>{App.version}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <WhatsAppPopover/>
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() => toggleColorScheme()}
              variant="transparent"
              color={dark ? 'yellow' : 'blue'}
              title="Toggle color scheme"
              mr={5}>
                {dark ? <IconSun size={iconSize} /> : <IconMoonStars size={iconSize} />}
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
          <ActionIcon
                        onClick={() =>  setDrawerObj({
                          ...dra,
                          title: <Text  fw={700}>Font Setting</Text>,
                          body: <>
                          <SessionSetting setting={"font"} />
                          </>,
                          open: true,
                          size: "md",
                          position: "right"
                        })}
                        variant="transparent"
                        // color={dark ? 'yellow' : 'blue'}
                        title="Font Settings"
                        mr={5}
                    >
                        <IconTypography size={iconSize} />
                    </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
          <ActionIcon
                        onClick={() => setDrawerObj({
                          ...dra,
                          title: <Text  fw={700}>Color Setting</Text>,
                          body: <>
                          <SessionSetting setting={"color"} />
                          </>,
                          open: true,
                          size: "md",
                          position: "right"
                          
                        })}
                        variant="transparent"
                        // color={dark ? 'yellow' : 'blue'}
                        title="color Settings"
                        mr={5}
                    >
                        <IconPalette size={iconSize} />
                    </ActionIcon>
          </Grid.Col>
          <Grid.Col span={4}>
          <Text variant='text' color={'gray'}>{new Date().toLocaleString('en-IN',{
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
})}</Text>
          </Grid.Col>
          <Grid.Col span={1}><ActionIcon
                        onClick={() => setDrawerObj({
                          ...dra,
                          title: <Text  fw={700}>Color Setting</Text>,
                          body: <>
                          <SessionSetting setting={"color"} />
                          </>,
                          open: true,
                          size: "md",
                          position: "right"
                          
                        })}
                        variant="transparent"
                        // color={dark ? 'yellow' : 'blue'}
                        title="color Settings"
                        mr={5}
                    >
                        <IconBellRinging size={iconSize} />
                    </ActionIcon></Grid.Col>
        </Grid>
        
        {/* <Divider orientation="vertical" /> */}
        
        
                    
                    
        {/* </Group> */}
      </Container>
      {/* </div> */}
      </>
  );
}