import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';

export const GetLeadEntryData = createAsyncThunk(
    "LeadEntry/GetLeadEntryData",
    async (data) => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": data ? data.action : "A",
                    "cCode": data ? data.code : "",
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM02Lead + "?pa=" + param)
            return response.data?.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error }
        }
    }
)

const LeadEntrySlice = createSlice({
    name: "LeadEntry",
    initialState: {
        LeadEntryData: [],
        isLoading: true,
        hasError: false,
        ErrorMsg: ""
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetLeadEntryData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetLeadEntryData.fulfilled, (state, action) => {
                console.log("GetLeadEntryData action.payload", action.payload);
                if (!action.payload?.error) {
                    state.LeadEntryData = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error;
                }
            })
            .addCase(GetLeadEntryData.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
            })
    }
});

export const { } = LeadEntrySlice.actions

export default LeadEntrySlice.reducer