import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";

export const GetAutoBillData = createAsyncThunk(
  "AutoBill/GetAutoBillData",
  async (props) => {
    console.log(props, "propsGetAutoBillData");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: props == "02370213" ? "" : "J",
        cCode: "",
        cOPara: "C",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetAutoBillData + "?pa=" + param
      );
      // console.log(response, "response");
      return response?.data?.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

const AutoBillSlice = createSlice({
  name: "AutoBill",
  initialState: {
    AutoBillData: [],
    isLoading: false,
    hasError: false,
    partyData: [],
    productData: [],
  },
  reducers: {
    SavePartyData: (state, action) => {
      // console.log(action.payload , "SavePartyData")
      state.partyData = action.payload;
    },
    SaveProductData: (state, action) => {
      // console.log(action.payload , "SaveProductData")

      state.productData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAutoBillData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetAutoBillData.fulfilled, (state, action) => {
        // console.log("GetAutoBillData==>Slice", action.payload)
        state.AutoBillData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetAutoBillData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const { SavePartyData, SaveProductData } = AutoBillSlice.actions;

export default AutoBillSlice.reducer;
