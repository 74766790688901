import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";
// import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import Store from "../store";
import { GetAccountList } from "../slices/AccountListSlice";

export const TransactionApi = createAsyncThunk(
  "Transaction/TransactionApi",
  async (OBJ) => {
    console.log('Transaction/TransactionApi==>', OBJ);
    
    try {
      var p = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ?.p0,
        cCode:
          OBJ?.p0 === "E"
            ? OBJ?.id
            : OBJ.p0 === "VD"
              ? OBJ?.id
              : OBJ.p0 === "T"
                ? OBJ?.id
                : OBJ.page === "04740462"
                  ? "BPBP"
                  : OBJ.page === "04800462"
                    ? "CPCP"
                    : OBJ.page === "04770462"
                      ? "BRBR"
                      : OBJ.page === "04830462"
                        ? "CRCR"
                        : OBJ.page === "02210213"
                          ? "SSSS" // sales Entry > sale invoice 1
                          : OBJ.page === "02230213"
                            ? "SSWS" // sales Entry > sale invoice 2
                            : OBJ.page === "02270213"
                              ? "SRWR" // sales Entry > sale return 1
                              : OBJ.page === "02250213"
                                ? "SRSR" // sales Entry > sale return 2
                                : OBJ.page === "02280213"
                                  ? "HSHS" // sales Entry > sale Challan
                                  : OBJ.page === "02430213"
                                    ? "OSOS" // sales Entry > sale Order
                                    : OBJ.page === "02340213"
                                      ? "SSSS" // sales Entry > Bill From Challan
                                      : OBJ.page === "02460213"
                                        ? "QTQT" // sales Entry > sale Quotation
                                        : OBJ.page === "02530249"
                                          ? "PPPP" // Purchase Entry > Purchase invoice-2
                                          : OBJ.page === "02590249"
                                            ? "PRPR" // Purchase Entry > Purchase Return-1
                                            : OBJ.page === "02670249"
                                              ? "HPHP" // Purchase Entry > Purchase Challan
                                              : OBJ.page === "02700249"
                                                ? "PPPP" // Purchase Entry > Bill From Challan
                                                : OBJ.page === "02760249"
                                                  ? "OPOP" // Purchase Entry > Purchase Order
                                                  : OBJ.page === "04860462"
                                                    ? "BCBC"
                                                    : (OBJ.page === "03900002" || OBJ.id === "20560002") // Production & Quick Production
                                                      ? "ININ"
                                                      : OBJ.page === "03960002"
                                                        ? "JAJA"
                                                        : OBJ.page === "03510342"
                                                          ? "HCHC"
                                                          : OBJ.page === "03540342"
                                                            ? "HDHD"
                                                            : OBJ.page === "03450342"
                                                              ? "HOHO"
                                                              : OBJ.page === "03600342"
                                                                ? "IJIJ"
                                                                : OBJ.page === "03630342"
                                                                  ? "TSTS"
                                                                  : OBJ.page === "20400399"
                                                                    ? "OJOJ"
                                                                    : OBJ.page === "04020399"
                                                                      ? "JIJI"
                                                                      : OBJ.page === "04050399"
                                                                        ? "JOJO"
                                                                        : OBJ.page === "04110399"
                                                                          ? "JJJJ"
                                                                          : OBJ.page === "04200417"
                                                                            ? "JXJX"
                                                                            : OBJ.page === "04230417"
                                                                              ? "JWJW"
                                                                              : OBJ.page === "04260417"
                                                                                ? "JYJY"
                                                                                : OBJ.page === "04320417"
                                                                                  ? "JZJZ"
                                                                                  : OBJ.page === "04290417"
                                                                                    ? "JZJZ"
                                                                                    : OBJ.page === "04080399"
                                                                                      ? "JJ"
                                                                                      : OBJ.page === "20432041"
                                                                                        ? "JXJX"
                                                                                        : OBJ.page === "20422041"
                                                                                          ? "JYJY"
                                                                                          : OBJ.page === "20442041"
                                                                                            ? "JJJJ"
                                                                                            : OBJ.page === "20452041"
                                                                                              ? "JJJJ"
                                                                                              : OBJ.page === "05520546"
                                                                                                ? "NCNC"
                                                                                                : OBJ.page === "05490546"
                                                                                                  ? "JRJR"
                                                                                                  : OBJ.page === "05550546"
                                                                                                    ? "NDND"
                                                                                                    : OBJ.page === "20460546"
                                                                                                      ? "JRJR"
                                                                                                      : OBJ.PageData
                                                                                                        ? OBJ.PageData //Journal Entry
                                                                                                        : "CRPR",
        cOPara:
          OBJ?.page == "02340213"
            ? "CTYPE~C~S!"
            : OBJ?.page == "02700249"
              ? "CTYPE~C~P! "
              : OBJ?.page == "20432041"
                ? "CTYPE~C~JC"
                : OBJ?.page == "20422041"
                  ? "CTYPE~C~JD"
                  : OBJ?.page == "20442041"
                    ? "CTYPE~C~J2"
                    : OBJ?.page == "20452041"
                      ? "CTYPE~C~J2"
                      : OBJ?.page == "05520546"
                        ? "CTYPE~C~EG"
                        : OBJ?.page == "05490546"
                          ? "CTYPE~C~EG"
                          : OBJ?.page == "05550546"
                            ? "CTYPE~C~EG"
                            : OBJ?.page == "20460546"
                              ? "CTYPE~C~IG"
                              : "",
                              "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      if (OBJ.lJournal) {
        p = { ...p, lJournal: OBJ.lJournal ?? "" };
      }
      let param = JSON.stringify(p);
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetVouEnt + "?pa=" + param);
      // console.log(response, "response");
      if (OBJ.page === "04860462" || OBJ?.isContraPage) {
        return [response.data.data, OBJ.page]
      }
      else {
        return response.data.data;
      }
    } catch (error) {
      GlobalClass.Notify("error", "Error", error.message);
      // console.log("Transaction Api", error);
      return { error };
    }
  }
);

export const BillFormChallanPartyAc = createAsyncThunk(
  "Transaction/BillFormChallanPartyAc",
  async (OBJ) => {
    // console.log('Transaction/TransactionApi==>', OBJ);
    try {
      var p = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: OBJ.page === "02340213" ? "SSSS" : 
          OBJ.page === "02700249" ? "PPPP" : OBJ.page === "04080399" || OBJ.page === "20452041" ? "JJJJ" : OBJ.page === "04290417"  ? "JZJZ" : 
            "",
        cOPara: OBJ?.page == "02340213" ? "CTYPE~C~S!" :
          OBJ?.page == "02700249" ? "CTYPE~C~P!" :  OBJ.page === "04080399" ? "CTYPE~C~J!" : OBJ.page === "20452041" ? "CTYPE~C~J2" : OBJ.page === "04290417" ? "CTYPE~C~J1" :
            "",
            "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      let param = JSON.stringify(p);
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetVouPrtData + "?pa=" + param
      );
      console.log(response, "response");
      return response.data.data;
    } catch (error) {
      GlobalClass.Notify("error", "Error", error.message);
      // console.log("Transaction Api", error);
      return { error };
    }
  }
)

export const TransactionDeleteApi = createAsyncThunk(
  "Transaction/TransactionDeleteApi",
  async (OBJ) => {
    GlobalClass.Notify(
      "info",
      OBJ.p0 == "D" ? "Deleting" : "Audit",
      "Please wait while we process your data"
    );

    try {
      let p = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: OBJ.id,
        cOPara: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      if (OBJ.lJournal) {
        p = { ...p, lJournal: OBJ.lJournal ?? "" };
      }
      let param = JSON.stringify(p);
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetVouEnt + "?pa=" + param
      );
      // console.log(response.data);

      if (response.data.status === "SUCCESS") {
        modals.closeAll();
        GlobalClass.Notify("success",OBJ.p0 == "D" ? "Deleted" : "Audit",OBJ.p0 == "D" ? "Deleted successfully" : "Audit SuccessFull");

        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
        // console.log("paginationObj",PaginationObj)

        switch (OBJ.page) {
          case "20460546":
            Store.dispatch(
              GetAccountList({
                id: "20460546",
                name: "GST Income",
                p0: "MT",
                p1: "TXNVOULISTIG",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["20460546"],
              })
            );
            break;
          case "05520546":
            Store.dispatch(
              GetAccountList({
                id: "05520546",
                name: "Credit Note (Without Stock)",
                p0: "MT",
                p1: "TXN_GSTLSTNC",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["05520546"],
              })
            );
            break;
          case "05550546":
            Store.dispatch(
              GetAccountList({
                id: "05550546",
                name: "Debit Note (Without Stock)",
                p0: "MT",
                p1: "TXN_GSTLSTND",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["05550546"],
              })
            );
            break;
          case "05490546":
            Store.dispatch(
              GetAccountList({
                id: "05490546",
                name: "GST Expense",
                p0: "MT",
                p1: "TXNVOULISTEG",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["05490546"],
              })
            );
            break;
          case "20442041":
            Store.dispatch(
              GetAccountList({
                id: "20442041",
                name: "Sub JobWork Bill",
                p0: "MT",
                p1: "TXNVOULISTSJ",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["20442041"],
              })
            );
            break;
          case "20422041":
            Store.dispatch(
              GetAccountList({
                id: "20422041",
                name: "Issue",
                p0: "MT",
                p1: "TXNVOULISTJD",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["20422041"],
              })
            );
            break;
          case "20432041":
            Store.dispatch(
              GetAccountList({
                id: "20432041",
                name: "Receipt",
                p0: "MT",
                p1: "TXNVOULISTJC",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["20432041"],
              })
            );
            break;
          case "04200417":
            Store.dispatch(
              GetAccountList({
                id: "04200417",
                name: "Receipt",
                p0: "MT",
                p1: "TXNVOULISTJX",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04200417"],
              })
            );
            break;
          case "04230417":
            Store.dispatch(
              GetAccountList({
                id: "04230417",
                name: "Production",
                p0: "MT",
                p1: "TXNVOULISTJW",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04230417"],
              })
            );
            break;
          case "04260417":
            Store.dispatch(
              GetAccountList({
                id: "04260417",
                name: "Issue",
                p0: "MT",
                p1: "TXNVOULISTJY",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04260417"],
              })
            );
            break;
          case "04320417":
            Store.dispatch(
              GetAccountList({
                id: "04320417",
                name: "Job work Bill",
                p0: "MT",
                p1: "TXNVOULISTJZ",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04320417"],
              })
            );
            break;
          case "03600342":
            Store.dispatch(
              GetAccountList({
                id: "03600342",
                name: "Stock Journal",
                p0: "MT",
                p1: "TXNVOULISTIJ",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["03600342"],
              })
            );
            break;
          case "03630342":
            Store.dispatch(
              GetAccountList({
                id: "03630342",
                name: "Stock Transfer",
                p0: "MT",
                p1: "T41VOULISTTS",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["03630342"],
              })
            );
            break;
          case "20400399":
            Store.dispatch(
              GetAccountList({
                id: "20400399",
                name: "Order",
                p0: "MT",
                p1: "TXNVOULISTOJ",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["20400399"],
              })
            );
            break;
          case "04020399":
            Store.dispatch(
              GetAccountList({
                id: "04020399",
                name: "Issue",
                p0: "MT",
                p1: "TXNVOULISTJI",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04020399"],
              })
            );
            break;
          case "04050399":
            Store.dispatch(
              GetAccountList({
                id: "04050399",
                name: "Receipt",
                p0: "MT",
                p1: "TXNVOULISTJO",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04050399"],
              })
            );
            break;
          case "04110399":
            Store.dispatch(
              GetAccountList({
                id: "04110399",
                name: "Job work Bill",
                p0: "MT",
                p1: "TXNVOULISTJJ",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04110399"],
              })
            );
            break;

          case "04740462":
            Store.dispatch(
              GetAccountList({
                id: "04740462",
                name: "Bank Payment",
                p0: "MT",
                p1: "T41VLISTBPBP",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04740462"],
              })
            );
            break;
          case "04800462":
            Store.dispatch(
              GetAccountList({
                id: "04800462",
                name: "Cash Payment",
                p0: "MT",
                p1: "T41VLISTCPCP",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04800462"],
              })
            );
            break;
          case "04770462":
            Store.dispatch(
              GetAccountList({
                id: "04770462",
                name: "Bank Receipt",
                p0: "MT",
                p1: "T41VLISTBRBR",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04770462"],
              })
            );
            break;
          case "04830462":
            Store.dispatch(
              GetAccountList({
                id: "04830462",
                name: "Cash Receipt",
                p0: "MT",
                p1: "T41VLISTCRCR",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04830462"],
              })
            );
            break;
          case "04860462":
            Store.dispatch(
              GetAccountList({
                id: "04860462",
                name: "Contra",
                p0: "MT",
                p1: "T41VLISTBCBC",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["04860462"],
              })
            );
            break;
          // case "04860462":
          //     Store.dispatch(GetAccountList({
          //         id: "04860462",
          //         name: "Contra",
          //         p0: "MT",
          //         p1: "T41VLISTBCBC",
          //         p2: "",
          //         p3: "",
          //         type: "A",
          //         pagination: PaginationObj["04860462"]
          //     }))
          //     break;
          case "03900002":
            Store.dispatch(
              GetAccountList({
                id: "03900002",
                name: "Production",
                p0: "MT",
                p1: "TXNVOULISTIN",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["03900002"],
              })
            );
            break;
          case "03960002":
            Store.dispatch(
              GetAccountList({
                id: "03960002",
                name: "Jobwork Opening",
                p0: "MT",
                p1: "TXNVOULISTJA",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["03960002"],
              })
            );
            break;
          case "03510342":
            Store.dispatch(
              GetAccountList({
                id: "03510342",
                name: "Credit Note",
                p0: "MT",
                p1: "TXNVOULISTHC",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["03510342"],
              })
            );
            break;
          case "03540342":
            Store.dispatch(
              GetAccountList({
                id: "03540342",
                name: "Debit Note",
                p0: "MT",
                p1: "TXNVOULISTHD",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["03540342"],
              })
            );
            break;
          case "03450342":
            Store.dispatch(
              GetAccountList({
                id: "03450342",
                name: "Opening Stock",
                p0: "MT",
                p1: "TXNVOULISTHO",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["03450342"],
              })
            );
            break;
          case "04920489":
          case "04950489":
          case "04980489":
          case "05010489":
          case "05040489":
          case "05070489":
          case "05100489":
          case "05130489":
            // console.log("AccountListObject delete==>", OBJ?.AcountListObj)
            Store.dispatch(GetAccountList(OBJ?.AcountListObj));
            break;
          case "02210213": //Sales Invoice
            Store.dispatch(
              GetAccountList({
                id: "02210213",
                name: "Sales Invoice",
                p0: "MT",
                p1: "T41VLISTSSSS",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02210213"],
              })
            );
            break;
          case "02230213":
            Store.dispatch(
              GetAccountList({
                id: "02230213",
                name: "Sales Invoice",
                p0: "MT",
                p1: "T41VLISTSSWS",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02230213"],
              })
            );
            break;
          case "02270213":
            Store.dispatch(
              GetAccountList({
                id: "02270213",
                name: "Sales Return",
                p0: "MT",
                p1: "T41VLISTSRWR",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02270213"],
              })
            );
            break;
          case "02250213":
            Store.dispatch(
              GetAccountList({
                id: "02250213",
                name: "Sales Return",
                p0: "MT",
                p1: "T41VLISTSRSR",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02250213"],
              })
            );
            break;
          case "02280213":
            Store.dispatch(
              GetAccountList({
                id: "02280213",
                name: "Sales Challan",
                p0: "MT",
                p1: "T41VLISTHSHS",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02280213"],
              })
            );
            break;
          case "02430213":
            Store.dispatch(
              GetAccountList({
                id: "02430213",
                name: "Sales Order",
                p0: "MT",
                p1: "T41VLISTOSOS",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02430213"],
              })
            );
            break;
          case "02460213":
            Store.dispatch(
              GetAccountList({
                id: "02460213",
                name: "Quotation",
                p0: "MT",
                p1: "T41VLISTQTQT",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02460213"],
              })
            );
            break;
          case "02530249":
            Store.dispatch(
              GetAccountList({
                id: "02530249",
                name: "Purchase Invoice",
                p0: "MT",
                p1: "T41VLISTPPPP",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02530249"],
              })
            );
            break;
          case "02590249":
            Store.dispatch(
              GetAccountList({
                id: "02590249",
                name: "Purchase Return",
                p0: "MT",
                p1: "T41VLISTPRPR",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02590249"],
              })
            );
            break;
          case "02670249":
            Store.dispatch(
              GetAccountList({
                id: "02670249",
                name: "Purchase Challan",
                p0: "MT",
                p1: "T41VLISTHPHP",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02670249"],
              })
            );
            break;
          case "02760249":
            Store.dispatch(
              GetAccountList({
                id: "02760249",
                name: "Purchase Order",
                p0: "MT",
                p1: "T41VLISTOPOP",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["02760249"],
              })
            );
            break;

          default:
            break;
        }
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);

export const BatchLogAddRemoveFun = createAsyncThunk(
  "Transaction/BatchLogAddRemoveFun",
  async ({ PageCode, T41Obj, T02Data, Row, OBJ }) => {
    console.log("BatchLogAddRemove parameter =>", [{
      "T41Obj": T41Obj,
      "T02Data": T02Data,
      "Row": Row,
      "OBJ": OBJ
    }]);
    if (Array.isArray(Row)) {
      Row?.map((TObj) => {
        let PostObj = { T02: TObj }
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:
            OBJ?.page == "02340213"
              ? "CTYPE~C~S!"
              : OBJ?.page == "02700249"
                ? "CTYPE~C~P! "
                : OBJ?.page == "20432041"
                  ? "CTYPE~C~JC"
                  : OBJ?.page == "20422041"
                    ? "CTYPE~C~JD"
                    : OBJ?.page == "20442041"
                      ? "CTYPE~C~J2"
                      : OBJ?.page == "20452041"
                        ? "CTYPE~C~J2"
                        : OBJ?.page == "05520546"
                          ? "CTYPE~C~EG"
                          : OBJ?.page == "05490546"
                            ? "CTYPE~C~EG"
                            : OBJ?.page == "05550546"
                              ? "CTYPE~C~EG"
                              : OBJ?.page == "20460546"
                                ? "CTYPE~C~IG"
                                : "",
          cSData: JSON.stringify(PostObj),
        };

        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:
            OBJ?.page == "02340213"
              ? "CTYPE~C~S!"
              : OBJ?.page == "02700249"
                ? "CTYPE~C~P! "
                : OBJ?.page == "20432041"
                  ? "CTYPE~C~JC"
                  : OBJ?.page == "20422041"
                    ? "CTYPE~C~JD"
                    : OBJ?.page == "20442041"
                      ? "CTYPE~C~J2"
                      : OBJ?.page == "20452041"
                        ? "CTYPE~C~J2"
                        : OBJ?.page == "05520546"
                          ? "CTYPE~C~EG"
                          : OBJ?.page == "05490546"
                            ? "CTYPE~C~EG"
                            : OBJ?.page == "05550546"
                              ? "CTYPE~C~EG"
                              : OBJ?.page == "20460546"
                                ? "CTYPE~C~IG"
                                : "",
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                "Success:",
                "Batch_Log Added Successfully"
              );
            } else if (data.status == "FAIL") {
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => GlobalClass.Notify("error", "Error", `${e?.message}`))
      })


    } else {
      let PostObj = { T02: Row };

      console.log("BatchLogAddRemove PostObj=>", PostObj);
      let GetObj = T02Data.find((a, i) => a.FIELD01 == Row.FIELD01);

      if (Row.FIELD01 != "" && GetObj.FIELD01 == Row.FIELD01) {
        console.log("BatchLogAddRemove Update Call")
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "R",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          // "cOPara": "",
          cSData: JSON.stringify(PostObj),
        };

        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "R",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
          // "cOPara": "",
          // "cSData": JSON.stringify(PostObj)
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              // GlobalClass.Notify('success', 'Success:' + 'Successfull', 'Remove Successfully')
              var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
                // "cOPara": "",
                cSData: JSON.stringify(PostObj),
              };

              let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                // "cOPara": "",
                // "cSData": JSON.stringify(PostObj)
              });
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
              };
              fetch(GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                  if (data.status == "SUCCESS") {
                    GlobalClass.Notify(
                      "success",
                      "Success:",
                      "Batch_Log Updated"
                    );
                  } else if (data.status == "FAIL") {
                    GlobalClass.Notify("error", data?.status, data?.message);
                  }
                })
                .catch((e) =>
                  GlobalClass.Notify("error", "Error", `${e?.message}`)
                );
            } else if (data.status == "FAIL") {
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => GlobalClass.Notify("error", "Error", `${e?.message}`));
      } else {
        console.log("BatchLogAddRemove Add Call")

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:
            OBJ?.page == "02340213"
              ? "CTYPE~C~S!"
              : OBJ?.page == "02700249"
                ? "CTYPE~C~P! "
                : OBJ?.page == "20432041"
                  ? "CTYPE~C~JC"
                  : OBJ?.page == "20422041"
                    ? "CTYPE~C~JD"
                    : OBJ?.page == "20442041"
                      ? "CTYPE~C~J2"
                      : OBJ?.page == "20452041"
                        ? "CTYPE~C~J2"
                        : OBJ?.page == "05520546"
                          ? "CTYPE~C~EG"
                          : OBJ?.page == "05490546"
                            ? "CTYPE~C~EG"
                            : OBJ?.page == "05550546"
                              ? "CTYPE~C~EG"
                              : OBJ?.page == "20460546"
                                ? "CTYPE~C~IG"
                                : "",
          cSData: JSON.stringify(PostObj),
        };

        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:
            OBJ?.page == "02340213"
              ? "CTYPE~C~S!"
              : OBJ?.page == "02700249"
                ? "CTYPE~C~P! "
                : OBJ?.page == "20432041"
                  ? "CTYPE~C~JC"
                  : OBJ?.page == "20422041"
                    ? "CTYPE~C~JD"
                    : OBJ?.page == "20442041"
                      ? "CTYPE~C~J2"
                      : OBJ?.page == "20452041"
                        ? "CTYPE~C~J2"
                        : OBJ?.page == "05520546"
                          ? "CTYPE~C~EG"
                          : OBJ?.page == "05490546"
                            ? "CTYPE~C~EG"
                            : OBJ?.page == "05550546"
                              ? "CTYPE~C~EG"
                              : OBJ?.page == "20460546"
                                ? "CTYPE~C~IG"
                                : "",
                                "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                "Success:",
                "Batch_Log Added Successfully"
              );
            } else if (data.status == "FAIL") {
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => GlobalClass.Notify("error", "Error", `${e?.message}`));
      }
    }

  }
);

export const GetUserFieldObj = createAsyncThunk(
  "TSalesSlices/GetUserFieldObj",
  async (cCode) => {
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": "A",
        "cCode": cCode,
        "cOPara": "T41",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetUserField + "?pa=" + param);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
)


const Transaction = createSlice({
  name: "Transaction",
  initialState: {
    TransactionData: [],
    BillFormChallanPartyAcData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    AccountListObject: {},
    UserField: [],
    flag: true,
    invTypeData : [],
    isUserFieldLoading : false,
  },
  reducers: {
    GetAccountListObject: (state, action) => {
      state.AccountListObject = action.payload;
    },
    GetNullTransactionData:(state, action)=>{
      state.TransactionData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(TransactionApi.pending, (state, action) => {

        // console.log("action?.meta?.arg?",action?.meta?.arg);

        if (action?.meta?.arg?.page === "04860462" && state.flag) {
          state.isLoading = true;
          state.hasError = false;
         
        }
        else if (action?.meta?.arg?.isContraPage === true && !state.flag) {
          state.isLoading = false;
          state.hasError = false;
        }
        else {
          state.isLoading = true;
          state.hasError = false;
        }
      })
      .addCase(TransactionApi.fulfilled, (state, action) => {

        // console.log("action.payload",action?.payload);

        if (!action.payload?.error) {

          if (Array.isArray(action?.payload) ) {
            if (state.flag && action?.payload[1] === "04860462") {
              state.TransactionData = action.payload[0]
              state.isLoading = false;
              state.hasError = false;
              state.flag = false
            }
            else if (!state.flag ) {
              state.isLoading = false;
              state.hasError = false;
              state.invTypeData[0] = action.payload[0]?.M45IDT
              state.invTypeData[1] = action.payload[0]?.M45I
            }

          } // Contra Page
          else {
            // state.flag = false
            state.TransactionData = action.payload;
            state.isLoading = false;
            state.hasError = false;
          }


        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(TransactionApi.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetUserFieldObj.pending, (state, action) => {
        state.isUserFieldLoading = true;
        // state.hasError = false;
      })
      .addCase(GetUserFieldObj.fulfilled, (state, action) => {
        console.log("state.UserField =>",action.payload);
        state.UserField = action.payload;
        state.isUserFieldLoading = false;
        // state.hasError = false;
      })
      .addCase(GetUserFieldObj.rejected, (state, action) => {
        state.isUserFieldLoading = false;
        // state.hasError = true;
      })
      .addCase(BillFormChallanPartyAc.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(BillFormChallanPartyAc.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.BillFormChallanPartyAcData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(BillFormChallanPartyAc.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
  },
});

export const { GetAccountListObject,GetNullTransactionData } = Transaction.actions;
export default Transaction.reducer;
